import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React from 'react';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { useAuth } from '../../../components/AuthContext';
import { helpCircleOutline } from 'ionicons/icons';

const Admission: React.FC = () => {
    const {t} = useTranslation();
    const {authInfo} = useAuth()!;
 
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("admissions")}</IonTitle>
                    {!isPlatform('mobile') && (
                        <IonButtons slot="end">
                            <ThemeToggle />
                            <LanguageToggle />
                        </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("admissions")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid className='mb-60'>

                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol>
                            
                            <IonRow>
                                <IonCol>
                                    <IonTitle id="open-setup-info" className='border-bottom-sec'>
                                        {t("admn_setup")}
                                    <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-setup-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("setup_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                {(authInfo.user.role === "Admin") && (
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/config/institute`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("basic_info")} src="assets/images/institute.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("basic_info")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                )}
                                {(authInfo.institute.type === "school") && (
                                <>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/admission/school/stats`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("key_stats")} src="assets/images/institute.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("key_stats")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/admission/school/facilities`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("facilities")} src="assets/images/item.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("facilities")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                </>
                                )}
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/admission/form`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("admn_form")} src="assets/images/form.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("admn_form")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/admission/class`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={(authInfo.institute.type === "school") ? t("admn_classes") : t("admn_courses")} src="assets/images/class.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{(authInfo.institute.type === "school") ? t("admn_classes") : t("admn_courses")} </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/admission/school/gallery`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("picture_gallery")} src="assets/images/gallery.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("picture_gallery")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                               

                            </IonRow>
                            <IonRow >
                                <IonCol>
                                    <IonTitle id="open-applications-info" className='border-bottom-sec'>
                                        {t("applications")}
                                    <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-applications-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("applications_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">

                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/admission/applications/board`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("applications_board")} src="assets/images/board-1.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("applications_board")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                               
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/admission/applications`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("pending_apps")} src="assets/images/registration-1.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("pending_apps")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/admission/applications/history`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("apps_history")} src="assets/images/registration-1.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("apps_history")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/admission/appointments`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("view_appointments")} src="assets/images/planner-1.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("view_appointments")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/admission/appointments/history`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("appointments_history")} src="assets/images/planner-1.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("appointments_history")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/admission/list`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("admn_list")} src="assets/images/form-1.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("admn_list")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/admission/reports`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                            <IonRow className="ion-padding-top">
                                                <IonCol className="ion-no-padding">
                                                    <img alt={t("admn_reports")} src="assets/images/reports-1.png"/>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("admn_reports")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>

                            </IonRow>
                           
                           
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Admission;