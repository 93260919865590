import {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonAlert,
    IonCard,
    IonCardContent,
    IonLoading,
    IonText,
    IonIcon
} from '@ionic/react';
import { callOutline, lockClosedOutline, mailOutline, personOutline } from 'ionicons/icons';
import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {useAuth} from "../../components/AuthContext";
import { useTranslation } from "react-i18next";

const SignUp: React.FC = () => {
    const {register, logIn} = useAuth()!;
    const history = useHistory();
    const {t, i18n} = useTranslation();
    const [name, setName] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [newPass, setNewPass] = useState < string > ("");
    const [confirmPass, setConfirmPass] = useState < string > ("");
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);

    const validateEmail = (email : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    }

    const validatePhone = (phone : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (newPass !== confirmPass)
        {
            setMessage(t("password_mismatch"));
            setIserror(true);
            setShowLoading(false);
            return;
        }

        if (!newPass || newPass.length < 8) {
            setMessage(t("invalid_password"));
            setIserror(true);
            setShowLoading(false);
            return;
        }

        if (validateEmail(email) === false) {
            setMessage(t("invalid_email"));
            setIserror(true);
            return;
        }

        if (validatePhone(phone) === false) {
            setMessage(t("invalid_mobile"));
            setIserror(true);
            return;
        }

        setShowLoading(true);

        register(email, newPass, name, phone, 'Admission', 'Admission').then(res => {

                logIn(email, newPass).then(res => {
                    history.push("/app");
                    setShowLoading(false);
                }).catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("unable_to_connect"));
                    setIserror(true);
                    setShowLoading(false);
                });
            
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("unable_to_connect"));
                setIserror(true);
                setShowLoading(false);
            }); 
    
      }

    return (
        <IonPage>

            <IonContent fullscreen={true}
                className="ion-text-center ion-padding grey-shade">

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />


                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                    
                <IonGrid className="ion-no-padding">
                   <IonRow>
                        <IonCol offsetXl="4" offsetLg="3" offsetSm="2" offset="0" sizeXl="4" sizeLg="6" sizeSm="8" size="12">

                                <IonRow className="mt-30">
                                    <IonCol className="ion-text-center">
                                        <img alt="Logo" src="assets/images/logo-trans.png" width="160" height="160" onClick={
                                                        () => history.push(`/home`)
                                                    }/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-text-center">
                                        <IonText className="auth-title">{i18n.language === "hi" ? process.env.REACT_APP_NAME_HI : process.env.REACT_APP_NAME}</IonText>
                                    </IonCol>
                                </IonRow>
                                     
                                <IonRow className="ion-justify-content-center mt-30">
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    <IonIcon slot="start"
                                                    icon={personOutline}
                                                    color="primary" 
                                                    className='mt-20'/>
                                                    <IonLabel position="stacked" className="auth-label">
                                                    {t("signup_name")}</IonLabel>
                                                    <IonInput type="text"
                                                        value={name}
                                                        className="auth-input"
                                                        placeholder={t("student_name")}
                                                        onIonInput={
                                                            (e) => setName(e.detail.value!)
                                                    }></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>

                                <IonRow className="ion-justify-content-center">
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    <IonIcon slot="start"
                                                    icon={mailOutline}
                                                    color="primary" 
                                                    className='mt-20'/>
                                                    <IonLabel position="stacked" className="auth-label">
                                                    {t("registered_email")}</IonLabel>
                                                    <IonInput type="text"
                                                        value={email}
                                                        className="auth-input"
                                                        placeholder={t("email")}
                                                        onIonInput={
                                                            (e) => setEmail(e.detail.value!)
                                                    }></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    <IonIcon slot="start"
                                                    icon={callOutline}
                                                    color="primary" 
                                                    className='mt-20'/>
                                                    <IonLabel position="stacked" className="auth-label">
                                                    {t("signup_mobile")}</IonLabel>
                                                    <IonInput type="text"
                                                        value={phone}
                                                        className="auth-input"
                                                        placeholder={t("phone")}
                                                        onIonInput={
                                                            (e) => setPhone(e.detail.value!)
                                                    }></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    <IonIcon slot="start"
                                                        icon={lockClosedOutline}
                                                        color="primary" 
                                                        className='mt-20'/>
                                                    <IonLabel position="stacked" className="auth-label">
                                                    {t("signup_pass")}</IonLabel>
                                                    <IonInput type="password"
                                                        value={newPass}
                                                        placeholder={t("your_password")}
                                                        className="auth-input"
                                                        onIonInput={
                                                            (e) => setNewPass(e.detail.value!)
                                                    }></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    <IonIcon slot="start"
                                                        icon={lockClosedOutline}
                                                        color="primary" 
                                                        className='mt-20'/>
                                                    <IonLabel position="stacked" className="auth-label">
                                                    {t("signup_confirm")}</IonLabel>
                                                    <IonInput type="password"
                                                        value={confirmPass}
                                                        placeholder={t("confirm_pass")}
                                                        className="auth-input"
                                                        onIonInput={
                                                            (e) => setConfirmPass(e.detail.value!)
                                                    }
                                                    onKeyUp={(e) => (e.key === 'Enter') && handleSubmit()}></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>

                                <IonRow className="ion-justify-content-center mt-30">
                                    <IonCol className='ion-padding'>
                                        <IonButton expand="block" onClick={handleSubmit}>{t("signup")}</IonButton>
                                        <p>
                                            <Link to="/login" className="auth-link" >  {t("already_account")}</Link>
                                        </p>
                                    </IonCol>
                                </IonRow>
                               
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default SignUp;
