import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useAuth} from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface Student {
    _id: string,
    name: string,
    attends: Attendance[]
}

interface Attendance {
    status: string,
    dated: Date
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

interface ParamTypes {
    classID?: string
}

const AttendClass: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {classID} = useParams < ParamTypes > ();
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [students, setStudents] = useState < Student[] > ([]);
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [session, setSession] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();
    const [days, setDays] = useState < number > (0);
    const [month, setMonth] = useState < number > (0);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string")
        {
            const monthNames = [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"),
            t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")
        ];

            const valueDate = new Date(value);
            return monthNames[valueDate.getMonth()] +' - '+ valueDate.getFullYear();
        }
        
      };

      const formatStatus = (studentID: string, day: number) => {

        if (typeof selectedDate === "string")
        {
            const onDate = new Date(selectedDate);
            const d = new Date(onDate.getFullYear(), onDate.getMonth(), day);

            if (authInfo.institute.type === "school" && d.getDay() === 0)
            {
                return "S";
            }

            const attendances = students.find(val => val._id === studentID)!.attends;
            const attend = attendances.find(val=> new Date(val.dated).getDate() === day);
           
            if (attend)
            {
                if (attend.status === "present")
                {
                    return "P";
                }
                else if (attend.status === "absent")
                {
                    return "A";
                }
            }
            else
            {
                return " ";
            }

        }
      };

      const handleSession = (sessionID : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionID}`);
                setClasses(result.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setSession(sessionID);
        setClassi("");
        setStudents([]);
        setSelectedDate(undefined);
    }

    const handleClassi = (classID: string) => {
        setClassi(classID);

        const dated = new Date(Date.now()).toISOString();
        setSelectedDate(dated);

        const onDate = new Date(dated);
        const d = new Date(onDate.getFullYear(), onDate.getMonth()+1, 0);
        setDays(d.getDate());
        setMonth(onDate.getMonth()+1);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/attends/getMonthClass/${classID}/${dated}`);
                setStudents(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
    }

    const handleSubmit = (dated: string | string[]) => {

        if (!classi || classi === "") {
            setMessage((authInfo.institute.type === "school") ? t("select_class") : t("select_batch"));
            setIserror(true);
            return;
        }
   
        if (!(typeof(dated) === "string")) {
            setMessage(t("select_month"));
            setIserror(true);
            return;
        }

        setSelectedDate(dated);
        const onDate = new Date(dated);

        const d = new Date(onDate.getFullYear(), onDate.getMonth()+1, 0);
        setDays(d.getDate());
        setMonth(onDate.getMonth()+1);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/attends/getMonthClass/${classi}/${dated}`);
                setStudents(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }

    useEffect(() => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                {
                    const classy = await api.get(`/classes/getByTeacher/${authInfo.user._id}`);
                    setClasses(classy.data);
                }
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                    setSessions(res.data);
                }

                if (classID) 
                {
                    const resu = await api.get('/classes/' + classID);
                    setSession(resu.data.sessionID);
                    const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${resu.data.sessionID}`);
                    setClasses(result.data);
                    setClassi(classID);

                    const dated = new Date(Date.now()).toISOString();
                    setSelectedDate(dated);

                    const onDate = new Date(dated);
                    const d = new Date(onDate.getFullYear(), onDate.getMonth()+1, 0);
                    setDays(d.getDate());
                    setMonth(onDate.getMonth()+1);

                    const studs = await api.get(`/attends/getMonthClass/${classID}/${dated}`);
                    setStudents(studs.data);
                }

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, classID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/student`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{(authInfo.institute.type === "school") ? t("class_attendance") : t("batch_attendance")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{(authInfo.institute.type === "school") ? t("class_attendance") : t("batch_attendance")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-md">
                    {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic"))) && (
                      <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("class_session")}</IonLabel>
                                            <IonSelect value={session}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => handleSession(e.detail.value)
                                                }>

                                                    {sessions.map((session) => 
                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                    )}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    )}
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {(authInfo.institute.type === "school") ? t("class") : t("batch")}</IonLabel>
                                            <IonSelect value={classi}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                    onIonChange={
                                                        (e) => handleClassi(e.detail.value)
                                                }>
                                                     
                                                    {classes.map((classy) => (
                                                        (classy.isActive) &&
                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name} ({classy.year})</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
    
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">{t("month")}</IonLabel>
                                            <IonInput className="input-field" value={(!selectedDate || selectedDate === '') ? t("pick_month") : formatDate(selectedDate)} readonly={true} />
                                            <IonPopover trigger="open-date-input" showBackdrop={false} size='cover'>
                                                <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("ok")}
                                                    value={selectedDate}
                                                    presentation="month-year"
                                                    onIonChange={ev => ev.detail.value && handleSubmit(ev.detail.value)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonPopover>
                                        </IonItem>

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding mt-30 mb-60 overflow-x">
                    <div className="ion-table min-xxxl">

                        <IonRow className="table-title ion-padding">

                            <IonCol size="1" className='table-heading heading-fixed ion-padding-start'>
                                {t("name")}
                            </IonCol>
                            {Array(days).fill(1).map((el, j) =>
                                <IonCol key={j} size="0.35" className='table-heading pl-01'>
                                    {j+1+"/"+month}
                                </IonCol>
                            )}
                            
                        </IonRow>
  

                        {(students.length > 0) ? (students.map((student, i) => {
                            return (
                                <IonRow key={student._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal ion-padding-start' : 'row-table-alt ion-padding-horizontal ion-padding-start'}>
                                    
                                        <IonCol size="1" className={((i%2) === 0) ? 'table-field-no-margin ion-padding field-fixed text-capitalize' : 'table-field-no-margin ion-padding field-fixed-alt text-capitalize'} >
                                            {student.name}
                                        </IonCol>
                                        {Array(days).fill(1).map((el, k) => {
                                            if(formatStatus(student._id, k+1) === "P")
                                            {
                                                return (<IonCol key={k} size="0.35" className='table-field-no-margin border-left ion-padding text-bold success-field'>
                                                        P</IonCol>);
                                            }
                                            else if(formatStatus(student._id, k+1) === "A")
                                            {
                                                return (<IonCol key={k} size="0.35" className='table-field-no-margin border-left ion-padding text-bold danger-field'>
                                                        A</IonCol>);
                                            }
                                            else if(formatStatus(student._id, k+1) === "S")
                                            {
                                                return (<IonCol key={k} size="0.35" className='table-field-no-margin border-left ion-padding text-bold warning-field'>
                                                S</IonCol>);
                                            }
                                            else
                                            {
                                                return (<IonCol key={k} size="0.35" className='table-field-no-margin border-left ion-padding text-bold'>
                                                </IonCol>);
                                            }
                                            
                                        }
                                            
                                        )}
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 

                    </div>

                </IonGrid>

            </IonContent>
           
        </IonPage>
    );
};

export default AttendClass;