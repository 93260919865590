import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, construct, checkboxOutline} from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useAuth} from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../components/usePageUnloadHandler';

interface Employee {
    _id: string,
    userInfo: UserInfo,
    dob: Date,
    gender: string,
    isActive: boolean
}
interface UserInfo {
    userID: string,
    name: string,
    role: string
}
interface Department {
    _id: string,
    name: string
}

interface ParamTypes {
    depID?: string
}

const Employees: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {depID} = useParams < ParamTypes > ();
    const [employees, setEmployees] = useState < Employee[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [searchText, setSearchText] = useState < string > ("");
    const [gender, setGender] = useState < string > ("all");
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [current, setCurrent] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);
    const [department, setDepartment] = useState < string > ("all");
    const [departments, setDepartments] = useState < Department[] > ([]);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem) {
            setScrollPosition(elem.scrollTop);
        }
    };

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleSubmit = () => {

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }
        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/employees/listInstitute/${authInfo.user.instituteID}`, {gender, department, toDOB, fromDOB, searchText});
                setEmployees(result.data.employees);
                setTotal(result.data.total);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };
        fetchUp();
    }

      const loadItems = async () => {

        let limit = skip + 30;
       
        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof toDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }
        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        try {
            const result = await api.post(`/employees/listInstitute/${authInfo.user.instituteID}?skip=${limit}`, {gender, department, toDOB, fromDOB, searchText});

            if(result.data.employees && result.data.employees.length)
            {
                setEmployees([...employees, ...result.data.employees]);
                setSkip(limit);
            }

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { employees, total, skip, departments, gender, department, toDate, fromDate, searchText, scrollPosition } = JSON.parse(storedState);
                // Data is present in local storage, use it to initialize state
                setEmployees(employees);
                setDepartments(departments);
                setTotal(total);
                setSkip(skip);
                setGender(gender);
                setDepartment(department);
                setToDate(toDate);
                setFromDate(fromDate);
                setSearchText(searchText);
               
                setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
            } 
            else 
            {

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {

                        const result = await api.get(`/departments/getAllInstitute/${authInfo.user.instituteID}`);
                        setDepartments(result.data);

                        if (depID)
                        {
                            setDepartment(depID);
                            
                            const result = await api.post(`/employees/listInstitute/${authInfo.user.instituteID}`, {gender: "all", department: depID, toDOB: "", fromDOB: "", searchText: ""});
                            setEmployees(result.data.employees);
                            setTotal(result.data.total);
                        }
                        else
                        {
                            const result = await api.post(`/employees/listInstitute/${authInfo.user.instituteID}`, {gender: "all", department: "all", toDOB: "", fromDOB: "", searchText: ""});
                            setEmployees(result.data.employees);
                            setTotal(result.data.total);
                            setDepartment("all");
                        }
                        setSkip(0);

                    } catch (error: any) {
                        setMessage(error.response?.data.message || t("something_went_wrong"));
                        setIserror(true);
                    } finally {
                        setShowLoading(false);
                    }
                    
                };

                fetchUp();
            }
     
        setInitialRender(false);

    }, [authInfo, depID, history.action, history.location.pathname]);

     // Save state to local storage whenever it changes
     useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            employees,
            departments,
            total,
            skip,
            gender,
            department,
            toDate,
            fromDate,
            searchText,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));

        
        }
    }, [employees, departments, total, skip, gender, department, toDate, fromDate, searchText, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_employees")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_employees")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-xl">
                       
                        <IonCol size="3">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                        
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("search_employee")}
                                            </IonLabel>

                                        <IonInput type="text"
                                            value={searchText}
                                            placeholder={`\uD83D\uDD0D\u00A0\u00A0\u00A0${t("search_employee")}`}
                                            onIonInput={
                                                (e) => setSearchText(e.detail.value!)
                                        } />
                                                
                                     
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>

                                <IonCol size="2">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("employee_dep")}
                                                </IonLabel>
                                                    <IonSelect value={department}
                                                            placeholder={t("employee_dep")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => setDepartment(e.detail.value)
                                                        }>
                                                            <IonSelectOption value="all">{t("all")}</IonSelectOption>                                           
                                                            {departments.length > 0 && departments.map((dep) => (
                                                                        (<IonSelectOption key={dep._id} value={dep._id}>{dep.name}</IonSelectOption>)
                                                                    ))}

                                                            
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>

                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("employee_gender")}
                                            </IonLabel>
                                            <IonSelect value={gender}
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                placeholder={t("employee_gender")}
                                                onIonChange={
                                                    (e) => setGender(e.detail.value)
                                                }>
                                                <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                <IonSelectOption value="male">{t("male")}</IonSelectOption>
                                                <IonSelectOption value="female">{t("female")}</IonSelectOption> 
                                                
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("dob_from")}
                                            </IonLabel>
                                            <IonInput value={formatDate(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("dob_from")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("dob_to")}
                                            </IonLabel>
                                            <IonInput value={formatDate(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("dob_to")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                        
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_employees")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">

                            <IonCol sizeLg="4" size="8" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                />
                                <IonText className='ion-margin-start'>{t("employee_name")}</IonText>
                            </IonCol>
                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                            {t("employee_role")}
                            </IonCol>
                            <IonCol sizeLg="2" size="3" className='table-heading'>
                            {t("dob")}
                            </IonCol>
                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("employee_gender")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                            (e) => setCurrent(e.detail.value!)
                                    }>

                        {(employees.length > 0) ? (employees.map((employee, i) => {
                            return (
                                <IonRow key={employee._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                onClick={
                                    () => setCurrent(employee._id)
                                }
                                onDoubleClick={
                                    () => history.push(`/app/employee/${employee._id}`)
                                }>
                                    
                                        <IonCol sizeLg="4" size="8" className='table-field'>
                                            <IonRow>
                                                <IonCol>
                                                    <IonRadio value={employee._id} className='table-icon' color="secondary" />
                                                    <IonText className='ion-margin-start text-capitalize'>{employee.userInfo.name}</IonText>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='ion-hide-lg-up vid-responsive-field text-capitalize'>
                                                <IonCol>
                                                    <IonLabel>
                                                        {employee.userInfo.role} &#8226; {employee.gender}
                                                    </IonLabel>
                                                        
                                                </IonCol>
                                            </IonRow>
                                            
                                        </IonCol>
                                        <IonCol sizeLg="3" size="0" className='table-field ion-hide-lg-down'>
                                            {employee.userInfo.role}
                                        </IonCol>
                                        <IonCol sizeLg="2" size="3" className='table-field'>
                                            {formatDated(employee.dob)}
                                        </IonCol>
                                        <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down'>
                                            {t(employee.gender)}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color={employee.isActive ? "dark" : "danger"}
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/employee/${employee._id}`)
                                                }
                                            />
                                            
                                        </IonCol>
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_employees")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 
                    </IonRadioGroup>
                    </div>

                    {(employees.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                   </IonRow>
                    }
                    
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                {t("showing")} {total === 0 ? t("no_records") : `1 - ${employees.length} ${t("of")} ${total}`} 
                                </IonCol>
                            </IonRow>
                                <IonRow className="ion-padding-vertical overflow-x">
                                    <IonCol className='min-xl'>
                            
                                        <IonButton 
                                            onClick={
                                                () => {
                                                    if (current === "")
                                                    {
                                                        setMessage(t("select_employee"));
                                                        setIserror(true);
                                                    }
                                                    else
                                                    {
                                                        history.push(`/app/employee/${current}`)
                                                    }

                                                }
                                            }
                                            className="first-button ion-margin-start"
                                            fill="clear"
                                            >{t("manage_employee")}</IonButton>

                                    {authInfo.institute.task && (
                                        <>
                                        <IonButton 
                                            onClick={
                                                () => {
                                                    if (current === "")
                                                    {
                                                        setMessage(t("select_employee"));
                                                        setIserror(true);
                                                    }
                                                    else
                                                    {
                                                        history.push(`/app/tasks/employee/${current}`)
                                                    }

                                                }
                                            }
                                            className="first-button ion-margin-start"
                                            fill="clear"
                                            >{t("view_tasks")}</IonButton>

                                        <IonButton 
                                            onClick={
                                                () => {
                                                    if (current === "")
                                                    {
                                                        setMessage(t("select_employee"));
                                                        setIserror(true);
                                                    }
                                                    else
                                                    {
                                                        history.push(`/app/reports/employee/${current}`)
                                                    }

                                                }
                                            }
                                            className="first-button ion-margin-start"
                                            fill="clear"
                                            >{t("view_reports")}</IonButton>
                                        </>)}
                                        
                                        <IonButton 
                                            onClick={
                                                () => {
                                                    if (current === "")
                                                    {
                                                        setMessage(t("select_employee"));
                                                        setIserror(true);
                                                    }
                                                    else
                                                    {
                                                        history.push(`/app/employee/attendance/${current}`)
                                                    }

                                                }
                                            }
                                            className="first-button ion-margin-start"
                                            fill="clear"
                                            >{t("view_attendance")}</IonButton>

                                    {authInfo.institute.communication && (
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (current === "")
                                                {
                                                    setMessage(t("select_employee"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/chats/employee/${current}`)
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_communications")}</IonButton>
                                    )}  

                                        {authInfo.institute.payroll && (
                                        <>
                                        <IonButton 
                                            className="first-button ion-margin-start"
                                            fill="clear"
                                            >{t("view_ledger")}</IonButton>

                                        <IonButton 
                                            className="first-button ion-margin-start"
                                            fill="clear"
                                            >{t("map_salary_structure")}</IonButton>
                                        </>
                                        )}
                                        
                                    <IonButton 
                                            className="first-button ion-margin-start"
                                            fill="clear"
                                            >{t("export_excel")}</IonButton>

                                </IonCol>
                            </IonRow>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Employees;