import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';


const Notifications: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [transportNotif, setTransportNotif] = useState < boolean > (true);
    const [communicationNotif, setCommunicationNotif] = useState < boolean > (true);
    const [attendNotif, setAttendNotif] = useState < boolean > (true);
    const [paymentNotif, setPaymentNotif] = useState < boolean > (true);
    const [admissionNotif, setAdmissionNotif] = useState < boolean > (true);
    const [academicsNotif, setAcademicsNotif] = useState < boolean > (true);
    const [inventoryNotif, setInventoryNotif] = useState < boolean > (true);
    const [staffNotif, setStaffNotif] = useState < boolean > (true);
    
    const handleSubmit = () => {

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upInstitute = () => {
          return new Promise((resolve, reject) => {
            api.put('/institutes/'+authInfo.user.instituteID, { transportNotif, communicationNotif, paymentNotif, attendNotif, admissionNotif, academicsNotif, inventoryNotif, staffNotif }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }
    
        setShowLoading(true);
      upInstitute()
        .then(data => {
  
            setPass(data+t("has_been_updated"));
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("something_went_wrong"));
          setIserror(true);
          setShowLoading(false);
        });
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/institutes/' + authInfo.user.instituteID);

                setCommunicationNotif(result.data.communicationNotif);
                setTransportNotif(result.data.transportNotif);
                setPaymentNotif(result.data.paymentNotif);
                setAttendNotif(result.data.attendNotif);
                setAdmissionNotif(result.data.admissionNotif);
                setAcademicsNotif(result.data.academicsNotif);
                setInventoryNotif(result.data.inventoryNotif);
                setStaffNotif(result.data.staffNotif);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/communication`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("notif_settings")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("notif_settings")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding">
                  
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("notif_categories")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonList className="ion-no-padding">

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">{t("attendance")}</IonLabel>
                                            <IonToggle checked={attendNotif} onIonChange={e => setAttendNotif(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">{t("academics")}</IonLabel>
                                            <IonToggle checked={academicsNotif} onIonChange={e => setAcademicsNotif(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">{t("communications")}</IonLabel>
                                            <IonToggle checked={communicationNotif} onIonChange={e => setCommunicationNotif(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">{t("accounting")}</IonLabel>
                                            <IonToggle checked={paymentNotif} onIonChange={e => setPaymentNotif(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">{t("transport")}</IonLabel>
                                            <IonToggle checked={transportNotif} onIonChange={e => setTransportNotif(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">{t("inventory")}</IonLabel>
                                            <IonToggle checked={inventoryNotif} onIonChange={e => setInventoryNotif(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">{t("admissions")}</IonLabel>
                                            <IonToggle checked={admissionNotif} onIonChange={e => setAdmissionNotif(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">{t("employee_tasks")}</IonLabel>
                                            <IonToggle checked={staffNotif} onIonChange={e => setStaffNotif(e.detail.checked)} />
                                        </IonItem>
                                        
                                    </IonList>
                              
                        </IonCol>
                    </IonRow>
                 
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_settings")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Notifications;
