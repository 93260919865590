import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, bus, cart, create, idCard, megaphone, school, ticket } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../components/AuthContext';
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';

interface Notification {
    _id: string,
    title: string,
    text: string,
    type: string,
    routerLink?: string,
    createdAt: Date
}

const UserNotifications: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const [notifications, setNotifications] = useState < Notification[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const getDate = (utcdate: Date) => {
        const bookDate = new Date(utcdate);
        return bookDate.toLocaleDateString('en-GB') +' at '+ bookDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    }

    const loadItems = async () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip + 30;
       
        try {
            const result = await api.get(`/notifications/getByUser/${authInfo.user._id}?skip=${limit}`);

            if(result.data && result.data.length)
            {
                setNotifications([...notifications, ...result.data]); 
            }
                
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
                else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const notif = await api.get(`/notifications/getByUser/${authInfo.user._id}`);
                setNotifications(notif.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("notifications")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="white-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("notifications")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>


                <IonGrid className="ion-no-padding mb-60">
                  
                    <IonRow>
                        <IonCol>
                          <IonList className="ion-no-padding">
                             {(notifications.length > 0) && (notifications.map((note, i) => {
                                    return (
                                        <IonItem detail={(note.routerLink && (note.routerLink !== "")) ? true : false}
                                            lines='full'
                                            button={(note.routerLink && (note.routerLink !== "")) ? true : false}
                                            key={note._id}
                                            className='transact-item'
                                            onClick={(note.routerLink && (note.routerLink !== "")) ? () => history.push(note.routerLink!) : () => null}
                                           >
                                            <IonIcon slot="start"
                                                icon={note.type === "transport" ? bus : note.type === "communication" ? megaphone : note.type === "payment" ? ticket : note.type === "admission" ? idCard : note.type === "inventory" ? cart : note.type === "staff" ? create : school}
                                                color={note.type === "transport" ? "warning" : note.type === "communication" ? "success" : note.type === "payment" ? "danger" : note.type === "admission" ? "tertiary" : note.type === "inventory" ? "primary" : note.type === "staff" ? "tertiary" : "secondary"}
                                                />
                                            <IonLabel className="transact-label ion-text-wrap">
                                                <h3>{note.title}</h3>
                                                <p>{note.text}</p>
                                                <p>{note.createdAt && getDate(note.createdAt)}</p>
                                            </IonLabel>
                                           
                                        </IonItem>
                                    )
                                }))
                                } 
                            </IonList>

                            {(notifications.length === 0) && (
                                <IonCard className='red-card ion-margin'>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger"
                                                size="large" />
                                            <IonLabel className="list-title"><h3>{t("no_notifications")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                                )}
                        </IonCol>
                    </IonRow>
                    {(notifications.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default UserNotifications;
