import {
  IonRouterOutlet,
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import DriverHome from "../pages/Home/DriverHome";
import News from "../pages/Employee/News/News";
import NewsDetail from "../pages/Employee/News/NewsDetail";

import OngoingTrip from "../pages/Driver/OngoingTrip";
import CompletedTrips from "../pages/Driver/CompletedTrips";
import RouteDetail from "../pages/Driver/RouteDetail";
import TripDetail from "../pages/Driver/TripDetail";

import MyTasks from "../pages/Employee/Task/MyTasks";
import DailyReports from "../pages/Employee/Task/DailyReports";
import MyChats from "../pages/Employee/Chat/MyChats";
import AddChat from "../pages/Employee/Chat/AddChat";
import ViewChat from "../pages/Employee/Chat/ViewChat";

import MyAttendance from "../pages/Employee/Attendance/MyAttendance";
import MyLeaves from "../pages/Employee/Attendance/MyLeaves";
import AddLeave from "../pages/Employee/Attendance/AddLeave";
import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdatePassword from "../pages/User/UpdatePassword";
import Terms from "../pages/Policies/TermsAndConditions";
import Privacy from "../pages/Policies/PrivacyPolicy";
import UserNotifications from "../pages/User/UserNotifications";
import ContactUs from "../pages/General/ContactUs";
import DeleteAccount from "../pages/General/DeleteAccount";

const DriverTabs: React.FC = () => {
  return (
      <IonRouterOutlet>
        <Switch>
            <Route path="/app/home" exact={true} component={DriverHome} />

            <Route path="/app/news" exact={true} component={News} />
            <Route path="/app/news/:newsID" exact={true} component={NewsDetail} />

            <Route path="/app/route/preview/:routeID" exact={true} component={RouteDetail} />
            <Route path="/app/trips/completed" exact={true} component={CompletedTrips} />
            <Route path="/app/trip/ongoing/:tripID" exact={true} component={OngoingTrip} />
            <Route path="/app/trip/detail/:tripID" exact={true} component={TripDetail} />

            <Route path="/app/user/tasks" exact={true} component={MyTasks} />
            <Route path="/app/user/task/:chatID" exact={true} component={ViewChat} />
            <Route path="/app/user/reports" exact={true} component={DailyReports} />
            <Route path="/app/user/chats" exact={true} component={MyChats} />
            <Route path="/app/user/chat" exact={true} component={AddChat} />
            <Route path="/app/user/chat/:chatID" exact={true} component={ViewChat} />

            <Route path="/app/user/attendance" exact={true} component={MyAttendance} />
            <Route path="/app/user/leaves" exact={true} component={MyLeaves} />
            <Route path="/app/user/leaves/add" exact={true} component={AddLeave} />
            <Route path="/app/user/notification" exact={true} component={UserNotifications} />
            <Route path="/app/user/profile" exact={true} component={Profile} />
            <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
            <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />
            <Route path="/app/policies/terms" exact={true} component={Terms} />
            <Route path="/app/policies/privacy" exact={true} component={Privacy} />
            <Route path="/app/support/contact" exact={true} component={ContactUs} />
            <Route path="/app/support/delete" exact={true} component={DeleteAccount} />
            
            <Redirect exact={true} path="/app" to="/app/home" />
            <Redirect from="*" to="/app"/>
          </Switch>
      </IonRouterOutlet>
  );
};

export default DriverTabs;
