import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, chatboxEllipses, checkboxOutline, informationCircle } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface Inquiry {
    _id: string,
    source: string,
    type: string,
    instituteInfo: Institute,
    status: string,
    messages: Message[],
    createdAt: Date
}

interface Message {
    timestamp: Date
}

interface Institute {
    instituteID: string,
    name: string
}

const AdmnInquiries: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const loadItems = async () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip+30;

        try {
            const result = await api.get(`/admissionInquiries/getByUser/${authInfo.user._id}?skip=${limit}`);
            if(result.data.inquiries && result.data.inquiries.length)
            {
                setInquiries([...inquiries, ...result.data.inquiries]);
            }
              
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
                else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const result = await api.get(`/admissionInquiries/getByUser/${authInfo.user._id}`);
                setInquiries(result.data.inquiries);
                setTotal(result.data.total);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("my_admn_inquiries")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("my_admn_inquiries")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>


                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="vid-table-title ion-padding">
                            <IonCol size="8" sizeLg="5" className='vid-table-heading'>
                               {t("institute")}
                            </IonCol>
                            <IonCol size="0" sizeLg="3" className='vid-table-heading ion-hide-lg-down'>
                            {t("type")}
                            </IonCol>
                            <IonCol size="3" className='vid-table-heading'>
                            {t("raised_on")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={chatboxEllipses}
                                    color="light"
                                    className='table-icon'
                                />
                            </IonCol>
                        </IonRow>

                            {(inquiries.length > 0) ? (inquiries.map((inquiry, i) => {
                                return (
                                    <IonRow key={inquiry._id} className={((i%2) === 0) ? 'row-table-alt ion-padding-horizontal' : 'row-table ion-padding-horizontal'}
                                    onClick={
                                            () => history.push(`/app/admission/inquiry/${inquiry._id}`)
                                        }
                                    >
                                        
                                            <IonCol size="8" sizeLg="5" className='table-field'>
                                                <IonRow>
                                                    <IonCol>
                                                        {inquiry.instituteInfo.name}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field-1 text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel>
                                                            {inquiry.type} &#8226; {inquiry.source}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>
                                                
                                            </IonCol>
                                            
                                            <IonCol size="0" sizeLg="3" className='table-field ion-hide-lg-down'>
                                                {inquiry.type}
                                            </IonCol>
                                            <IonCol size="3" className='table-field'>
                                                {formatDate(inquiry.createdAt)}
                                            </IonCol>
                                            <IonCol size="1" className='table-field'>
                                                <IonBadge className=''>{inquiry.messages.length+1}</IonBadge>
                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_inquiries")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                    </div>

                    {(inquiries.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow className='max-lg'>
                        <IonCol size="6" className="ion-padding footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${inquiries.length} ${t("of")} ${total}`} 
                        </IonCol>
                        <IonCol size="6" className="ion-padding-horizontal">

                            <IonButton 
                                onClick={
                                    () => history.push(`/app/admission/all`)
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("inquire")}
                            </IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AdmnInquiries;