import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSkeletonText,

    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, lockClosed, playCircle } from 'ionicons/icons';
import React, {useEffect,  useState} from 'react';
import {useAuth} from '../../components/AuthContext';
import {useParams} from 'react-router-dom';


interface Chapter {
    chapterID: string,
    chapterName: string
}

interface Topic {
    _id: string,
    name: string,
    video?: string,
    chapterInfo: Chapter,
    isActive: boolean,
    order: number
}

interface ParamTypes {
    chapterID: string
}

const Topics: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {chapterID} = useParams < ParamTypes > ();
    const [topics, setTopics] = useState < Topic[] > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [name, setName] = useState < string > ("");

    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }
    
    useEffect(() => {

        setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            try {
                const tops = await api.get(`/topics/getByChapter/${chapterID}`);

                if (tops.data.length !== 0)
                {
                    setTopics(tops.data);
                    setName(tops.data[0].chapterInfo.chapterName);
                }
                else
                {
                    setName("Chapter's Topics");
                }
                

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, refreshed, chapterID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle color="secondary">Topics</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Topics</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding">

                    <IonRow className='mt-30'>
                        <IonCol>
                            <IonTitle className='title-heading'>
                                {
                                name ? (name) : 
                                ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                                }
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                   
                        {topics && (topics.length > 0) ? (

                   
                            <IonCard className='ion-no-margin ion-no-padding'>
                                <IonCardContent className='ion-no-margin ion-no-padding'>
                                    <IonList className='ion-no-margin ion-no-padding'>
                                    {topics.map((topic) => {
                                        if (topic.isActive)
                                        {
                                            return ( <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        key={topic._id}
                                                        className="topic-item"
                                                        >

                                                            <IonIcon
                                                                slot="start"
                                                                className='tran-icon'
                                                                icon={lockClosed}
                                                                color="danger"
                                                                />

                                                        <IonLabel className='topic-label'>
                                                            <h3>{topic.name}</h3>
                                                            <p>5 Mins &#8226; Test Not Taken</p>
                                                        </IonLabel>
                                                           
                                                        <IonButtons slot="end">
                                                            <IonButton onClick={() => topic.video ? (window.location.href = topic.video) : (null)}> 
                                                                <IonIcon
                                                                className='topic-icon'
                                                                icon={playCircle}
                                                                color={topic.video ? "secondary" : "dark"}
                                                                />
                                                            </IonButton>
                                                           
                                                        </IonButtons>
                                                    
                                                        </IonItem>
                                                    )
                                        }
                                        else
                                        {
                                            return null;
                                        }
                                    })}
                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        
                            ) : ( 

                                <IonCard className='action-card-b'>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                            slot="start" 
                                            color="danger" />
                                            <IonLabel className="list-label"><h3>No topics available</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            
                            )
                        } 
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Topics;
