import {
    IonAlert,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useAuth} from "../../components/AuthContext";
import { useHistory } from 'react-router-dom';

const ContentHome: React.FC = () => {
    const {authInfo, logOut} = useAuth()!;
    const history = useHistory();
    const [isexpired, setIsexpired] = useState < boolean > (false);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [isold, setIsold] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchVersion = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/version-check`);
                if (parseInt(result.data) > parseInt(process.env.REACT_APP_VERSION!))
                {
                    setIsold(true);
                }
                setShowLoading(false);

            } catch (error: any) {  
                if (error.response !== undefined) 
                {
                    if (error.response.data.message === 'expired')
                    {
                        setIsexpired(true);
                    }
                    else
                    {
                        setMessage(error.response.data.message);
                        setIserror(true);
                    }
                    
                }   
                else 
                {
                    setMessage('Something went wrong...');
                    setIserror(true);
                }  
                setShowLoading(false);
                
            }

        };
         
        fetchVersion();
        

    }, [authInfo]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonTitle color="secondary">Hello! {authInfo.user.name}</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="home-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Hello! {authInfo.user.name}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert
                    isOpen={isexpired}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/login");
                    }}
                    header="Session Expired"
                    message="Please login again to continue using our services"
                    buttons={["Logout"]}
                />

                <IonAlert
                    isOpen={iserror}
                    onDidDismiss={() => setIserror(false)}
                    header="Alert"
                    message={message}
                    buttons={["Close"]}
                />

                <IonAlert
                    isOpen={isold}
                    backdropDismiss={false}
                    onDidDismiss={
                        () => document.location.href = isPlatform('android') ? process.env.REACT_APP_PLAY_STORE! : process.env.REACT_APP_APP_STORE!
                    }
                    header="Unsupported Version"
                    message="This version is no longer supported. Please update to latest version from Play/App Store."
                    buttons={
                        ["Update"]
                    }
                />


                <IonGrid className="ion-no-padding white-shade">
                    <IonRow className="home-row">
                        <IonCol className="ion-text-center ion-padding-vertical">
                            <p className="logo-text">Dashboard</p>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonGrid>
                    <IonRow className="ion-padding-top">
                        <IonCol>
                            <IonCard className="action-card" routerLink="/app/courses">
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-text-center ion-no-padding">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt="Courses" src="assets/images/subject.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="action-title ion-no-padding">
                                                Courses & Chapters
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="6">
                            <IonCard className="action-card" routerLink="/app/topics">
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-text-center ion-no-padding">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt="Topics" src="assets/images/inquiry.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="action-title ion-no-padding">
                                                Topics
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="6">
                            <IonCard className="action-card" routerLink="/app/assessments">
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-text-center ion-no-padding">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt="Assessments" src="assets/images/form.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="action-title ion-no-padding">
                                                Assessment
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                    </IonRow>
                  
                   
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ContentHome;
