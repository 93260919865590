import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { alertCircleOutline } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface ParamTypes {
    studentID?: string
}

interface Due {
    _id: string,
    remarks: string,
    amount: number,
    dueDate: Date,
    isChecked: boolean
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}
interface Student {
    _id: string,
    name: string,
    isActive: boolean
}


const CollectFee: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {studentID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [selectedStudent, setSelectedStudent] = useState <string> ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [students, setStudents] = useState < Student[] > ([]);
    const [dues, setDues] = useState < Due[] > ([]);
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > (new Date().toISOString());
    const [late, setLate] = useState < number > (0);
    const [concession, setConcession] = useState < number > (0);
    const [advance, setAdvance] = useState < number > (0);
    const [mode, setMode] = useState <string> ("");
    const [remarks, setRemarks] = useState <string> ("");
    const [outstanding, setOutstanding] = useState < number > (0);
    const [credit, setCredit] = useState < number > (0);
    const [adjusted, setAdjusted] = useState < number > (0);

    const formatDated = (value: Date) => {

        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
 
    };

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
      };

    const handleChecked = (id : number) => {

            let comps = [...dues];
            comps[id].isChecked = !comps[id].isChecked;
            setDues(comps);

    }

    const handleConcession = (amt: number) => {
        let sum: number = 0;
        for (let i = 0; i < dues.length; i++)
        {
            if (dues[i].isChecked)
            {
                sum += dues[i].amount;
            }
        }

        sum += late;

        if (amt <= sum && amt >= 0)
        {
            setConcession(amt);
        }
        else
        {
            setMessage(t("invalid_concession"));
            setIserror(true);
        }
    }


    const getTotal = () => {
        let sum: number = 0;
        for (let i = 0; i < dues.length; i++)
        {
            if (dues[i].isChecked)
            {
                sum += dues[i].amount;
            }
        }

        sum += late;
        sum += advance;
        sum -= concession;

        return sum;
    }

    const handleSubmit = () => {

        if(!selectedStudent || selectedStudent === "")
        {
            setMessage(t("student_mandatory"));
            setIserror(true);
            return;
        }

        if(!mode || mode === "")
        {
            setMessage(t("mode_mandatory"));
            setIserror(true);
            return;
        }

        if(!remarks || remarks === "")
        {
            setMessage(t("collect_remarks_mandatory"));
            setIserror(true);
            return;
        }

        if (typeof selectedDate !== "string")
        {
            setMessage(t("collect_date_mandatory"));
            setIserror(true);
            return;
        }

        let count = 0;

        for (let i = 0; i < dues.length; i++)
        {
            if (dues[i].isChecked)
            {
                count++;
            }
        }

        if (count === 0 && late === 0 && concession === 0 && advance === 0)
        {
            setMessage(t("no_collection"));
            setIserror(true);
            return;
        }

        if (getTotal() < 0)
        {
            setMessage(t("negative_amount"));
            setIserror(true);
            return;
        }

        const studentName = students.find(val => val._id === selectedStudent)!.name;

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const studentCollect = ( dated: string) => {
          return new Promise((resolve, reject) => {
            api.post(`/transactions/add`, { 'instituteID': authInfo.user.instituteID, 'studentID': selectedStudent, studentName, 'adminID': authInfo.user._id, dated, dues, mode, remarks, concession, late, advance, adjusted }).then(res => {
      
              return resolve(res.data.updated);
              
            }).catch(err => reject(err));
  
          });
        }
  
        setShowLoading(true);
      studentCollect(selectedDate)
        .then(data => {
          
          setPass(t("fee_collected")+data);
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("something_went_wrong"));
  
          setIserror(true);
          setShowLoading(false);
        });
        
      }

      const handleSession = (sessionName : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClassi("");
        setSelectedStudent("");
        setStudents([]);
        setDues([]);
        setOutstanding(0);
        setCredit(0);
        setAdjusted(0);
    }

    const handleClassi = (classID : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/getByClass/${classID}`);
                setStudents(studs.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchUp();
        setClassi(classID);
        setSelectedStudent("");
        setDues([]);
        setOutstanding(0);
        setCredit(0);
        setAdjusted(0);
    }

    const handleStudent = (studentID : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const stud = await api.get(`/dues/pending/${studentID}`);
                setDues(stud.data);

                const res = await api.get(`/students/${studentID}`);

                setOutstanding(res.data.due);
                if(res.data.credit > 0) setCredit(res.data.credit);
                setAdjusted(0);
                setLate(0);
                setAdvance(0);
                setConcession(0);
                setShowLoading(false);
          
            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchUp();
        setSelectedStudent(studentID);
    }

     // For Adjustments from Student Credits
     useEffect(() => {
   
            let sum: number = 0;
            for (let i = 0; i < dues.length; i++)
            {
                if (dues[i].isChecked)
                {
                    sum += dues[i].amount;
                }
            }

            sum += late;
            sum -= concession;
            
            if (sum <= credit)
            {
                setAdjusted(sum);
            }
            else
            {
                setAdjusted(credit);
            }    
    
    }, [dues, late, concession, credit]);


      useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);

                if (studentID)
                {
                    const stud = await api.get(`/students/${studentID}`);

                    setOutstanding(stud.data.due);
                    if(stud.data.credit > 0) setCredit(stud.data.credit);

                    const classInfo = await api.get('/classes/' + stud.data.classID);
                    setSession(classInfo.data.sessionID);

                    const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${classInfo.data.sessionID}`);
                    setClasses(query.data);
                    setClassi(stud.data.classID);
                    const studs = await api.get(`/students/getByClass/${stud.data.classID}`);
                    setStudents(studs.data);
                    setSelectedStudent(studentID);

                    const pending = await api.get(`/dues/pending/${studentID}`);
                    setDues(pending.data);
                }
                else
                {
                    setOutstanding(0);
                    setCredit(0);
                    setSession("");
                    setClasses([]);
                    setClassi("");
                    setStudents([]);
                    setSelectedStudent("");
                    setDues([]);
                }

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, studentID]);
      
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/accounting`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("collect_fees")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("collect_fees")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => (studentID ? history.push(`/app/student/ledger/${studentID}`) : history.push(`/app/accounting`))
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className="max-xxl">
                        <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("select_student")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className='mt-30'>
                                <IonCol>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("class_session")}</IonLabel>
                                                            <IonSelect value={session}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={t("class_session")}
                                                                    onIonChange={
                                                                        (e) => handleSession(e.detail.value)
                                                                }>

                                                                    {sessions.map((session) => (
                                                                        (session.isActive) && 
                                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                    ))}

                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                                            <IonSelect value={classi}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                                    onIonChange={
                                                                        (e) => handleClassi(e.detail.value)
                                                                }>
                                                                    
                                                                    {classes.map((classy) => (
                                                                        (classy.isActive) &&
                                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                                    ))}
                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">                   
                                            <IonItem
                                            detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="stacked" className="input-label" color="secondary">{t("select_student")}</IonLabel>
                                                <IonSelect value={selectedStudent}
                                                            className="input-field text-capitalize"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("select_student")}
                                                            onIonChange={
                                                                (e) => e.detail.value && handleStudent(e.detail.value)
                                                        }>
                                                        { students.map((student) => 
                                                            (student.isActive) &&
                                                            (<IonSelectOption key={student._id} value={`${student._id}`} className="text-capitalize">{student.name}</IonSelectOption>)
                                                            
                                                        )}
                                                </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("pending_dues")} ({t("current_outstanding")} {t("rs")} {outstanding})
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-10">
                                <IonCol>
                                <div className="ion-table">

                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="6" className='table-heading mt-05'>
                                                {t("remarks")}
                                        </IonCol>
                                        <IonCol size="3" className='table-heading mt-05'>
                                                {t("due_date")}
                                        </IonCol>
                                        <IonCol size="3" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                                {t("fee_amount")}
                                        </IonCol>
                                    </IonRow>

                                    {(dues.length > 0) ? (dues.map((due, i) => {
                                        return (
                                            <IonRow key={due._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                                    <IonCol size="6" className='table-field' >
                                                        
                                                            <IonCheckbox labelPlacement="end" value={due._id} checked={due.isChecked} 
                                                                onClick={e => handleChecked(i)}>
                                                                <span className="ion-text-wrap">{due.remarks}</span>
                                                            </IonCheckbox>
                                                       
                                                    </IonCol>

                                                    <IonCol size="3" className='table-field' >
                                                        {formatDated(due.dueDate)}
                                                    </IonCol>

                                                    <IonCol size="3" className='table-field ion-text-right ion-padding-end' >
                                                        {due.amount}
                                                    </IonCol>
                                              
                                            </IonRow>
                                        )
                                    })) : ( 
                                    <IonRow>
                                        <IonCol>
                                            <IonCard>
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            slot="start"
                                                            color="danger" />
                                                        <IonLabel className="list-title"><h3>{t("no_pending")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                    )
                                } 

                                </div>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                    <IonCol className="ion-padding-horizontal">
                                        <IonButton expand="block" color="dark" onClick={() => history.push(`/app/dues/add/${selectedStudent}`)}>{t("add_dues")}</IonButton> 

                                    </IonCol>
                                </IonRow>
                        </IonCol>
                        <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("receipt_details")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-date-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("collect_date")}*
                                                    </IonLabel>
                                                    <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("collect_date")}</IonTitle>
                                                                
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={selectedDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                        
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("collect_remarks")}*
                                                    </IonLabel>
                                                    <IonInput value={remarks} 
                                                        placeholder={t("collect_remarks")}
                                                        type="text"
                                                        className="input-field" 
                                                        onIonInput={ev => setRemarks(ev.detail.value!)}
                                                    />
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("collect_mode")}*
                                                    </IonLabel>
                                                    <IonSelect placeholder={t("collect_mode")}
                                                        className='input-field' 
                                                        style={{'maxWidth': '100%'}} 
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        value={mode}
                                                        onIonChange={
                                                            (e) => setMode(e.detail.value!)
                                                        }
                                                        >
                                                        <IonSelectOption value="cash">{t("cash")}</IonSelectOption>
                                                        <IonSelectOption value="cheque">{t("cheque")}</IonSelectOption>      
                                                        <IonSelectOption value="online">{t("online")}</IonSelectOption>   
                                                        
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                           
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <div className="ion-table">

                                    <IonRow className="table-title ion-padding">
                                        <IonCol size="8" className='table-heading mt-05'>
                                                {t("remarks")}
                                        </IonCol>
                                        <IonCol size="4" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                                {t("due_amount")}
                                        </IonCol>
                                    </IonRow>
                                
                                    {(dues.length > 0) && (dues.map((due, i) => (
                                        (due.isChecked) &&
                                        (
                                            <IonRow key={i} className='row-table ion-padding-horizontal'>
                                                
                                                <IonCol size="8" className='table-field ion-padding-end'>
                                                    <IonItem className='fee-comp-height'>
                                                        <IonInput type="text"
                                                            value={due.remarks}
                                                            readonly={true}
                                                            />
                                                    </IonItem>
                                                </IonCol>
                                                
                                                <IonCol size="4" className='table-field'>
                                                    <IonItem className='fee-comp-height'>
                                                        <IonInput type="number"
                                                            className='ion-text-right'
                                                            value={due.amount}
                                                            readonly={true}
                                                            />
                                                    </IonItem>
                                                </IonCol>

                                            </IonRow>
                                        )
                                    )))
                                } 

                                <IonRow className='row-table ion-padding-horizontal'>
                                        
                                    <IonCol size="8" className='table-field ion-padding-end'>
                                        <IonItem className='fee-comp-height'>
                                            <IonInput type="text"
                                                value={t("collect_advance")}
                                                readonly={true}
                                                />
                                        </IonItem>
                                    </IonCol>
                                    
                                    <IonCol size="4" className='table-field'>
                                        <IonItem className='fee-comp-height'>
                                            <IonInput type="number"
                                                className='ion-text-right'
                                                value={advance}
                                                onIonInput={
                                                    (e) => e.detail.value && setAdvance(Math.abs(parseInt(e.detail.value)))
                                                }
                                                />
                                        </IonItem>
                                    </IonCol>

                                </IonRow>
                                <IonRow className='row-table ion-padding-horizontal'>
                                        
                                    <IonCol size="8" className='table-field ion-padding-end'>
                                        <IonItem className='fee-comp-height'>
                                            <IonInput type="text"
                                                value={t("collect_late")}
                                                readonly={true}
                                                />
                                        </IonItem>
                                    </IonCol>
                                    
                                    <IonCol size="4" className='table-field'>
                                        <IonItem className='fee-comp-height'>
                                            <IonInput type="number"
                                                className='ion-text-right'
                                                value={late}
                                                onIonInput={
                                                    (e) => e.detail.value && setLate(Math.abs(parseInt(e.detail.value)))
                                                }
                                                />
                                        </IonItem>
                                    </IonCol>

                                </IonRow>
                                <IonRow className='row-table ion-padding-horizontal'>
                                        
                                    <IonCol size="8" className='table-field ion-padding-end'>
                                        <IonItem className='fee-comp-height'>
                                            <IonInput type="text"
                                                value={t("collect_concession")}
                                                readonly={true}
                                                />
                                        </IonItem>
                                    </IonCol>
                                    
                                    <IonCol size="4" className='table-field'>
                                        <IonItem className='fee-comp-height'>
                                            <IonInput type="number"
                                                className='ion-text-right'
                                                value={concession}
                                                onIonInput={
                                                    (e) => e.detail.value && handleConcession(parseInt(e.detail.value))
                                                }
                                                />
                                        </IonItem>
                                    </IonCol>

                                </IonRow>
                                <IonRow className='row-table ion-padding-horizontal'>
                                        
                                    <IonCol size="8" className='table-field ion-padding-end'>
                                        <IonItem className='fee-comp-height'>
                                            <IonInput type="text"
                                                value={t("amount_adjusted")}
                                                disabled={true}
                                                />
                                        </IonItem>
                                    </IonCol>
                                    
                                    <IonCol size="4" className='table-field'>
                                        <IonItem className='fee-comp-height'>
                                            <IonInput type="number"
                                                className='ion-text-right'
                                                value={adjusted}
                                                disabled={true}
                                                />
                                        </IonItem>
                                    </IonCol>

                                </IonRow>
                                

                                <IonRow className="table-title ion-padding">
                                    <IonCol size="8" className='table-heading heading-fixed mt-05'>
                                            {t("total")}
                                    </IonCol>
                                    <IonCol size="4" className='table-heading ion-text-right mt-05 ion-padding-end'>
                                            {getTotal()-adjusted}
                                    </IonCol>
                                </IonRow>
      
                                </div>
                                
                            </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("collect_fees")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default CollectFee;