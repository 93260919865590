import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React from 'react';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { useAuth } from '../../../components/AuthContext';
import { helpCircleOutline } from 'ionicons/icons';

const Academics: React.FC = () => {
    const {t} = useTranslation();
    const {authInfo} = useAuth()!;

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("academics")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("academics")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid className='mb-60'>

                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol>

                            {authInfo.institute.timetable && (
                            <>
                            <IonRow>
                                <IonCol>
                                    <IonTitle id="open-subjects-info" className='border-bottom-sec'>
                                        {t("manage_subjects")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-subjects-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("subjects_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/subjects/add`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("add_subject")} src="assets/images/subject.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("add_subject")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/subjects`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("manage_subjects")} src="assets/images/subject.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("manage_subjects")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonTitle id="open-timetable-info" className='border-bottom-sec'>
                                        {t("manage_time_tables")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    
                                    <IonPopover trigger="open-timetable-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("timetable_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/timeslots`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("manage_timeslots")} src="assets/images/timetable-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear" >{t("manage_timeslots")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/timetables/add`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("new_timetable")} src="assets/images/timetable-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("new_timetable")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/timetables`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("manage_timetables")} src="assets/images/class-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("manage_timetables")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/timetables/day`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("consolidated_timetable")} src="assets/images/institute-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("consolidated_timetable")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/timetables/teacher`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("teacher_timetable")} src="assets/images/teachers-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                    <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("teacher_timetable")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/substitutes`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("substitutes")} src="assets/images/transaction-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("substitutes")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                
                            </IonRow>
                          
                            </>)}

                            {!authInfo.institute.timetable && (
                            <>
                            <IonRow>
                                <IonCol>
                                    <IonTitle id="open-schedules-info"  className='border-bottom-sec'>
                                        {t("course_schedule")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-schedules-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("schedules_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/courses/add`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("add_new_schedule")} src="assets/images/planner.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("add_new_schedule")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/courses/view`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("view_schedules")} src="assets/images/list.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_schedules")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/courses/topics`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("view_topics")} src="assets/images/template.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_topics")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/batch/schedule`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("assign_batch_schedule")} src="assets/images/class.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("assign_batch_schedule")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonTitle id="open-submissions-info"  className='border-bottom-sec'>
                                        {t("submissions")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-submissions-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("submissions_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/submissions/student`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("view_student_wise")} src="assets/images/students-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("view_student_wise")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/submissions/topic`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("view_topic_wise")} src="assets/images/template-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("view_topic_wise")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/hide/app/leaderboards`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("view_leaderboards")} src="assets/images/board-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("view_leaderboards")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                               
                            </IonRow>
                            </>
                            )}

                            {authInfo.institute.reportcard && (
                            <>
                            <IonRow>
                                <IonCol>
                                    <IonTitle id="open-reportcards-info"  className='border-bottom-sec'>
                                        {t("manage_report_cards")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-reportcards-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("reportcards_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                    
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/hide/app/reportcard/formats/add`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("add_new_format")} src="assets/images/registration.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("add_new_format")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/hide/app/reportcard/formats`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("manage_formats")} src="assets/images/registration.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("manage_formats")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>

                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/hide/app/reportcard/generate`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("generate_report_card")} src="assets/images/admission.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("generate_report_card")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/hide/app/reportcard/view`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("view_report_card")} src="assets/images/admission.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_report_card")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            </>
                            )}
                            {!authInfo.institute.reportcard && (
                                <>
                            <IonRow>
                                <IonCol>
                                    <IonTitle id="open-certificates-info" className='border-bottom-sec'>
                                        {t("manage_certificates")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-certificates-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("certificates_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/hide/app/certificate/generate`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("generate_certificate")} src="assets/images/admission.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("generate_certificate")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/certificate/upload`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("upload_certificate")} src="assets/images/admission.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("upload_certificate")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/certificates`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("view_certificates")} src="assets/images/list.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("view_certificates")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            </>
                            )}
                           
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Academics;