import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, star, starOutline } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import usePageUnloadHandler from '../../../components/usePageUnloadHandler';

interface Evaluation {
    _id: string,
    topic: Topic,
    student: StudentObj,
    rating?: number,
    score?: number,
    createdAt: Date
}

interface Topic {
    topicID: string,
    topicName: string
}
interface StudentObj {
    studentID: string,
    studentName: string
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}
interface Student {
    _id: string,
    name: string,
    isActive: boolean
}

interface ParamTypes {
    studentID?: string
}

const StudentSubmissions: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {studentID} = useParams < ParamTypes > ();
    const [evaluations, setEvaluations] = useState < Evaluation[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [selectedStudent, setSelectedStudent] = useState <string> ("all");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("all");
    const [students, setStudents] = useState < Student[] > ([]);
    const [total, setTotal] = useState < number > (0);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    // Create an instance of axios with default settings
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if (elem) setScrollPosition(elem.scrollTop);
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleClassi = (classID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/getByClass/${classID}`);
                setStudents(studs.data);
                const result = await api.get(`/evaluations/listByTeacher/${authInfo.user._id}/${classi}/all`);
                setEvaluations(result.data.evaluations);
                setTotal(result.data.total);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/evaluations/listByTeacher/${authInfo.user._id}/all/all`);
                setEvaluations(result.data.evaluations);
                setTotal(result.data.total);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        if (classID !== 'all')
        {
            fetchUp();
        }
        else
        {
            fetchAll();
            setStudents([]);
        }
        setClassi(classID);
        setSelectedStudent("all");
       
    }

    const handleSubmit = () => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/evaluations/listByTeacher/${authInfo.user._id}/${classi}/${selectedStudent}`);
                setEvaluations(result.data.evaluations);
                setTotal(result.data.total);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
    }


    const loadItems = async () => {

        let limit = skip+30;

        try {
            const result = await api.get(`/evaluations/listByTeacher/${authInfo.user._id}/${classi}/${selectedStudent}?skip=${limit}`);
            if(result.data.evaluations && result.data.evaluations.length)
            {
                setEvaluations([...evaluations, ...result.data.evaluations]);
                setSkip(limit);
            }
        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        } 
        
    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { evaluations, total, classes, students, classi, selectedStudent, skip, scrollPosition } = JSON.parse(storedState);
            // Data is present in local storage, use it to initialize state
            setEvaluations(evaluations);
            setTotal(total);
            setClasses(classes);
            setStudents(students);
            setClassi(classi);
            setSelectedStudent(selectedStudent);
            setSkip(skip);
            setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
        } 
        else 
        {
            const fetchUp = async () => {
                setShowLoading(true);
                try {

                    const res = await api.get('/classes/getByTeacher/' + authInfo.user._id);
                    setClasses(res.data);

                    if (studentID)
                    {
                        const result = await api.get(`/evaluations/getByStudent/${studentID}`);
                        setEvaluations(result.data.evaluations);
                        setTotal(result.data.total);

                        const stud = await api.get(`/students/${studentID}`);
                        setClassi(stud.data.classID);
                        const studs = await api.get(`/students/getByClass/${stud.data.classID}`);
                        setStudents(studs.data);
                        setSelectedStudent(studentID);
                    }
                    else
                    {
                        const result = await api.get(`/evaluations/listByTeacher/${authInfo.user._id}/all/all`);
                        setEvaluations(result.data.evaluations);
                        setTotal(result.data.total);
                        setStudents([]);
                        setClassi("all");
                        setSelectedStudent("all");
                        
                    }
                    setSkip(0);

                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
                
            };

            fetchUp();
        }
        setInitialRender(false);

    }, [authInfo, studentID, refreshed, history.action, history.location.pathname]);

     // Save state to local storage whenever it changes
     useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
                evaluations,
                total,
                classes,
                students,
                classi,
                selectedStudent,
                skip,
                scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [evaluations, total, classes, students, skip, selectedStudent, classi, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_submissions")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_submissions")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
              
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding max-xl">
                    <IonRow>
                        
                        <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {(authInfo.institute.type === "school") ? t("class") : t("batch")}</IonLabel>
                                            <IonSelect value={classi}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                    onIonChange={
                                                        (e) => handleClassi(e.detail.value)
                                                }>
                                                     <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {classes.map((classy) => (
                                                        (classy.isActive) &&
                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name} ({classy.year})</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">                   
                                    
                                    <IonItem
                                    detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_student")}</IonLabel>
                                        <IonSelect value={selectedStudent}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    className="input-field text-capitalize"
                                                    placeholder={t("select_student")}
                                                    onIonChange={
                                                        (e) => e.detail.value && setSelectedStudent(e.detail.value)
                                                }>
                                                    <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                {students.map((student) => 
                                                    (student.isActive) && 
                                                    (<IonSelectOption key={student._id} value={`${student._id}`} className="text-capitalize">{student.name}</IonSelectOption>)
                                                )}
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                
                <IonGrid className="ion-no-padding mb-60 mt-10">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_submissions")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">
                            <IonCol sizeLg="3" size="6" className='table-heading'>
    
                                {t("name")}
                            </IonCol>
                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                                {t("topic_name")}
                            </IonCol>

                            <IonCol size="3" className='table-heading'>
                            {t("date")}
                            </IonCol>

                            <IonCol size="3" className='table-heading'>
                            {t("rating")}
                            </IonCol>
                        </IonRow>

                            {(evaluations.length > 0) ? (evaluations.map((evaluation, i) => {
                                return (
                                    <IonRow key={evaluation._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}

                                    onClick={
                                        () => (evaluation.score !== undefined) ? history.push(`/app/assessment/${evaluation._id}`) : history.push(`/app/submission/${evaluation._id}`)
                                    }>
                                        
                                            <IonCol sizeLg="3" size="6" className='table-field' >
                                                <IonRow>
                                                    <IonCol>
                                                        <IonText className='text-capitalize'>{evaluation.student.studentName}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel>
                                                        {evaluation.topic.topicName}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>

                                            </IonCol>
                                            <IonCol sizeLg="3" size="0" className='table-field ion-hide-lg-down'>
                                                {evaluation.topic.topicName}
                                            </IonCol>
                                            <IonCol size="3" className='table-field'>
                                                {formatDate(evaluation.createdAt)}
                                            </IonCol>
                                            <IonCol size="3" className='table-field'>
                                                {(evaluation.score !== undefined) && `${evaluation.score} %`}
                                                {(evaluation.score === undefined) && !evaluation.rating && (
                                                    <>
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium"
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 1) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium"
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 2) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark"
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 3) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark"
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 4) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark"
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={starOutline}
                                                            color="medium" 
                                                        />
                                                    </>
                                                )}
                                                {evaluation.rating && (evaluation.rating === 5) && (
                                                    <>
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark"
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                        <IonIcon
                                                            icon={star}
                                                            color="dark" 
                                                        />
                                                    </>
                                                )}
                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_evaluations")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                    </div>

                    {(evaluations.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                        <IonRow>
                            <IonCol>
                                <IonRow>
                                    <IonCol className="ion-padding-start footer-stats">
                                {t("showing")} {total === 0 ? t("no_records") : `1 - ${evaluations.length} ${t("of")} ${total}`} 
                                    </IonCol>
                                </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>
                                    
                                    <IonButton 
                                    onClick={
                                        () => history.push(`/app/teacher/submissions/topic`)
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("view_topic_wise")}</IonButton>

                           
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    </IonRow>
                        
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default StudentSubmissions;