import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonReorder,
    IonReorderGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    ItemReorderEventDetail,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { close, informationCircleOutline } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    routeID: string,
    studentID: string
}

interface TripStudent {
    studentID: string,
    name: string,
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

interface Student {
    _id: string,
    name: string,
    isActive: boolean
}

const RouteStudent: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {routeID, studentID} = useParams < ParamTypes > ();
    const [instituteID, setInstituteID] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [classStudents, setClassStudents] = useState < Student[] > ([]);
    const [classStudent, setClassStudent] = useState < string > ("");
    const [students, setStudents] = useState < TripStudent[] > ([]);
    const [student, setStudent] = useState < TripStudent > ();
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [session, setSession] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [type, setType] = useState < string > ("");

    const handleSession = (sessionName : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${instituteID}/${sessionName}`);
                setClasses(result.data);
                setShowLoading(false);
            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClassi("");
        setClassStudents([]);
    }

    const handleClass = async (classID : string) => {

        setClassStudents([]);

        if (classID && classID !== "")
        {
            setClassi(classID);
            

            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });

            setShowLoading(true);
            try {
                    const studs = await api.get(`/students/getByClass/${classID}`);
                    setClassStudents(studs.data);
                    setShowLoading(false);
            }
            catch (error: any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        }
    }

  

    const handleClassStudent = (stud: string) => {
        if (stud && stud !== "")
        {
            const myArray = stud.split("##");
            let sid = myArray[0];
            let sname = myArray[1];

            let studs = [...students];
            
            let checkDuplicate = studs.filter(s => (s.studentID === sid));

            if (!checkDuplicate.length)
            {
                let el = {studentID: sid, name: sname};
                studs.push(el);
                setStudents(studs);
                setStudent(el);
                setClassStudent(stud);
            }
           
        }
        
    }

    const handleDelete = (index: number) => {
        let studs = [...students];
        if (index > -1) {
            studs.splice(index, 1); // 2nd parameter means remove one item only
          }
        setStudents(studs);
    }
   
    const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {

        let from = event.detail.from;
        let to = event.detail.to;
        let toCopy = event.detail.to;

        let studs = [...students];

      
        if (to < from)
        {
            studs[to] = {...students[from]};
            to = to + 1;
            while (to <= from)
            {
                studs[to] = {...students[to-1]};
                to = to + 1;
            }
        }

        if (toCopy > from)
        {
            studs[toCopy] = {...students[from]};
            toCopy = toCopy - 1;
            while (toCopy >= from)
            {
                studs[toCopy] = {...students[toCopy+1]};
                toCopy = toCopy - 1;
            }
        }
        setStudents(studs);
        
        event.detail.complete();
      }


      const handleStudent = () => {

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upRoute = (students: TripStudent[]) => {
          return new Promise((resolve, reject) => {
            api.put('/routes/'+routeID, { students }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }
    
        setShowLoading(true);
      upRoute(students)
        .then(data => {
  
            setPass(data+t("has_been_updated"));
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
            setIserror(true);
            setShowLoading(false);
        });
    }

    const handleRemoval = () => {

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upRoute = (students: TripStudent[]) => {
          return new Promise((resolve, reject) => {
            api.put('/routes/'+routeID, { students }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }
    
        setShowLoading(true);
      upRoute([])
        .then(data => {
            setStudents([]);
            setPass(data+t("has_been_updated"));
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
            setIserror(true);
            setShowLoading(false);
        });
    }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            setShowLoading(true);
            try {
                    
                    const result = await api.get('/routes/' + routeID);
                    setInstituteID(result.data.instituteID);

                    const res = await api.get(`/sessions/getAllInstitute/${result.data.instituteID}`);
                    setSessions(res.data);

                    setName(result.data.name);
                    setType(result.data.type);
    
                    let studs: TripStudent[] = result.data.students;

                    if (!studentID || studentID === 'none')
                    {
                        setStudents(studs);
                    }
                    else
                    {
                        //stud ID provided ie student ID fixed && not duplicate beforehand (duplicate on back button)
                        const stud = await api.get('/students/' + studentID);

                        const classInfo = await api.get('/classes/' + stud.data.classID);
                        setSession(classInfo.data.sessionID);

                        const resu = await api.get(`/classes/allInstitute/${result.data.instituteID}/${classInfo.data.sessionID}`);
                        setClasses(resu.data);

                        setClassi(stud.data.classID);

                        let checkDuplicate = studs.filter(s => (s.studentID === studentID));
                        if (!checkDuplicate.length)
                        {
                           
                            let el = {studentID: studentID, name: stud.data.name};
                            setStudent(el);
                            studs.push(el);
                            setStudents(studs);

                            const cstuds = await api.get(`/students/getByClass/${stud.data.classID}`);
                            setClassStudents(cstuds.data);
                            setClassStudent(`${studentID}##${stud.data.name}`);


                        }
                        else
                        {
                            setPass(t("student_exists"));
                            setIspass(true);
                        }
                    
                    }

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, routeID, studentID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/route/${routeID}`}/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("route_students")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("route_students")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/route/${routeID}`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass="first-alert"
                    header={t("confirm")}
                    message={t("remove_students")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            handleRemoval();
                        }
                        }
                    ]}
                />

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='note-card'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="primary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                                {t("route_info")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                   
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem color="secondary">
                                        
                                        <IonLabel position="stacked" className="input-label">
                                            {t("route_name")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                value={`${name} (${type})`}
                                                readonly={true}
                                                ></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("add_student")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="2" sizeMd="4" size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("class_session")}*
                                        </IonLabel>
                                            <IonSelect value={session}
                                                    className="input-field"
                                                    placeholder={t("class_session")}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    onIonChange={
                                                        (e) => handleSession(e.detail.value)
                                                }>

                                                    {sessions.map((session) => (
                                                                (session.isActive) && 
                                                                (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                            ))}

                                                    
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeXl="2" sizeMd="4" size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}*
                                        </IonLabel>
                                            <IonSelect value={classi}
                                                    className="input-field"
                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    onIonChange={
                                                        (e) => handleClass(e.detail.value)
                                                }>

                                                    { classes.map((classy) => 
                                                    (classy.isActive) &&
                                                    (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                    )}
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                 {classStudents && (classStudents.length > 0) && (
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">      
                                        
                                              <IonItem
                                                detail={false}
                                                lines='full'
                                                button={false}>
                                                  <IonLabel position="stacked" className="input-label" color="secondary">{t("student")}*</IonLabel>
                                                    <IonSelect value={classStudent}
                                                                     className="input-field text-capitalize"
                                                                        placeholder={t("student")}
                                                                        onIonChange={
                                                                            (e) => e.detail.value && handleClassStudent(e.detail.value)
                                                                    }>
                                                                    { classStudents.map((student) => 
                                                                            (student.isActive) &&
                                                                            (<IonSelectOption key={student._id} value={`${student._id}##${student.name}`} className="text-capitalize">{student.name}</IonSelectOption>)
                                                                    )}
                                                    </IonSelect>
                                                </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                  )}

            {students && (students.length > 0) && (
                <>
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("route_students")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                                            {students.map((stud, i) => {
                                                return (
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        key={stud.studentID}
                                                        className={(stud.studentID === student?.studentID) ? "active-item" : ""}
                                                        >
                                                            <IonReorder slot="start"/>
                                                            <IonLabel className="text-capitalize">{stud.name}</IonLabel>
                                                            <IonButtons slot="end">
                                                                <IonButton onClick={() => handleDelete(i)}> 
                                                                    <IonIcon
                                                                    icon={close}
                                                                    color="danger"
                                                                    />
                                                                </IonButton>
                                                            </IonButtons>
                                                            
                                                    </IonItem>
                                                )})
                                            }

                                    </IonReorderGroup>
                                </IonCardContent>
                            </IonCard>
                         </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" className="ion-padding-horizontal">
                            <IonButton onClick={() => setShowAlert(true)}
                                expand="block" color="danger">{t("remove_all")}</IonButton>

                        </IonCol>
                    </IonRow>
                </>
                )}
                   
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleStudent}
                                className="first-button"
                                fill="clear">{t("save_changes")}</IonButton>


                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default RouteStudent;
