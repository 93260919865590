import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonNote,
    IonPage,
    IonRow,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { 
    alertCircleOutline, 
    attach, 
    chevronForwardOutline, 
    imageOutline, 
    star, 
    starOutline,
    videocam,
} from 'ionicons/icons';
import axios from 'axios';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { PhotoViewer, Image } from '@capacitor-community/photoviewer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import FileOpener from "../../../../components/FileOpener";

interface Report {
    _id: string,
    calendarDate: Date,
    work: string,
    files?: Attach[],
    rating?: number
}

interface Attach {
    caption: string,
    filename: string
}

interface ParamTypes {
    reportID: string
}

interface Employee {
    userInfo: UserInfo,
    departmentInfo: UserInfo,
    photo?: string,
    dob: Date
}
interface UserInfo {
    name: string
}

const ReportDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {reportID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [report, setReport] = useState < Report > ();
    const [employee, setEmployee] = useState < Employee > ();
    const [response, setResponse] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);

    const openImage = (url: string, name: string) => {

        let images: Image[] = [];
        let startFrom = 0;
        let j = -1;

        if(report && report.files)
        {
            for (let i = 0; i < report.files.length; i++)
            {
                if(isFileImage(report.files[i].filename))
                {
                    j += 1;
                    images.push({url: `${process.env.REACT_APP_STATIC_S3}/reports/${report.files[i].filename}`, title: report.files[i].caption});
                    if (report.files[i].filename === url)
                    {
                        startFrom = j;
                    }
                }
            }
        
            if (images.length > 1)
            {
                PhotoViewer.show({images, mode: isPlatform('mobile') ? 'slider' : 'gallery', startFrom});
            }
            if (images.length === 1)
            {
                PhotoViewer.show({images, mode: 'one'});
            }
        }
    }

    const isFileImage= (fileName: string) => {
        // Extract the extension from the file name
        const extension = fileName.split('.').pop();
    
        // Check if the extension matches 'jpeg' or 'png'
        return extension === 'jpeg' || extension === 'png';
    }

    const isFileVideo= (fileName: string) => {
        const extension = fileName.split('.').pop();
        return extension === 'mp4';
    }

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        if (ext === 'mp4')
            mime = 'video/mp4';
        return mime;
    };

    const downloadFile = async (fileName : string) => {

        const myArray = fileName.split(".");
        const mimeType = getMimeType(myArray[1]);
        const url = process.env.REACT_APP_STATIC_S3 + '/reports/' + fileName;

        
        if (isPlatform('capacitor'))
        {
            try {
            Filesystem.checkPermissions().then(async (res) => {
                if (res.publicStorage !== 'granted') {
                    Filesystem.requestPermissions().then(async (ress) => {
                        if (ress.publicStorage === 'denied') {
                            setMessage(t("storage_denied"));
                            setIserror(true);
                        }
                        else {
                            try {
                                setShowLoading(true);
                                const savedFile = await Filesystem.downloadFile({
                                    path: fileName,
                                    url,
                                    directory: Directory.External,
                                });
                                setShowLoading(false);
                                
                                if(savedFile.path)
                                {
                                    await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                                }
                                else
                                {
                                    setMessage(t("download_error"));
                                    setIserror(true);
                                }
                                
                            }
                            catch(error: any) {
                                setShowLoading(false);
                                setMessage(t("download_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        setShowLoading(true);
                        const savedFile = await Filesystem.downloadFile({
                            path: fileName,
                            url,
                            directory: Directory.External,
                        });
                        setShowLoading(false);
                        
                        if(savedFile.path)
                        {
                            await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                        }
                        else
                        {
                            setMessage(t("download_error"));
                            setIserror(true);
                        }
                        
                    }
                    catch(error: any) {
                        setShowLoading(false);
                        setMessage(t("download_error"));  
                        setIserror(true);
                    }
                }
            });

            } catch {
                setShowLoading(false);
                setMessage(t("download_error"));
                setIserror(true);
            }
        }
        else
        {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.rel = 'noreferrer';
            link.download = 'download'; // Triggers download

            // Append the anchor to the body
            document.body.appendChild(link);

            // Programmatically click the link
            link.click();

            // Remove the anchor from the document
            document.body.removeChild(link);
        }
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleSubmit = (rating: number) => {
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upApli = () => {
            return new Promise((resolve, reject) => {
              api.put('/dailyReports/'+reportID, { rating }).then(res => {
                return resolve(res.data);
              }).catch(err => reject(err));
            });
        }
      
        setShowLoading(true);
        upApli()
        .then(data => {

            setPass(t("report_updated"));
            setIspass(true);
            setShowLoading(false);
        
        })
        .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
            setIserror(true);
            setShowLoading(false);
        });
    
    }

    const handleReview = () => {

        if (!response || response === "") {
            setMessage(t("review_mandatory"));
            setIserror(true);
            return;
        }
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upApli = () => {
            return new Promise((resolve, reject) => {
              api.put('/dailyReports/'+reportID, { response }).then(res => {
                return resolve(res.data);
              }).catch(err => reject(err));
            });
        }
      
        setShowLoading(true);
        upApli()
        .then(data => {

            setShowLoading(false);
        
        })
        .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
            setIserror(true);
            setShowLoading(false);
        });
    
    }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/dailyReports/' + reportID);
                setReport(result.data);
                setResponse(result.data.response);
                setMode(result.data.mode);
 
                const res = await api.get(`/employees/getByUID/${result.data.employee.employeeID}`);
                setEmployee(res.data);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, refreshed, reportID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/staff"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_report")}</IonTitle>
                    
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_report")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {report && (
                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className="action-card-b">
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="3">
                                                
                                                {employee && employee.photo && (employee.photo !== "") ?
                                                    (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${employee.photo}`} alt={t("profile_pic")} />)
                                                    : (<img src={`assets/images/avatar1.png`} alt={t("profile_pic")} />)
                                                }
                                                
                                            </IonCol>
                                            <IonCol size="9">
                                                <IonText className='action-text-3'>{employee?.userInfo.name}<br/>
                                                {employee?.departmentInfo.name}
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonNote className='border-bottom-sec ion-padding'>
                                {t("work_details_for")} {formatDate(report.calendarDate)}<br/>
                                {(mode === "submit") ? t("report_submitted") : (mode === "save") ? t("report_saved") : t("report_not") }
                            </IonNote>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}
                                        >
                                        <IonLabel position="floating" color="dark">{t("work_details")}</IonLabel>
                                        <IonTextarea rows={6} autoGrow={true} value={report.work} readonly={true} ></IonTextarea>
                                    </IonItem>
                                            
                                        
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            {report.files && (report.files.length > 0) && (report.files.map((afile, i) => {
                                return (

                                    <IonCard key={i}>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonItem lines="none"
                                            button={true}
                                            detail={true}
                                            detailIcon={chevronForwardOutline}
                                            onClick={() => isFileImage(afile.filename) ? openImage(afile.filename, afile.caption) : downloadFile(afile.filename)}
                                            >
                                                <IonIcon icon={isFileImage(afile.filename) ? imageOutline : isFileVideo(afile.filename) ? videocam : attach}
                                                    slot="start"
                                                    color="tertiary" />
                                                <IonLabel className="action-title-two"><h3>{afile.caption}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>

                                )}))}

                                {(!report.files || (report.files.length === 0)) && (
                                    <IonCard className='red-card'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start" 
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_files_uploaded")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>

                                )}
                        </IonCol>
                    </IonRow>
                    {(mode === 'submit') && (<>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("rate_report")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    <IonLabel className='ml-30'>
                                    {!report.rating && (
                                            <>
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(1)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium"
                                                    size="large"
                                                    onClick={() => handleSubmit(2)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(3)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(4)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(5)}
                                                />
                                            </>
                                        )}
                                        {report.rating && (report.rating === 1) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(1)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium"
                                                    size="large"
                                                    onClick={() => handleSubmit(2)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(3)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(4)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(5)}
                                                />
                                            </>
                                        )}
                                        {report.rating && (report.rating === 2) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(1)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    onClick={() => handleSubmit(2)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(3)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(4)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(5)}
                                                />
                                            </>
                                        )}
                                        {report.rating && (report.rating === 3) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(1)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    onClick={() => handleSubmit(2)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(3)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(4)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(5)}
                                                />
                                            </>
                                        )}
                                        {report.rating && (report.rating === 4) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(1)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    onClick={() => handleSubmit(2)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(3)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(4)}
                                                />
                                                <IonIcon
                                                    icon={starOutline}
                                                    color="medium" 
                                                    size="large"
                                                    onClick={() => handleSubmit(5)}
                                                />
                                            </>
                                        )}
                                        {report.rating && (report.rating === 5) && (
                                            <>
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(1)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary"
                                                    size="large"
                                                    onClick={() => handleSubmit(2)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(3)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(4)}
                                                />
                                                <IonIcon
                                                    icon={star}
                                                    color="primary" 
                                                    size="large"
                                                    onClick={() => handleSubmit(5)}
                                                />
                                            </>
                                        )}
                                                                    
                                    </IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("admin_response")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}
                                        >
                                        <IonLabel position="floating" color="dark">{t("response_details")}</IonLabel>
                                        <IonTextarea rows={6} autoGrow={true} value={response} onIonInput={
                                                (e) => setResponse(e.detail.value!)}></IonTextarea>
                                    </IonItem>
                                            
                                        
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    </>)}
                </IonGrid>
                )}
            </IonContent>
            {(mode === 'submit') && (
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center">
                            <IonButton onClick={handleReview}
                                className="first-button"
                                fill="clear"
                                > {t("submit_review")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
            )}
        </IonPage>
    );
};

export default ReportDetail;