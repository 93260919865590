import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, checkboxOutline, construct } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface Inquiry {
    _id: string,
    title: string,
    type: string,
    student?: Student,
    students?: string[],
    createdAt: Date
}

interface Student {
    studentID: string,
    name: string
}

interface ParamTypes {
    instituteID: string
}

const PendingInquiries: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {instituteID} = useParams < ParamTypes > ();
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [current, setCurrent] = useState < string > ("");
    const [total, setTotal] = useState < number > (0);
    const [isNot, setIsNot] = useState < boolean > (false);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
      };

    const loadItems = async () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip+30;

        try {

            if (instituteID === 'teacher')
            {
                const result = await api.get(`/inquiries/listPendingTeacher/${authInfo.user._id}?skip=${limit}`);
                if(result.data.inquiries && result.data.inquiries.length)
                {
                    setInquiries([...inquiries, ...result.data.inquiries]);
                }
            }
            else
            {
                const result = await api.get(`/inquiries/listPendingInstitute/${instituteID}?skip=${limit}`);
                if(result.data.inquiries && result.data.inquiries.length)
                {
                    setInquiries([...inquiries, ...result.data.inquiries]);
                }
            }
                
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
                else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                if (instituteID === 'teacher')
                {
                    const teacher = await api.get(`/employees/getByUID/${authInfo.user._id}`);
                    if(teacher.data.classID === "none")
                    {
                        setIsNot(true);
                    }
                    else
                    {
                        const result = await api.get(`/inquiries/listPendingTeacher/${authInfo.user._id}`);
                        setInquiries(result.data.inquiries);
                        setTotal(result.data.total);
                    }
                }
                else
                {
                    const result = await api.get(`/inquiries/listPendingInstitute/${instituteID}`);
                    setInquiries(result.data.inquiries);
                    setTotal(result.data.total);
                }

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, instituteID, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/${instituteID}/communication`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("pending_inquiries")}</IonTitle>
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("pending_inquiries")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isNot}
                    onDidDismiss={() => {
                        history.replace("/app");
                    }}
                    header={t("unauthorized")}
                    message={t("unauth_msg")}
                    buttons={[`${t("close")}`]}
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">
                            <IonCol sizeLg="4" size="8" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                    />
                                    <IonText className='ion-margin-start'>{t("title")}</IonText>
                            </IonCol>

                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("type")}
                            </IonCol>
                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                            {t("student")}
                            </IonCol>
                            <IonCol sizeLg="2" size="3" className='table-heading'>
                            {t("started_at")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                    (e) => setCurrent(e.detail.value!)
                            }>

                            {(inquiries.length > 0) ? (inquiries.map((inquiry, i) => {
                                return (
                                    <IonRow key={inquiry._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={
                                        () => setCurrent(inquiry._id)
                                    }>
                                        
                                            <IonCol sizeLg="4" size="8" className='table-field' >
                                                <IonRow>
                                                    <IonCol>
                                                    <IonRadio value={inquiry._id} className='table-icon' color="secondary" />
                                                        <IonText className='ion-margin-start text-capitalize'>{inquiry.title}</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel>
                                                         {inquiry.student ? inquiry.student.name : `${t("total_no_students")}: ${inquiry.students!.length}`} &#8226; {inquiry.type}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>
                                                
                                            </IonCol>
                                            
                                            <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down'>
                                                {inquiry.type}
                                            </IonCol>
                                            <IonCol sizeLg="3" size="0" className='table-field ion-hide-lg-down'>
                                                {inquiry.student ? inquiry.student.name : inquiry.students!.length}
                                            </IonCol>
                                            <IonCol sizeLg="2" size="3" className='table-field'>
                                                {formatDate(inquiry.createdAt)}
                                            </IonCol>
                                            <IonCol size="1" >
                                                    <IonIcon 
                                                    icon={construct}
                                                    color="dark"
                                                    className='table-icon mt-10'
                                                    onClick={
                                                        () => history.push(`/app/inquiry/${inquiry._id}`)
                                                    }
                                                    />

                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_pending_inq")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                        </IonRadioGroup>
                    </div>

                    {(inquiries.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${inquiries.length} ${t("of")} ${total}`} 
                        </IonCol>
                        </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-md'>
                            <IonButton 
                                onClick={
                                    () => {
                                        if (current === "")
                                        {
                                            setMessage(t("select_inquiry"));
                                            setIserror(true);
                                        }
                                        else
                                        {
                                            history.push(`/app/inquiry/${current}`);
                                        }

                                    }
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("view_chat")}</IonButton>

                            <IonButton 
                                onClick={
                                    () => {
                                        if ((instituteID !== 'teacher'))
                                        {
                                            history.push(`/app/${instituteID}/inquiries/new`);
                                        }
                                        else
                                        {
                                            history.push(`/app/teacher/inquiries/new`);
                                        }
                                    }
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("make_inquiry")}</IonButton>
                                
                            <IonButton 
                                onClick={
                                    () => {
                                        if ((instituteID !== 'teacher'))
                                        {
                                            history.push(`/app/${instituteID}/inquiries/processed`);
                                        }
                                        else
                                        {
                                            history.push(`/app/teacher/inquiries/processed`);
                                        }
                                    }
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("processed_inquiries")}</IonButton>
                        </IonCol>
                    </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default PendingInquiries;