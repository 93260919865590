import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useAuth} from "../../../../components/AuthContext";
import { useParams } from 'react-router-dom';
import { checkmark, close, starOutline } from 'ionicons/icons';

interface Application {
    _id: string,
    amount: number,
    status: string,
    classInfo: ClassInfo,
    instituteInfo: InstituteInfo,
    childInfo: ChildInfo,
    formData: AppForm[]
}

interface ClassInfo {
    classID: string,
    className: string
}

interface InstituteInfo {
    instituteID: string,
    instituteName: string
}

interface ChildInfo {
    childID: string,
    childName: string
}

interface AppForm {
    name: string,
    category: string,
    value: string
}

interface ParamTypes {
    applicationID: string
}

const ApplicationDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {applicationID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [application, setApplication] = useState < Application > ();
    const [refreshed, setRefreshed] = useState < number > (0);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [showAlert1, setShowAlert1] = useState < boolean > (false);
    const [showAlert2, setShowAlert2] = useState < boolean > (false);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");


    const openImage = (url: string, name: string) => {
       
    }

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    //Reject
    const handleDelete = () => {
    
        setShowLoading(true);
  
          const api = axios.create({
              baseURL: process.env.REACT_APP_API_URL,
              timeout: parseInt(process.env.REACT_APP_API_TO!),
              headers: {
                  'Authorization': 'Bearer ' + authInfo.token
              }
          });
  
      const deleteApp = () => {
          return new Promise((resolve, reject) => {
  
            api.put(`/applications/${applicationID}`, {status: 'rejected'}).then(res => {
      
                  return resolve(true);
                  
                }).catch(err => reject(err));
  
          });
        }
  
      deleteApp()
        .then(() => {
  
          setPass('Application has been rejected. Parent has been notified of the same.');
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
  
          setIserror(true);
          setShowLoading(false);
        });
        
      }

    //Shortlist
    const handleShort = () => {
    
        setShowLoading(true);
  
          const api = axios.create({
              baseURL: process.env.REACT_APP_API_URL,
              timeout: parseInt(process.env.REACT_APP_API_TO!),
              headers: {
                  'Authorization': 'Bearer ' + authInfo.token
              }
          });
  
      const shortApp = () => {
          return new Promise((resolve, reject) => {
  
            api.put(`/applications/${applicationID}`, {status: 'shortlisted'}).then(res => {
      
                  return resolve(true);
                  
                }).catch(err => reject(err));
  
          });
        }
  
      shortApp()
        .then(() => {
  
          setPass('Application has been shortlisted. Parent has been notified of the same.');
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
  
          setIserror(true);
          setShowLoading(false);
        });
        
      }

    const handleApproved = () => {
    
        setShowLoading(true);
  
          const api = axios.create({
              baseURL: process.env.REACT_APP_API_URL,
              timeout: parseInt(process.env.REACT_APP_API_TO!),
              headers: {
                  'Authorization': 'Bearer ' + authInfo.token
              }
          });
  
      const shortApp = () => {
          return new Promise((resolve, reject) => {
  
            api.put(`/applications/${applicationID}`, {status: 'approved'}).then(res => {
      
                  return resolve(true);
                  
                }).catch(err => reject(err));
  
          });
        }
  
      shortApp()
        .then(() => {
  
          setPass('Application has been approved. Parent has been notified of the same.');
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
  
          setIserror(true);
          setShowLoading(false);
        });

    }
        
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const appp = await api.get(`/applications/${applicationID}`);
                setApplication(appp.data);

            } catch (error: any) {  
                
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage('Something went wrong...');

                setIserror(true);
            }
            setShowLoading(false);
        };

     
        fetchData();

    }, [authInfo, refreshed, applicationID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle color="secondary">Application Form</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Application Form</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert
                    isOpen={iserror}
                    onDidDismiss={() => setIserror(false)}
                    header="Oh No!"
                    message={message}
                    buttons={["Close"]}
                />

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header="Alert!"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={'Confirm!'}
                    message={`Are you sure you want to reject this application?`}
                    buttons={[
                        {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: 'Yes',
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                    />

                <IonAlert
                    isOpen={showAlert1}
                    onDidDismiss={() => setShowAlert1(false)}
                    header={'Confirm!'}
                    message={`Are you sure you want to shortlist this application?`}
                    buttons={[
                        {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: 'Yes',
                        handler: () => {
                            handleShort();
                        }
                        }
                    ]}
                    />

                    <IonAlert
                        isOpen={showAlert2}
                        onDidDismiss={() => setShowAlert2(false)}
                        header={'Confirm!'}
                        message={`Are you sure you want to approve this application?`}
                        buttons={[
                            {
                            text: 'No',
                            role: 'cancel',
                            cssClass: 'secondary',
                            },
                            {
                            text: 'Yes',
                            handler: () => {
                                handleApproved();
                            }
                            }
                        ]}
                    />


                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>
                    <IonRow>
                            <IonCol>
                                {(application) && (
                                <IonCard className="ion-margin-vertical class-card">

                                    <IonCardContent className="ion-no-padding">
                                            <IonRow>
                                                <IonCol>
                                                    <IonRow className="pb-08">
                                                        <IonCol size="8">
                                                            <p><span className="class-name" >{application.childInfo.childName}</span></p>
                                                            
                                                            <p><span className="class-info-2">{application.classInfo.className}</span></p>

                                                        </IonCol>
                                                        <IonCol size="4">
                                                        {((application.status === "pending") || (application.status === "shortlisted")) && (
                                                              <IonIcon icon={close} size="large" color="danger"
                                                              onClick={() => {
                                                                  setShowAlert(true);
                                                              }}/>
                                                        )}
                                                        {(application.status === "pending") && (
                                                              <IonIcon icon={starOutline} size="large" color="success"
                                                              className='pl-08'
                                                              onClick={() => {
                                                                  setShowAlert1(true);
                                                              }}/>
                                                        )}
                                                        {(application.status === "shortlisted") && (
                                                              <IonIcon icon={checkmark} size="large" color="success"
                                                              className='pl-08'
                                                              onClick={() => {
                                                                  setShowAlert2(true);
                                                              }}/>
                                                        )}
                                                      
                                                          </IonCol>
                                                    </IonRow>
                                                    <IonRow className="pr-16">
                                                        <IonCol size="8" className='mt-05'>
                                                            <p><span className="class-info-3">Application Fees: Rs. {application.amount}/-</span></p>
                                                        </IonCol>
                                                        <IonCol size="4" className="ion-text-right">
                                                            {(application.status === "pending") && (
                                                                <IonBadge color="warning">Pending</IonBadge>
                                                            )}
                                                            {(application.status === "shortlisted") && (
                                                                <IonBadge color="secondary">Shortlisted</IonBadge>
                                                            )}
                                                            {(application.status === "approved") && (
                                                                <IonBadge color="success">Approved</IonBadge>
                                                            )}
                                                            {(application.status === "rejected") && (
                                                                <IonBadge color="danger">Rejected</IonBadge>
                                                            )}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            
                                            </IonRow>
                                    </IonCardContent>
                                </IonCard>
                                )}
                            </IonCol>
                                
                        </IonRow>
                        
                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Application's Entries
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>Child's Details</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Child') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                   
                                                    <IonInput type="text"
                                                        value={admnField.value}
                                                        readonly={true}
                                                    ></IonInput>

                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                                </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>Mother's Details</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Mother') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                   
                                                    <IonInput type="text"
                                                        value={admnField.value}
                                                        readonly={true}
                                                    ></IonInput>

                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                                </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>Father's Details</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Father') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                   
                                                    <IonInput type="text"
                                                        value={admnField.value}
                                                        readonly={true}
                                                    ></IonInput>

                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                                </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>Address</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Address') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='inset'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                   
                                                    <IonInput type="text"
                                                        value={admnField.value}
                                                        readonly={true}
                                                    ></IonInput>

                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                                </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>Child's Documents</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'ChildDocument') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='none'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>
                                  
                                                        <img onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/documents/${admnField.value}`, admnField.name)}
                                                        src={`${process.env.REACT_APP_STATIC_S3}/documents/${admnField.value}`} 
                                                        alt={admnField.name}
                                                        width="320" 
                                                        className='ion-padding-vertical'
                                                        />
                                       
                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                                </IonCard>

                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>

                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>Other Documents</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        {(application && application.formData.length > 0) && application.formData.map((admnField, i) => (
                                            (admnField.category === 'Document') &&
                                            (
                                                <IonItem detail={false}
                                                    lines='none'
                                                    button={false}
                                                    key={i}>
                                                    <IonLabel position="stacked" color="secondary">{admnField.name}</IonLabel>

                                                            <img onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/documents/${admnField.value}`, admnField.name)}
                                                            src={`${process.env.REACT_APP_STATIC_S3}/documents/${admnField.value}`} 
                                                            alt={admnField.name}
                                                            width="320" 
                                                            className='ion-padding-vertical'
                                                            />
                                       
                                                </IonItem>
                                            )
                                        ))}

                                    </IonList>
                                </IonCardContent>
                                </IonCard>

                        </IonCol>
                    </IonRow>
                  
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ApplicationDetail;