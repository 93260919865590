import {
    IonButton,
} from '@ionic/react';

import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

const LanguageToggle: React.FC = () => {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState < string > (i18n.language);

    const handleLanguageChange = () => {
        if (lang === "en")
        {
            i18n.changeLanguage("hi");
            setLang("hi");
        }
        else
        {
            i18n.changeLanguage("en");
            setLang("en");
        }
      };

    return (
       
        <IonButton onClick={handleLanguageChange} fill="clear" className='ion-no-padding'> 
            <img alt="Translate" src="assets/images/translate.png" width="40" height="40" />
        </IonButton>
                    
    );
}

export default LanguageToggle;
