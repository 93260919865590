import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  isPlatform
} from '@ionic/react';
import axios from 'axios';
import React, {useState} from 'react';
import {useAuth} from '../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';

const UpdatePassword: React.FC = () => {
  const {authInfo} = useAuth()!;
  const {t} = useTranslation();
  const [showLoading, setShowLoading] = useState < boolean > (false);
  const [iserror, setIserror] = useState < boolean > (false);
  const [message, setMessage] = useState < string > ("");
  const [ispass, setIspass] = useState < boolean > (false);
  const [pass, setPass] = useState < string > ("");
  const [newPass, setNewPass] = useState < string > ("");
  const [confirmPass, setConfirmPass] = useState < string > ("");
  const [oldPass, setOldPass] = useState < string > ("");

  const handleSubmit = () => {

    if (!newPass || newPass.length < 8) {
        setMessage(t("invalid_password"));
        setIserror(true);
        return;
    }
    else if (newPass !== confirmPass)
    {
        setMessage(t("password_mismatch"));
        setIserror(true);
        return;
    }
    else
    {
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const upUser = (oldPass: string, newPass: string, uid: string) => {
          return new Promise((resolve, reject) => {
            api.post('/users/password', { 'oldPass': oldPass, 'newPass': newPass, 'uid': uid}).then(res => {
      
              return resolve(res.data);
              
            }).catch(err => reject(err));

          });
        }

        setShowLoading(true);
      upUser(oldPass, newPass, authInfo.user._id)
        .then(data => {

                setShowLoading(false);
                setPass(t("pass_updated"));
                setIspass(true);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("something_went_wrong"));
          setIserror(true);
          setShowLoading(false);
        });
    }
    
  }
  

  return (
      <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/user/profile"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("change_password")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


          <IonContent fullscreen={true} className="grey-shade">

              <IonHeader collapse="condense">
                  <IonToolbar>
                      <IonTitle size="large" color="secondary">{t("change_password")}</IonTitle>
                  </IonToolbar>
              </IonHeader>

              <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(true)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

              <IonGrid className="ion-no-padding">
                
                <IonRow className="mt-30">
                    <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                        <IonCard>
                            <IonCardContent className="ion-no-padding">
                                <IonItem>
                                    
                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("old_pass")}*
                                    </IonLabel>
                                        <IonInput type="password"
                                            className="input-field"
                                            placeholder={t("old_pass")}
                                            value={oldPass}
                                            onIonInput={
                                                (e) => setOldPass(e.detail.value !)
                                        }></IonInput>
                                </IonItem>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-margin-top">
                    <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                        <IonCard>
                            <IonCardContent className="ion-no-padding">
                                <IonItem>
                                    
                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("new_pass")}*
                                    </IonLabel>
                                        <IonInput type="password"
                                            className="input-field"
                                            placeholder={t("new_pass")}
                                            value={newPass}
                                            onIonInput={
                                                (e) => setNewPass(e.detail.value !)
                                        }></IonInput>
                                </IonItem>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-margin-top">
                    <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                        <IonCard>
                            <IonCardContent className="ion-no-padding">
                                <IonItem>
                                    
                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("confirm_pass")}*
                                    </IonLabel>
                                        <IonInput type="password"
                                            className="input-field"
                                            placeholder={t("confirm_pass")}
                                            value={confirmPass}
                                            onIonInput={
                                                (e) => setConfirmPass(e.detail.value !)
                                        }></IonInput>
                                </IonItem>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
              </IonGrid>

          </IonContent>
          <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_pass")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
      </IonPage>
  );
};

export default UpdatePassword;
