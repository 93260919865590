import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router';
import {useAuth} from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    instituteID: string
}

interface Region {
    _id: string,
    name: string,
    isActive: boolean
}

const InstituteDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {instituteID} = useParams < ParamTypes > ();
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [regions, setRegions] = useState < Region[] > ([]);
    const [region, setRegion] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [address, setAddress] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [transport, setTransport] = useState < boolean > (true);
    const [comm, setComm] = useState < boolean > (true);
    const [payment, setPayment] = useState < boolean > (false);
    const [admn, setAdmn] = useState < boolean > (false);
    const [acad, setAcad] = useState < boolean > (false);
    const [invent, setInvent] = useState < boolean > (false);
    const [what, setWhat] = useState < boolean > (false);
    const [fileName, setFileName] = useState < string > ("");
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [website, setWebsite] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [payroll, setPayroll] = useState < boolean > (false);
    const [timetable, setTimetable] = useState < boolean > (false);
    const [reportcard, setReportcard] = useState < boolean > (false);
    const [medical, setMedical] = useState < boolean > (false);
    const [task, setTask] = useState < boolean > (false);
    const [exam, setExam] = useState < boolean > (false);
    const [diary, setDiary] = useState < boolean > (false);

    function validateEmail(email : string) { // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    }

    function validatePhone(phone : string) { // eslint-disable-next-line no-control-regex
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    }

    const handleSection = (section: string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

    const toggleInstitute = (isActive: string) => {
        return new Promise((resolve, reject) => {

            api.put(`/institutes/${instituteID}`, { 'isActive': section }).then(res => {
    
                return resolve(res.data.name);
                
              }).catch(err => reject(err));

        });
      }

      if ((active !== "") && (section !== active))
      {
            setShowLoading(true);
                toggleInstitute(section)
            .then(data => {

                setActive(section);
                setPass(data+' has been updated.');
                setIspass(true);
                setShowLoading(false);
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage('Please try again afer sometime.');

                setIserror(true);
                setShowLoading(false);
            });
        }
    }

    function onFileChange(fileChangeEvent : React.ChangeEvent<HTMLInputElement>) {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                setUploadFile(fileChangeEvent.target.files[0]);
            }
            else
            {
                setUploadFile(null);
            }
            
        }
            
      }

    const handleLogo = () => {

        if (!uploadFile) {
            setMessage("Upload the logo in jpg/png format less than 500kb");
            setIserror(true);
            return;
        }
    
        setShowLoading(true);

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const upInstitute = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/institutes/updateLogo', formData).then(res => {
        
                return resolve(res.data.institute.name);
                
              }).catch(err => reject(err));
    
            });
          }

          
      // Change uploadFile to blob in native implementation using capacitor plugin
      let formData = new FormData();
      formData.append('image', uploadFile, uploadFile.name);
      formData.append('instituteID', instituteID);
  
    upInstitute(formData)
        .then(data => {
        
            setPass(data+' has been updated.');
            setIspass(true);
            setShowLoading(false);
        })
        .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Please try again afer sometime.');

            setIserror(true);
            setShowLoading(false);
        });
        
      }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage("Name is a mandatory field!");
            setIserror(true);
            return;
        }

        if (!address || address === "") {
            setMessage("Address is a mandatory field!");
            setIserror(true);
            return;
        }

        if (validateEmail(email) === false) {
            setMessage("Thats an invalid email address!");
            setIserror(true);
            return;
        }

        if (validatePhone(phone) === false) {
            setMessage("Enter 10 digits mobile number!");
            setIserror(true);
            return;
        }
        
        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upInstitute = (regionID: string, communication: boolean, admission: boolean, academics: boolean, inventory: boolean, whatsapp: boolean) => {
          return new Promise((resolve, reject) => {
            api.put('/institutes/'+instituteID, { regionID, type, name, address, phone, email, website, transport, communication, payment, admission, academics, exam, inventory, whatsapp, payroll, medical, task, timetable, reportcard, diary }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }
    
    
      upInstitute(region, comm, admn, acad, invent, what)
        .then(data => {
  
            setPass(data+' has been updated.');
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
          setIserror(true);
          setShowLoading(false);
        });
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/institutes/' + instituteID);

                const reg = await api.get(`/regions/active`);
                setRegions(reg.data);

                setRegion(result.data.regionID);
                setType(result.data.type);
                setName(result.data.name);
                setAddress(result.data.address);
                setPhone(result.data.phone);
                setEmail(result.data.email);
                if (result.data.website)
                {
                    setWebsite(result.data.website);
                }
                
                setFileName(result.data.logo);

                setComm(result.data.communication);
                setTransport(result.data.transport);
                setPayment(result.data.payment);
                setAdmn(result.data.admission);
                setAcad(result.data.academics);
                setExam(result.data.exam);
                setInvent(result.data.inventory);
                setWhat(result.data.whatsapp);
                setPayroll(result.data.payroll);
                setTask(result.data.task);
                setMedical(result.data.medical);
                setTimetable(result.data.timetable);
                setReportcard(result.data.reportcard);
                setDiary(result.data.diary);

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");
    
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, instituteID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/institutes" />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined}  color="secondary">Manage Institute</IonTitle>
                   {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Manage Institute</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                <IonRow className="mt-10">
                        <IonCol>
                            <IonCard className='border-radius-10 active-group'>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="8">
                                                <IonTitle className='list-title'>
                                                    Current Status 
                                                </IonTitle>
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                            {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>Active</IonBadge>) : 
                                            ( <IonBadge color="danger" className='ion-padding-horizontal'>Inactive</IonBadge>)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-top ion-padding-horizontal">
                                            <IonCol>
                                                <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                    <IonSegmentButton value="enable">
                                                        <IonLabel>Make Active</IonLabel>
                                                    </IonSegmentButton>
                                                    <IonSegmentButton value="disable">
                                                        <IonLabel>Make Inactive</IonLabel>
                                                    </IonSegmentButton>
                                                </IonSegment>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol>
                            <IonCard>
                                <IonCardHeader className="ion-text-center">
                                    <IonCardSubtitle>Logo</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow className="">
                                            <IonCol size="3" className="">
                                                 <div className="vertical-container">
                                                    <div className="vertical-center"> 
                                                {fileName && (fileName !== "") ?
                                                    (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${fileName}`} alt="Logo" width="70" />)
                                                    : (<img src={`assets/images/institute.png`} alt="Logo" width="70" />)
                                                }

                                                    </div>
                                                </div>

                                            </IonCol>
                                            <IonCol size="9" className="ion-text-center ">
                                                <div className="vertical-container">
                                                    <div className="vertical-center"> <p className="preview-text">{name}</p>
                                                    </div>
                                                </div>

                                               
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>        
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}
                                        >
                                                    
                                        <IonLabel position="fixed" color="secondary">New Logo</IonLabel>
                                        <input type="file" accept=".jpg, .jpeg, .png" onChange={ev => onFileChange(ev)}></input>
                                                    
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleLogo}
                                expand="block">Update Logo</IonButton>

                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={ () => history.push(`/app/location/${instituteID}`)}
                                expand="block" color="dark">View Location</IonButton>

                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Institute's Details
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol>
                                <IonList className="ion-no-padding">
                                        
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">Region</IonLabel>
                                                    <IonSelect value={region}
                                                            placeholder="Select Region"
                                                            onIonChange={
                                                                (e) => setRegion(e.detail.value)
                                                        }>
                                                        { regions.map((reg) => {
                                                                return (<IonSelectOption key={reg._id} value={reg._id}>{reg.name}</IonSelectOption>);
                                                            })
                                                        }
                                            </IonSelect>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">Type</IonLabel>
                                                    <IonSelect value={type}
                                                            placeholder="Select Type"
                                                            onIonChange={
                                                                (e) => setType(e.detail.value)
                                                        }>
                                                        <IonSelectOption value="school">School</IonSelectOption> 
                                                        <IonSelectOption value="institute">Institute</IonSelectOption> 
                                            </IonSelect>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Name</IonLabel>
                                            <IonInput type="text"
                                                value={name}
                                                placeholder="Institute's Name"
                                                onIonInput={
                                                    (e) => setName(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Address</IonLabel>
                                            <IonInput type="text"
                                                value={address}
                                                placeholder="Full Address"
                                                onIonInput={
                                                    (e) => setAddress(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Phone</IonLabel>
                                            <IonInput type="text"
                                                value={phone}
                                                placeholder="Phone Number"
                                                onIonInput={
                                                    (e) => setPhone(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Email</IonLabel>
                                            <IonInput type="text"
                                                value={email}
                                                placeholder="Email Address"
                                                onIonInput={
                                                    (e) => setEmail(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Website</IonLabel>
                                            <IonInput type="text"
                                                value={website}
                                                placeholder="Website"
                                                onIonInput={
                                                    (e) => setWebsite(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Task Management</IonLabel>
                                            <IonToggle checked={task} onIonChange={e => setTask(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Medical Room</IonLabel>
                                            <IonToggle checked={medical} onIonChange={e => setMedical(e.detail.checked)} />
                                        </IonItem>
                                        
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Transport</IonLabel>
                                            <IonToggle checked={transport} onIonChange={e => setTransport(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Communication</IonLabel>
                                            <IonToggle checked={comm} onIonChange={e => setComm(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Payment</IonLabel>
                                            <IonToggle checked={payment} onIonChange={e => setPayment(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Payroll</IonLabel>
                                            <IonToggle checked={payroll} onIonChange={e => setPayroll(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Academics</IonLabel>
                                            <IonToggle checked={acad} onIonChange={e => setAcad(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Examinations</IonLabel>
                                            <IonToggle checked={exam} onIonChange={e => setExam(e.detail.checked)} />
                                        </IonItem>


                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Timetable</IonLabel>
                                            <IonToggle checked={timetable} onIonChange={e => setTimetable(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Report Cards</IonLabel>
                                            <IonToggle checked={reportcard} onIonChange={e => setReportcard(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Daily Diary</IonLabel>
                                            <IonToggle checked={diary} onIonChange={e => setDiary(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Inventory</IonLabel>
                                            <IonToggle checked={invent} onIonChange={e => setInvent(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Admission</IonLabel>
                                            <IonToggle checked={admn} onIonChange={e => setAdmn(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Whatsapp</IonLabel>
                                            <IonToggle checked={what} onIonChange={e => setWhat(e.detail.checked)} />
                                        </IonItem>

                                    </IonList>
                              
                        </IonCol>
                    </IonRow>
               
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                expand="block">Update Institute</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default InstituteDetail;
