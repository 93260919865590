import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import {useAuth} from '../../../components/AuthContext';
import axios from 'axios';
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

const Reports: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [categories, setCategories] = useState < string[] > (['First', 'Second', 'Third']);
    const [incomeSeries, setIncomeSeries] = useState < number[] > ([0, 0, 0]);
    const [expenseSeries, setExpenseSeries] = useState < number[] > ([0, 0, 0]);
    const [income, setIncome] = useState < number > (0);
    const [expense, setExpense] = useState < number > (0);
    const [series1, setSeries1] = useState < number[] > ([0, 0]);
    const [series2, setSeries2] = useState < number[] > ([0, 0]);

    const series =  [{
        name: 'Income',
        data: incomeSeries
        }, {
        name: 'Expenses',
        data: expenseSeries
    }];

    const options =  {
        chart: {
            toolbar: {
              show: false
            }
        },
        colors : ['#2dd36f', '#eb445a'],
        plotOptions: {
            bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
            text: 'Rs. (thousands)'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return "Rs. " + val + " thousands"
                }
            },
            theme: 'dark'
        }
    };

    const options1 = {
        colors : ['#FFC900', '#086E7D'],
        labels: ['Cash', 'Online'],
        dataLabels: {
            enabled: false
        },
        legend: {
            position: 'bottom' as 'bottom',
            labels: {
                colors: '#777'
            },
          }
    };
         
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/transactions/reports/' + authInfo.user.instituteID);

                setCategories(result.data.categories);
                setIncomeSeries(result.data.incomeSeries);
                setExpenseSeries(result.data.expenseSeries);
                setIncome(result.data.income);
                setSeries1(result.data.series1);
                setExpense(result.data.expense);
                setSeries2(result.data.series2);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage(t('something_went_wrong'));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo]); 

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/accounting"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_reports")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_reports")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                <IonGrid className="ion-no-padding mb-60">
                    
                    <IonRow>
                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonCard className='action-card-s'>
                                        <IonCardHeader className="ion-text-center">
                                            <IonCardSubtitle>{t("current_income")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="reports-in">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size='6'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <h1>Rs. {income}</h1>
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                    <IonCol size='6'>
                                                        <Chart options={options1} series={series1} type="donut" width={180} />
                                                    </IonCol>
                                                </IonRow>
                                            
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol>
                                    <IonCard className='action-card-d'>
                                        <IonCardHeader className="ion-text-center">
                                            <IonCardSubtitle>{t("current_expense")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="reports-out">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size='6'>
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <h1>Rs. {expense}</h1>
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                    <IonCol size='6'>
                                                        <Chart options={options1} series={series2} type="donut" width={180} />
                                                    </IonCol>
                                                </IonRow>
                                            
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="12" sizeLg='6' className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("last_3_months")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol className='ion-padding'>
                                    <Chart options={options} series={series} type="bar" height={350} />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>

                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Reports;
