import {
  IonIcon,
  IonLabel,
  IonRouterOutlet, IonTabBar, IonTabButton, IonTabs,
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import StudentHome from "../pages/Home/StudentHome";
import Planner from "../pages/Student/Planner";
import Attendance from "../pages/Student/Attendance";
import Leaderboard from "../pages/Student/Leaderboard";
import Subjects from "../pages/Student/Subjects";
import Chapters from "../pages/Student/Chapters";
import Topics from "../pages/Student/Topics";

import UserNotifications from "../pages/User/UserNotifications";
import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdatePassword from "../pages/User/UpdatePassword";
import Terms from "../pages/Policies/TermsAndConditions";
import Privacy from "../pages/Policies/PrivacyPolicy";
import ContactUs from "../pages/General/ContactUs";
import DeleteAccount from "../pages/General/DeleteAccount";

import { book, calendarNumber, home, ribbon } from "ionicons/icons";

const StudentTabs: React.FC = () => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Switch>
            <Route path="/app/home" exact={true} component={StudentHome} />
            <Route path="/app/planner" exact={true} component={Planner} />
            <Route path="/app/attendance" exact={true} component={Attendance} />
            <Route path="/app/leaderboard" exact={true} component={Leaderboard} />
            <Route path="/app/subjects" exact={true} component={Subjects} />
            <Route path="/app/chapters/:subjectID" exact={true} component={Chapters} />
            <Route path="/app/topics/:chapterID" exact={true} component={Topics} />
            <Route path="/app/user/notification" exact={true} component={UserNotifications} />
            <Route path="/app/user/profile" exact={true} component={Profile} />
            <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
            <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />
            <Route path="/app/policies/terms" exact={true} component={Terms} />
            <Route path="/app/policies/privacy" exact={true} component={Privacy} />
            <Route path="/app/support/contact" exact={true} component={ContactUs} />
            <Route path="/app/support/delete" exact={true} component={DeleteAccount} />
            <Redirect exact={true} path="/app" to="/app/home" />
            <Redirect from="*" to="/app"/>
          </Switch>
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className="tab-bar">
        <IonTabButton tab="home" href="/app/home" className="tab-button" target="_blank">
          <IonIcon icon={home} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab="subjects" href="/app/subjects" className="tab-button" target="_blank">
          <IonIcon icon={book} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">Subjects</IonLabel>
        </IonTabButton>
        <IonTabButton tab="leaderboard" href="/app/leaderboard" className="tab-button" target="_blank">
          <IonIcon icon={ribbon} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">Leaderboard</IonLabel>
        </IonTabButton>
        <IonTabButton tab="planner" href="/app/planner" className="tab-button" target="_blank">
          <IonIcon icon={calendarNumber} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">Planner</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default StudentTabs;
