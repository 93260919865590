import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, locationSharp, lockClosedSharp, megaphoneSharp } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useAuth} from '../../components/AuthContext';
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';

interface Institute {
    _id: string,
    name: string,
    address: string,
    phone: string,
    email: string,
    logo: string,
    admnPhoto?: string,
    admnOpen: boolean
}

const Institutes: React.FC = () => {
    const {authInfo, switchInstitute} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [institutes, setInstitutes] = useState < Institute[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");

    const manageInstitute = (instituteID: string) => {

        setShowLoading(true);

        switchInstitute(authInfo.user._id, instituteID).then(res => {

            history.push("/app/home/admin");
            setShowLoading(false);
        
        })
        .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("unable_to_connect"));
            setIserror(true);
            setShowLoading(false);
        }); 
    
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/regions/getWhiteHome/${process.env.REACT_APP_APP_ID}`);
                setInstitutes(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_branches")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_branches")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                

                <IonGrid className="ion-no-padding">

                    <IonRow className='mt-30'>
                        <IonCol>
                            <IonTitle className='vid-title'>
                                {t("branches")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-vertical">
                        
                        {(institutes.length > 0) ? (institutes.map((insti) => {
                                return (
                                    <IonCol key={insti._id} sizeXl='4' sizeMd='6' size='12'>
                                        <IonCard className="ion-margin-vertical">
                                            {(insti.admnPhoto !== "") &&
                                            (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${insti.admnPhoto}`} alt={insti.name} onClick={() => manageInstitute(insti._id)} 
                                                style={{'width': '100%', 'height': 'auto'}} />)
                                            }
                                            <IonCardContent className="ion-no-padding">
                                                <IonGrid className="white-shade">
                                                    <IonRow>
                                                    
                                                        <IonCol size="9" className="insti-info">
                                                                <IonRow className="ion-margin-horizontal pb-08">
                                                                    <IonCol>
                                                                        <p><span className="insti-name" onClick={() => manageInstitute(insti._id)} >{insti.name}</span></p>
                                                                        
                                                                        <p><IonIcon icon={locationSharp} color="secondary" /><span className="insti-address">{insti.address}</span></p>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow className="ion-margin-horizontal pb-08">
                                                                        <IonCol>
                                                                            <IonButton color="primary" size="small" onClick={() => manageInstitute(insti._id)} >{t("manage_branch")}</IonButton>
                                                                        </IonCol>
                                                                        
                                                                </IonRow>
                                                                <IonRow className="ion-margin-horizontal pb-08">
                                                                    <IonCol>
                                                                        <p><IonIcon className="mt-05" icon={insti.admnOpen ? megaphoneSharp : lockClosedSharp} color="danger" /><span className="admn-open">{insti.admnOpen ? t("admn_open") : t("admn_closed")}</span></p>
                                                                        
                                                                    </IonCol>
                                                                </IonRow>
                                                        </IonCol>
                                                        <IonCol size="3" className="ion-text-center">
                                                            <div className="vertical-container">
                                                                <div className="vertical-center"> 
                                                                {(insti.logo !== "") ?
                                                                    (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${insti.logo}`} alt={insti.name} width="60" onClick={() => manageInstitute(insti._id)} />)
                                                                    : (<img src={`assets/images/institute.png`} alt={insti.name} width="60" onClick={() => manageInstitute(insti._id)} />)
                                                                }
                                                                </div>
                                                            </div>
                                                              
                                                        </IonCol>
                                                    </IonRow>
                                                    
                                                </IonGrid>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    );
                                    })) : (
                                    <IonCol>
                                        <IonCard className="ion-margin-vertical red-card">
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        color="danger"
                                                        slot="start"
                                                        size="large" />
                                                    <IonLabel className="list-title"><h3>{t("no_branches")}</h3></IonLabel>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    )
                                    }

                       
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Institutes;
