import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonProgressBar,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSkeletonText,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, bookOutline, ellipsisHorizontalOutline } from 'ionicons/icons';
import React, {useEffect,  useState} from 'react';
import {useAuth} from '../../components/AuthContext';
import {useParams} from 'react-router-dom';

interface Course {
    _id: string,
    name: string,
    desc: string,
    chapters: Chapter[],
    isActive: boolean
}

interface Chapter {
    _id: string,
    chapterName: string,
    order: number,
    isActive: boolean
}

interface ParamTypes {
    subjectID: string
}

const Chapters: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {subjectID} = useParams < ParamTypes > ();
    const [course, setCourse] = useState < Course > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }
    
    useEffect(() => {

        setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            try {
                const sub = await api.get(`/subjects/${subjectID}`);
                setName(sub.data.name);
                const result = await api.get('/courses/' + sub.data.courseInfo.courseID);

                if (result.data.length !== 0)
                {
                    let course = result.data;

                    //sort chapters based on order
                    let res = result.data.chapters.sort((a: Chapter, b: Chapter) => a.order! - b.order!);
    
                    course.chapters = res;
                    setCourse(course);
                }

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, refreshed, subjectID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle color="secondary">Chapters</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Chapters</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding">

                    <IonRow className='ion-margin-top'>
                        <IonCol className='ion-padding-horizontal'>
                            <IonCard className="subject-card-4">
                                <IonCardContent>
                                    <IonRow className='mt-15'>
                                        <IonCol size="1">
                                                <IonIcon 
                                                    icon={bookOutline}
                                                    className='subject-icon-4'
                                                />
                                        </IonCol>
                                        <IonCol size="11" className='ion-text-center'>
                                      
                                            <IonText className='subject-head-4'> 
                                                    {
                                                    name ? (name) : 
                                                    ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                                                    }
                                            </IonText>
                                              
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='subject-desc-4'>
                                        <IonCol>
                                        {
                                        course ? (`${course.desc}`) : 
                                        ((<IonSkeletonText animated={true} style={{ 'width': '180px', 'height': '40px', 'marginLeft': '20px' }}></IonSkeletonText>))
                                        }
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    {course && course.isActive && (course.chapters.length > 0) ? (
                    <>
                    <IonRow className='mt-30'>
                        <IonCol>
                            <IonTitle className='title-heading'>
                                Chapters
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                             {course.chapters.map((chapter) => {
                                if(chapter.isActive)
                                    {
                                    return (
                                         <IonCard className="chapter-card" routerLink={`/app/topics/${chapter._id}`} key={chapter._id}>
                                            <IonCardContent>
                                                <IonRow>
            
                                                    <IonCol size="10">

                                                        <IonText className='chapter-head'>{chapter.chapterName}</IonText>

                                                    </IonCol>
                                                    <IonCol size="1">
                                                                
                                                        <IonIcon className='chapter-icon'
                                                            icon={ellipsisHorizontalOutline}
                                                            color='dark'
                                                            
                                                            />
                                                      
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='mt-30'>
                                                    <IonCol>
                                                        <IonText color="medium" >Overall Progress 55%</IonText>
                                                        <IonProgressBar value={0.55} color="secondary" className='chapter-progress'></IonProgressBar>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    )
                                }
                                else {
                                    return null;
                                    }
                                })}
                        </IonCol>
                    </IonRow>
                    </>
                    ) : ( 

                        <IonCard className='action-card-b'>
                            <IonCardContent className="ion-no-padding ion-no-margin">
                                <IonItem lines="none">
                                    <IonIcon icon={alertCircleOutline}
                                    slot="start" 
                                    color="danger" />
                                    <IonLabel className="list-label"><h3>No chapters available</h3></IonLabel>

                                </IonItem>
                            </IonCardContent>
                        </IonCard>
                    
                    )
                } 
           
                </IonGrid>

            </IonContent>
          
        </IonPage>
    );
};

export default Chapters;
