import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useAuth} from "../../../../components/AuthContext";
import { useHistory, useParams } from 'react-router-dom';
import { alertCircleOutline } from 'ionicons/icons';

interface Application {
    _id: string,
    amount: number,
    status: string,
    classInfo: ClassInfo,
    instituteInfo: InstituteInfo,
    childInfo: ChildInfo
}

interface ClassInfo {
    classID: string,
    className: string
}

interface InstituteInfo {
    instituteID: string,
    instituteName: string
}

interface ChildInfo {
    childID: string,
    childName: string
}

interface ParamTypes {
    instituteID: string
}

const ApprovedApps: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {instituteID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [approveApps, setApproveApps] = useState < Application[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const approve = await api.get(`/applications/getApprovedInstitute/${instituteID}`);
                setApproveApps(approve.data);

            } catch (error: any) {  
                
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage('Something went wrong...');

                setIserror(true);
            }
            setShowLoading(false);
        };

     
        fetchData();

    }, [authInfo, refreshed, instituteID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/${instituteID}/admission/applications`} />
                    </IonButtons>
                    <IonTitle color="secondary">Approved</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Approved</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert
                    isOpen={iserror}
                    onDidDismiss={() => setIserror(false)}
                    header="Oh No!"
                    message={message}
                    buttons={["Close"]}
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>
                   
                    <IonRow>
                        <IonCol offsetMd="4" offsetSm="2" offset="0" sizeMd="4" sizeSm="8" size="12">
                      

                            {(approveApps.length > 0) && approveApps.map((app) => {
                                return (
                                        <IonCard key={app._id} className="ion-margin-vertical class-card">

                                            <IonCardContent className="ion-no-padding">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonRow className="pb-08">
                                                                <IonCol>
                                                                    <p><span className="class-name" >{app.childInfo.childName}</span></p>
                                                                    
                                                                    <p><span className="class-info-2">{app.classInfo.className}</span></p>
 
                                                                </IonCol>
                                                               
                                                            </IonRow>
                                                            <IonRow className="pr-16">
                                                                <IonCol size="8" className='mt-05'>
                                                                    <p><span className="class-info-3">Application Fees: Rs. {app.amount}/-</span></p>
                                                                </IonCol>
                                                                <IonCol size="4" className="ion-text-right">
                                                                    <IonButton color="primary" size="small" onClick={() => history.push(`/app/application/${app._id}`)}>View</IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonCol>
                                                      
                                                    </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        );
                                    })
                                }
                                {(approveApps.length === 0) && (
                                     <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>No applications</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )}


                        </IonCol>
                    </IonRow>
                  
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ApprovedApps;
