import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import translationEN from "../locales/en/translation.json";
import translationHI from "../locales/hi/translation.json";

// Importing translation files
const resources = {
    en: {
      translation: translationEN,
    },
    hi: {
      translation: translationHI,
    }
};

//i18N Initialization
i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    react: {
      useSuspense: false
    },
    lng:"en", //default language
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [
        HttpBackend, // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
        resourcesToBackend(resources),
      ],
      backendOptions: [{
        loadPath: 'https://scapp-uploads.s3.ap-south-1.amazonaws.com/statics/locales/{{lng}}/{{ns}}.json',
        crossDomain: true,
      }]
    }
  });

export default i18n;