import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonIcon,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React from 'react';
import { helpCircleOutline } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import {useAuth} from "../../../components/AuthContext";

const ManageCash: React.FC = () => {
    const {t} = useTranslation();
    const {authInfo} = useAuth()!;

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("petty_cash")}</IonTitle>
                    <IonButtons slot="end">
                        {!isPlatform('mobile') && (
                        <>
                            <ThemeToggle />
                            <LanguageToggle />
                        </>
                        )}
                         <IonButton id="open-cash-info" fill="clear" className='ion-no-padding badge-button'> 
                            <IonIcon color="success" icon={helpCircleOutline} className="badge-icon" />
                            <IonPopover trigger="open-cash-info" className='info-popover'>
                                <IonCard className='note-card-3 ion-no-margin'>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonRow>
                                            <IonCol className='note-text'>
                                                <p>
                                                {t("cash_help")}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonPopover>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("petty_cash")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid className='mb-60'>
                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card action-card-2" routerLink={`/app/transactions/expense`}>
                            <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow className="ion-padding-top">
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("general_expense")} src="assets/images/payroll-1.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("general_expense")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        {authInfo.institute.transport && (
                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card action-card-2" routerLink={`/app/transactions/transport`}>
                            <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow className="ion-padding-top">
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("transport_expense")} src="assets/images/transporters-1.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("transport_expense")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card action-card-2" routerLink={`/app/bills/add`}>
                            <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow className="ion-padding-top">
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("add_bill")} src="assets/images/bill-1.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("add_bill")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card action-card-2" routerLink={`/app/bills/list`}>
                            <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow className="ion-padding-top">
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("view_expenses")} src="assets/images/bill-1.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("view_expenses")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    {/*
                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card action-card-2" routerLink={`/app/expense/reports`}>
                            <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow className="ion-padding-top">
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("expense_report")} src="assets/images/reports-1.png" />
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("expense_report")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    */}
                        
                    </IonRow>
                        
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ManageCash;