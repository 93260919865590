import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React from 'react';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { helpCircleOutline } from 'ionicons/icons';

const ManageTimeTables: React.FC = () => {
    const {t} = useTranslation();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("subjects_time_tables")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("subjects_time_tables")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid className='mb-60'>

                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol>
                        <IonRow>
                                <IonCol>
                                    <IonTitle id="open-subjects-info" className='border-bottom-sec'>
                                        {t("manage_subjects")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-subjects-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("subjects_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/subjects/add`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("add_subject")} src="assets/images/subject-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("add_subject")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/subjects`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("manage_subjects")} src="assets/images/subject-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("manage_subjects")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonTitle id="open-timetable-info" className='border-bottom-sec'>
                                        {t("manage_time_tables")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    
                                    <IonPopover trigger="open-timetable-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                        {t("timetable_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/timeslots`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("manage_timeslots")} src="assets/images/timetable.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("manage_timeslots")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/timetables/add`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("new_timetable")} src="assets/images/timetable.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("new_timetable")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/timetables`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("manage_timetables")} src="assets/images/class.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                    <IonButton  className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("manage_timetables")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/timetables/day`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("consolidated_timetable")} src="assets/images/institute.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("consolidated_timetable")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/timetables/teacher`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("teacher_timetable")} src="assets/images/teachers.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("teacher_timetable")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/substitutes`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className="ion-no-padding">
                                                        <img alt={t("substitutes")} src="assets/images/transaction.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                    <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("substitutes")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                
                            </IonRow>
                          
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default ManageTimeTables;