import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonReorder,
    IonReorderGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    ItemReorderEventDetail,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { addCircle, alertCircleOutline, arrowUndoCircle, documentText, toggle } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from 'react-router-dom';
import {useAuth} from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';

interface ParamTypes {
    courseID?: string,
    chapterID?: string
}

interface Course {
    _id: string,
    name: string,
    chapters: Chapter[],
    courseType: string,
    isActive: boolean
}
interface Chapter {
    _id: string,
    chapterName: string,
    isActive: boolean,
    order: number
}
interface Topic {
    _id: string,
    name: string,
    isActive: boolean,
    order?: number
}

const CourseTopics: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {courseID, chapterID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [courses, setCourses] = useState < Course[] > ([]);
    const [course, setCourse] = useState < Course > ();
    const [chapter, setChapter] = useState < string > ("");
    const [topics, setTopics] = useState < Topic[] > ([]);

    const handleCourse = (courseID : string) => {
        const theCourse = courses.find(val => val._id === courseID);
        if (theCourse)
        {
            setCourse(theCourse);
            setChapter("");
            setTopics([]);
        }
    }

    const handleChapter = (chapterID : string) => {

        setTopics([]);
        
        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const tops = await api.get(`/topics/getByChapter/${chapterID}`);
                setTopics(tops.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
            }
            setShowLoading(false);
        };

        if (chapterID !== "")
        {
            setChapter(chapterID);
            fetchUp();
        }
        
    }

    const handleUndo = () => {
        if (chapter !== "")
        {
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });
            const fetchUp = async () => {
                setShowLoading(true);
                try {
                    const tops = await api.get(`/topics/getByChapter/${chapter}`);
                    setTopics(tops.data);
    
                } catch (error : any) {
                    if (error.response !== undefined) 
                        setMessage(error.response.data.message);
                     else 
                        setMessage(t("something_went_wrong"));
                    setIserror(true);
                }
                setShowLoading(false);
            };
            fetchUp();
        }
    }

    const handleName = (name: string, index: number) => {
        let comps = [...topics];
        comps[index].name = name;
        setTopics(comps);
    }

    const handleDelete = (index: number) => {
        let comps = [...topics];
        if(comps[index]._id.length > 10)
        {
            comps[index].isActive = !comps[index].isActive;
        }
        else
        {
            if (index > -1) {
                comps.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        setTopics(comps);
    }

    const handleAdd = () => {
        if (chapter !== "")
        {
            let r = (Math.random() + 1).toString(36).substring(7);
            let comps = [...topics];
            comps.push({_id: r, name: '', isActive: true});
            setTopics(comps);
        }
    }


    const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {

        let from = event.detail.from;
        let to = event.detail.to;
        let toCopy = event.detail.to;

        let chaps = [...topics];

        if (to < from)
        {
            chaps[to] = {...topics[from]};
            to = to + 1;
            while (to <= from)
            {
                chaps[to] = {...topics[to-1]};
                to = to + 1;
            }
        }

        if (toCopy > from)
        {
            chaps[toCopy] = {...topics[from]};
            toCopy = toCopy - 1;
            while (toCopy >= from)
            {
                chaps[toCopy] = {...topics[toCopy+1]};
                toCopy = toCopy - 1;
            }
        }

        setTopics(chaps);
        
        event.detail.complete();
      }

    
    const handleTopics = () => {
        if (course)
        {
            if (!topics || (topics.length < 1)) {
                setMessage(t("topics_mandatory"));
                setIserror(true);
                return;
            }
    
            let sendTopics: Topic[] = [];
            for (let i = 0; i < topics.length; i++)
            {
                if (topics[i].name === "")
                {
                    setMessage(t("topic_name_mandatory"));
                    setIserror(true);
                    return;
                }
                sendTopics.push({
                    _id: topics[i]._id,
                    name: topics[i].name,
                    isActive: topics[i].isActive
                });
            }
    
            setShowLoading(true);
        
            const api = axios.create({
              baseURL: process.env.REACT_APP_API_URL,
              timeout: parseInt(process.env.REACT_APP_API_TO!),
              headers: {
                  'Authorization': 'Bearer ' + authInfo.token
                }
            });
    
            const chapterName = course!.chapters.find((val) => val._id === chapter)!.chapterName;
                       
            const chapterInfo = {
                chapterID,
                chapterName
            }
      
            const upTopic = (courseID: string, topics: Topic[]) => {
              return new Promise((resolve, reject) => {
                api.post('/topics/set', { courseID, chapterInfo, topics }).then(res => {
                  return resolve(res.data);
                }).catch(err => reject(err));
      
              });
            }
    
            upTopic(course._id, sendTopics)
            .then((data: any) => {
                setTopics(data);
                setPass(chapterName+t("has_been_updated"));
                setIspass(true);
                setShowLoading(false);
            
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            });
        }
    }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let instituteID: string = "";
        if (process.env.REACT_APP_APP_ID === 'com.bricked.stms')
        {
            instituteID = authInfo.user.instituteID;
        }
        else
        {
            instituteID = process.env.REACT_APP_APP_ID!;
        }

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get(`/courses/getAllInstitute/${instituteID}`);
                setCourses(result.data);

                if (courseID && chapterID)
                {
                    const res = await api.get('/courses/' + courseID);
                    setCourse(res.data);
                    setChapter(chapterID);

                    const tops = await api.get(`/topics/getByChapter/${chapterID}`);
                    if (tops.data.length) 
                    {
                        setTopics(tops.data);
                    }
                    else
                    {
                        const r = (Math.random() + 1).toString(36).substring(7);
                        setTopics([{_id: r, name: '', isActive: true}]);
                    }
                }

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(error.message);
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, courseID, chapterID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_topics")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_topics")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className='border-bottom-sec ion-padding-bottom'>
                            <IonRow>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("select_course")}</IonLabel>
                                                    <IonSelect value={course?._id}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("select_course")}
                                                            onIonChange={
                                                                (e) => e.detail.value && handleCourse(e.detail.value)
                                                        }>

                                                            {courses.map((course) => 
                                                                (<IonSelectOption key={course._id} value={course._id}>{course.name}</IonSelectOption>)
                                                            )}

                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("select_chapter")}</IonLabel>
                                                    <IonSelect value={chapter}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("select_chapter")}
                                                            onIonChange={
                                                                (e) => handleChapter(e.detail.value)
                                                        }>
                                                            
                                                            {(course) && courses.find(val => val._id === course._id)!.chapters.map((chapter) => 
                                                                (chapter.isActive) && 
                                                                (<IonSelectOption key={chapter._id} value={`${chapter._id}`}>{chapter.chapterName}</IonSelectOption>)
                                                            )}
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                       
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonGrid className="ion-no-padding">

                    <IonRow className='mt-30'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className='border-bottom-sec ion-padding-bottom'>
                            <IonRow>
                                <IonCol size="8" className='mt-05'>
                                    <IonText className='list-title ion-padding-start'>
                                    {t("term_topics")}
                                    </IonText>
                                </IonCol>
                                <IonCol size="2" className='ion-text-right'>
                                    <IonIcon size="large"
                                        icon={arrowUndoCircle}
                                        color='secondary'
                                        onClick={handleUndo}
                                    />
                                </IonCol>
                                <IonCol size="2" className='ion-text-center'>
                                    <IonIcon size="large"
                                        icon={addCircle}
                                        color='secondary'
                                        onClick={handleAdd}
                                    />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>

                    <IonRow className='mt-10'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                        {topics && (topics.length > 0) ? (
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                                            {topics.map((topic, i) => {
                                                return (
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        key={topic._id}>
                                                            <IonReorder slot="start"/>
                                                            <IonInput type="text"
                                                                    placeholder={t("topic_name")}
                                                                    value={topic.name}
                                                                    onIonInput={
                                                                        (e) => handleName(e.detail.value!, i)
                                                                }
                                                                readonly={!topic.isActive}
                                                                ></IonInput>
                                                            <IonButtons slot="end">
                                                                <IonButton onClick={() => handleDelete(i)}> 
                                                                    <IonIcon
                                                                    icon={toggle}
                                                                    color={topic.isActive ? "success" : "danger"}
                                                                    />
                                                                </IonButton>
                                                                {(authInfo.institute.exam) && (
                                                                <IonButton onClick={() => ((topic._id.length > 10))  ? history.push(`/app/courses/topic/${topic._id}`) : null }> 
                                                                    <IonIcon
                                                                    icon={documentText}
                                                                    color="primary"
                                                                    />
                                                                </IonButton>
                                                                )}
                                                            </IonButtons>
                                                            
                                                    </IonItem>
                                                )
                                                
                                                })
                                            }

                                    </IonReorderGroup>
                                </IonCardContent>
                            </IonCard>
                        ) : ( 
                            <IonCard className='red-card'>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_topics")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                            )
                        } 
                        </IonCol>
                    </IonRow>

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleTopics}
                                className="first-button"
                                fill="clear">{t("update_topics")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default CourseTopics;
