import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useState} from 'react';
import {useAuth} from '../../components/AuthContext';
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';

const SendNotification: React.FC = () => {
    const {authInfo} = useAuth()!;
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
   
    const [note, setNote] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");


    const handleSubmit = () => {
    
      setShowLoading(true);
  
      const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
          }
      });

      const send = (title: string, note: string) => {
        return new Promise((resolve, reject) => {
          api.post('/users/notification', { 'title': title, 'note': note }).then(res => {
    
            return resolve(true);
            
          }).catch(err => reject(err));

        });
      }

  
    send(title, note)
      .then(() => {
        
        setPass('Notification sent to all users!');
        setIspass(true);
        setShowLoading(false);
      })
      .catch(error => {
        if (error.response !== undefined)
            setMessage(error.response.data.message);
        else
            setMessage('Please try again afer sometime.');

        setIserror(true);
        setShowLoading(false);
      });
      
    }

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home" />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined}  color="secondary">Send Notification</IonTitle>
                   {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Send Notification</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh No!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert
                  isOpen={showAlert}
                  onDidDismiss={() => setShowAlert(false)}
                  header={'Confirm!'}
                  message={`Are you sure you want to send this notification to all Users?`}
                  buttons={[
                    {
                      text: 'No',
                      role: 'cancel',
                      cssClass: 'secondary',
                    },
                    {
                      text: 'Yes',
                      handler: () => {
                        handleSubmit();
                      }
                    }
                  ]}
                />

              <IonGrid className="ion-no-padding">

                  
                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Send Notification to All
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="floating" color="secondary">Title</IonLabel>
                                            <IonInput type="text"
                                                value={title}
                                                onIonInput={
                                                    (e) => setTitle(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="floating" color="secondary">Message</IonLabel>
                                            <IonInput type="text"
                                                value={note}
                                                onIonInput={
                                                    (e) => setNote(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top">
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={() => setShowAlert(true)}
                                expand="block"color="dark">Send Notification</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default SendNotification;
