import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, school } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface ClassInterface {
    classID: string,
    name: string,
    year: string,
    last: Date | string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

const AttendStatus: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [categories, setCategories] = useState < string[] > ([]);
    const [series, setSeries] = useState < number[] > ([]);
    const [total, setTotal] = useState < number > (0);
    const [present, setPresent] = useState < number > (0);
    const [series1, setSeries1] = useState < number[] > ([0, 0]);

    const options1 = {
        colors : ['#2dd36f', '#eb445a'],
        labels: [t("present"), t("absent")],
        dataLabels: {
            enabled: false
        },
        legend: {
            position: 'bottom' as 'bottom',
            labels: {
                colors: '#777'
            },
          }
    };

    const daySeries =  [{
        name: t("present"),
        data: series
        }];

    const options = {
        chart: {
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight' as 'straight'
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
            text: t("present")
            }
        },
        tooltip: {
            theme: 'dark'
        }
      };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    function getDate(utcdate: Date | string)
    {
        if (utcdate === 'no')
        {
            return t("na");
        }
        else
        {
            const bookDate = new Date(utcdate);
            return bookDate.toLocaleDateString('en-GB') +" "+ t("at") +" "+ bookDate.toLocaleTimeString('en-US');
        }
        
    }

    const handleSession = (sessionName : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/attends/status/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
    }


    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                setSessions(res.data);
                setClasses([]);

                const report = await api.get('/attends/reports/institute/' + authInfo.user.instituteID);

                setCategories(report.data.categories);
                setSeries(report.data.series);

                setTotal(report.data.total);
                setPresent(report.data.today);

                let percent = Math.round((report.data.today/report.data.total)*100);

                setSeries1([percent, 100-percent]);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/student`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("attendance_report")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("attendance_report")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mb-60 max-xl">

                    <IonRow className='ion-margin-top'>
                        <IonCol size="12" sizeLg="6" className='ion-padding'>
                            <IonRow>
                                <IonCol>
                                    <IonCard className='action-card-s'>
                                        <IonCardHeader className="ion-text-center">
                                            <IonCardSubtitle>{t("today_attendance")}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent className="reports-attend">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size='4'>
                                                        
                                                                <h1>{t("present")} <IonText className='attend-badge' color="success">{present}</IonText></h1>
                                                                <br/>
                                                                <h1>{t("total")} <IonText className='attend-badge' >{total}</IonText></h1>
                                                        
                                                    </IonCol>
                                                    <IonCol size='8'>
                                                        <Chart options={options1} series={series1} type="donut" width={180} />
                                                    </IonCol>
                                                </IonRow>
                                            
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("last_10_days")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <Chart options={options} series={daySeries} type="line" height={400} />
                                </IonCol>
                            </IonRow>
                           
                        </IonCol>
                       
                        <IonCol size="12" sizeLg="6" className='ion-padding'>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("attendance_status")}
                                        </IonTitle>
                                </IonCol>
                           </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("class_session")}</IonLabel>
                                                    <IonSelect value={session}
                                                            className="input-field"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("class_session")}
                                                            onIonChange={
                                                                (e) => handleSession(e.detail.value)
                                                        }>

                                                            {sessions.map((session) => 
                                                                (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                            )}

                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                           
                           <IonRow className="ion-margin-vertical">
                                <IonCol>
                                     <IonList className="ion-no-padding">
                                    {(classes.length > 0) ? (classes.map((classi, i) => {
                                            return (
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    key={classi.classID}
                                                    className={((i%2) === 0) ? '' : 'alt-item'}
                                                    >
                                                    <IonIcon slot="start"
                                                        icon={school}
                                                        color="secondary" />
                                                    <IonLabel className='list-label'>
                                                        <h3>{classi.name} ({classi.year})<br/>
                                                        {(t("last_marked")+getDate(classi.last))}
                                                        </h3>
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        })) : ( 
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                                <IonLabel className="list-label"><h3>{(authInfo.institute.type === "school") ? t("no_classes") : t("no_batches")}</h3></IonLabel>

                                            </IonItem>
                                        )
                                        } 
                                    </IonList>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                   
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default AttendStatus;
