import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import {useAuth} from "../../../components/AuthContext";
import { useHistory, useParams } from 'react-router-dom';
import { businessOutline } from 'ionicons/icons';
import { GoogleMap, Marker, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import { Geolocation} from '@capacitor/geolocation';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    instituteID: string
}

interface Institute {
    name: string,
    address: string,
    phone: string,
    lat: number,
    lon: number
}

interface LatLon {
    lat: number,
    lon: number
}

const libraries = ["places" as "places"];

const InstituteLocation: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {instituteID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [message, setMessage] = useState < string > ("");
    const [institute, setInstitute] = useState < Institute > ();
    const [locate, setLocation] = useState < LatLon > ({lat: parseInt(process.env.REACT_APP_DEFAULT_LAT!), lon: parseInt(process.env.REACT_APP_DEFAULT_LNG!)});
    const [current, setCurrent] = useState < LatLon > ();
    const [place, setPlace] = useState < any > ();
    const placeRef = useRef < any > ();
    const mapRef = useRef < any > ();
    const markerRef = useRef < any > ();

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY!,
        libraries
      });

    if (loadError)
    {
        setMessage("Cant open Google Maps");
        setIserror(true);
    }

    const mapStyles = {        
        height: "52vh",
        width: "100%"
    };

    const handleMapLoad = (ref: any) => mapRef.current = ref;

    const handleMarkerLoad = (ref: any) => markerRef.current = ref;

    const handleSearchLoad = (ref: any) => placeRef.current = ref;

    const handlePlacesChanged = () => {
        if (placeRef.current && placeRef.current.getPlaces()[0])
        {
            setPlace(placeRef.current.getPlaces()[0]);
            setCurrent(undefined);
            
        }
    }

    const getCenter = () => {
        if(place && (place.geometry) && (place.geometry.location))
        {
            return place.geometry.location;
           
        }
        else if(current) 
        {
            return {lat: current.lat, lng: current.lon};
        }
        else if(locate) 
        {
            return {lat: locate.lat, lng: locate.lon};
        }
        else
        {
            return {lat: parseInt(process.env.REACT_APP_DEFAULT_LAT!), lng: parseInt(process.env.REACT_APP_DEFAULT_LNG!)};
        }
    }

    const handleCurrent = async () => {

        if (isPlatform('capacitor'))
        {
            Geolocation.checkPermissions().then(async (res) => {
            if (res.location !== 'granted') {
                Geolocation.requestPermissions().then(async (ress) => {
                    if (ress.location === 'denied') {
                        setPass('Location permission denied. You won\'t be able to update location!');
                        setIspass(true);
                    }
                    else {
                        try {
                            const coordinates = await Geolocation.getCurrentPosition();
                           
                            setCurrent({lat: coordinates.coords.latitude, lon: coordinates.coords.longitude});
                            setPlace(undefined);

                        }
                        catch(error: any) {

                            setPass(error.message);
                            setIspass(true);

                        }
                    }
                });
                }
                else {
                    try {
                        const coordinates = await Geolocation.getCurrentPosition();
                       
                        setCurrent({lat: coordinates.coords.latitude, lon: coordinates.coords.longitude});
                        setPlace(undefined);

                    }
                    catch(error: any) {

                        setPass(error.message);
                        setIspass(true);

                    }
                }
            }).catch(err =>  {
                setMessage('Unable to access location. Please make sure location is turned on and location permission is granted and try again.');
                setIserror(true);
            });
        }
    }
    
    const handleLocation = async () => {
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upInsti = (instituteID: string, lat: number, lon: number) => {
          return new Promise((resolve, reject) => {
            api.put('/institutes/'+instituteID, { 'lat': lat, 'lon': lon }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }
        
        if (markerRef.current)
        {
            setShowLoading(true);
            try {
                const latLng = await markerRef.current.getPosition();

                upInsti(instituteID, latLng.lat(), latLng.lng())
                .then(data => {
            
                    setPass(data+' has been updated.');
                    setIspass(true);
                    setShowLoading(false);
                    
                })
                .catch((error: any) => {
                    if (error.response !== undefined) 
                            setMessage(error.response.data.message);
                        else 
                            setMessage(error.message);
                        
                    setIserror(true);
                    setShowLoading(false);
                });
            }
            catch(error: any) {
                if (error.response !== undefined) 
                        setMessage(error.response.data.message);
                    else 
                        setMessage(error.message);
                    
                setIserror(true);
                setShowLoading(false);
            }
        }
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const insti = await api.get(`/institutes/${instituteID}`);
                setInstitute(insti.data);
                if (insti.data.lat && insti.data.lon)
                {
                    setLocation({lat: insti.data.lat, lon: insti.data.lon});
                }
                    
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(error.message);
                
                setIserror(true);
                setShowLoading(false);
            }
        };

      
        fetchData();
     
    }, [authInfo, instituteID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/institutes" />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined}  color="secondary">Institute Location</IonTitle>
                   {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Institute Location</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert
                    isOpen={iserror}
                    onDidDismiss={ () => history.push(`/app/home`)}
                    header="Theres a problem"
                    message={message}
                    buttons={["Close"]}
                />

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => window.location.reload()
                    }
                    header="Result"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className='ion-margin-top'>
                        <IonCol className="ion-padding-horizontal ion-text-center">
                            <IonText className='info-text'>
                                Move the pin on the map and press 'Update Location' to change {institute?.name}'s location
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol className="ion-text-center">
                            <IonButton color="primary" onClick={handleCurrent}>Use Current Location</IonButton>    
                        </IonCol>
                    </IonRow>
                 
                    <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin">
                            <IonCard className="white-shade">
                                <IonCardContent className="ion-no-padding map-container-small">
                                    {isLoaded ? (
                                        <GoogleMap
                                        onLoad={handleMapLoad}
                                        mapContainerStyle={mapStyles}
                                        zoom={14}
                                        center={getCenter()}
                                        clickableIcons={false}
                                        options={{disableDefaultUI: true}}
                                        >                               
                                         <StandaloneSearchBox
                                            onLoad={handleSearchLoad}
                                            onPlacesChanged={handlePlacesChanged}
                                            >
                                            <input
                                                type="text"
                                                placeholder="&#x1F50D;&#xFE0E; Search for any location"
                                                style={{
                                                    boxSizing: `border-box`,
                                                    border: `2px solid transparent`,
                                                    width: `100%`,
                                                    height: `48px`,
                                                    padding: `10px`,
                                                    borderRadius: `5px`,
                                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                    fontSize: `14px`,
                                                    outline: `none`,
                                                    textOverflow: `ellipses`,
                                                    position: `absolute`,
                                                    left:  `0%`
                                                    }}
                                            />
                                            </StandaloneSearchBox>     

                                            {current && 
                                            <Marker onLoad={handleMarkerLoad} zIndex={50} position={{lat: current.lat, lng: current.lon}} draggable={true} />
                                           }
                                           
                                            {place && (place.geometry) && (place.geometry.location) && 
                                            <Marker onLoad={handleMarkerLoad} zIndex={50} title={place.name} position={place.geometry.location} draggable={true} />
                                           }

                                           {!current && !place && locate &&
                                            <Marker onLoad={handleMarkerLoad} zIndex={50} position={{lat: locate.lat, lng: locate.lon}} draggable={true} />
                                           }
                                        </GoogleMap>
                                    ) : ( 
                                        <div className="load-body">
                                            <div className="load-wrapper">
                                                <span className="circle circle-1"></span>
                                                <span className="circle circle-2"></span>
                                                <span className="circle circle-3"></span>
                                                <span className="circle circle-4"></span>
                                                <span className="circle circle-5"></span>

                                            </div>
                                            <div className="load-title">
                                                Loading Maps...
                                            </div>
                                        </div>

                                        )}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-no-padding ion-no-margin">
                        <IonCol className="ion-no-padding ion-no-margin">
                            <IonCard className="action-card-b">
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="2" className="ion-padding-top">
                                                <IonIcon className='second-icon'
                                                    icon={businessOutline}
                                                    color='secondary'/>
                                                </IonCol>
                                                <IonCol size="10">
                                                    <IonText className='action-text'>{institute?.name}<br/>
                                                    {institute?.phone}<br/>
                                                    {institute?.address}
                                                    </IonText>
                                                </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton expand="block" 
                            fill="clear"
                            className='first-button'
                             onClick={handleLocation}>Update Location</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default InstituteLocation;
