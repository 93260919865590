import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useAuth} from "../../../components/AuthContext";
import { useParams } from 'react-router-dom';
import { business, busOutline, person } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

interface ParamTypes {
    tripID: string,
    studentID: string
}

interface TripStudent {
    studentID: string,
    name: string,
    order: number,
    status: string,
    doneAt: Date
}

const TripDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {tripID, studentID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [status, setStatus] = useState < string > ("");
    const [students, setStudents] = useState < TripStudent[] > ([]);

    const [startedDate, setStartedDate] = useState < Date > ();
    const [finishedDate, setFinishedDate] = useState < Date > ();
    const [institute, setInstitute] = useState < string > ("");

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
      };
   
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/trips/' + tripID);
                setStudents(result.data.students);
                setName(result.data.name);
                setType(result.data.type);
                setStatus(result.data.status);

                if(result.data.status === 'completed')
                {
                    setStartedDate(result.data.startedAt);
                    setFinishedDate(result.data.finishedAt);
                }

                const insti = await api.get(`/institutes/${result.data.instituteID}`);
                setInstitute(insti.data.name);

                setShowLoading(false);
            } catch (error: any) {  
                
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage(t("something_went_wrong"));

                setIserror(true);
                setShowLoading(false);
            }
        };
         
        fetchData();

    }, [authInfo, tripID]);

    return (
        <IonPage>
           <IonHeader>
            <IonToolbar className="white-shade">
                <IonButtons slot="start">
                    <IonMenuButton color="secondary" />
                    <IonBackButton color="secondary" defaultHref={`/app/home`} />
                </IonButtons>
                <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("trip_details")}</IonTitle>
                {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
            </IonToolbar>
        </IonHeader>

        <IonContent fullscreen={true} className="grey-shade">

            <IonHeader collapse="condense">
                <IonToolbar>
                    <IonTitle size="large" color="secondary">{t("trip_details")}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonLoading isOpen={showLoading}
                cssClass="first-loading"
                spinner="circular"
                message={t("please_wait")}
            />

            <IonAlert isOpen={iserror}
                cssClass="first-alert"
                onDidDismiss={
                    () => setIserror(false)
                }
                header={t("error")}
                message={message}
                buttons={
                    [`${t("close")}`]
                }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className={(type === 'drop') ? "action-card-b" : "action-card-c"}>
                                <IonCardContent>

                                        <IonRow>
                                            <IonCol size="2">
                                                <IonIcon className='third-icon'
                                                    icon={busOutline}
                                                    color={type === 'drop' ? 'secondary' : 'primary'} />
                                                </IonCol>
                                                <IonCol size="10" className="ion-text-center mt-05">
                                                    <IonText className='action-text-3'>{name} ({type === "pickup" ? t("pickup") : t("drop")})
                                                    </IonText>
                                                </IonCol>
                                        </IonRow>

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                  
                  
                    <IonRow>
                    <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <VerticalTimeline layout='1-column' animate={false} lineColor="#086E7D">
                                                <VerticalTimelineElement
                                                    className="vertical-timeline-element"
                                                    icon={ <IonIcon icon={business} className='timeline-icon' />}
                                                    iconStyle={{ background: 'rgb(8, 110, 125)', color: '#fff', textAlign: 'center' }}>
                                                    <IonText className='timeline-para'>
                                                        {t("started_from")} {institute} {t("on")}
                                                      
                                                    </IonText>
                                                    {startedDate && (
                                                    <IonText className='timeline-date'>
                                                        <br/>
                                                        {formatDate(startedDate)}
                                                    </IonText>
                                                    )}
                                                   
                                                </VerticalTimelineElement> 
                                            {
                                                students.map(student => {
                                                return (
                                               
                                                (student.studentID === studentID) && (
                                                <VerticalTimelineElement
                                                    key={student.studentID}
                                                    className="vertical-timeline-element"
                                                    icon={ <IonIcon icon={person} className='timeline-icon' />}
                                                    contentStyle={{}}
                                                    contentArrowStyle={{}}
                                                    iconStyle={{ background: 'rgb(8, 110, 125)', color: '#fff', textAlign: 'center' }}>
                                                    <IonText className='timeline-para'>
                                                        <IonText className="text-capitalize">{student.name} </IonText>
                                                        {student.status === 'picked' ? t("was_picked")+" "+t("on") : ""} 
                                                        {student.status === 'dropped' ? t("was_dropped")+" "+t("on") : ""} 
                                                        {student.status === 'absent' ? t("was_absent") : ""} 
                                                        {(student.status === 'upcoming') && (type === 'pickup') ? t("was_picked") : ""} 
                                                        {(student.status === 'upcoming') && (type === 'drop') ? t("was_dropped") : ""}
                                                    </IonText>
                                                    {student.doneAt && (
                                                    <IonText className='timeline-date'>
                                                        <br/>
                                                        {formatDate(student.doneAt)}
                                                    </IonText>
                                                    )}   
                                                </VerticalTimelineElement> 
                                                )
                                                    )
                                                })
                                            }

                                                <VerticalTimelineElement
                                                    className="vertical-timeline-element"
                                                    icon={ <IonIcon icon={business} className='timeline-icon' />}
                                                    iconStyle={{ background: 'rgb(8, 110, 125)', color: '#fff', textAlign: 'center' }}>
                                                    <IonText className='timeline-para'>
                                                        {t("reached_at")} {institute} {t("on")}
                                                    </IonText>
                                                    {finishedDate && (
                                                    <IonText className='timeline-date'>
                                                        <br/>
                                                        {formatDate(finishedDate)}
                                                    </IonText>
                                                    )}
                                                </VerticalTimelineElement> 
                            
                            </VerticalTimeline>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default TripDetail;
