import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useAuth} from "../../components/AuthContext";
import { useHistory } from 'react-router-dom';
import { alertCircleOutline } from 'ionicons/icons';
import {Calendar, Day} from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

interface Student {
    _id: string,
    name: string,
    dob: Date
}
interface Attend {
    year: number,
    month: number,
    day: number,
    className: string
}

const Attendance: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();

    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [student, setStudent] = useState < Student > ();
    const [selectedDate, setSelectedDate] = useState < string > ('');
    const [minDay, setMinDay] = useState < Day > ();
    const [maxDay, setMaxDay] = useState < Day > ();
    const [attend, setAttend] = useState < Attend[] > ([]);

      const formatDate = (value: string) => {
            const monthNames = ["January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"
                            ];

            const valueDate = new Date(value);
            return monthNames[valueDate.getMonth()] +' - '+ valueDate.getFullYear();
      };

      const getDaysInMonth = (year: number, month: number) => {
        return new Date(year, month, 0).getDate();
      }

      const handleSubmit = (theDate: string | string[]) => {

        if (typeof(theDate) === "string" && student)
        {
            setSelectedDate(theDate);

            const onDate = new Date(theDate);

            const minDate = {
                year: onDate.getFullYear(),
                month: onDate.getMonth()+1,
                day: 1
            }

            setMinDay(minDate);

            const maxDate = {
                year: onDate.getFullYear(),
                month: onDate.getMonth()+1,
                day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1)
            }

            setMaxDay(maxDate);

            setShowLoading(true);

            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });

            const fetchUp = async () => {

                try {
                    const studs = await api.get(`/attends/getByStudent/${student._id}/${theDate}`);

                    let result: Array < Attend > = [];

                for (let i = 0; i < studs.data.length ; i++) {
                        let dated: Date = new Date(studs.data[i].dated);
                        let dateA: number = dated.getDate();

                        let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: studs.data[i].status}; 
                        result.push(calObj);
                
                }
                setAttend(result);
                

                } catch (error : any) {
                    if (error.response !== undefined) 
                        setMessage(error.response.data.message);
                    else 
                        setMessage('Something went wrong...');
                    setIserror(true);
                }
                setShowLoading(false);
            };

            fetchUp();
        }
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get(`/students/getByAccount/${authInfo.user._id}`);
                setStudent(result.data);

                const onDate = new Date(Date.now());
                setSelectedDate(onDate.toISOString());
    
                const minDate = {
                    year: onDate.getFullYear(),
                    month: onDate.getMonth()+1,
                    day: 1
                }
    
                setMinDay(minDate);
    
                const maxDate = {
                    year: onDate.getFullYear(),
                    month: onDate.getMonth()+1,
                    day: getDaysInMonth(onDate.getFullYear(), onDate.getMonth()+1)
                }
    
                setMaxDay(maxDate);
    
                const studs = await api.get(`/attends/getByStudent/${result.data._id}/${onDate.toISOString()}`);
    
                let res: Array < Attend > = [];
    
                    for (let i = 0; i < studs.data.length ; i++) {
                            let dated: Date = new Date(studs.data[i].dated);
                            let dateA: number = dated.getDate();
    
                            let calObj: Attend = {year: onDate.getFullYear(), month: onDate.getMonth()+1, day: dateA, className: studs.data[i].status}; 
                            res.push(calObj);
                    
                    }

                setAttend(res);
                    
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(error.message);
                
                setIserror(true);
                setShowLoading(false);
            }
        };

      
        fetchData();
     
    }, [authInfo]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app" />
                    </IonButtons>
                    <IonTitle color="secondary">My Attendance</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">My Attendance</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert
                    isOpen={iserror}
                    onDidDismiss={ () => history.push(`/app`)}
                    header="Theres a problem"
                    message={message}
                    buttons={["Close"]}
                />


                <IonGrid className="ion-no-padding">
                    
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={true}
                                            id="open-date-input">
                                            <IonLabel position="floating" color="secondary">Month</IonLabel>
                                            <IonInput value={selectedDate === '' ? "Pick a Month" : formatDate(selectedDate)} readonly={true} />
                                            <IonPopover trigger="open-date-input" showBackdrop={false} size='cover'>
                                                <IonDatetime
                                                    value={selectedDate}
                                                    presentation="month-year"
                                                    onIonChange={ev => handleSubmit(ev.detail.value!)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonPopover>
                                        </IonItem>


                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                   Attendance Logs
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding">
                        <IonCol>
                    {(attend.length > 0) ? (
                   
                            <Calendar 
                                value={minDay}
                                minimumDate={minDay}
                                maximumDate={maxDay}
                                shouldHighlightWeekends={true}
                                customDaysClassName={attend}
                                calendarSelectedDayClassName="selected-day"
                                calendarClassName="custom-calendar"
                            />
                       
                    ) : (
                        <IonCard className='action-card-b'>
                        <IonCardContent className="ion-no-padding ion-no-margin">
                            <IonItem lines="none">
                                <IonIcon icon={alertCircleOutline}
                                slot="start"
                                color="danger" />
                                <IonLabel className="list-label"><h3>No Attendance</h3></IonLabel>

                            </IonItem>
                        </IonCardContent>
                        </IonCard>
                    )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Attendance;
