import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';

interface ParamTypes {
    instituteID: string
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

interface Parent {
    _id: string,
    name: string,
    isActive: boolean
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean,
    order: number
}


const AddStudent: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {instituteID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [address, setAddress] = useState < string > ("");
    const [parentName, setParentName] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [newPass, setNewPass] = useState < string > ("");
    const [confirmPass, setConfirmPass] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [parent, setParent] = useState < Parent > ();
    const [studEmail, setStudEmail] = useState < string > ("");
    const [studNewPass, setStudNewPass] = useState < string > ("");
    const [studConfirmPass, setStudConfirmPass] = useState < string > ("");
    const [additional, setAdditional] = useState < boolean > (false);
    const [houses, setHouses] = useState < string[] > ([]);
    const [house, setHouse] = useState < string > ("");
    const [blood, setBlood] = useState < string > ("");
    const [gender, setGender] = useState < string > ("");
    const [category, setCategory] = useState < string > ("general");
    const [disability, setDisability] = useState < boolean > (false);
    const [disableInfo, setDisableInfo] = useState < string > ("");
    const [medicalInfo, setMedicalInfo] = useState < string > ("");
    const [year, setYear] = useState < string > ("");

    const validateEmail = (email : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    }

    const validatePhone = (phone : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    }

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
      };

    const handleSession = (sessionName : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${instituteID}/${sessionName}`);
                setClasses(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();
        setYear(sessionName);
        setClassi("");
    }


    const handleSubmit = () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
              }
          });

        const addStudent = ( name: string, address: string, parentName: string, email: string, phone: string, classID: string, className: string, session: string, password: string, dob: string, gender: string) => {
            return new Promise((resolve, reject) => {
                api.post('/students/add', { instituteID, name, address, parentName, email, phone, classID, className, session, password, dob, house, gender, category, blood, disability, disableInfo, medicalInfo, 'isDefault': 'yes' }).then(res => {
        
                return resolve(res.data);
                
                }).catch(err => reject(err));

            });
        }

        const addStudentAccount = ( name: string, address: string, parentName: string, email: string, phone: string, classID: string, className: string, session: string, password: string, dob: string, studEmail: string, studPass: string, gender: string) => {
            return new Promise((resolve, reject) => {
                api.post('/students/add', { instituteID, name, address, parentName, email, phone, classID, className, session, password, dob, house, gender, category, blood, disability, disableInfo, medicalInfo, studEmail, studPass, 'isDefault': 'yes' }).then(res => {
        
                return resolve(res.data);
                
                }).catch(err => reject(err));

            });
        }

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (!parentName || parentName === "") {
            setMessage(t("parent_mandatory"));
            setIserror(true);
            return;
        }

        if (!address || address === "") {
            setMessage(t("address_mandatory"));
            setIserror(true);
            return;
        }

        if (!gender || gender === "") {
            setMessage(t("gender_mandatory"));
            setIserror(true);
            return;
        }

        if (!classi || classi === "") {
            setMessage(t("class_mandatory"));
            setIserror(true);
            return;
        }

        if (newPass !== confirmPass)
        {
            setMessage(t("password_mismatch"));
            setIserror(true);
            setShowLoading(false);
            return;
        }

        if (!newPass || newPass.length < 8) {
            setMessage(t("invalid_password"));
            setIserror(true);
            setShowLoading(false);
            return;
        }

        if (validateEmail(email) === false) {
            setMessage(t("invalid_email"));
            setIserror(true);
            return;
        }

        if (validatePhone(phone) === false) {
            setMessage(t("invalid_mobile"));
            setIserror(true);
            return;
        }

        if (typeof selectedDate !== "string")
        {
            setMessage(t("dob_mandatory"));
            setIserror(true);
            return;
        }

        if(disability)
        {
            if (!disableInfo || disableInfo === "") {
                setMessage(t("disability_mandatory"));
                setIserror(true);
                return;
            }
        }

        const classObj = classes.find(e => e._id === classi)!;
        const className = classObj.name + " (" + classObj.year + ")";
        const session = classObj.year;

        if (additional)
        {
            if (validateEmail(studEmail) === false) {
                setMessage(t("invalid_student_email"));
                setIserror(true);
                return;
            }
    
            if (studNewPass !== studConfirmPass)
            {
                setMessage(t("password_mismatch"));
                setIserror(true);
                setShowLoading(false);
                return;
            }
    
            if (!studNewPass || studNewPass.length < 8) {
                setMessage(t("invalid_password"));
                setIserror(true);
                setShowLoading(false);
                return;
            }

            setShowLoading(true);

            addStudentAccount(name, address, parentName, email, phone, classi, className, session, newPass, selectedDate, studEmail, studNewPass, gender)
              .then((data: any) => {
                
                  if (data.student)
                  {
                    setPass(data.student.name+t("has_been_added"));
                    setIspass(true);
                  }
                  if (data.parent)
                  {
                    setParent(data.parent);
                    setShowAlert(true);
                    
                  }
                  setShowLoading(false);
              })
              .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("try_again"));
        
                setIserror(true);
                setShowLoading(false);
              });

        }
        else
        {
            setShowLoading(true);

            addStudent(name, address, parentName, email, phone, classi, className, session, newPass, selectedDate, gender)
              .then((data: any) => {
                
                  if (data.student)
                  {
                    setPass(data.student.name+t("has_been_added"));
                    setIspass(true);
                  }
                  if (data.parent)
                  {
                    setParent(data.parent);
                    setShowAlert(true);
                    
                  }
                  setShowLoading(false);
              })
              .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("try_again"));
        
                setIserror(true);
                setShowLoading(false);
              });
        }

      }

      const handleSecondary = () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
              }
          });
    
        const addStudent = ( name: string, address: string, classID: string, className: string, session: string, dob: string, parentID: string, gender: string ) => {
            return new Promise((resolve, reject) => {
                api.post('/students/add', { instituteID, classID, className, session, name, address, dob, house, gender, category, blood, disability, disableInfo, medicalInfo, parentID, 'isDefault': 'no' }).then(res => {
                    return resolve(res.data.student.name);
                }).catch(err => reject(err));
            });
        }

        const addStudentAccount = ( name: string, address: string, classID: string, className: string, session: string, dob: string, parentID: string, studEmail: string, studPass: string, gender: string) => {
            return new Promise((resolve, reject) => {
                api.post('/students/add', { instituteID, name, address, classID, className, session, dob, house, gender, category, blood, disability, disableInfo, medicalInfo, parentID, studEmail, studPass, 'isDefault': 'no' }).then(res => {
                    return resolve(res.data.student.name);
                }).catch(err => reject(err));
            });
        }

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        if (!address || address === "") {
            setMessage(t("address_mandatory"));
            setIserror(true);
            return;
        }

        if (!gender || gender === "") {
            setMessage(t("gender_mandatory"));
            setIserror(true);
            return;
        }

        if (!classi || classi === "") {
            setMessage(t("class_mandatory"));
            setIserror(true);
            return;
        }

        if (typeof selectedDate !== "string")
        {
            setMessage(t("dob_mandatory"));
            setIserror(true);
            return;
        }

        if(disability)
        {
            if (!disableInfo || disableInfo === "") {
                setMessage(t("disability_mandatory"));
                setIserror(true);
                return;
            }
        }

        const classObj = classes.find(e => e._id === classi)!;
        const className = classObj.name;
        const session = classObj.year;

        if (additional)
        {
            if (validateEmail(studEmail) === false) {
                setMessage(t("invalid_student_email"));
                setIserror(true);
                return;
            }
    
            if (studNewPass !== studConfirmPass)
            {
                setMessage(t("password_mismatch"));
                setIserror(true);
                setShowLoading(false);
                return;
            }
    
            if (!studNewPass || studNewPass.length < 8) {
                setMessage(t("invalid_password"));
                setIserror(true);
                setShowLoading(false);
                return;
            }

            setShowLoading(true);

            addStudentAccount(name, address, classi, className, session, selectedDate, parent!._id, studEmail, studNewPass, gender)
            .then((data) => {
        
                setPass(data+t("has_been_added"));
                setIspass(true);
              setShowLoading(false);
             
            })
            .catch(error => {
              if (error.response !== undefined)
                  setMessage(error.response.data.message);
              else
                  setMessage(t("try_again"));
      
              setIserror(true);
              setShowLoading(false);
            });

        }
        else
        {
            setShowLoading(true);

            addStudent(name, address, classi, className, session, selectedDate, parent!._id, gender)
            .then((data) => {
        
                setPass(data+t("has_been_added"));
                setIspass(true);
              setShowLoading(false);
             
            })
            .catch(error => {
              if (error.response !== undefined)
                  setMessage(error.response.data.message);
              else
                  setMessage(t("try_again"));
      
              setIserror(true);
              setShowLoading(false);
            });
        }
        
      }

      useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {

                const insti = await api.get(`/institutes/${instituteID}`);

                if(insti.data.houses)
                {
                    setHouses(insti.data.houses);
                }

                const res = await api.get(`/masters/getSessions`);
                const formFields: Session[] = res.data.classSessions;
                setSessions(formFields);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authInfo, instituteID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/${instituteID}/student`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_student")}</IonTitle>
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_student")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/${instituteID}/student`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    cssClass="first-alert"
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("sibling")}
                    message={t("sibling_alert_1")+parent?.name+t("sibling_alert_2")}
                    buttons={[
                        {
                        text: `${t("no")}`,
                        role: 'cancel',   
                        cssClass: 'secondary',
                        },
                        {
                        text: `${t("yes")}`,
                        handler: () => {
                            handleSecondary();
                        }
                        }
                    ]}
                />


                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("basic_info")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("student_name")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("student_name")}
                                                        value={name}
                                                        onIonChange={
                                                            (e) => setName(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("student_gender")}*
                                                    </IonLabel>
                                                    <IonSelect value={gender}
                                                        className="input-field"
                                                        placeholder={t("student_gender")}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        onIonChange={
                                                            (e) => setGender(e.detail.value)
                                                        }>
                                                    
                                                        <IonSelectOption value="male">{t("male")}</IonSelectOption>
                                                        <IonSelectOption value="female">{t("female")}</IonSelectOption> 
                                                        
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-date-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("student_dob")}*
                                                    </IonLabel>
                                                    <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7]} handle={false}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("student_dob")}</IonTitle>
                                                                
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={selectedDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                        
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("student_address")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("student_address")}
                                                        value={address}
                                                        onIonChange={
                                                            (e) => setAddress(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("student_category")}*
                                                    </IonLabel>
                                                    <IonSelect value={category}
                                                        className="input-field"
                                                        placeholder={t("student_category")}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        onIonChange={
                                                            (e) => setCategory(e.detail.value)
                                                        }>
                                                    
                                                        <IonSelectOption value="general">{t("general")}</IonSelectOption>
                                                        <IonSelectOption value="ews">{t("ews")}</IonSelectOption> 
                                                        
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("class_session")}*
                                                </IonLabel>
                                                    <IonSelect value={year}
                                                            className="input-field"
                                                            placeholder={t("class_session")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => handleSession(e.detail.value)
                                                        }>

                                                            {sessions.length > 1 && sessions.map((session) => (
                                                                        (session.isActive) && 
                                                                        (<IonSelectOption key={session._id} value={session.name}>{session.name}</IonSelectOption>)
                                                                    ))}

                                                            
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("select_class")}*
                                                </IonLabel>
                                                    <IonSelect value={classi}
                                                            className="input-field"
                                                            placeholder={t("select_class")}
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            onIonChange={
                                                                (e) => setClassi(e.detail.value)
                                                        }>

                                                            { classes.map((classy) => {
                                                                    if (classy.isActive)
                                                                    {
                                                                        return (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name} ({classy.year})</IonSelectOption>);
                                                                    }
                                                                    else
                                                                    {
                                                                        return null;
                                                                    }
                                                                })
                                                            }
                                                    </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("student_house")}
                                                </IonLabel>
                                                <IonSelect value={house}
                                                    className="input-field"
                                                    placeholder={t("student_house")}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    onIonChange={
                                                        (e) => setHouse(e.detail.value)
                                                    }>
                                                    { houses.map((house, i) => {
                                                            
                                                        return (<IonSelectOption key={i} value={`${house}`}>{house}</IonSelectOption>);
                                                            
                                                        })
                                                    }
                                                </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow> 

                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("medical_info")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow className="mt-30">
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("student_blood")}
                                                    </IonLabel>
                                                    <IonSelect value={blood}
                                                        className="input-field"
                                                        placeholder={t("student_blood")}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        onIonChange={
                                                            (e) => setBlood(e.detail.value)
                                                        }>
                                                    
                                                        <IonSelectOption value="A+">A+</IonSelectOption>
                                                        <IonSelectOption value="A-">A-</IonSelectOption> 
                                                        <IonSelectOption value="B+">B+</IonSelectOption>
                                                        <IonSelectOption value="B-">B-</IonSelectOption>      
                                                        <IonSelectOption value="O+">O+</IonSelectOption>
                                                        <IonSelectOption value="O-">O-</IonSelectOption>      
                                                        <IonSelectOption value="AB+">AB+</IonSelectOption>
                                                        <IonSelectOption value="AB-">AB-</IonSelectOption>         
                                                        
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                color="tertiary"
                                                >
                                                <IonLabel className="input-label" color="secondary">{t("student_disabled")}</IonLabel>
                                                <IonToggle color="secondary" checked={disability} onIonChange={e => setDisability(e.detail.checked)} />
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            {disability && (
                                <IonRow>
                                    <IonCol >
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel className="input-label" position="floating" color="secondary">
                                                        {t("nature_of_disability")}
                                                    </IonLabel>
                                                    <IonTextarea rows={4} value={disableInfo} onIonChange={
                                                            (e) => setDisableInfo(e.detail.value!)
                                                    }></IonTextarea>
                                                
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                
                            )}
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel  className="input-label" position="floating" color="secondary">
                                                    {t("allergies")}
                                                </IonLabel>
                                                <IonTextarea rows={4} value={medicalInfo} onIonChange={
                                                        (e) => setMedicalInfo(e.detail.value!)
                                                }></IonTextarea>
                                                    
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>

                        </IonCol>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("parent_info")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("parent_name")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("parent_name")}
                                                        value={parentName}
                                                        onIonChange={
                                                            (e) => setParentName(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("parent_phone")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("parent_phone")}
                                                        value={phone}
                                                        onIonChange={
                                                            (e) => setPhone(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("parent_email")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("parent_email")}
                                                        value={email}
                                                        onIonChange={
                                                            (e) => setEmail(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("set_pass")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("set_pass")}
                                                        value={newPass}
                                                        onIonChange={
                                                            (e) => setNewPass(e.detail.value !)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("confirm_pass")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("confirm_pass")}
                                                        value={confirmPass}
                                                        onIonChange={
                                                            (e) => setConfirmPass(e.detail.value !)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                {/* FOR LMS
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                color="tertiary"
                                                >
                                                <IonLabel className="input-label" color="secondary">{t("student_account")}</IonLabel>
                                                <IonToggle color="secondary" checked={additional} onIonChange={e => setAdditional(e.detail.checked)} />
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                */}                 
                            {additional && (
                            <>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("student_lms")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("student_email")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("student_email")}
                                                        value={studEmail}
                                                        onIonChange={
                                                            (e) => setStudEmail(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("set_pass")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("set_pass")}
                                                        value={studNewPass}
                                                        onIonChange={
                                                            (e) => setStudNewPass(e.detail.value !)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("confirm_pass")}
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("confirm_pass")}
                                                        value={studConfirmPass}
                                                        onIonChange={
                                                            (e) => setStudConfirmPass(e.detail.value !)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            </>
                            )}
                        </IonCol>
                    </IonRow>
                  
                    

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                >{t("add_student")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddStudent;
