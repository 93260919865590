import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router';
import {useAuth} from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    adminID: string
}

interface Institute {
    _id: string,
    name: string,
    isActive: boolean,
    isChecked: boolean
}

const AdminDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {adminID} = useParams < ParamTypes > ();
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [institutes, setInstitutes] = useState < Institute[] > ([]);
    const [instituteID, setInstituteID] = useState < string > ("");
    const [role, setRole] = useState < string > ("");

    const validateEmail = (email : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    }

    const validatePhone = (phone : string) => { 
        // eslint-disable-next-line no-control-regex
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    }

    const handleReset = () => {
    
        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upUser = () => {
            return new Promise((resolve, reject) => {
              api.get('/users/reset/'+adminID).then(res => {
        
                return resolve(res.data.name);
                
              }).catch(err => reject(err));
    
            });
          }
      
      
        upUser()
          .then(data => {
    
              setPass(data+' password has been reset to 12345678.');
              setIspass(true);
              setShowLoading(false);
            
          })
          .catch(error => {
              if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Please try again afer sometime.');
            setIserror(true);
            setShowLoading(false);
          });
        
      }

    const handleSection = (section: string) => {
        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

    const toggleAdmin = (isActive: string) => {
        return new Promise((resolve, reject) => {

            api.put(`/users/${adminID}`, { 'isActive': section }).then(res => {
    
                return resolve(res.data.name);
                
              }).catch(err => reject(err));

        });
      }

      if ((active !== "") && (section !== active))
      {
            setShowLoading(true);
                toggleAdmin(section)
            .then(data => {

                setActive(section);

                setPass(data+' has been updated.');
                setIspass(true);
                setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage('Please try again afer sometime.');

                    setIserror(true);
                    setShowLoading(false);
                });
            }
    }

    const handleInstituteID = (value: string) => {
        let classic: Array < Institute > = [];
        for (let i = 0; i < institutes.length ; i++) {
            if (institutes[i]._id === value) {
                classic[i] = {'_id': institutes[i]._id, 'name': institutes[i].name, 'isActive': institutes[i].isActive, 'isChecked' : true};    
            }
            else 
            {
                classic[i] = {'_id': institutes[i]._id, 'name': institutes[i].name, 'isActive': institutes[i].isActive, 'isChecked' : false}; 
            }       
        }
        setInstitutes(classic);
        setInstituteID(value);
    }

    const handleInstiChecked = (value: boolean, id : number) => {

        let classicx: Array < Institute > = [];
        for (let i = 0; i < institutes.length ; i++) {
            if (i === id) {
                classicx[i] = {'_id': institutes[i]._id, 'name': institutes[i].name, 'isActive': institutes[i].isActive, 'isChecked' : value};    
            }
            else 
            {
                classicx[i] = institutes[i];
            }       
        }
        setInstitutes(classicx);
    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage("Name is a mandatory field!");
            setIserror(true);
            return;
        }

        if (validateEmail(email) === false) {
            setMessage("Thats an invalid email address!");
            setIserror(true);
            return;
        }

        if (validatePhone(phone) === false) {
            setMessage("Enter 10 digits mobile number!");
            setIserror(true);
            return;
        }

        let branches: string[] = [];
        if (role === "Super")
        {
            for (let i = 0; i < institutes.length ; i++) {
                if (institutes[i].isChecked)
                {
                    branches.push(institutes[i]._id);
                }
            }

            if (branches.length < 2) {
                setMessage("Select atleast 2 institutes.");
                setIserror(true);
                return;
            }
        }
        
    
        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upAdmin = () => {
          return new Promise((resolve, reject) => {
            api.put('/users/'+adminID, { instituteID, name, phone, email }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }

        const upSuper = (institutes: string[]) => {
            return new Promise((resolve, reject) => {
              api.put('/users/'+adminID, { instituteID, institutes, name, phone, email }).then(res => {
        
                return resolve(res.data.name);
                
              }).catch(err => reject(err));
    
            });
          }
    
          if (role === "Super")
          {
            upSuper(branches)
            .then(data => {
      
                setPass(data+' has been updated.');
                setIspass(true);
                setShowLoading(false);
              
            })
            .catch(error => {
              if (error.response !== undefined)
                  setMessage(error.response.data.message);
              else
                  setMessage('Please try again afer sometime.');
              setIserror(true);
              setShowLoading(false);
            });
          }
          else
          {
            upAdmin()
            .then(data => {
      
                setPass(data+' has been updated.');
                setIspass(true);
                setShowLoading(false);
              
            })
            .catch(error => {
              if (error.response !== undefined)
                  setMessage(error.response.data.message);
              else
                  setMessage('Please try again afer sometime.');
              setIserror(true);
              setShowLoading(false);
            });
          }
        
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/users/' + adminID);

                setName(result.data.name);
                setPhone(result.data.phone);
                setEmail(result.data.email);
                setRole(result.data.role);

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                if(result.data.instituteID !== "Content")
                {
                    const res = await api.get(`/institutes`);

                    let instis = res.data;
                    
                    if (result.data.role === "Super")
                    {
                        let classic: Institute[] = [];
                        for (let i = 0; i < instis.length ; i++) {
                            if (result.data.institutes.includes(instis[i]._id)) {
                                classic[i] = {'_id': instis[i]._id, 'name': instis[i].name, 'isActive': instis[i].isActive, 'isChecked' : true};    
                            }
                            else 
                            {
                                classic[i] = {'_id': instis[i]._id, 'name': instis[i].name, 'isActive': instis[i].isActive, 'isChecked' : false}; 
                            }       
                        }
                        instis = classic;
                    }

                    setInstitutes(instis);
                    setInstituteID(result.data.instituteID);

                }


                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, adminID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home" />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined}  color="secondary">Manage Admin</IonTitle>
                   {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Manage Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header="Success!"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-10">
                        <IonCol>
                            <IonCard className='border-radius-10 active-group'>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="8">
                                                <IonTitle className='list-title'>
                                                    Current Status 
                                                </IonTitle>
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                            {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>Active</IonBadge>) : 
                                            ( <IonBadge color="danger" className='ion-padding-horizontal'>Inactive</IonBadge>)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-top ion-padding-horizontal">
                                            <IonCol>
                                                <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                    <IonSegmentButton value="enable">
                                                        <IonLabel>Make Active</IonLabel>
                                                    </IonSegmentButton>
                                                    <IonSegmentButton value="disable">
                                                        <IonLabel>Make Inactive</IonLabel>
                                                    </IonSegmentButton>
                                                </IonSegment>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleReset}
                                expand="block" color="dark">Reset Password to 12345678</IonButton>

                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Admin's Details
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                                    <IonList className="ion-no-padding">
                                       
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Email</IonLabel>
                                            <IonInput type="text"
                                                value={email}
                                                placeholder="Email Address"
                                                onIonInput={
                                                    (e) => setEmail(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>


                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Name</IonLabel>
                                            <IonInput type="text"
                                                value={name}
                                                placeholder="Full Name"
                                                onIonInput={
                                                    (e) => setName(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Phone</IonLabel>
                                            <IonInput type="text"
                                                value={phone}
                                                placeholder="10 Digits Mobile No."
                                                onIonInput={
                                                    (e) => setPhone(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        {(instituteID !== "") && (
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                    <IonLabel position="fixed" color="secondary">Institute</IonLabel>
                                                        <IonSelect value={instituteID}
                                                                placeholder="Select Institute"
                                                                onIonChange={
                                                                    (e) => handleInstituteID(e.detail.value)
                                                            }>
                                                            { institutes.map((institute) => 
                                                                (institute.isActive) && 
                                                                (<IonSelectOption key={institute._id} value={institute._id}>{institute.name}</IonSelectOption>)
                                                            )}
                                                </IonSelect>
                                            </IonItem>
                                            )}

                                        {(role === "Super") && (
                                        <IonRow>
                                            <IonCol>
                                                <IonCard>
                                                    <IonCardContent className="ion-no-padding">
                                                        <IonList>
                                                
                                                            {institutes.map((insti, i) => (
                                                    
                                                                (insti.isActive) &&
                                                                (<IonItem key={insti._id}
                                                                    detail={false}
                                                                    lines='full'
                                                                    button={false}>
                                                                    <IonCheckbox slot="start" value={insti._id} checked={insti.isChecked} disabled={insti._id === instituteID}
                                                                        onIonChange={e => handleInstiChecked(e.detail.checked, i)}/>
                                                                    <IonLabel>{insti.name}</IonLabel>
                                                                </IonItem>)
                                                            ))}
                                                    </IonList>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                        )}

                                    </IonList>
                                
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                fill="clear"
                                className='first-button'
                                expand="block">Update Admin</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AdminDetail;
