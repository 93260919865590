import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { informationCircleOutline } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

interface Facility {
    _id: string,
    name: string,
    category: string,
    isActive: boolean,
    isChecked: boolean
}

const Facilities: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [categories, setCategories] = useState < string[] > ([]);

    const [allFacilities, setAllFacilities] = useState < Facility[] > ([]);

    const handleChecked = (value: boolean, id : number) => {

        let facx: Array < Facility > = [];
        for (let i = 0; i < allFacilities.length ; i++) {
            if (i === id) {
                facx[i] = {'_id': allFacilities[i]._id, 'name': allFacilities[i].name, 'category': allFacilities[i].category, 'isActive': allFacilities[i].isActive, 'isChecked' : value};    
            }
            else 
            {
                facx[i] = allFacilities[i];
            }       
        }
        setAllFacilities(facx);
    }
    

      const handleSubmit = () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
              'Authorization': 'Bearer ' + authInfo.token
              }
          });

          const updateFacilities = (facilities: string[]) => {
            return new Promise((resolve, reject) => {
              api.post('/institutes/updateFacilities', { 'instituteID': authInfo.user.instituteID, facilities }).then(res => {
                return resolve(res.data.institute.name);
              }).catch(err => reject(err));
            });
          }

            let newFacilities: string[] = [];

            for (let i=0 ; i < allFacilities.length ; i++)
                if (allFacilities[i].isChecked)
                {
                    newFacilities.push(allFacilities[i]._id);
                }

            setShowLoading(true);

            updateFacilities(newFacilities)
                .then(data => {
                    setShowLoading(false);
                    setPass(data+t("facilities_updated"));
                    setIspass(true);
                })
                .catch(error => {
                    if (error.response !== undefined) 
                        setMessage(error.response.data.message);
                    else 
                        setMessage(t("something_went_wrong"));
                    setIserror(true);
                    setShowLoading(false);
                });

    }

      useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/institutes/${authInfo.user.instituteID}`);

                const allFac = await api.get(`/masters/getFacilities`);
                const insituteFacilities = allFac.data.insituteFacilities;

                let facx: Array < Facility > = [];
                for (let i = 0; i < insituteFacilities.length ; i++) {
                    if (result.data.facilities && result.data.facilities.indexOf(insituteFacilities[i]._id) > -1) {
                        facx[i] = {'_id': insituteFacilities[i]._id, 'name': insituteFacilities[i].name, 'category': insituteFacilities[i].category, 'isActive': insituteFacilities[i].isActive, 'isChecked' : true};    
                    }
                    else 
                    {
                        facx[i] = {'_id': insituteFacilities[i]._id, 'name': insituteFacilities[i].name, 'category': insituteFacilities[i].category, 'isActive': insituteFacilities[i].isActive, 'isChecked' : false}; 
                    }       
                }
                setAllFacilities(facx);

                //const unique = [...new Set(facx.map(item => item.category))];
                //setCategories(unique);

                //temp fix for ion col height issue
                setCategories(["Classroom", "Lab", "Safety and Security" ,"Boarding", "Infrastructure", "Extra Curricular", "Sports and Fitness", "Advanced Facilities"]);

                setShowLoading(false);
            

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
           
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/admission`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("facilities")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("facilities")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>
                    
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                            {t("stats_info")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
 
              
                    <IonRow className="ion-margin-bottom">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                      
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{t("facilities")}</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                              
                                    <IonRow>
                                        
                                        {categories && categories.length > 0 && categories.map((category, i) => (
                                            <IonCol size="6" className="cat-info" key={i} >
                                                <IonItemDivider>
                                                    <IonLabel className="cat-label">{category}</IonLabel>
                                                </IonItemDivider>
                                                {allFacilities.length > 1 && allFacilities.map((fac, i) => (
                                                    (fac.isActive) && (fac.category === category) &&
                                                    (<IonItem key={fac._id}
                                                        detail={false}
                                                        lines='none'
                                                        button={false}>
                                                       <IonCheckbox slot="start" value={fac._id} checked={fac.isChecked}
                                                            onIonChange={e => handleChecked(e.detail.checked, i)} />
                                                        <IonLabel className="fac-label mt-15 ion-text-wrap" >{fac.name}</IonLabel>
                                                    </IonItem>)
                                                ))}

                                            </IonCol>
                                        ))}

                                        
                                        
                                        
                                    </IonRow>
                                          
                                </IonCardContent>
                            </IonCard>
                                      
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className='ion-text-center'>
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_facilities")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Facilities;
