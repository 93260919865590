import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    routeID: string
}

interface Driver {
    _id: string,
    name: string,
    phone: string,
    isActive: boolean
}
interface Vehicle {
    _id: string,
    name: string,
    plate: string,
    isActive: boolean
}

const RouteDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {routeID} = useParams < ParamTypes > ();
    const [active, setActive] = useState < string > ("");
    const [drivers, setDrivers] = useState < Driver[] > ([]);
    const [driver, setDriver] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [driverName, setDriverName] = useState < string > ("");
    const [driverPhone, setDriverPhone] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > ();
    const [vehicles, setVehicles] = useState < Vehicle[] > ([]);
    const [vehicle, setVehicle] = useState < string > ("");
    const [vehicleName, setVehicleName] = useState < string > ("");
    const [vehiclePlate, setVehiclePlate] = useState < string > ("");

    const handleDriver = (driverID : string) => {
        let selectedDriver: Driver | undefined;
        selectedDriver = drivers.find(x => x._id === driverID);
        if(selectedDriver)
        {
            setDriver(driverID);
            setDriverName(selectedDriver!.name);
            setDriverPhone(selectedDriver!.phone);
        }
        
    }

    const handleVehicle = (vehicleID : string) => {
        let selectedVehicle: Vehicle | undefined;
        selectedVehicle = vehicles.find(x => x._id === vehicleID);
        setVehicle(vehicleID);
        setVehicleName(selectedVehicle!.name);
        setVehiclePlate(selectedVehicle!.plate);
    }

    const handleSection = (section: string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

    const toggleRoute = (isActive: string) => {
        return new Promise((resolve, reject) => {

            api.put(`/routes/${routeID}`, { 'isActive': isActive }).then(res => {
    
                return resolve(res.data.name);
                
              }).catch(err => reject(err));

        });
      }

      if ((active !== "") && (section !== active))
      {
          setShowLoading(true);
            toggleRoute(section)
            .then(data => {
        
                setActive(section);
                setPass(data+t("has_been_updated"));
                setIspass(true);
                setShowLoading(false);
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));

                setIserror(true);
                setShowLoading(false);
            });
        }
    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("route_name_mandatory"));
            setIserror(true);
            return;
        }

        if (!type || type === "") {
            setMessage(t("route_type_mandatory"));
            setIserror(true);
            return;
        }

        if (!driver || driver === "") {
            setMessage(t("route_driver_mandatory"));
            setIserror(true);
            return;
        }

        if (!selectedDate || selectedDate === "") {
            setMessage(t("start_time_mandatory"));
            setIserror(true);
            return;
        }

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upRoute = (startTime: string | string[]) => {
          return new Promise((resolve, reject) => {
            api.put('/routes/'+routeID, { name, type, startTime, driver, driverName, driverPhone, vehicle, vehicleName, vehiclePlate }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }
    
        setShowLoading(true);
      upRoute(selectedDate)
        .then(data => {
  
            setPass(data+t("has_been_updated"));
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
            setIserror(true);
            setShowLoading(false);
        });
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/routes/' + routeID);

                const drive = await api.get(`/users/${result.data.instituteID}/drivers`);
                setDrivers(drive.data);

                const res = await api.get(`/vehicles/getAllInstitute/${result.data.instituteID}`);
                setVehicles(res.data);

                setName(result.data.name);
                setType(result.data.type);
                setDriver(result.data.driver.driverID);
                setDriverName(result.data.driver.name);
                if (result.data.driver.phone)
                {
                    setDriverPhone(result.data.driver.phone);
                }

                setSelectedDate(result.data.startTime);

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                if (result.data.vehicle)   
                {
                    setVehicle(result.data.vehicle.vehicleID);
                    setVehicleName(result.data.vehicle.name);
                    setVehiclePlate(result.data.vehicle.plate);
                }
                    

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, routeID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/routes"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_route")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_route")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

               <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>


                <IonGrid className="ion-no-padding mb-60">

                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("route_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='border-radius-10 active-group'>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="8">
                                                
                                                {t("current_status")}
                                               
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                            {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>{t("active")}</IonBadge>) : 
                                            ( <IonBadge color="danger" className='ion-padding-horizontal'>{t("inactive")}</IonBadge>)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-top ion-padding-horizontal">
                                            <IonCol>
                                                <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                    <IonSegmentButton value="enable">
                                                        <IonLabel>{t("make_active")}</IonLabel>
                                                    </IonSegmentButton>
                                                    <IonSegmentButton value="disable">
                                                        <IonLabel>{t("make_inactive")}</IonLabel>
                                                    </IonSegmentButton>
                                                </IonSegment>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("route_name")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("route_name")}
                                                value={name}
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("route_type")}
                                        </IonLabel>
                                            <IonSelect value={type}
                                                className="input-field" 
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                placeholder={t("route_type")}
                                                onIonChange={
                                                    (e) => setType(e.detail.value)
                                            }>
                                               
                                                <IonSelectOption value="pickup">{t("pickup")}</IonSelectOption>
                                                <IonSelectOption value="drop">{t("drop")}</IonSelectOption>      
                                                
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("route_driver")}
                                        </IonLabel>
                                            <IonSelect value={driver}
                                                className="input-field" 
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                placeholder={t("route_driver")}
                                                onIonChange={
                                                    (e) => e.detail.value && handleDriver(e.detail.value)
                                            }>
                                               
                                               { drivers.map((driver) => 
                                                    (driver.isActive) &&
                                                    (<IonSelectOption key={driver._id} value={`${driver._id}`}>{driver.name}</IonSelectOption>)
                                                )}
                                                
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("route_vehicle")}
                                        </IonLabel>
                                            <IonSelect value={vehicle}
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                                className="input-field" 
                                                placeholder={t("route_vehicle")}
                                                onIonChange={
                                                    (e) => e.detail.value && handleVehicle(e.detail.value)
                                            }>
                                               
                                               {vehicles.map((vehicle) => 
                                                 (vehicle.isActive) &&
                                                (<IonSelectOption key={vehicle._id} value={`${vehicle._id}`}>{vehicle.name}</IonSelectOption>)
                                                )}
                                                
                                            </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="fixed" className="input-label" color="secondary">
                                        {t("route_start")}
                                        </IonLabel>
                                            <IonDatetime
                                                value={selectedDate}
                                                presentation="time"
                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                            />
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
    
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_route")}</IonButton>

                            <IonButton onClick={() => history.push(`/app/mapstudents/${routeID}/none`)}
                                className="first-button ion-margin-start"
                                fill="clear">{t("route_students")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default RouteDetail;
