import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { alertCircleOutline, informationCircle, receipt } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import { useAuth } from '../../../components/AuthContext';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../components/usePageUnloadHandler';

interface Tran {
    _id: string,
    amount: number,
    invoiceDate: Date,
    dueDate: Date,
    dueComponents: Component[],
    paidAt?: Date
}

interface Component {
    amount: number,
    head?: FeeHead,
    frequency?: string,
    duration?: string,
    remarks?: string,
    isChecked?: boolean
}

interface FeeHead {
    headID: string,
    name: string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

interface Student {
    _id: string,
    name: string,

    isActive: boolean
}

interface ParamTypes {
    studentID?: string
}

const GiveConcession: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {studentID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [students, setStudents] = useState < Student[] > ([]);
    const [selectedStudent, setSelectedStudent] = useState <string> ("");
    const [transactions, setTransactions] = useState < Tran[] > ([]);
    const [initialRender, setInitialRender] = useState < boolean > (true);
    const [amount, setAmount] = useState < number > ();
    const [remarks, setRemarks] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > (new Date().toISOString());
    const [outstanding, setOutstanding] = useState < number > (0);

    // Create an instance of axios with default settings
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
    
    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const handleSubmit = () => {

        if(!selectedStudent || selectedStudent === "")
        {
            setMessage(t("student_mandatory"));
            setIserror(true);
            return;
        }

        if(!remarks || remarks === "")
        {
            setMessage(t("tran_remarks_mandatory"));
            setIserror(true);
            return;
        }

        if (!amount || amount === 0) {
            setMessage(t("tran_amount_mandatory"));
            setIserror(true);
            return;
        }

        if (typeof selectedDate !== "string")
        {
            setMessage(t("tran_date_mandatory"));
            setIserror(true);
            return;
        }


        const studentName = students.find(val => val._id === selectedStudent)!.name;
  
        setShowLoading(true);
        api.post(`/dues/giveConcession`, { 'instituteID': authInfo.user.instituteID, 'studentID': selectedStudent, studentName, 'adminID': authInfo.user._id, dated: selectedDate, amount, remarks })
        .then(res => {
          
          setPass(t("concession_given")+res.data.total);
          setIspass(true);
        })
        .catch((error) => {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        })
        .finally(() => setShowLoading(false));
        
      }

      const handleSession = (sessionName : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClassi("");
        setSelectedStudent("");
        setStudents([]);
    }

    const handleClassi = (classID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/getByClass/${classID}`);
                setStudents(studs.data);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setClassi(classID);
        setSelectedStudent("");
    }

    const handleStudent = (studentID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const stud = await api.get(`/students/${studentID}`);
                setOutstanding(stud.data.due);
                const inv = await api.get(`/invoices/getFiveByStudent/${studentID}`);
                setTransactions(inv.data);

                setShowLoading(false);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setSelectedStudent(studentID);
    }

      useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { students, sessions, classes, session, classi, selectedStudent, transactions } = JSON.parse(storedState);
            setStudents(students);
            setClasses(classes);
            setSessions(sessions);
            setSession(session);
            setClassi(classi);
            setSelectedStudent(selectedStudent);
            setTransactions(transactions);
        } 
        else 
        {

            const fetchData = async () => {
                setShowLoading(true);
                try {

                    const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                    setSessions(res.data);

                    if (studentID)
                    {
                        const stud = await api.get(`/students/${studentID}`);
                        setOutstanding(stud.data.due);

                        const classInfo = await api.get('/classes/' + stud.data.classID);
                        setSession(classInfo.data.sessionID);

                        const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${classInfo.data.sessionID}`);
                        setClasses(query.data);
                        setClassi(stud.data.classID);
                        const studs = await api.get(`/students/getByClass/${stud.data.classID}`);
                        setStudents(studs.data);
                        setSelectedStudent(studentID);

                        const inv = await api.get(`/invoices/getFiveByStudent/${studentID}`);
                        setTransactions(inv.data);
                    }
                    else
                    {
                        setSession("");
                        setClasses([]);
                        setClassi("");
                        setStudents([]);
                        setSelectedStudent("");
                        setTransactions([]);
                    }

                } catch (error: any) {
                    setMessage(error.response?.data.message || t("something_went_wrong"));
                    setIserror(true);
                } finally {
                    setShowLoading(false);
                }
            };

            fetchData();

        }
        setInitialRender(false);

    }, [authInfo, studentID, history.action, history.location.pathname]);
    
    // Save state to local storage whenever it changes
     useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
                students,
                sessions,
                classes,
                session,
                classi,
                selectedStudent,
                transactions,
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        }
    }, [students, sessions, classes, session, classi, selectedStudent, transactions, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();
  
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/accounting`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("give_concession")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("give_concession")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/accounting`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">
                    <IonRow className="max-xxl">
                        <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("select_student")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className='mt-30'>
                                <IonCol>
                                    <IonRow>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("class_session")}</IonLabel>
                                                            <IonSelect value={session}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={t("class_session")}
                                                                    onIonChange={
                                                                        (e) => handleSession(e.detail.value)
                                                                }>

                                                                    {sessions.map((session) => (
                                                                        (session.isActive) && 
                                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                                    ))}

                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonCard>
                                                <IonCardContent className="ion-no-padding">
                                                    <IonItem>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                                            <IonSelect value={classi}
                                                                    className="input-field"
                                                                    cancelText={t("cancel")}
                                                                    okText={t("ok")}
                                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                                    onIonChange={
                                                                        (e) => handleClassi(e.detail.value)
                                                                }>
                                                                    
                                                                    {classes.map((classy) => (
                                                                        (classy.isActive) &&
                                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                                    ))}
                                                            </IonSelect>
                                                        </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>

                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">                   
                                            <IonItem
                                            detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="stacked" className="input-label" color="secondary">{t("select_student")}</IonLabel>
                                                <IonSelect value={selectedStudent}
                                                            className="input-field text-capitalize"
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("select_student")}
                                                            onIonChange={
                                                                (e) => e.detail.value && handleStudent(e.detail.value)
                                                        }>
                                                        { students.map((student) => 
                                                                (student.isActive) &&
                                                               (<IonSelectOption key={student._id} value={`${student._id}`} className="text-capitalize">{student.name}</IonSelectOption>)
                                                        )}
                                                </IonSelect>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-30">
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("recent_invoices")} ({t("current_outstanding")} {t("rs")} {outstanding})
                                    </IonTitle>
                                </IonCol>
                            </IonRow>
                           
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                        {(transactions.length > 0) ? (
                                        <IonRow>
                                            <IonCol>
                                                {(transactions.map((tran, i) => {
                                                    return (
                                                        <IonCard className='border-radius-10' key={tran._id}>
                                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                                <IonGrid className="ion-no-padding ion-no-margin">
                                                                    <IonRow className="tran-pad tran-row-1">
                                                                        <IonCol size="11">
                                                                        
                                                                            <IonText className='tran-label-1'>{t("invoice_date")}<br/></IonText>
                                                                            <IonText className='tran-text'>
                                                                                {formatDated(tran.invoiceDate)}
                                                                                </IonText>
                                                                        

                                                                        </IonCol>
                                                                        <IonCol size="1" className='mt-10'>
                                                                                <IonIcon className='tran-icon-2'
                                                                                    icon={informationCircle}
                                                                                    color='dark'
                                                                                    onClick={() => history.push(`/app/invoice/${tran._id}`)}
                                                                                />
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <IonRow className='ion-padding border-top'>
                                                                        <IonCol size="2">
                                                                            <IonIcon className='tran-icon'
                                                                                icon={receipt}
                                                                                color={(tran.paidAt !== undefined) ? 'success' : ((new Date(tran.dueDate)) < (new Date(Date.now()))) ? 'danger' : 'secondary'} 
                                                                                />
                                                                                <IonText className='tran-mode'><br/> {(tran.paidAt !== undefined) ? t("cleared") : ((new Date(tran.dueDate)) < (new Date(Date.now()))) ? t("past_due") : ((new Date(tran.invoiceDate)) <= (new Date(Date.now()))) ? t("uncleared") : t("scheduled")}</IonText>
                                                                            </IonCol>
                                                                            <IonCol size="10" className='ion-padding-horizontal'>
                                                                                <IonText className='action-text'> 
                                                                                {t("rs")} {Math.abs(tran.amount)}<br/> 
                                                                                </IonText>
                                                                                <IonText className='tran-rem'> 
                                                                                    {t("due_on")} {formatDated(tran.dueDate)}
                                                                                
                                                                                </IonText>
                                                                            </IonCol>
                                                                        
                                                                    </IonRow>
                                                                
                                                                
                                                                </IonGrid>
                                                            </IonCardContent>
                                                        </IonCard>
                                                    )
                                                        })) 
                                                    } 
                                                </IonCol>
                                            </IonRow>
                                            ) : (
                                            <IonRow className="ion-padding-vertical">
                                                <IonCol>
                                                    <IonItem lines="none" className='red-card ion-margin'>
                                                        <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                        <IonLabel className="list-title">
                                                                <h3>{t("no_invoices")}</h3></IonLabel>

                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                            )}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol sizeLg="6" size="12" className='ion-padding-horizontal'>
                            <IonRow className='mt-30'>
                                <IonCol>
                                    <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("concession_details")}
                                    </IonTitle >
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}
                                                    id="open-date-input">
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("tran_date")}*
                                                    </IonLabel>
                                                    <IonInput value={formatDate(selectedDate)} className="input-field" readonly={true} />
                                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                            <IonToolbar>
                                                                <IonTitle>{t("tran_date")}</IonTitle>
                                                                
                                                            </IonToolbar>
                                                            <IonDatetime
                                                                value={selectedDate}
                                                                presentation="date"
                                                                cancelText={t("cancel")}
                                                                doneText={t("done")}
                                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                                showDefaultButtons={true}
                                                                size="cover"
                                                            />
                                                        </IonContent>
                                                        
                                                    </IonModal>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("tran_remarks")}*
                                                    </IonLabel>
                                                    <IonInput value={remarks} 
                                                        placeholder={t("tran_remarks")}
                                                        type="text"
                                                        className="input-field" 
                                                        onIonInput={ev => setRemarks(ev.detail.value!)}
                                                    />
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("tran_amount")}*
                                                    </IonLabel>
                                                <IonInput type="number"
                                                    value={amount}
                                                    placeholder={t("tran_amount")}
                                                    className="input-field" 
                                                    onIonInput={
                                                        (e) => setAmount(parseInt(e.detail.value!))
                                                }></IonInput>
                                            </IonItem>
                                            
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>               
                          
                            
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("give_concession")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default GiveConcession;