import {
    IonButton, IonIcon,
} from '@ionic/react';
import { contrast } from 'ionicons/icons';

import React from 'react';

const ThemeToggle: React.FC = () => {

    const handleThemeChange = () => {
        document.body.classList.toggle('dark');
    };

    return (
       
        <IonButton onClick={handleThemeChange} fill="clear" className='ion-no-padding'> 
            <IonIcon icon={contrast} color="secondary" />
        </IonButton>
                    
    );
}

export default ThemeToggle;
