import React from 'react';
import axios from 'axios';

interface AuthProps {
  children?: React.ReactNode;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: parseInt(process.env.REACT_APP_API_TO!)
});

type UserDataInterface = { initialized: boolean; loggedIn: boolean; token: string, user: any, institute: any };
type MyContextInterface = {
  authInfo: UserDataInterface;
  switchInstitute: (userID: string, instituteID: string) => Promise<boolean>;
  logOut: () => Promise<boolean>;
  logIn: (email: string, password: string) => Promise<boolean>;
  register: (email: string, password: string, name: string, phone: string, role: string, instituteID: string, roles?: string[], institutes?: string[]) => Promise<string>;
  initialize: () => Promise<boolean>;
};

// create the context
export const AuthContext = React.createContext<MyContextInterface | undefined> (undefined);

// create the context provider, we are using use state to ensure that
// we get reactive values from the context...

export const AuthProvider: React.FC<AuthProps> = (props: any) => {
  // the reactive values
  const [authInfo, setAuthInfo] = React.useState<UserDataInterface>();

  const switchInstitute = (userID: string, instituteID: string) => {
    return new Promise((resolve, reject) => {
      api.post('/auth/switch', { userID, instituteID }).then(res => {

        let v = {
          initialized: true,
          loggedIn: true,
          token: res.data.token,
          user: res.data.user,
          institute: res.data.institute
        };
        setAuthInfo(v);
        localStorage.setItem('TOKEN', v.token);
        localStorage.setItem('USER', JSON.stringify(v.user));
        localStorage.setItem('INSTITUTE', JSON.stringify(v.institute));

        return resolve(true);
        
      }).catch(err => reject(err));
     
    });
  };

  const logOut = () => {
    return new Promise((resolve) => {
      localStorage.removeItem('TOKEN');
      localStorage.removeItem('USER');

      if (localStorage.getItem('INSTITUTE'))
      {
        localStorage.removeItem('INSTITUTE');
      }
        
      setAuthInfo({ initialized: true, loggedIn: false, token: "", user: null, institute: null });
      return resolve(true);
    });
  };

  const logIn = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      api.post('/auth/login', { email, password, 'appID': process.env.REACT_APP_APP_ID}).then(res => {

        let v = {
          initialized: true,
          loggedIn: true,
          token: res.data.token,
          user: res.data.user,
          institute: res.data.institute
        };
        setAuthInfo(v);
        localStorage.setItem('TOKEN', v.token);
        localStorage.setItem('USER', JSON.stringify(v.user));

        if (v.institute !== null)
        {
          localStorage.setItem('INSTITUTE', JSON.stringify(v.institute));
        }

        return resolve(true);
        
      }).catch(err => reject(err));
     
    });
  };

  const register = (email: string, password: string, name: string, phone: string, role: string, instituteID: string, roles: string[] = [], institutes: string[] = []) => {
    return new Promise((resolve, reject) => {
      api.post('/auth/register', { email, password, name, phone, role, instituteID, roles, institutes}).then(res => {
        return resolve(res.data.user._id);
      }).catch(err => reject(err));
    });
  };

  const initialize = async () => {
    const value = localStorage.getItem('TOKEN');
    const resu = localStorage.getItem('USER');
    const resi = localStorage.getItem('INSTITUTE');

    if ((value !== null) && (resu !== null))
    {
      setAuthInfo({
        initialized: true,
        loggedIn: true,
        token: value,
        user: JSON.parse(resu),
        institute: (resi !== null) ? JSON.parse(resi) : null
      });
    }
    else
    {
      setAuthInfo({
        initialized: true,
        loggedIn: false,
        token: "",
        user: null,
        institute: null
      });
    }
  };

  let v = {
    authInfo,
    logOut,
    switchInstitute,
    logIn,
    register,
    initialize
  };

  return <AuthContext.Provider value={v} {...props} />;
};

export const useAuth = () => React.useContext(AuthContext);