import {
    ActionSheetButton,
    IonActionSheet,
    IonAlert,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSkeletonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import axios from 'axios';
import { alertCircleOutline, chevronDownOutline, closeOutline, locationOutline, locationSharp, lockClosedSharp, megaphoneSharp, search } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { SendIntent } from "send-intent";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';

import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/autoplay";
import '@ionic/react/css/ionic-swiper.css';

interface Feed {
    _id: string,
    title: string,
    description: string,
    media: string
}

interface Institute {
    _id: string,
    name: string,
    address: string,
    phone: string,
    email: string,
    logo: string,
    admnPhoto?: string,
    admnOpen: boolean
}

interface Region {
    _id: string,
    name: string,
    isActive: boolean
}

interface Workshop {
    _id: string,
    name: string,
    duration: string,
    startDate: Date,
    applicationFees: number,
    isActive: boolean,
    photo?: string
}

const Home: React.FC = () => {
    const history = useHistory();
    const {t, i18n} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [isold, setIsold] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [institutes, setInstitutes] = useState < Institute[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [searchText, setSearchText] = useState < string > ("");
    const [region, setRegion] = useState < Region > ();
    const [showActionSheet, setShowActionSheet] = useState < boolean > (false);
    const [regions, setRegions] = useState < ActionSheetButton[] > ([]);
    const [iserror1, setIserror1] = useState < boolean > (false);
    const [message1, setMessage1] = useState < string > ("");
    const [workshops, setWorkshops] = useState < Workshop[] > ([]);
    const [feed, setFeed] = useState < Feed[] > ([]);
    const [contactUs, setContactUs] = useState < string > ("");
    const [buttonText, setButtonText] = useState < string > ("");
    const [buttonLink, setButtonLink] = useState < string > ("");

    function openURL(value: string) {

        var url = value;
        if (!/^https?:\/\//i.test(value)) {
            url = 'http://' + value;
        }

        window.open(url, '_system');
        return false;
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const swapRegion = (selected: Region) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
          });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/regions/getForHome/${selected._id}`);
                setInstitutes(result.data);
                setRegion(selected);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("unable_to_connect"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const handleSearch = () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!)
        });

        if (!searchText || searchText === "") {
            setMessage(t("enter_keywords"));
            setIserror(true);
            return;
        }

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.post(`/regions/search/`, { 'regionID': region!._id, 'searchText': searchText});
                setInstitutes(result.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("unable_to_connect"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();
    }

   
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!)
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {

                if (process.env.REACT_APP_APP_ID === 'com.bricked.stms')
                {
                    const reg = await api.get(`/regions/active`);

                    setRegion(reg.data[0]);
    
                    const result = await api.get(`/regions/getForHome/${reg.data[0]._id}`);
                    setInstitutes(result.data);
    
                        let cObjects: ActionSheetButton[] = [];
                        for (let i=0; i < reg.data.length; i++)
                        {
                            let cObject: ActionSheetButton;
                           
                            cObject = {
                                text: reg.data[i].name,
                                icon: !isPlatform('ios') ? locationOutline : undefined,
                                handler: () => swapRegion(reg.data[i])
                            }
                            cObjects.push(cObject);
    
                        }
    
                        cObjects.push({
                            text: t("cancel"),
                            icon: !isPlatform('ios') ? closeOutline : undefined,
                            role: 'cancel'
                        });
    
                        setRegions(cObjects);
                }
                else
                {
                    const result = await api.get(`/regions/getWhiteHome/${process.env.REACT_APP_APP_ID}`);
                    setInstitutes(result.data);

                    const res = await api.get(`/admissionCourses/getForHome/${process.env.REACT_APP_APP_ID}`);
                    setWorkshops(res.data);

                    const feeds = await api.get(`/feeds/getForHome/${process.env.REACT_APP_APP_ID}`);
                    setFeed(feeds.data);

                    const contact = await api.get(`/feeds/getContact/${process.env.REACT_APP_APP_ID}`);
                    if (contact.data)
                    {
                        setContactUs(contact.data.description);
                        setButtonText(contact.data.title);
                        setButtonLink(contact.data.media);
                    }
                    
                }
               
                setShowLoading(false);

            } catch (error: any) {  
                
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage(t("unable_to_connect"));

                setIserror(true);
                setShowLoading(false);
                
            }

        };

        const checkIntent = () => {
           
            SendIntent.checkSendIntentReceived().then((result : any) => {
                if (result) {
                        
                    setMessage1(t("intent_error"));
                    setIserror1(true);
                    
                }
                else
                {
                    fetchData();
                }
            })
            .catch((err: any) => {
                fetchData();
            });

        }

        const fetchVersion = async () => {

            try {
                const result = await api.get(`/version-check`);
                if (parseInt(result.data) > parseInt(process.env.REACT_APP_VERSION!))
                {
                    setIsold(true);
                }
                else
                {
                    if (isPlatform('android') && isPlatform('capacitor'))
                    {
                        checkIntent();
                    }
                    else
                    {
                        fetchData();
                    }

                }


            } catch (error: any) {  
                if (error.response !== undefined) 
                {
                    setMessage(error.response.data.message);
                    setIserror(true);
                }   
                else 
                {
                    setMessage(t("unable_to_connect"));
                    setIserror(true);
                }

                 
            }
        };
         
        fetchVersion();

    }, [refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade pl-16">
                    <IonRow>
                        <IonCol size="1.5" className="ion-text-right mt-05">
                            {(process.env.REACT_APP_APP_ID === 'com.bricked.stms') && (<IonIcon icon={locationSharp} color="warning" />)}
                            {(process.env.REACT_APP_APP_ID !== 'com.bricked.stms') && (<img src={`assets/images/logo-trans.png`} alt="logo" width="32" />)}
                        </IonCol>
                        <IonCol size="10.5" className="text-uppercase">
                            <IonTitle size="small" color="secondary">
                            {(process.env.REACT_APP_APP_ID === 'com.bricked.stms') && region && (region.name)}
                            {(process.env.REACT_APP_APP_ID === 'com.bricked.stms') && !region && ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))}
                            {(process.env.REACT_APP_APP_ID !== 'com.bricked.stms') && (i18n.language === "hi") && process.env.REACT_APP_NAME_HI}
                            {(process.env.REACT_APP_APP_ID !== 'com.bricked.stms') && (i18n.language !== "hi") && process.env.REACT_APP_NAME}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonButtons slot="end">
                        {(process.env.REACT_APP_APP_ID === 'com.bricked.stms') && regions && (
                            <IonButton onClick={() => setShowActionSheet(true)}><IonIcon icon={chevronDownOutline} color="warning" /></IonButton>
                        )}
                         
                        <ThemeToggle />
                        <LanguageToggle />
                           
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">
                        {(process.env.REACT_APP_APP_ID === 'com.bricked.stms') && region && (region.name)}
                        {(process.env.REACT_APP_APP_ID === 'com.bricked.stms') && !region && ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))}
                        {(process.env.REACT_APP_APP_ID !== 'com.bricked.stms') && (i18n.language === "hi") && process.env.REACT_APP_NAME_HI}
                        {(process.env.REACT_APP_APP_ID !== 'com.bricked.stms') && (i18n.language !== "hi") && process.env.REACT_APP_NAME}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={iserror1}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => SendIntent.finish()
                    }
                    header={t("error")}
                    message={message1}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isold}
                    backdropDismiss={false}
                    onDidDismiss={
                        () => document.location.href = isPlatform('android') ? process.env.REACT_APP_PLAY_STORE! : process.env.REACT_APP_APP_STORE!
                    }
                    header={t("outdated_version")}
                    message={t("outdated_msg")}
                    buttons={
                        [`${t("update_app")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonActionSheet isOpen={showActionSheet}
                    onDidDismiss={
                        () => setShowActionSheet(false)
                    }
                    cssClass='swap-sheet'
                    subHeader={`${t("currently_selected")} - ${region?.name}`}
                    header={t("change_region")}
                    buttons={regions} 
                />

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-no-padding">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <Swiper 
                                modules={[Autoplay, Pagination]}
                                pagination={{el: '.swiper-pagination', clickable: false}}
                                spaceBetween={0}
                                initialSlide={0}
                                slidesPerView={1}         
                                autoplay={{
                                    delay: 3000
                                }}
                            >

                                {(feed.length > 0) && feed.map((post) => (
                                (post.media) &&
                                    (
                                    <SwiperSlide key={post._id} onClick={post.description && (post.description !== "") ? () => openURL(post.description) : undefined }>
                                            <img src={`${process.env.REACT_APP_STATIC_S3}/images/${post.media}`} alt={post.title}  style={{'width': '100%', 'height': 'auto'}} />
                                    </SwiperSlide>  
                                    )
                                ))}
                                
                            </Swiper>
                        </IonCol>
                    </IonRow>
                    {(feed.length > 0) && (
                    <IonRow className="mt-30">
                        <IonCol>
                            <div className="swiper-pagination" />
                        </IonCol>
                    </IonRow>
                    )}
                </IonGrid>

                <IonGrid className="ion-no-padding">

                {(process.env.REACT_APP_APP_ID === 'com.bricked.stms') && (
                    <>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                        
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}>

                                        <IonInput type="text"
                                            value={searchText}
                                            placeholder={t("whats_happening")}
                                            onIonInput={
                                                (e) => setSearchText(e.detail.value!)
                                        } />
                                                
                                        <IonButtons slot="end">
                                            <IonButton onClick={handleSearch}> 
                                                <IonIcon 
                                                icon={search}
                                                color='secondary'/>
                                            </IonButton>
                                        </IonButtons>
                                                        
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='vid-title' >
                                {t("schools_in")} {region?.name}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                </>
                )}
                {(workshops.length > 0) && (
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol size="8">
                                    <IonTitle className='vid-title mt-05' >
                                        {t("workshops")}
                                    </IonTitle>
                                </IonCol>
                                <IonCol size="4" className='ion-text-right'>
                                    <IonButton color="primary" size="small" className='pr-16' onClick={() => history.push(`/workshops`)}>{t("view_all")}</IonButton>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                            {(workshops.map((workshop) => 
                                (
                                <IonCard key={workshop._id} className="ion-margin-vertical border-radius-10">

                                    <IonCardContent className="ion-no-padding">
                                            
                                        <IonRow className="ion-margin-horizontal">
                                            {(workshop.photo) ? (
                                            <>
                                                <IonCol size="4" className='ion-padding-vertical'>
                                                    <img src={`${process.env.REACT_APP_STATIC_S3}/images/${workshop.photo}`} alt={workshop.name} style={{'width': '100%', 'height': 'auto'}} />
                                                </IonCol>
                                                <IonCol size="8" className='ion-padding'>
                                                    <p><span className="class-name" >{workshop.name}</span></p>
                                                    <p><span className="class-info">{t("project_start")}: {formatDate(workshop.startDate)}</span></p>
                                                    <p><span className="class-info">{t("duration")}: {workshop.duration}</span></p>
                                                </IonCol>
                                            </>
                                            ) : (
                                            <IonCol className='ion-padding'>
                                                    <p><span className="class-name" >{workshop.name}</span></p>
                                                    <p><span className="class-info">{t("project_start")}: {formatDate(workshop.startDate)}</span></p>
                                                    <p><span className="class-info">{t("duration")} {workshop.duration}</span></p>
                                            </IonCol>
                                            )}
                                            
                                        </IonRow>
                                        <IonRow className="ion-margin-horizontal pb-08 blur-row"
                                            onClick={() => history.push(`/login`)}>
                                            <IonCol size="8" className='mt-05'>
                                                <p><span className="class-info"> {t("total_cost")} {t("rs")} {workshop.applicationFees}/-</span></p>
                                            </IonCol>
                                            <IonCol size="4" className="ion-text-right">
                                                <IonButton color="secondary" size="small">{t("attend")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                                
                                    </IonCardContent>
                                </IonCard>
                                )
                            ))}
                              </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                )}
                {(process.env.REACT_APP_APP_ID !== 'com.bricked.stms') && (
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='vid-title' >
                                {t("branches")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                )}
                    <IonRow className="ion-margin-vertical">
                        <IonCol className="ion-no-padding ion-no-margin" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                        {
                            (institutes.length > 0) ? (institutes.map((insti) => {
                                return (
                                        <IonCard key={insti._id} className="ion-margin-vertical">
                                            {(insti.admnPhoto !== "") &&
                                            (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${insti.admnPhoto}`} alt={insti.name} onClick={() => history.push(`/school/${insti._id}`)} 
                                                style={{'width': '100%', 'height': 'auto'}} />)
                                            }
                                            <IonCardContent className="ion-no-padding">
                                                <IonGrid className="white-shade">
                                                    <IonRow>
                                                    
                                                        <IonCol size="9" className="insti-info">
                                                                <IonRow className="ion-margin-horizontal pb-08">
                                                                    <IonCol>
                                                                        <p><span className="insti-name" onClick={() => history.push(`/school/${insti._id}`)}>{insti.name}</span></p>
                                                                        
                                                                        <p><IonIcon icon={locationSharp} color="secondary" /><span className="insti-address">{insti.address}</span></p>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow className="ion-margin-horizontal pb-08">
                                                                        <IonCol>
                                                                            <IonButton className="forth-button" fill="clear"  onClick={() => history.push('/login')}>{t("shortlist")}</IonButton>
                                                                            <IonButton color="primary" size="small" onClick={() => history.push(`/school/${insti._id}`)}>{insti.admnOpen ? t("apply_now") : t("profile")}</IonButton>
                                                                        </IonCol>
                                                                        
                                                                </IonRow>
                                                                <IonRow className="ion-margin-horizontal pb-08">
                                                                    <IonCol>
                                                                        <p><IonIcon className="mt-05" icon={insti.admnOpen ? megaphoneSharp : lockClosedSharp} color="danger" /><span className="admn-open">{insti.admnOpen ? t("admn_open") : t("admn_closed")}</span></p>
                                                                        
                                                                    </IonCol>
                                                                </IonRow>
                                                        </IonCol>
                                                        <IonCol size="3" className="ion-text-center">
                                                            <div className="vertical-container">
                                                                <div className="vertical-center"> 
                                                                {(insti.logo !== "") ?
                                                                    (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${insti.logo}`} alt={insti.name} width="60" onClick={() => history.push(`/school/${insti._id}`)}/>)
                                                                    : (<img src={`assets/images/institute.png`} alt={insti.name} width="60" onClick={() => history.push(`/school/${insti._id}`)}/>)
                                                                }
                                                                </div>
                                                            </div>
                                                              
                                                        </IonCol>
                                                    </IonRow>
                                                    
                                                </IonGrid>
                                            </IonCardContent>
                                        </IonCard>
                                        );
                                        })) : (
                                            <IonCard className="ion-margin-vertical red-card">
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonIcon icon={alertCircleOutline}
                                                            color="danger"
                                                            slot="start"
                                                            size="large" />
                                                        <IonLabel className="list-title"><h3>{t("no_institutes")}</h3></IonLabel>
                                                    </IonItem>
                                                </IonCardContent>
                                            </IonCard>
                                        )
                                    }

                        </IonCol>
                    </IonRow>
                    {(contactUs !== "") && (
                    <>
                     <IonRow className='mt-30'>
                         <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                 <IonTitle className='vid-title' >
                                 {t("get_in_touch")}
                                 </IonTitle>
                         </IonCol>
                     </IonRow>
                     <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className="border-radius-10 ion-padding" color="tertiary">
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>
                                        <p className='contact-us-home'>{contactUs}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-margin-top">
                                        <IonCol className="ion-text-center">
                                            <IonButton color="dark" onClick={buttonLink && (buttonLink !== "") ? () => openURL(buttonLink) : () => history.push(`/login`) }>{buttonText}</IonButton> 
                                       </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                     </>
                    )}

                   
                </IonGrid>

            </IonContent>
            
                <IonFooter className='ion-padding home-footer'>
                    <IonGrid>
                        <IonRow>
                            <IonCol className="ion-padding-horizontal" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonButton onClick={() => history.push('/login')}
                                    fill="clear"
                                    className='home-button'
                                    expand="block">
                                        {(i18n.language === "hi") && (
                                            `${process.env.REACT_APP_NAME_HI?.split(" ")[0]} ${t("at")} `
                                        )}
                                        {t("login_to")}

                                        {(i18n.language !== "hi") && (
                                            ` ${process.env.REACT_APP_NAME?.split(" ")[0]}`
                                        )}
                                        </IonButton>

                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonFooter>
          
        </IonPage>
    );
};

export default Home;
