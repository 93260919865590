import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React from 'react';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { useAuth } from '../../../components/AuthContext';
import { helpCircleOutline } from 'ionicons/icons';

const Student: React.FC = () => {
    const {t} = useTranslation();
    const {authInfo} = useAuth()!;

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_students")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_students")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid className='mb-60'>

                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol>
                            <IonRow>
                                <IonCol>
                                    <IonTitle id="open-student-info" className="border-bottom-sec">
                                        {t("student_data")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-student-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                            {t("student_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/student/add`} >
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("add_student")} src="assets/images/students.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("add_student")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/students`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("manage_students")} src="assets/images/students.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("manage_students")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/student/withdrawn`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("withdrawn_students")} src="assets/images/withdrawn.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("withdrawn_students")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card" routerLink={`/app/student/graduated`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("graduated_students")} src="assets/images/graduated.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("graduated_students")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonTitle id="open-attendance-info" className="border-bottom-sec">
                                        {t("student_attendance")}
                                        <IonIcon className="help-icon" color="success" icon={helpCircleOutline} />
                                    </IonTitle>
                                    <IonPopover trigger="open-attendance-info" className='info-popover'>
                                        <IonCard className='note-card-3 ion-no-margin'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol className='note-text'>
                                                        <p>
                                                            {t("attendance_help")}
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonPopover>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/attend`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("mark_attendance")} src="assets/images/attendance-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("mark_attendance")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/attend/class`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={(authInfo.institute.type === "school") ? t("class_attendance") : t("batch_attendance")} src="assets/images/attendance-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{(authInfo.institute.type === "school") ? t("class_attendance") : t("batch_attendance")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/entryexit`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("mark_entry_exit")} src="assets/images/timetable-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("mark_entry_exit")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/entry/log`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("entry_exit_logs")} src="assets/images/timetable-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("entry_exit_logs")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/attend/leave`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("leave_applications")} src="assets/images/medical-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("leave_applications")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeMd='2' sizeSm='3' size="6">
                                    <IonCard className="action-card action-card-2" routerLink={`/app/attend/status`}>
                                    <IonCardContent className="ion-no-padding ion-text-center">
                                                <IonRow className="ion-padding-top">
                                                    <IonCol className='ion-no-padding'>
                                                        <img alt={t("attendance_report")} src="assets/images/reports-1.png"/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("attendance_report")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                {(isPlatform("capacitor")) && (
                                <>
                                    
                                    <IonCol sizeMd='2' sizeSm='3' size="6">
                                        <IonCard className="action-card action-card-2" routerLink={`/app/scan/entry`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className='ion-no-padding'>
                                                            <img alt={t("student_entry")} src="assets/images/qrcode-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("student_entry")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol sizeMd='2' sizeSm='3' size="6">
                                        <IonCard className="action-card action-card-2" routerLink={`/app/scan/exit`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className='ion-no-padding'>
                                                            <img alt={t("student_exit")} src="assets/images/qrcode-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("student_exit")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                  
                                    <IonCol sizeMd='2' sizeSm='3' size="6">
                                        <IonCard className="action-card action-card-2" routerLink={`/app/scan/present`}>
                                        <IonCardContent className="ion-no-padding ion-text-center">
                                                    <IonRow className="ion-padding-top">
                                                        <IonCol className='ion-no-padding'>
                                                            <img alt={t("student_present")} src="assets/images/qrcode-1.png"/>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton className="ion-no-padding dash-button-red ion-text-wrap" fill="clear">{t("student_present")}</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </>
                                )}
                            </IonRow>

                        </IonCol>
                    </IonRow>
                
                 
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Student;