import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface Region {
    _id: string,
    name: string,
    isActive: boolean
}

const AddInstitute: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [regions, setRegions] = useState < Region[] > ([]);
    const [region, setRegion] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [address, setAddress] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [transport, setTransport] = useState < boolean > (false);
    const [comm, setComm] = useState < boolean > (true);
    const [payment, setPayment] = useState < boolean > (false);
    const [admn, setAdmn] = useState < boolean > (false);
    const [acad, setAcad] = useState < boolean > (true);
    const [what, setWhat] = useState < boolean > (true);
    const [inventory, setInventory] = useState < boolean > (false);
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [website, setWebsite] = useState < string > ("");
    const [type, setType] = useState < string > ("");
    const [payroll, setPayroll] = useState < boolean > (false);
    const [timetable, setTimetable] = useState < boolean > (false);
    const [reportcard, setReportcard] = useState < boolean > (false);
    const [diary, setDiary] = useState < boolean > (false);
    const [medical, setMedical] = useState < boolean > (false);
    const [task, setTask] = useState < boolean > (true);
    const [exam, setExam] = useState < boolean > (false);

    function validateEmail(email : string) { // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    }

    function validatePhone(phone : string) { // eslint-disable-next-line no-control-regex
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    }

    function onFileChange(fileChangeEvent : React.ChangeEvent<HTMLInputElement>) {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                setUploadFile(fileChangeEvent.target.files[0]);
            }
            else
            {
                setUploadFile(null);
            }
        }  
    }

    const handleSubmit = () => {

        if (!region || region === "") {
            setMessage("Select a region.");
            setIserror(true);
            return;
        }

        if (!type || type === "") {
            setMessage("Select Institute's type.");
            setIserror(true);
            return;
        }

        if (!name || name === "") {
            setMessage("Name is a mandatory field!");
            setIserror(true);
            return;
        }

        if (!address || address === "") {
            setMessage("Address is a mandatory field!");
            setIserror(true);
            return;
        }

        if (validateEmail(email) === false) {
            setMessage("Thats an invalid email address!");
            setIserror(true);
            return;
        }

        if (validatePhone(phone) === false) {
            setMessage("Enter 10 digits mobile number!");
            setIserror(true);
            return;
        }

        if (!uploadFile) {
            setMessage("Upload the logo in jpg/png format less than 500kb.");
            setIserror(true);
            return;
        }
    
        setShowLoading(true);

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const addInstitute = ( formData: FormData) => {
            return new Promise((resolve, reject) => {
              api.post('/institutes/add', formData).then(res => {
        
                return resolve(res.data.institute.name);
                
              }).catch(err => reject(err));
    
            });
          }

          let communication: string = "no";
          if(comm)
          {
            communication = "yes";
          }
          let trans: string = "no";
          if(transport)
          {
            trans = "yes";
          }
          let pay: string = "no";
          if(payment)
          {
            pay = "yes";
          }
          let admission: string = "no";
          if(admn)
          {
            admission = "yes";
          }
          let invent: string = "no";
          if(inventory)
          {
            invent = "yes";
          }
          let academy: string = "no";
          if(acad)
          {
            academy = "yes";
          }
          let exams: string = "no";
          if(exam)
          {
            exams = "yes";
          }
          let whatsapp: string = "no";
          if(what)
          {
            whatsapp = "yes";
          }
          let payr: string = "no";
          if(payroll)
          {
            payr = "yes";
          }
          let med: string = "no";
          if(medical)
          {
            med = "yes";
          }
          let taskm: string = "no";
          if(task)
          {
            taskm = "yes";
          }
          let timet: string = "no";
          if(timetable)
          {
            timet = "yes";
          }
          let card: string = "no";
          if(reportcard)
          {
            card = "yes";
          }
          let dailyDiary: string = "no";
          if(diary)
          {
            dailyDiary = "yes";
          }
          
      // Change uploadFile to blob in native implementation using capacitor plugin
      let formData = new FormData();
      formData.append('image', uploadFile, uploadFile.name);
      formData.append('regionID', region);
      formData.append('type', type);
      formData.append('name', name);
      formData.append('address', address);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('website', website);
      formData.append('communication', communication);
      formData.append('payment', pay);
      formData.append('transport', trans);
      formData.append('admission', admission);
      formData.append('academics', academy);
      formData.append('inventory', invent);
      formData.append('whatsapp', whatsapp);
      formData.append('medical', med);
      formData.append('task', taskm);
      formData.append('payroll', payr);
      formData.append('timetable', timet);
      formData.append('reportcard', card);
      formData.append('exam', exams);
      formData.append('diary', dailyDiary);
  
    addInstitute(formData)
        .then(data => {
        
            setPass(data+' has been added.');
            setIspass(true);
            setShowLoading(false);
        })
        .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Please try again afer sometime.');

            setIserror(true);
            setShowLoading(false);
        });
        
      }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/regions/active`);
                setRegions(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/institutes" />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined}  color="secondary">Add Institute</IonTitle>
                   {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Add Institute</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>
                
                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => history.push(`/app/institutes`)
                    }
                    header="Success!"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>



                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Institute's Details
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol>
                                    <IonList className="ion-no-padding">

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">Region</IonLabel>
                                                    <IonSelect value={region}
                                                            placeholder="Select Region"
                                                            onIonChange={
                                                                (e) => setRegion(e.detail.value)
                                                        }>
                                                        { regions.map((reg) => {
                                                                return (<IonSelectOption key={reg._id} value={`${reg._id}`}>{reg.name}</IonSelectOption>);
                                                            })
                                                        }
                                            </IonSelect>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">Type</IonLabel>
                                                    <IonSelect value={type}
                                                            placeholder="Select Type"
                                                            onIonChange={
                                                                (e) => setType(e.detail.value)
                                                        }>
                                                        <IonSelectOption value="school">School</IonSelectOption> 
                                                        <IonSelectOption value="institute">Institute</IonSelectOption> 
                                            </IonSelect>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Name</IonLabel>
                                            <IonInput type="text"
                                                value={name}
                                                placeholder="Institute's Name"
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Address</IonLabel>
                                            <IonInput type="text"
                                                value={address}
                                                placeholder="Full Address"
                                                onIonInput={
                                                    (e) => setAddress(e.detail.value!)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Phone</IonLabel>
                                            <IonInput type="text"
                                                value={phone}
                                                placeholder="Phone Number"
                                                onIonInput={
                                                    (e) => setPhone(e.detail.value!)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Email</IonLabel>
                                            <IonInput type="text"
                                                value={email}
                                                placeholder="Email Address"
                                                onIonInput={
                                                    (e) => setEmail(e.detail.value!)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Website</IonLabel>
                                            <IonInput type="text"
                                                value={website}
                                                placeholder="Website"
                                                onIonInput={
                                                    (e) => setWebsite(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">Logo Image*</IonLabel>
                                            <input type="file" accept=".jpg, .jpeg, .png" onChange={ev => onFileChange(ev)} className="ion-padding"></input>
                                            
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Task Management</IonLabel>
                                            <IonToggle checked={task} onIonChange={e => setTask(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Medical Room</IonLabel>
                                            <IonToggle checked={medical} onIonChange={e => setMedical(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Transport</IonLabel>
                                            <IonToggle checked={transport} onIonChange={e => setTransport(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Communication</IonLabel>
                                            <IonToggle checked={comm} onIonChange={e => setComm(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Accounting</IonLabel>
                                            <IonToggle checked={payment} onIonChange={e => setPayment(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Payroll</IonLabel>
                                            <IonToggle checked={payroll} onIonChange={e => setPayroll(e.detail.checked)} />
                                        </IonItem>


                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Academics</IonLabel>
                                            <IonToggle checked={acad} onIonChange={e => setAcad(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Examinations</IonLabel>
                                            <IonToggle checked={exam} onIonChange={e => setExam(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Timetable</IonLabel>
                                            <IonToggle checked={timetable} onIonChange={e => setTimetable(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Report Cards</IonLabel>
                                            <IonToggle checked={reportcard} onIonChange={e => setReportcard(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Daily Diary</IonLabel>
                                            <IonToggle checked={diary} onIonChange={e => setDiary(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Inventory</IonLabel>
                                            <IonToggle checked={inventory} onIonChange={e => setInventory(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Admission</IonLabel>
                                            <IonToggle checked={admn} onIonChange={e => setAdmn(e.detail.checked)} />
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            <IonLabel color="secondary">Whatsapp</IonLabel>
                                            <IonToggle checked={what} onIonChange={e => setWhat(e.detail.checked)} />
                                        </IonItem>

                                    </IonList>
                               
                        </IonCol>
                    </IonRow>

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                expand="block">Add Institute</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddInstitute;
