import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonReorder,
    IonReorderGroup,
    IonRow,
    IonTitle,
    IonToolbar,
    ItemReorderEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { addCircle, arrowUndoCircle, construct, toggle } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';

interface ParamTypes {
    courseID: string
}

interface Chapter {
    _id: string,
    chapterName: string,
    isActive: boolean,
    order?: number
}

const Chapters: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {courseID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [chapters, setChapters] = useState < Chapter[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);

    const handleName = (name: string, index: number) => {

        let comps = [...chapters];
        comps[index].chapterName = name;
        setChapters(comps);
    
    }

    const handleDelete = (index: number) => 
    {
        //let chaps = [...chapters];
        //if (index > -1) {
         //   chaps.splice(index, 1); // 2nd parameter means remove one item only
        //  }
        //setChapters(chaps);

        let comps = [...chapters];
        comps[index].isActive = !comps[index].isActive;
        setChapters(comps);
    }

    const handleAdd = () => {
        let r = (Math.random() + 1).toString(36).substring(7);
        let comps = [...chapters];
        comps.push({_id: r, chapterName: '', isActive: true});
        setChapters(comps);
    }


    const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {

        let from = event.detail.from;
        let to = event.detail.to;
        let toCopy = event.detail.to;

        let chaps = [...chapters];

        if (to < from)
        {
            chaps[to] = {...chapters[from]};
            to = to + 1;
            while (to <= from)
            {
                chaps[to] = {...chapters[to-1]};
                to = to + 1;
            }
        }

        if (toCopy > from)
        {
            chaps[toCopy] = {...chapters[from]};
            toCopy = toCopy - 1;
            while (toCopy >= from)
            {
                chaps[toCopy] = {...chapters[toCopy+1]};
                toCopy = toCopy - 1;
            }
        }

        setChapters(chaps);
        
        event.detail.complete();
      }

    
      const handleChapters = () => {

        if (!chapters || (chapters.length < 1)) {
            setMessage("Assign atleast 1 chapter!");
            setIserror(true);
            return;
        }

        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upCourse = (chapters: Chapter[] ) => {
          return new Promise((resolve, reject) => {
            api.put('/courses/'+courseID, { chapters }).then(res => {
              return resolve(res.data.name);
            }).catch(err => reject(err));
  
          });
        }

      upCourse(chapters)
        .then(data => {
  
            setPass(data+' has been updated.');
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
          setIserror(true);
          setShowLoading(false);
        });
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/courses/' + courseID);

                setName(result.data.name);

                //sort chapters based on order
                let res = result.data.chapters.sort((a: Chapter, b: Chapter) => a.order! - b.order!);

                setChapters(res);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, courseID, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle color="secondary">Manage Chapters</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Manage Chapters</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">
                   
                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    {name}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                 
                    <IonRow>
                        <IonCol>
                            <IonCard className='border-radius-10' color='tertiary'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonGrid>
                                        <IonRow className="mt-05">
                                            <IonCol size="8" className='ion-padding-horizontal'>
                                                Course Chapters
                                            </IonCol>
                                            <IonCol size="2" className='ion-text-right'>
                                               
                                               <IonIcon className='tran-icon'
                                                   icon={arrowUndoCircle}
                                                   color='secondary'
                                                   onClick={() => setRefreshed(Math.random())}
                                               />
                                           
                                           
                                            </IonCol>
                                            <IonCol size="2" className='ion-text-center'>
                                               
                                                    <IonIcon className='tran-icon'
                                                        icon={addCircle}
                                                        color='secondary'
                                                        onClick={handleAdd}
                                                    />
                                                
                                                
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                {chapters && (chapters.length > 0) && (
                <>
                 
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                                            {chapters.map((chap, i) => {
                                                return (
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        key={chap._id}>
                                                            <IonReorder slot="start"/>
                                                            <IonInput type="text"
                                                                    placeholder='Chapter Name'
                                                                    value={chap.chapterName}
                                                                    onIonChange={
                                                                        (e) => handleName(e.detail.value!, i)
                                                                }
                                                                readonly={!chap.isActive}
                                                                ></IonInput>
                                                            <IonButtons slot="end">
                                                                <IonButton onClick={() => handleDelete(i)}> 
                                                                    <IonIcon
                                                                    icon={toggle}
                                                                    color={chap.isActive ? "success" : "danger"}
                                                                    />
                                                                </IonButton>
                                                                <IonButton onClick={() => (chap.isActive && (chap._id.length > 10)) ? history.push(`/app/topics/${chap._id}`) : null}> 
                                                                    <IonIcon
                                                                    icon={construct}
                                                                    color="dark"
                                                                    />
                                                                </IonButton>
                                                            </IonButtons>
                                                            
                                                    </IonItem>
                                                )
                                                
                                                })
                                            }

                                    </IonReorderGroup>
                                </IonCardContent>
                            </IonCard>
                         </IonCol>
                    </IonRow>

                </>
                )}


                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleChapters}
                                className="first-button"
                                fill="clear"
                                expand="block">Update Chapters</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Chapters;
