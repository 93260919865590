import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';


const AddProject: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [startDate, setStartDate] = useState < string | string[] | undefined > ();
    const [endDate, setEndDate] = useState < string | string[] | undefined > ();

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
      };

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("project_name_mandatory"));
            setIserror(true);
            return;
        }

        if (!text || text === "") {
            setMessage(t("project_info_mandatory"));
            setIserror(true);
            return;
        }

        if (typeof startDate !== "string")
        {
            setMessage(t("start_date_mandatory"));
            setIserror(true);
            return;
        }

        if (typeof endDate !== "string")
        {
            setMessage(t("end_date_mandatory"));
            setIserror(true);
            return;
        }

        const fDate = new Date(startDate);
        const tDate = new Date(endDate);

        if (tDate < fDate)
        {
            setMessage(t("dates_invalid"));
            setIserror(true);
            return;
        }     

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const addDep = () => {
          return new Promise((resolve, reject) => {
            api.post('/projects/add', { 'instituteID': authInfo.user.instituteID, name, text, startDate, endDate }).then(res => {
      
              return resolve(res.data.project.name);
              
            }).catch(err => reject(err));
  
          });
        }
  
        setShowLoading(true);
      addDep()
        .then(data => {
          
          setPass(data+t("has_been_added"));
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("something_went_wrong"));
  
          setIserror(true);
          setShowLoading(false);
        });
        
      }

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("add_project")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("add_project")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => history.push(`/app/staff`)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("project_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("project_name")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("project_name")}
                                                value={name}
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel  className="input-label" position="floating" color="secondary">
                                        {t("project_info")}
                                        </IonLabel>
                                        <IonTextarea rows={4} value={text} onIonInput={
                                                (e) => setText(e.detail.value!)
                                        }></IonTextarea>
                                            
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol  offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("project_start")}
                                            </IonLabel>
                                            <IonInput value={formatDate(startDate)} className="input-field" readonly={true} />
                                            <IonModal trigger="open-date-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("project_start")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        value={startDate}
                                                        presentation="date"
                                                        cancelText={t("cancel")}
                                                        doneText={t("done")}
                                                        onIonChange={ev => setStartDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol  offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-dated-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("project_end")}
                                            </IonLabel>
                                            <IonInput value={formatDate(endDate)} className="input-field" readonly={true} />
                                            <IonModal trigger="open-dated-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("project_end")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        value={endDate}
                                                        presentation="date"
                                                        cancelText={t("cancel")}
                                                        doneText={t("done")}
                                                        onIonChange={ev => setEndDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                 
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("add_project")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddProject;