import React, {useEffect} from 'react';
import {
    setupIonicReact,
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    isPlatform,
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import { App as CapApp } from '@capacitor/app';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAuth } from './AuthContext';

import Home from '../pages/Home/Home';
import AllWorkshops from '../pages/General/AllWorkshops';
import SchoolProfile from '../pages/General/SchoolProfile';
import Login from '../pages/Auth/Login';
import SignUp from '../pages/Auth/SignUp';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import Menu from './Menu';
import MasterTabs from './MasterTabs';
import ContentTabs from './ContentTabs';
import SuperAdminTabs from './SuperAdminTabs';
import AdminTabs from './AdminTabs';
import EmployeeTabs from './EmployeeTabs';
import AccountantTabs from './AccountantTabs';
import AdmissionHeadTabs from './AdmissionHeadTabs';
import AcademicTabs from './AcademicTabs';
import TeacherTabs from './TeacherTabs';
import TransporterTabs from './TransporterTabs';
import DriverTabs from './DriverTabs';
import InventoryTabs from './InventoryTabs';
import ParentTabs from './ParentTabs';
import StudentTabs from './StudentTabs';
import AdmissionTabs from './AdmissionTabs';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../theme/variables.css';
import '../theme/style.css';

/* i18n Init */
import './Translator';

setupIonicReact();

CapApp.addListener('backButton', ({ canGoBack }) => {
    if(!canGoBack){
      CapApp.minimizeApp();
    }
});

const App: React.FC = () => {

    const {authInfo, initialize} = useAuth()!;

    const toggleDarkTheme = (shouldAdd: boolean) => {
        document.body.classList.toggle('dark', shouldAdd);
    };

    useEffect(() => {
        !authInfo?.initialized && (async () => await initialize())();

        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

        toggleDarkTheme(prefersDark.matches);
    
        // Listen for changes to the prefers-color-scheme media query
        prefersDark.addEventListener('change', (mediaQuery) => toggleDarkTheme(mediaQuery.matches));

    }, [authInfo, initialize]);

    if (!authInfo || !authInfo.initialized) {
        return null; // Return null if authInfo is not initialized
    }

    return (
        <IonApp>
            {(authInfo.loggedIn !== true) ? (
                <IonReactRouter>
                    <IonRouterOutlet>
                        {isPlatform('mobile') ? (
                            <Switch>
                                <Route path="/login" exact={true} component={Login} />
                                <Route path="/forgot" exact={true} component={ForgotPassword} />
                                <Route path="/signup" exact={true} component={SignUp} />
                                <Route path="/home" exact={true} component={Home} />
                                <Route path="/workshops" exact={true} component={AllWorkshops} />
                                <Route path="/school/:schoolID" exact={true} component={SchoolProfile} />
                                <Redirect exact={true} path="/" to="/home" />
                                <Redirect from="*" to="/" />
                            </Switch>
                        ) : (
                            <Switch>
                                <Route path="/login" exact={true} component={Login} />
                                <Route path="/forgot" exact={true} component={ForgotPassword} />
                                <Redirect exact={true} path="/" to="/login" />
                                <Redirect from="*" to="/" />
                            </Switch>
                        )}
                    </IonRouterOutlet>
                </IonReactRouter>
            ) : (
                <IonReactRouter>
                    <IonSplitPane contentId="main" when="xl">
                        <Menu/>
                        <IonRouterOutlet id="main">
                            <Switch>
                                {authInfo.user.role === 'Master' &&
                                (
                                    <Route path="/app" component={MasterTabs}/>
                                )}

                                {authInfo.user.role === 'Content' &&
                                (
                                    <Route path="/app" component={ContentTabs}/>
                                )}

                                {authInfo.user.role === 'Super' &&
                                (
                                    <Route path="/app" component={SuperAdminTabs}/>
                                )}

                                {authInfo.user.role === 'Admin' &&
                                (
                                    <Route path="/app" component={AdminTabs}/>
                                )}

                                {authInfo.user.role === 'Employee' &&
                                (
                                    <Route path="/app" component={EmployeeTabs}/>
                                )}

                                {authInfo.user.role === 'Accountant' &&
                                (
                                    <Route path="/app" component={AccountantTabs}/>
                                )}

                                {authInfo.user.role === 'AdmissionHead' &&
                                (
                                    <Route path="/app" component={AdmissionHeadTabs}/>
                                )}

                                {authInfo.user.role === 'Academic' &&
                                (
                                    <Route path="/app" component={AcademicTabs}/>
                                )}

                                {authInfo.user.role === 'Teacher' &&
                                (
                                    <Route path="/app" component={TeacherTabs}/>
                                )}

                                {authInfo.user.role === 'Transporter' &&
                                (
                                    <Route path="/app" component={TransporterTabs}/>
                                )}

                                {authInfo.user.role === 'Driver' &&
                                (
                                    <Route path="/app" component={DriverTabs}/>
                                )}

                                {authInfo.user.role === 'Inventory' &&
                                (
                                    <Route path="/app" component={InventoryTabs}/>
                                )}

                                    {authInfo.user.role === 'Parent' &&
                                (
                                    <Route path="/app" component={ParentTabs}/>
                                )}

                                {authInfo.user.role === 'Admission' &&
                                (
                                    <Route path="/app" component={AdmissionTabs}/>
                                )}

                                {authInfo.user.role === 'Student' &&
                                (
                                    <Route path="/app" component={StudentTabs}/>
                                )}
                                
                                <Redirect from="/" to="/app" exact={true}/>
                                <Redirect from="*" to="/app" />
                                
                            </Switch>
                        </IonRouterOutlet>

                    </IonSplitPane>
                </IonReactRouter>
            )} 
        </IonApp>
    );
};

export default App;