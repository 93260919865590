import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { informationCircleOutline } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';

interface AdmnField {
    _id: string,
    name: string,
    category: string,
    options?: string[],
    isActive: boolean,
    order: number
}

interface AdmnStats {
    masterID: string,
    title: string,
    info: string
}

const KeyStats: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [admnFields, setAdmnFields] = useState < AdmnField[] > ([]);
    const [formDataOne, setFormDataOne] = useState < any > ({});

    const handleChange = (val: string, property: string) => {

        setFormDataOne({
            ...formDataOne, // Copy the old fields
            [property]: val // But override this one
          });
        
      }
    

      const handleSubmit = () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
              'Authorization': 'Bearer ' + authInfo.token
              }
          });

          const updateStats = (formData: AdmnStats[]) => {
            return new Promise((resolve, reject) => {
              api.post('/institutes/updateStats', { 'instituteID': authInfo.user.instituteID, formData }).then(res => {
                return resolve(res.data.institute.name);
              }).catch(err => reject(err));
            });
          }


            let newStats: AdmnStats[] = [];

            for (let i=0 ; i < admnFields.length ; i++)
                if (formDataOne[admnFields[i]._id] !== "")
                {
                   newStats.push({
                    'masterID': admnFields[i]._id,
                    'title': admnFields[i].name,
                    'info': formDataOne[admnFields[i]._id]
                   });
                }
         


            setShowLoading(true);

            updateStats(newStats)
                .then(data => {
                    setShowLoading(false);
                    setPass(data+t("stats_updated"));
                    setIspass(true);
                })
                .catch(error => {
                    if (error.response !== undefined) 
                        setMessage(error.response.data.message);
                    else 
                        setMessage(t("something_went_wrong"));
                    setIserror(true);
                    setShowLoading(false);
                });

    }

      useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/institutes/${authInfo.user.instituteID}`);
                const master = await api.get(`/masters/getStats`);

                let allFields = master.data.instituteStats;
                setAdmnFields(allFields);

                let childData: any = {};
                if(result.data.keyStats && result.data.keyStats.length > 0)
                {
                    for (let i = 0; i < result.data.keyStats.length ; i++) {
                        childData[result.data.keyStats[i].masterID] = result.data.keyStats[i].info;

                    }
                    setFormDataOne(childData);
                }
                else
                {
                    setFormDataOne({});
                }
                
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/admission`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("key_stats")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("key_stats")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>
                    
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                            {t("stats_info")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
 
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("key_stats")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                   
                                        {(admnFields.length > 0) && admnFields.map((admnField) => (
                                             (admnField.isActive) &&
                                             (
                                            <IonRow key={admnField._id}>
                                                <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                                    <IonCard>
                                                        <IonCardContent className="ion-no-padding">
                                                            <IonItem detail={false}
                                                                lines='inset'
                                                                button={false}
                                                                >
                                                                <IonLabel position="stacked" color="secondary" className='input-label'>{admnField.name}</IonLabel>

                                                                {(admnField.options === undefined) && (
                                                                <IonInput type="text"
                                                                    className='input-field'
                                                                    value={formDataOne[admnField._id]}
                                                                    placeholder={admnField.name}
                                                                    onIonInput={
                                                                        (e) => handleChange(e.detail.value!, admnField._id)
                                                                }></IonInput>
                                                                )}

                                                                {(admnField.options !== undefined && admnField.options.length === 0) && (
                                                                <IonInput type="text"
                                                                    className='input-field'
                                                                    value={formDataOne[admnField._id]}
                                                                    placeholder={`${admnField.name}`}
                                                                    onIonInput={
                                                                        (e) => handleChange(e.detail.value!, admnField._id)
                                                                }></IonInput>
                                                                )}

                                                                {(admnField.options !== undefined && admnField.options.length > 0) && (
                                                                    <IonSelect value={formDataOne[admnField._id]}
                                                                        className='input-field'
                                                                        cancelText={t("cancel")}
                                                                        okText={t("ok")}
                                                                        placeholder={`${t("select")} ${admnField.name}`}
                                                                        onIonChange={
                                                                            (e) => handleChange(e.detail.value, admnField._id)
                                                                        }
                                                                        >
                                                                    
                                                                    { admnField.options.map((option, i) => {
                                                                            return (<IonSelectOption key={i} value={option}>{option}</IonSelectOption>);
                                                                        })
                                                                    }
                                                                    </IonSelect>
                                                                )}

                                                            </IonItem>
                                                        </IonCardContent>
                                                    </IonCard>
                                                </IonCol>
                                            </IonRow>
                                             )
                                        ))}

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className='ion-text-center'>
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                >{t("update_stats")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default KeyStats;
