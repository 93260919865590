import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, bookOutline, chevronForwardOutline } from 'ionicons/icons';
import React, {useEffect,  useState} from 'react';
import {useAuth} from '../../components/AuthContext';

interface AllSubject {
    _id: string,
    name: string,
    subjectType: string,
    isActive: boolean
}

const Subjects: React.FC = () => {
    const {authInfo} = useAuth()!;

    const [acadSubjects, setAcadSubjects] = useState < AllSubject[] > ([]);
    const [noAcadSubjects, setNoAcadSubjects] = useState < AllSubject[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
  
    const [name, setName] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }
    
    useEffect(() => {

        setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            try {
                const stud = await api.get(`/students/getByAccount/${authInfo.user._id}`);
                const result = await api.get('/classes/' + stud.data.classID);

                setName(result.data.name);

                if (result.data.subjects.length !== 0)
                {
                    const subs = await api.get(`/subjects/getByInstitute/${result.data.instituteID}`);

                    let subject: AllSubject;
                    let acadsubs: AllSubject[] = [];
                    let nosubs: AllSubject[] = [];

                    for(let i = 0; i < result.data.subjects.length; i++)
                    {
                        subject = subs.data.find((val: {_id:string}) => val._id === result.data.subjects[i].subjectID);
                        if (subject.subjectType === 'academic')
                        {
                            acadsubs.push(subject);
                        }
                        else
                        {
                            nosubs.push(subject);
                        }
                    }
                    setAcadSubjects(acadsubs);
                    setNoAcadSubjects(nosubs);
                }

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle color="secondary">{name} Subjects</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{name} Subjects</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding">

                    <IonRow className='mt-30'>
                        <IonCol>
                            <IonTitle className='title-heading'>
                                Subjects with Study Material
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-vertical">
                        <IonCol size="10" offset="1">
                             {acadSubjects && (acadSubjects.length > 0) ? (acadSubjects.map((subject) => {
                                    return (
                                    
                                         <IonCard className="subject-card" routerLink={`/app/chapters/${subject._id}`} key={subject._id}>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol size="2">
                                                            <IonIcon 
                                                                icon={bookOutline}
                                                                className='subject-icon'
                                                            />
                                                    </IonCol>
                                                    <IonCol size="9">
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <IonText className='subject-head'>{subject.name}</IonText>
                                                            </div>
                                                        </div>
                                                    </IonCol>
                                                    <IonCol size="1" className='mt-10'>
                                                                
                                                                <IonIcon className='second-icon'
                                                                    icon={chevronForwardOutline}
                                                                    color='dark'/>
                                                      
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    )
                                })) : ( 

                                        <IonCard className='action-card-c'>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                    slot="start" 
                                                    color="danger" />
                                                    <IonLabel className="list-label"><h3>No Subjects</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    
                                )
                                } 
                        </IonCol>
                    </IonRow>

                     <IonRow className='mt-30'>
                        <IonCol>
                            <IonTitle className='title-heading'>
                                All Subjects
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-vertical">
                        <IonCol size="10" offset="1">
                             {noAcadSubjects && (noAcadSubjects.length > 0) ? (noAcadSubjects.map((subject) => {
                                    return (
                                    
                                    <IonCard className="subject-card-1" key={subject._id}>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol size="2">
                                                        <IonIcon 
                                                            icon={bookOutline}
                                                            className='subject-icon-1'
                                                        />
                                                </IonCol>
                                                <IonCol size="10">
                                                    <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                            <IonText className='subject-head-1'>{subject.name}</IonText>
                                                            </div>
                                                        </div>

                                                </IonCol>
                                               
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                )
                            })) : ( 

                                    <IonCard className='action-card-b'>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                slot="start" 
                                                color="danger" />
                                                <IonLabel className="list-label"><h3>No Subjects</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )
                                } 

                        </IonCol>
                    </IonRow>


                </IonGrid>

            </IonContent>
          
        </IonPage>
    );
};

export default Subjects;
