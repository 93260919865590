import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, checkboxOutline, construct, star, starOutline } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useAuth} from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../../components/usePageUnloadHandler';

interface Report {
    _id: string,
    calendarDate: Date,
    work: string,
    files?: Attach[],
    response?: string,
    rating?: number
}

interface Attach {
    caption: string,
    filename: string
}

interface Employee {
    _id: string,
    userInfo: UserInfo,
    isActive: boolean
}

interface UserInfo {
    userID: string,
    name: string
}

interface Department {
    _id: string,
    name: string
}

interface ParamTypes {
    employeeID?: string
}

const EmployeeReports: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {employeeID} = useParams < ParamTypes > ();
    const [reports, setReports] = useState < Report[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [current, setCurrent] = useState < string > ("");
    const [selectedEmployee, setSelectedEmployee] = useState <string> ("");
    const [employees, setEmployees] = useState < Employee[] > ([]);
    const [departments, setDepartments] = useState < Department[] > ([]);
    const [department, setDepartment] = useState < string > ("");
    const [total, setTotal] = useState < number > (0);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem) {
            setScrollPosition(elem.scrollTop);
        }
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleDepartment = (departmentID: string) => {

        setDepartment(departmentID);
        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/employees/getByDepartment/${departmentID}`);
                setEmployees(studs.data);
            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/employees/getAllInstitute/${authInfo.user.instituteID}`);
                setEmployees(studs.data);
            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        if (departmentID === 'all')
        {
            fetchAll();
        }
        else
        {
            fetchUp();
        }

        setSelectedEmployee("");
        setReports([]);
        
    }

    const handleEmployee = (employeeID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/dailyReports/getByEmployee/${employeeID}`);
                setReports(result.data.reports);
                setTotal(result.data.total);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setSelectedEmployee(employeeID);
    }

    const loadItems = async () => {

        if (!selectedEmployee || selectedEmployee === "") {
            setMessage(t("select_employee"));
            setIserror(true);
            return;
        }

        let limit = skip+30;

        try {
            const result = await api.get(`/dailyReports/getByEmployee/${selectedEmployee}?skip=${limit}`);
            if(result.data.reports && result.data.reports.length)
            {
                setReports([...reports, ...result.data.reports]);
                setSkip(limit);
            }
        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { reports, employees, total, skip, departments, selectedEmployee, department, scrollPosition } = JSON.parse(storedState);
                // Data is present in local storage, use it to initialize state
                setReports(reports);
                setEmployees(employees);
                setDepartments(departments);
                setTotal(total);
                setSkip(skip);
                setSelectedEmployee(selectedEmployee);
                setDepartment(department);
                setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
            } 
            else 
            {

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {

                        const res = await api.get(`/departments/getAllInstitute/${authInfo.user.instituteID}`);
                        setDepartments(res.data);
                            
                        if (employeeID)
                        {
                            const stud = await api.get(`/employees/${employeeID}`);  
                            setDepartment(stud.data.departmentInfo.departmentID);
        
                            const studs = await api.get(`/employees/getByDepartment/${stud.data.departmentInfo.departmentID}`);
                            setEmployees(studs.data);
                            setSelectedEmployee(stud.data.userInfo.userID);
        
                            const result = await api.get(`/dailyReports/getByEmployee/${stud.data.userInfo.userID}`);
                            setReports(result.data.reports);
                            setTotal(result.data.total);
                        }
                        else
                        {
                            setDepartment("");
                            setEmployees([]);
                            setSelectedEmployee("");
                            setReports([]);
                            setTotal(0);
                        }
                        setSkip(0);

                    } catch (error: any) {
                        setMessage(error.response?.data.message || t("something_went_wrong"));
                        setIserror(true);
                    } finally {
                        setShowLoading(false);
                    }
                    
                };

                fetchUp();
            }
   
        setInitialRender(false);

    }, [authInfo, employeeID, refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            reports,
            departments,
            employees,
            total,
            skip,
            selectedEmployee,
            department,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));

        
        }
    }, [reports, departments, employees, total, skip, selectedEmployee, department, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("employee_reports")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("employee_reports")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

              
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding max-xl">
                    <IonRow>
      
                        <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("select_department")}</IonLabel>
                                            <IonSelect value={department}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_department")}
                                                    onIonChange={
                                                        (e) => handleDepartment(e.detail.value)
                                                }>
                                                     <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {departments.map((dep) => (
                                                        (<IonSelectOption key={dep._id} value={`${dep._id}`}>{dep.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>

                        <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">                   
                                    
                                    <IonItem
                                    detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_emp")}</IonLabel>
                                        <IonSelect value={selectedEmployee}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    className="input-field text-capitalize"
                                                    placeholder={t("select_emp")}
                                                    onIonChange={
                                                        (e) => e.detail.value && handleEmployee(e.detail.value)
                                                }>
                                                    {employees.map((employee) => (
                                                        (employee.isActive) &&
                                                        (<IonSelectOption key={employee._id} value={`${employee.userInfo.userID}`} className='text-capitalize'>{employee.userInfo.name}</IonSelectOption>)
                                                    ))}
                                                 
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                
                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">
                            <IonCol size="5" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                    />
                                    <IonText className='ion-margin-start'>{t("date")}</IonText>
                            </IonCol>

                            <IonCol size="6" className='table-heading'>
                            {t("rating")}
                            </IonCol>
                           
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                    (e) => setCurrent(e.detail.value!)
                            }>

                            {(reports.length > 0) ? (reports.map((report, i) => {
                                return (
                                    <IonRow key={report._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={
                                        () => setCurrent(report._id)
                                    }
                                    onDoubleClick={
                                        () => history.push(`/app/report/${report._id}`)
                                    }>
                                        
                                        <IonCol size="5" className='table-field' >
                                                <IonRow>
                                                    <IonCol>
                                                    <IonRadio value={report._id} className='table-icon' color="secondary" />
                                                        <IonText className='ion-margin-start text-capitalize'>{formatDate(report.calendarDate)}</IonText>
                                                       
                                                    </IonCol>
                                                </IonRow>
                                                
                                            </IonCol>
                                            
                                            <IonCol size="6" className='table-field'>
                                            {!report.rating && (
                                                                        <>
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium"
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                     </>
                                                                    )}
                                                                    {report.rating && (report.rating === 1) && (
                                                                        <>
                                                                         <IonIcon
                                                                             icon={star}
                                                                             color="dark" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium"
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                     </>
                                                                    )}
                                                                    {report.rating && (report.rating === 2) && (
                                                                        <>
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark"
                                                                            />
                                                                            <IonIcon
                                                                                icon={starOutline}
                                                                                color="medium" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={starOutline}
                                                                                color="medium" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={starOutline}
                                                                                color="medium" 
                                                                            />
                                                                        </>
                                                                    )}
                                                                    {report.rating && (report.rating === 3) && (
                                                                        <>
                                                                         <IonIcon
                                                                             icon={star}
                                                                             color="dark" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={star}
                                                                             color="dark"
                                                                         />
                                                                         <IonIcon
                                                                             icon={star}
                                                                             color="dark" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                         <IonIcon
                                                                             icon={starOutline}
                                                                             color="medium" 
                                                                         />
                                                                     </>
                                                                    )}
                                                                    {report.rating && (report.rating === 4) && (
                                                                        <>
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark"
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={starOutline}
                                                                                color="medium" 
                                                                            />
                                                                        </>
                                                                    )}
                                                                    {report.rating && (report.rating === 5) && (
                                                                        <>
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark"
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                            <IonIcon
                                                                                icon={star}
                                                                                color="dark" 
                                                                            />
                                                                        </>
                                                                    )}
                                            </IonCol>
                                            <IonCol size="1" >
                                                    <IonIcon 
                                                    icon={construct}
                                                    color="dark"
                                                    className='table-icon mt-10'
                                                    onClick={
                                                        () => history.push(`/app/report/${report._id}`)
                                                    }
                                                    />

                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_reports")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                        </IonRadioGroup>
                    </div>

                    {(reports.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                        <IonRow>
                            <IonCol>
                                <IonRow>
                                    <IonCol className="ion-padding-start footer-stats">
                                {t("showing")} {total === 0 ? t("no_records") : `1 - ${reports.length} ${t("of")} ${total}`} 
                                    </IonCol>
                                </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>
                                    <IonButton 
                                    onClick={
                                        () => {
                                            if (current === "")
                                            {
                                                setMessage(t("select_report"));
                                                setIserror(true);
                                            }
                                            else
                                            {
                                                history.push(`/app/report/${current}`);
                                            }

                                        }
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("view_report")}</IonButton>

                           
                            </IonCol>
                        </IonRow>
                    </IonCol>
                    </IonRow>
                        
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default EmployeeReports;