import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    timetableID: string
}

interface TimeSlot {
    _id: string,
    name: string,
    startTime: string,
    endTime: string,
    isActive: boolean,
    isChecked: boolean
}

interface TimeTable {
    _id: string,
    name: string
}

const UpdateTimeTableOne: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {timetableID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [className, setClassName] = useState < string > ("");
    const [classYear, setClassYear] = useState < string > ("");
    const [timeSlots, setTimeSlots] = useState < TimeSlot[] > ([]);
    const [name, setName] = useState < string > ("");
    const [active, setActive] = useState < string > ("");
    const [original, setOriginal] = useState < string[] > ([]);
    const [timetable, setTimeTable] = useState < TimeTable > ();

    const handleChecked = (value: boolean, id : number) => {

        let slots: Array < TimeSlot > = [];
        for (let i = 0; i < timeSlots.length ; i++) {
            if (i === id) {
                slots[i] = {'_id': timeSlots[i]._id, 'name': timeSlots[i].name, 'startTime': timeSlots[i].startTime, 'endTime': timeSlots[i].endTime, 'isActive': timeSlots[i].isActive, 'isChecked' : value};    
            }
            else 
            {
                slots[i] = timeSlots[i];
            }       
        }
        setTimeSlots(slots);
    }

    const handleSection = (section: string) => {
    
          const api = axios.create({
              baseURL: process.env.REACT_APP_API_URL,
              timeout: parseInt(process.env.REACT_APP_API_TO!),
              headers: {
                  'Authorization': 'Bearer ' + authInfo.token
              }
          });
  
        const toggleTable = (isActive: string) => {
            return new Promise((resolve, reject) => {
    
                api.put(`/timeTables/${timetableID}`, { isActive }).then(res => {
        
                    return resolve(res.data.name);
                    
                    }).catch(err => reject(err));
    
            });
            }
  
        if ((active !== "") && (active !== section))
        {
                setShowLoading(true);
                toggleTable(section)
                .then(data => {
                    let msg: string = "";
                    if (section === 'enable')
                    {
                        setActive("enable");
                        msg = data+t("now_active");
                    }
                        
                    if (section === 'disable')
                    {
                        setActive("disable");
                        msg = data+t("now_inactive");
                    }

                    setPass(msg);
                    setIspass(true);
                    setShowLoading(false);
                })
                .catch(error => {
                    if (error.response !== undefined)
                        setMessage(error.response.data.message);
                    else
                        setMessage(t("something_went_wrong"));
            
                    setIserror(true);
                    setShowLoading(false);
                });
            }
      }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("table_name_mandatory"));
            setIserror(true);
            return;
        }

        let slots: Array < TimeSlot > = [];
        for (let i = 0; i < timeSlots.length ; i++) {
            if (timeSlots[i].isChecked)
                slots.push(timeSlots[i]);
        }

        if(!slots.length)
        {
            setMessage(t("slot_mandatory"));
            setIserror(true);
            return;
        }

        history.push({
            pathname: `/app/timetable/update/${timetableID}/subjects`,
            state: { 
                'timetable': timetable,
                'originalSlots': original,
                'selectedSlots': slots,
                'name': name
            }});
        
      }

      useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/timeTables/${timetableID}`);
                setTimeTable(result.data);
                setName(result.data.name);
                setClassName(result.data.classInfo.className);

                const res = await api.get(`/sessions/${result.data.classInfo.session}`);
                setClassYear(res.data.name);
                

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                let slotids: string[] = [];  

                for(let i = 0; i < result.data.mon.length; i++)
                {
                    slotids.push(result.data.mon[i].timeSlot.timeSlotID);
                }

                setOriginal(slotids);

                const slots = await api.get(`/timeSlots/getByInstitute/${result.data.instituteID}`);

                let slotsArray: Array < TimeSlot > = [];
                for (let i = 0; i < slots.data.length ; i++) {
                    let slotObj: TimeSlot = slots.data[i];

                        if(slotids.indexOf(slotObj._id) !== -1)  
                        {  
                            slotObj['isChecked'] = true;
                        }   
                        else
                        {
                            slotObj['isChecked'] = false;
                        }

                    slotsArray.push(slotObj);
                }

                setTimeSlots(slotsArray);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, timetableID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/timetable/${timetableID}`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("update_time_table")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("update_time_table")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                 
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("table_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='border-radius-10 active-group'>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="8">
                                                
                                                    {t("current_status")}
                                                
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                            {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>{t("active")}</IonBadge>) : 
                                            ( <IonBadge color="danger" className='ion-padding-horizontal'>{t("inactive")}</IonBadge>)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-top ion-padding-horizontal">
                                            <IonCol>
                                                <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                    <IonSegmentButton value="enable">
                                                        <IonLabel>{t("make_active")}</IonLabel>
                                                    </IonSegmentButton>
                                                    <IonSegmentButton value="disable">
                                                        <IonLabel>{t("make_inactive")}</IonLabel>
                                                    </IonSegmentButton>
                                                </IonSegment>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem color="secondary">
                                                
                                                <IonLabel position="stacked" className="input-label">
                                                    {t("session")}
                                                </IonLabel>
                                                <IonInput type="text"
                                                    value={classYear}
                                                    readonly={true}
                                                ></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol size="6">
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem color="secondary">
                                                
                                                <IonLabel position="stacked" className="input-label">
                                                    {t("class")}
                                                </IonLabel>
                                                <IonInput type="text"
                                                    value={className}
                                                    readonly={true}
                                                ></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("table_name")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("table_name")}
                                                value={name}
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("select_slots")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-vertical'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        {timeSlots && timeSlots.length > 1 && timeSlots.map((slot, i) => (
                                            (slot.isActive) &&
                                            (<IonItem key={slot._id}
                                                detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonCheckbox slot="start" value={slot._id} checked={slot.isChecked} 
                                                    onIonChange={e => handleChecked(e.detail.checked, i)}/>
                                                <IonLabel>{slot.name} ({slot.startTime} - {slot.endTime})</IonLabel>
                                            </IonItem>)
                                        ))}

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("proceed_to_subjects")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default UpdateTimeTableOne;
