import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React from 'react';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import {useAuth} from "../../../components/AuthContext";
import { helpCircleOutline } from 'ionicons/icons';

const AdmnSetup: React.FC = () => {
    const {t} = useTranslation();
    const {authInfo} = useAuth()!;

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("admn_setup")}</IonTitle>
                    <IonButtons slot="end">
                        {!isPlatform('mobile') && (
                        <>
                            <ThemeToggle />
                            <LanguageToggle />
                        </>
                        )}
                         <IonButton id="open-setup-info" fill="clear" className='ion-no-padding badge-button'> 
                            <IonIcon color="success" icon={helpCircleOutline} className="badge-icon" />
                            <IonPopover trigger="open-setup-info" className='info-popover'>
                                <IonCard className='note-card-3 ion-no-margin'>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonRow>
                                            <IonCol className='note-text'>
                                                <p>
                                                {t("setup_help")}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonPopover>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("admn_setup")}</IonTitle>
                    </IonToolbar>
                </IonHeader>


                <IonGrid className='mb-60'>

                    <IonRow className="ion-padding-vertical max-xl">
                        
                        {(authInfo.institute.type === "school") && (
                        <>
                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card" routerLink={`/app/admission/school/stats`}>
                            <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow className="ion-padding-top">
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("key_stats")} src="assets/images/institute.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("key_stats")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card" routerLink={`/app/admission/school/facilities`}>
                            <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow className="ion-padding-top">
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("facilities")} src="assets/images/item.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("facilities")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        </>
                        )}

                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card" routerLink={`/app/admission/form`}>
                            <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow className="ion-padding-top">
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("admn_form")} src="assets/images/form.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("admn_form")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card" routerLink={`/app/admission/class`}>
                            <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow className="ion-padding-top">
                                            <IonCol className="ion-no-padding">
                                                <img alt={(authInfo.institute.type === "school") ? t("admn_classes") : t("admn_courses")} src="assets/images/class.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{(authInfo.institute.type === "school") ? t("admn_classes") : t("admn_courses")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                        <IonCol sizeMd='2' sizeSm='3' size="6">
                            <IonCard className="action-card" routerLink={`/app/admission/school/gallery`}>
                            <IonCardContent className="ion-no-padding ion-text-center">
                                        <IonRow className="ion-padding-top">
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("picture_gallery")} src="assets/images/gallery.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton className="ion-no-padding dash-button ion-text-wrap" fill="clear">{t("picture_gallery")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        

                    </IonRow>
                           
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default AdmnSetup;