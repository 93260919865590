import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonReorder,
    IonReorderGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    ItemReorderEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { addCircle, arrowUndoCircle, construct, toggle } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';

interface ParamTypes {
    chapter?: string,
    topic?: string
}


interface Course {
    _id: string,
    name: string,
    chapters: Chapter[],
    isActive: boolean
}

interface Chapter {
    _id: string,
    chapterName: string,
    isActive: boolean,
    order: number
}

interface Topic {
    _id: string,
    name: string,
    video?: string,
    isActive: boolean,
    questions: Question[],
    order: number
}

interface Question {
    _id: string,
    title: string,
    isActive: boolean,
    order?: number
}

const TopicDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {chapter, topic} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");

    const [courses, setCourses] = useState < Course[] > ([]);
    const [courseID, setCourseID] = useState < string > ("");
    const [chapterID, setChapterID] = useState < string > ("");
    const [topics, setTopics] = useState < Topic[] > ([]);
    const [topicID, setTopicID] = useState < string > ("");
    const [questions, setQuestions] = useState < Question[] > ([]);
    const [video, setVideo] = useState < string > ("");
    const [initial, setInitial] = useState < boolean > (true);

    const handleCourse = (course : string) => {
        if(!initial)
        {
            setQuestions([]);
            setTopicID("");
            setTopics([]);
            setChapterID("");
            setVideo("");
            setCourseID(course);
        }
        else
        {
            setInitial(false);
        }
    }


    const handleChapter = (chapter : string) => {

        setVideo("");
        setQuestions([]);
        setTopicID("");
        setTopics([]);
        

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const tops = await api.get(`/topics/getByChapter/${chapter}`);
                setTopics(tops.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        if (chapter !== "")
        {
            setChapterID(chapter);
            fetchUp();
        }
        
    }

    const handleTopic = (topic: string) => {

        setTopicID(topic);
        setQuestions([]);
        setVideo("");

        if (topic !== "")
        {
            if (topics.find(val => val._id === topic)!.video !== undefined)
            {
                setVideo(topics.find(val => val._id === topic)!.video!);
            }
        
            
            if (topics.find(val => val._id === topic)!.questions.length)
            {
                
                //sort ques based on order
                let res = topics.find(val => val._id === topic)!.questions.sort((a: Question, b: Question) => a.order! - b.order!);

                setQuestions(res);

            }
        }
        
    }

    const handleVideo = () => {

        if (!video || (video === "")) {
            setMessage("Video link cant be blank!");
            setIserror(true);
            return;
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const upTopic = () => {
            return new Promise((resolve, reject) => {
              api.put('/topics/'+topicID, { video }).then(res => {
                return resolve(res.data.name);
              }).catch(err => reject(err));
    
            });
          }

        if (topicID !== "")
        {
            setShowLoading(true);
            upTopic()
            .then(data => {
      
                setPass(data+' has been updated.');
                setIspass(true);
                setShowLoading(false);
              
            })
            .catch(error => {
                if (error.response !== undefined)
                  setMessage(error.response.data.message);
              else
                  setMessage('Please try again afer sometime.');
              setIserror(true);
              setShowLoading(false);
            });
        }

    }

    const handleUndo = () => {

        if (topicID !== "")
        {
            if (topics.find(val => val._id === topicID)!.questions.length)
            {
               //sort ques based on order
                let res = topics.find(val => val._id === topicID)!.questions.sort((a: Question, b: Question) => a.order! - b.order!);

                setQuestions(res);
            }
        }
    }

    const handleTitle = (title: string, index: number) => {

        let comps = [...questions];
        comps[index].title = title;
        setQuestions(comps);
    
    }

    const handleDelete = (index: number) => 
    {

        let comps = [...questions];
        comps[index].isActive = !comps[index].isActive;
        setQuestions(comps);
    }

    const handleAdd = () => {
        let r = (Math.random() + 1).toString(36).substring(7);
        let comps = [...questions];
        comps.push({_id: r, title: '', isActive: true});
        setQuestions(comps);
    }


    const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {

        let from = event.detail.from;
        let to = event.detail.to;
        let toCopy = event.detail.to;

        let ques = [...questions];

        if (to < from)
        {
            ques[to] = {...questions[from]};
            to = to + 1;
            while (to <= from)
            {
                ques[to] = {...questions[to-1]};
                to = to + 1;
            }
        }

        if (toCopy > from)
        {
            ques[toCopy] = {...questions[from]};
            toCopy = toCopy - 1;
            while (toCopy >= from)
            {
                ques[toCopy] = {...questions[toCopy+1]};
                toCopy = toCopy - 1;
            }
        }

        setQuestions(ques);
        
        event.detail.complete();
      }

    
      const handleQuestions = () => {

        if (!questions || (questions.length < 1)) {
            return;
        }

        let sendQuestions: Question[] = [];
        for (let i = 0; i < questions.length; i++)
        {
            sendQuestions.push({
                _id: questions[i]._id,
                title: questions[i].title,
                isActive: questions[i].isActive
            });
        }

        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

  
        const upTopic = (questions: Question[]) => {
          return new Promise((resolve, reject) => {
            api.post('/topics/setQuestions', { topicID, questions }).then(res => {
              return resolve(res.data);
            }).catch(err => reject(err));
  
          });
        }

        upTopic(sendQuestions)
            .then(data => {
    
                setPass(topics.find(val => val._id === topicID)!.name+' has been updated.');
                setIspass(true);
                setShowLoading(false);
            
            })
            .catch(error => {
                if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Please try again afer sometime.');
            setIserror(true);
            setShowLoading(false);
            });
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/courses/');

                setCourses(result.data);

                if(chapter !== undefined && topic !== undefined)
                {
                    const index = result.data.findIndex((co: { chapters: any[]; }) => co.chapters.find((chap: { _id: string; }) => chap._id === chapter));
                      
                    setCourseID(result.data[index]._id);
                    setChapterID(chapter);

                    const tops = await api.get(`/topics/getByChapter/${chapter}`);
                    setTopics(tops.data);

                    setTopicID(topic);

                    if (tops.data.find((val: { _id: string; }) => val._id === topic)!.video !== undefined)
                    {
                        setVideo(tops.data.find((val: { _id: string; }) => val._id === topic)!.video!);
                    }
                
                    
                    if (tops.data.find((val: { _id: string; }) => val._id === topic)!.questions.length)
                    {
                        
                        //sort ques based on order
                        let res = tops.data.find((val: { _id: string; }) => val._id === topic)!.questions.sort((a: Question, b: Question) => a.order! - b.order!);

                        setQuestions(res);

                    }
                
                }
                else
                {
                    setInitial(false);
                }

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, topic, chapter]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle color="secondary">Topic Assessment</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Topic Assessment</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle className="list-title">
                                    Select Topic
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">
                                        
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">Course</IonLabel>
                                                    <IonSelect value={courseID}
                                                            placeholder="Select Course"
                                                            onIonChange={
                                                                (e) => handleCourse(e.detail.value)
                                                        }>
                                                        { courses.map((course) => {
                                                                if (course.isActive)
                                                                {
                                                                    return (<IonSelectOption key={course._id} value={`${course._id}`}>{course.name}</IonSelectOption>);
                                                                }
                                                                else
                                                                {
                                                                    return null;
                                                                }
                                                            })
                                                        }
                                            </IonSelect>
                                        </IonItem>
                                        {(courseID !== "") && (
                                            <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">Chapter</IonLabel>
                                                    <IonSelect value={chapterID}
                                                            placeholder="Select Chapter"
                                                            onIonChange={
                                                                (e) => handleChapter(e.detail.value)
                                                        }>
                                                        { courses.find(val => val._id === courseID)!.chapters.map((chapter) => {
                                                                if (chapter.isActive)
                                                                {
                                                                    return (<IonSelectOption key={chapter._id} value={`${chapter._id}`}>{chapter.chapterName}</IonSelectOption>);
                                                                }
                                                                else
                                                                {
                                                                    return null;
                                                                }
                                                            })
                                                        }
                                                </IonSelect>
                                            </IonItem>
                                             )}
                                            {(chapterID !== "") && (topics.length > 0) && (
                                            <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">Topic</IonLabel>
                                                    <IonSelect value={topicID}
                                                            placeholder="Select Topic"
                                                            onIonChange={
                                                                (e) => handleTopic(e.detail.value)
                                                        }>
                                                        { topics.map((topic) => {
                                                                if (topic.isActive)
                                                                {
                                                                    return (<IonSelectOption key={topic._id} value={`${topic._id}`}>{topic.name}</IonSelectOption>);
                                                                }
                                                                else
                                                                {
                                                                    return null;
                                                                }
                                                            })
                                                        }
                                                </IonSelect>
                                            </IonItem>
                                             )}
                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                   

                {(topicID !== "") && (
                    <>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle className="list-title">
                                    Topic Content
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>
                                            <IonList className="ion-no-padding">

                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="fixed" color="secondary">Video Link</IonLabel>
                                                <IonInput type="text"
                                                    placeholder='Video Link'
                                                    value={video}
                                                    onIonChange={
                                                        (e) => setVideo(e.detail.value!)
                                                }></IonInput>
                                            </IonItem>

                                            </IonList>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-padding-vertical">
                                        <IonCol className="ion-padding-horizontal">
                                            <IonButton onClick={handleVideo} className="first-button"
                                                expand="block" fill="clear">Update Topic Video</IonButton>
                                        </IonCol>
                                    </IonRow>
                                

                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className='ion-margin-top'>
                        <IonCol>
                            <IonCard className='border-radius-10' color='tertiary'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonGrid>
                                        <IonRow className="mt-05">
                                            <IonCol size="8" className='ion-padding-horizontal'>
                                                Topic Questions
                                            </IonCol>
                                            <IonCol size="2" className='ion-text-right'>
                                               
                                               <IonIcon className='tran-icon'
                                                   icon={arrowUndoCircle}
                                                   color='secondary'
                                                   onClick={handleUndo}
                                               />
                                           
                                           
                                            </IonCol>
                                            <IonCol size="2" className='ion-text-center'>
                                               
                                                    <IonIcon className='tran-icon'
                                                        icon={addCircle}
                                                        color='secondary'
                                                        onClick={handleAdd}
                                                    />
                                                
                                                
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    </>
                )}

                {questions && (questions.length > 0) && (

                    <IonRow className="ion-margin-top mb-60">
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                                            {questions.map((ques, i) => {
                                                return (
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        key={ques._id}>
                                                            <IonReorder slot="start"/>
                                                            <IonInput type="text"
                                                                    placeholder='Question Title'
                                                                    value={ques.title}
                                                                    onIonChange={
                                                                        (e) => handleTitle(e.detail.value!, i)
                                                                }
                                                                readonly={!ques.isActive}
                                                                ></IonInput>
                                                            <IonButtons slot="end">
                                                                <IonButton onClick={() => handleDelete(i)}> 
                                                                    <IonIcon
                                                                    icon={toggle}
                                                                    color={ques.isActive ? "success" : "danger"}
                                                                    />
                                                                </IonButton>
                                                                <IonButton onClick={() => (ques._id.length > 10) ? history.push(`/app/assessments/${chapterID}/${topicID}/${ques._id}`) : null }> 
                                                                    <IonIcon
                                                                    icon={construct}
                                                                    color="dark"
                                                                    />
                                                                </IonButton>
                                                            </IonButtons>
                                                            
                                                    </IonItem>
                                                )
                                                
                                                })
                                            }

                                    </IonReorderGroup>
                                </IonCardContent>
                            </IonCard>
                         </IonCol>
                    </IonRow>

                )}


            </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleQuestions}
                                className="first-button"
                                fill="clear"
                                expand="block">Update Topic Questions</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default TopicDetail;
