import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonRouterOutlet,
  IonLabel,
  IonIcon
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import AdmissionHeadHome from "../pages/Home/AdmissionHeadHome";
import AdmnSetup from "../pages/Employee/Dashboard/AdmnSetup";
import AdmnApplications from "../pages/Employee/Dashboard/AdmnApplications";
import Marketing from "../pages/Admin/Dashboard/Marketing";

import News from "../pages/Employee/News/News";
import NewsDetail from "../pages/Employee/News/NewsDetail";

import KeyStats from "../pages/Admin/Admission/School/KeyStats";
import Facilities from "../pages/Admin/Admission/School/Facilities";
import Gallery from "../pages/Admin/Admission/School/Gallery";
import ClassSetup from "../pages/Admin/Admission/School/ClassSetup";
import NewCourse from "../pages/Admin/Admission/School/NewCourse";
import UpdateCourse from "../pages/Admin/Admission/School/UpdateCourse";
import FormSetup from "../pages/Admin/Admission/School/FormSetup";
import ApplicationsBoard from "../pages/Admin/Admission/Application/ApplicationsBoard";
import Applications from "../pages/Admin/Admission/Application/Applications";
import AdmissionList from "../pages/Admin/Admission/Application/AdmissionList";
import ApplicationsHistory from "../pages/Admin/Admission/Application/ApplicationsHistory";
import ApplicationDetail from "../pages/Admin/Admission/Application/ApplicationDetail";
import Appointments from "../pages/Admin/Admission/Application/Appointments";
import AppointmentsHistory from "../pages/Admin/Admission/Application/AppointmentsHistory";

import NewInquiry from "../pages/Admin/Admission/Enquiry/NewInquiry";
import Inquiries from "../pages/Admin/Admission/Enquiry/Inquiries";
import InquiriesBoard from "../pages/Admin/Admission/Enquiry/InquiriesBoard";
import Inquiry from "../pages/Admin/Admission/Enquiry/Inquiry";
import EnquiryDetail from "../pages/Admin/Admission/Enquiry/EnquiryDetail";
import InquiryChat from "../pages/Admin/Admission/Enquiry/InquiryChat";
import InquiryReports from "../pages/Admin/Admission/Enquiry/InquiryReports";

import AddMedia from "../pages/Admin/Whatsapp/AddMedia";
import Medias from "../pages/Admin/Whatsapp/Medias";
import MediaDetail from "../pages/Admin/Whatsapp/MediaDetail";
import AddTemplate from "../pages/Admin/Whatsapp/AddTemplate";
import Templates from "../pages/Admin/Whatsapp/Templates";
import TemplateDetail from "../pages/Admin/Whatsapp/TemplateDetail";
import StartCampaign from "../pages/Admin/Whatsapp/StartCampaign";
import Campaigns from "../pages/Admin/Whatsapp/Campaigns";
import CampaignReports from "../pages/Admin/Whatsapp/CampaignReports";
import NewMessage from "../pages/Admin/Whatsapp/NewMessage";
import Messages from "../pages/Admin/Whatsapp/Messages";
import MessageDetail from "../pages/Admin/Whatsapp/MessageDetail";

import MyTasks from "../pages/Employee/Task/MyTasks";
import DailyReports from "../pages/Employee/Task/DailyReports";
import MyChats from "../pages/Employee/Chat/MyChats";
import AddChat from "../pages/Employee/Chat/AddChat";
import ViewChat from "../pages/Employee/Chat/ViewChat";

import MyAttendance from "../pages/Employee/Attendance/MyAttendance";
import MyLeaves from "../pages/Employee/Attendance/MyLeaves";
import AddLeave from "../pages/Employee/Attendance/AddLeave";
import UserNotifications from "../pages/User/UserNotifications";
import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdatePassword from "../pages/User/UpdatePassword";
import Terms from "../pages/Policies/TermsAndConditions";
import Privacy from "../pages/Policies/PrivacyPolicy";
import ContactUs from "../pages/General/ContactUs";
import DeleteAccount from "../pages/General/DeleteAccount";

import { calendarNumber, chatbubbleEllipses, clipboard, home, newspaper } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useAuth } from './AuthContext';

const AdmissionHeadTabs: React.FC = () => {
  const {t} = useTranslation();
  const {authInfo} = useAuth()!;
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Switch>
          <Route path="/app/home" exact={true} component={AdmissionHeadHome} />
          <Route path="/app/setup" exact={true} component={AdmnSetup} />
          <Route path="/app/applications" exact={true} component={AdmnApplications} />
          <Route path="/app/crm" exact={true} component={Marketing} />

          <Route path="/app/news" exact={true} component={News} />
          <Route path="/app/news/:newsID" exact={true} component={NewsDetail} />

          <Route path="/app/admission/school/stats" exact={true} component={KeyStats} />
          <Route path="/app/admission/school/facilities" exact={true} component={Facilities} />
          <Route path="/app/admission/school/gallery" exact={true} component={Gallery} />
          <Route path="/app/admission/class" exact={true} component={ClassSetup} />
          <Route path="/app/admission/course" exact={true} component={NewCourse} />
          <Route path="/app/admission/course/:courseID" exact={true} component={UpdateCourse} />
          <Route path="/app/admission/form" exact={true} component={FormSetup} />   

          <Route path="/app/admission/inquiries/new/:mode?" exact={true} component={NewInquiry} />
          <Route path="/app/admission/inquiries/list" exact={true} component={Inquiries} />
          <Route path="/app/admission/inquiries/board" exact={true} component={InquiriesBoard} />
          <Route path="/app/admission/inquiry/:inquiryID" exact={true} component={Inquiry} />
          <Route path="/app/admission/inquiry/:inquiryID/details" exact={true} component={EnquiryDetail} />
          <Route path="/app/admission/inquiry/:inquiryID/chat" exact={true} component={InquiryChat} />
          <Route path="/app/admission/inquiries/reports" exact={true} component={InquiryReports} />

          <Route path="/app/admission/applications/board" exact={true} component={ApplicationsBoard} />
          <Route path="/app/admission/applications" exact={true} component={Applications} />
          <Route path="/app/admission/applications/history" exact={true} component={ApplicationsHistory} />
          <Route path="/app/application/:applicationID" exact={true} component={ApplicationDetail} />
          <Route path="/app/admission/appointments" exact={true} component={Appointments} />
          <Route path="/app/admission/appointments/history" exact={true} component={AppointmentsHistory} />
          <Route path="/app/admission/list" exact={true} component={AdmissionList} />

          <Route path="/app/whatsapp/medias/add" exact={true} component={AddMedia} />
          <Route path="/app/whatsapp/medias" exact={true} component={Medias} />
          <Route path="/app/whatsapp/media/:mediaID" exact={true} component={MediaDetail} />
          <Route path="/app/whatsapp/templates/add" exact={true} component={AddTemplate} />
          <Route path="/app/whatsapp/templates" exact={true} component={Templates} />
          <Route path="/app/whatsapp/template/:templateID" exact={true} component={TemplateDetail} />
          <Route path="/app/whatsapp/campaigns" exact={true} component={Campaigns} />
          <Route path="/app/whatsapp/campaigns/start" exact={true} component={StartCampaign} />
          <Route path="/app/whatsapp/campaign/:campaignID" exact={true} component={CampaignReports} />
          <Route path="/app/whatsapp/messages" exact={true} component={Messages} />
          <Route path="/app/whatsapp/messages/new" exact={true} component={NewMessage} />
          <Route path="/app/whatsapp/message/:messageID" exact={true} component={MessageDetail} />

          <Route path="/app/user/tasks" exact={true} component={MyTasks} />
          <Route path="/app/user/task/:chatID" exact={true} component={ViewChat} />
          <Route path="/app/user/reports" exact={true} component={DailyReports} />
          <Route path="/app/user/chats" exact={true} component={MyChats} />
          <Route path="/app/user/chat" exact={true} component={AddChat} />
          <Route path="/app/user/chat/:chatID" exact={true} component={ViewChat} />
 
          <Route path="/app/user/attendance" exact={true} component={MyAttendance} />
          <Route path="/app/user/leaves" exact={true} component={MyLeaves} />
          <Route path="/app/user/leaves/add" exact={true} component={AddLeave} />
          <Route path="/app/user/notification" exact={true} component={UserNotifications} />
          <Route path="/app/user/profile" exact={true} component={Profile} />
          <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
          <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />
          <Route path="/app/policies/terms" exact={true} component={Terms} />
          <Route path="/app/policies/privacy" exact={true} component={Privacy} />
          <Route path="/app/support/contact" exact={true} component={ContactUs} />
          <Route path="/app/support/delete" exact={true} component={DeleteAccount} />

          <Redirect exact={true} path="/app" to={`/app/home`} />
          <Redirect from="*" to="/app"/>

        </Switch>
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className="tab-bar">
        <IonTabButton tab="home" href="/app/home" className="tab-button" target="_blank">
          <IonIcon icon={home} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("home")}</IonLabel>
        </IonTabButton>

        {(authInfo.institute.task) ? 
        (<IonTabButton tab="reports" href="/app/user/reports" className="tab-button" target="_blank">
          <IonIcon icon={clipboard} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("daily_reports")}</IonLabel>
        </IonTabButton>) 
        : 
        ( <IonTabButton tab="news" href="/app/news" className="tab-button" target="_blank">
          <IonIcon icon={newspaper} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("circulars")}</IonLabel>
        </IonTabButton>)}

        <IonTabButton tab="chat" href="/app/user/chats" className="tab-button" target="_blank">
          <IonIcon icon={chatbubbleEllipses} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("my_chats")}</IonLabel>
        </IonTabButton>
        
        <IonTabButton tab="attendance" href="/app/user/attendance" className="tab-button" target="_blank">
          <IonIcon icon={calendarNumber} aria-hidden="true" className="tab-icon" />
          <IonLabel className="tab-label">{t("my_attendance")}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default AdmissionHeadTabs;