import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, calendar, informationCircleOutline } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Toast } from '@capacitor/toast';
import {useAuth} from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';

interface Employee {
    _id: string,
    name: string,
    departmentName: string,
    status: string,
    doneAt: Date
}

interface Department {
    _id: string,
    name: string,
    isActive: boolean
}

interface ParamTypes {
    role?: string
}

const StaffEntryExit: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {role} = useParams < ParamTypes > ();
    const [employees, setEmployees] = useState < Employee[] > ([]);
    const [departments, setDepartments] = useState < Department[] > ([]);
    const [department, setDepartment] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB') +" "+ t("at") +" "+ valueDate.toLocaleTimeString('en-US');
    };

    const formatDated = (value: string | string[] | undefined) => {
        if (typeof value === "string")
        {
            const valueDate = new Date(value);
            return " "+valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' }) + " at " + valueDate.toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" });
        }
    };
      
    const handleDepartment = (departmentID: string) => {

        setDepartment(departmentID);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getEntryDepartment/${departmentID}`);
                setEmployees(studs.data);
                setShowLoading(false);
            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/staffAttends/getEntryInstitute/${authInfo.user.instituteID}`);
                setEmployees(studs.data);
                setShowLoading(false);
            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            setShowLoading(false);
        };

        if (departmentID === 'all')
        {
            fetchAll();
        }
        else
        {
            fetchUp();
        }
        
    }

    const handleEntry = (employeeID : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const entry = () => {
            return new Promise((resolve, reject) => {
              api.get('/staffAttends/markEntry/' + employeeID).then(res => {
                return resolve(res.data.employee.userInfo.name);
              }).catch(err => reject(err));
            });
        }

        const entryDate = () => {
            return new Promise((resolve, reject) => {
              api.get(`/staffAttends/markEntry/${employeeID}/${selectedDate}`).then(res => {
                return resolve(res.data.employee.userInfo.name);
              }).catch(err => reject(err));
            });
        }
    

        if (typeof selectedDate === "string")
        {
            entryDate()
            .then(async (data) => {
                
                let studs = [...employees];
                const index = studs.findIndex((val) => val._id === employeeID);
                if (index > -1)
                {
                    studs[index].status = 'in';
                    studs[index].doneAt = new Date(selectedDate);
                }
                setEmployees(studs);
                await Toast.show({
                    text: `${data} ${t("marked_entered")} ${formatDate(new Date(selectedDate))}`,
                  });

            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
        
                setIserror(true);

            });
        }
        else
        {
            entry()
            .then(async (data) => {
                
                let studs = [...employees];
                const index = studs.findIndex((val) => val._id === employeeID);
                if (index > -1)
                {
                    studs[index].status = 'in';
                    studs[index].doneAt = new Date(Date.now());
                }
                setEmployees(studs);
                await Toast.show({
                    text: `${data} ${t("marked_entered")} ${formatDate(new Date(Date.now()))}`,
                });

            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
        
                setIserror(true);

            });
        }

    }

    const handleExit = (employeeID : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const exit = () => {
            return new Promise((resolve, reject) => {
              api.get('/staffAttends/markExit/' + employeeID).then(res => {
                return resolve(res.data.employee.userInfo.name);
              }).catch(err => reject(err));
            });
        }

        const exitDate = () => {
            return new Promise((resolve, reject) => {
              api.get(`/staffAttends/markExit/${employeeID}/${selectedDate}`).then(res => {
                return resolve(res.data.employee.userInfo.name);
              }).catch(err => reject(err));
            });
        }


        if (typeof selectedDate === "string")
        {
            exitDate()
            .then(async (data) => {

                let studs = [...employees];
                const index = studs.findIndex((val) => val._id === employeeID);
                if (index > -1)
                {
                    studs[index].status = 'out';
                    studs[index].doneAt = new Date(selectedDate);
                }
                setEmployees(studs);
                await Toast.show({
                    text: `${data} ${t("marked_exit")} ${formatDate(new Date(selectedDate))}`,
                });

            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
        
                setIserror(true);

            });
        }
        else
        {
            exit()
            .then(async (data) => {

                let studs = [...employees];
                const index = studs.findIndex((val) => val._id === employeeID);
                if (index > -1)
                {
                    studs[index].status = 'out';
                    studs[index].doneAt = new Date(Date.now());
                }
                setEmployees(studs);
                await Toast.show({
                    text: `${data} ${t("marked_exit")} ${formatDate(new Date(Date.now()))}`,
                });
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
        
                setIserror(true);

            });
        }

    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try 
            {
                if ((authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && role === "teachers"))
                {
                    const studs = await api.get(`/staffAttends/getEntryAcademic/${authInfo.user.instituteID}`);
                    setEmployees(studs.data);
                }
                if ((authInfo.user.role === 'Transporter') || (authInfo.user.role === 'Employee' && role === "drivers"))
                {
                    const studs = await api.get(`/staffAttends/getEntryTransporter/${authInfo.user.instituteID}`);
                    setEmployees(studs.data);
                }
                if (authInfo.user.role === 'Super' || authInfo.user.role === 'Admin')
                {
                    const result = await api.get(`/departments/getAllInstitute/${authInfo.user.instituteID}`);
                    setDepartments(result.data);
                    setEmployees([]);
                }
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, role]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("mark_entry_exit")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("mark_entry_exit")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin')) && (
                <IonGrid className="ion-no-padding max-xl">
                  
                    <IonRow>
                         <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("select_department")}</IonLabel>
                                            <IonSelect value={department}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_department")}
                                                    onIonChange={
                                                        (e) => handleDepartment(e.detail.value)
                                                }>
                                                     <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {departments.map((dep) => dep.isActive && (
                                                        (<IonSelectOption key={dep._id} value={`${dep._id}`}>{dep.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                )}
                <IonGrid className="ion-no-padding max-xl">
                    
                    <IonRow className='ion-margin-top'>
                        <IonCol>
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon mt-05" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08 pt-08'>
                                            <p>
                                                {t("entry_note")}
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}
                                        id="open-date-input">
                                        <IonLabel className="input-label" position="stacked" color="secondary">{t("attendance_date")}</IonLabel>
                                        <IonInput className="input-field" value={typeof selectedDate !== 'string' ? t("current_time") : formatDated(selectedDate)} readonly={true} />
                                            <IonModal trigger="open-date-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("pick_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        cancelText={t("cancel")}
                                                        doneText={t("ok")}
                                                        value={selectedDate}
                                                        presentation="date-time"
                                                        onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                        </IonModal>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol>
                            <IonTitle className="list-title">
                                {t("employees_last_update")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    {(employees.length > 0) && (
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                        
                          <IonList className="ion-no-padding">

                             {(employees.map((employee) => {
                                    return (
                                        <IonItemSliding key={employee._id}>

                                            {isPlatform("mobile") && (
                                                <IonItemOptions side="start" onIonSwipe={() => handleEntry(employee._id)}>
                                                    <IonItemOption color="success" onClick={() => handleEntry(employee._id)}>{(authInfo.institute.type === "school") ? t("entered_school") : t("entered_insti")}</IonItemOption>
                                                </IonItemOptions>
                                            )}

                                            <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                  <IonIcon slot="start"
                                                    icon={calendar}
                                                    color={
                                                        (employee.status === 'in') ? 'success' : (employee.status === 'out') ? 'danger' : ''
                                                    }/>
                                                <IonLabel>
                                                    <h2 className="text-capitalize">{employee.name} {(department === "all") && ("("+employee.departmentName+")")} </h2>
                                                    {(employee.status === 'out') && (
                                                        <p>{(authInfo.institute.type === "school") ? t("left_school_at") : t("left_insti_at")} {formatDate(employee.doneAt)}</p>
                                                    )}

                                                    {(employee.status === 'in') && (
                                                        <p>{(authInfo.institute.type === "school") ? t("entered_school_at") : t("entered_insti_at")} {formatDate(employee.doneAt)}</p>
                                                    )}
                                                </IonLabel>
                                                {!isPlatform("mobile") && 
                                                (<IonButtons slot="end">
                                                        <IonButton className="present-button" onClick={
                                                            () => handleEntry(employee._id)
                                                        }>
                                                            {(authInfo.institute.type === "school") ? t("entered_school") : t("entered_insti")}
                                                        </IonButton>
                                                        
                                                        <IonButton className="absent-button" onClick={
                                                            () => handleExit(employee._id)
                                                        }>
                                                            {(authInfo.institute.type === "school") ? t("left_school") : t("left_insti")}
                                                        </IonButton>
                                                </IonButtons>
                                                )}
                                            </IonItem>
                                        
                                            {isPlatform("mobile") && (
                                                <IonItemOptions side="end" onIonSwipe={() => handleExit(employee._id)}>
                                                    <IonItemOption color="danger" onClick={() => handleExit(employee._id)}> {(authInfo.institute.type === "school") ? t("left_school") : t("left_insti")}</IonItemOption>
                                                </IonItemOptions>
                                            )}
                                            
                                      </IonItemSliding>
                                      
                                      
                                    )
                                }))}
                                
                                </IonList>
                            </IonCol>
                        </IonRow>
                        )}
                            
                {(employees.length === 0) && ( 
                    <IonRow>
                        <IonCol>
                            <IonCard className='red-card'>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_employees")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )} 
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow className="max-xl">
                        <IonCol size="4" className="ion-padding footer-stats">
                            {t("total")}: {employees.length}
                        </IonCol>
                        <IonCol size="4" className="ion-padding footer-stats">
                            {t("entered")}: {employees.filter(val => val.status === "in").length}
                        </IonCol>
                        <IonCol size="4" className="ion-padding footer-stats">
                            {t("left")}: {employees.filter(val => val.status === "out").length}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default StaffEntryExit;
