import {
  IonRouterOutlet,
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import ContentHome from "../pages/Home/ContentHome";
import Courses from "../pages/Content/Course/Courses";
import AddCourse from "../pages/Content/Course/AddCourse";
import CourseDetail from "../pages/Content/Course/CourseDetail";
import Chapters from "../pages/Content/Course/Chapters";
import Topics from "../pages/Content/Topic/Topics";
import TopicDetail from "../pages/Content/Topic/TopicDetail";
import QuestionDetail from "../pages/Content/Topic/QuestionDetail";

import UserNotifications from "../pages/User/UserNotifications";
import Profile from "../pages/User/Profile";
import UpdatePersonal from "../pages/User/UpdatePersonal";
import UpdatePassword from "../pages/User/UpdatePassword";
import Terms from "../pages/Policies/TermsAndConditions";
import Privacy from "../pages/Policies/PrivacyPolicy";
import ContactUs from "../pages/General/ContactUs";
import DeleteAccount from "../pages/General/DeleteAccount";

const ContentTabs: React.FC = () => {
  return (
      <IonRouterOutlet>
        <Switch>
            <Route path="/app/home" exact={true} component={ContentHome} />
            <Route path="/app/courses" exact={true} component={Courses} />
            <Route path="/app/courses/add" exact={true} component={AddCourse} />
            <Route path="/app/course/:courseID" exact={true} component={CourseDetail} />
            <Route path="/app/chapters/:courseID" exact={true} component={Chapters} />
            <Route path="/app/topics" exact={true} component={Topics} />
            <Route path="/app/topics/:chapter" exact={true} component={Topics} />
            <Route path="/app/assessments" exact={true} component={TopicDetail} />
            <Route path="/app/assessments/:chapter/:topic" exact={true} component={TopicDetail} />
            <Route path="/app/assessments/:chapterID/:topicID/:questionID" exact={true} component={QuestionDetail} />
            <Route path="/app/user/notification" exact={true} component={UserNotifications} />
            <Route path="/app/user/profile" exact={true} component={Profile} />
            <Route path="/app/user/profile/personal" exact={true} component={UpdatePersonal} />
            <Route path="/app/user/profile/password" exact={true} component={UpdatePassword} />
            <Route path="/app/policies/terms" exact={true} component={Terms} />
            <Route path="/app/policies/privacy" exact={true} component={Privacy} />
            <Route path="/app/support/contact" exact={true} component={ContactUs} />
            <Route path="/app/support/delete" exact={true} component={DeleteAccount} />
            <Redirect exact={true} path="/app" to="/app/home" />
            <Redirect from="*" to="/app"/>
          </Switch>
      </IonRouterOutlet>
  );
};

export default ContentTabs;
