import {
    IonAlert,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    IonCard,
    IonCardContent,
    IonIcon,
    IonText,
    IonItem,
    IonList,
    IonButton,
    IonLabel,
    IonRefresher,
    IonRefresherContent,
    IonSkeletonText,
    RefresherEventDetail
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useAuth} from "../../components/AuthContext";
import { useHistory } from 'react-router-dom';
import { calendarOutline, checkboxOutline, closeCircle, createOutline, playCircle } from 'ionicons/icons';
import Chart from "react-apexcharts";

const tasks = ["Maths Test", "Social Science Test", "English Test", "Science Test"];

const options1 = {
    colors : ['#333333', '#DDDDDD'],
    labels: ['Present', 'Absent'],
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
      },
    stroke: {
        show: false
      },
      plotOptions: {
        pie: {
            donut: {
                labels: {
                  show: false
                }},
          expandOnClick: false,
          
        }
      }
};

const StudentHome: React.FC = () => {
    const {authInfo, logOut} = useAuth()!;
    const history = useHistory();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [isexpired, setIsexpired] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [isold, setIsold] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [institute, setInstitute] = useState < string > ("");
    const [fileName, setFileName] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

       

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/institutes/${authInfo.user.instituteID}`);
                setInstitute(result.data.name);
                setFileName(result.data.logo);
                setShowLoading(false);

            } catch (error: any) {  
                if (error.response !== undefined) 
                {
                    if (error.response.data.message === 'expired')
                    {
                        setIsexpired(true);
                    }
                    else
                    {
                        setMessage(error.response.data.message);
                        setIserror(true);
                    }
                    
                }   
                else 
                {
                    setMessage('Something went wrong...');
                    setIserror(true);
                }
                setShowLoading(false);
                    
            }
           
        };

     

        const fetchVersion = async () => {

            try {
                const result = await api.get(`/version-check`);
                if (parseInt(result.data) > parseInt(process.env.REACT_APP_VERSION!))
                {
                    setIsold(true);
                }
                else
                {
                    fetchData();
                }
            } catch (error: any) {  
                if (error.response !== undefined) 
                {
                    if (error.response.data.message === 'expired')
                    {
                        setIsexpired(true);
                    }
                    else
                    {
                        setMessage(error.response.data.message);
                        setIserror(true);
                    }
                    
                }   
                else 
                {
                    setMessage('Something went wrong...');
                    setIserror(true);
                }
            }

        };

        fetchVersion();   

    }, [authInfo, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="end">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle color="secondary" className="text-capitalize">Hello! {authInfo.user.name}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className="text-capitalize">Hello! {authInfo.user.name}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert
                    isOpen={isexpired}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/login");
                    }}
                    header="Session Expired"
                    message="Please login again to continue using our services"
                    buttons={["Logout"]}
                />

                <IonAlert
                    isOpen={iserror}
                    onDidDismiss={() => setIserror(false)}
                    header="Alert"
                    message={message}
                    buttons={["Close"]}
                />

                <IonAlert
                    isOpen={isold}
                    backdropDismiss={false}
                    onDidDismiss={
                        () => document.location.href = isPlatform('android') ? process.env.REACT_APP_PLAY_STORE! : process.env.REACT_APP_APP_STORE!
                    }
                    header="Unsupported Version"
                    message="This version is no longer supported. Please update to latest version from Play/App Store."
                    buttons={
                        ["Update"]
                    }
                />

            

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding white-shade">
                    <IonRow className="home-row">
                        <IonCol size="3" className="ion-text-center ion-padding-vertical">
                            <div className="vertical-container">
                                <div className="vertical-center"> 
                            {fileName && (fileName !== "") ?
                                (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${fileName}`} alt="Logo" width="70" />)
                                : (<img src={`assets/images/institute.png`} alt="Logo" width="70" />)
                            }

                                </div>
                            </div>
                        </IonCol>
                        <IonCol size="9" className="ion-text-center">
                            <div className="vertical-container">
                                <div className="vertical-center"> 
                                {
                                (institute !== "") ? (<p className="logo-text">{institute}</p>) : 
                                ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'height': '40px', 'marginLeft': '20px' }}></IonSkeletonText>))
                                }
                                

                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonGrid className='mb-60'>
                    <IonRow className="ion-padding ion-margin-top">
                        <IonCol size="6">
                            <IonRow>
                                <IonCol>
                                    <IonCard className="stat-card-1 ion-no-margin">
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol size="3">
                                                        <IonIcon 
                                                            icon={checkboxOutline}
                                                            className='stat-icon'
                                                        />
                                                </IonCol>
                                                <IonCol size="9">
                                                        <IonText className='stat-head'>32 Tests</IonText>
                                                        <p className='stat-subhead'>Completed</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className="stat-card-2 ion-no-margin">
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol size="3">
                                                    <IonIcon 
                                                            icon={createOutline}
                                                            className='stat-icon'
                                                        />
                                                </IonCol>
                                                <IonCol size="9">
                                                        <IonText className='stat-head'>4 Tasks</IonText>
                                                        <p className='stat-subhead'>Pending</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="6" className='mt-05'>
                            <IonCard className="stat-card-3 ion-no-margin">
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>
                                            <Chart options={options1} series={[75,25]} type="donut" width={120} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="3">
                                            <IonIcon 
                                                    icon={calendarOutline}
                                                    className='stat-icon-1'
                                                />
                                        </IonCol>
                                        <IonCol size="9">
                                                
                                            <IonText className='stat-head-1'>75%</IonText>
                                            <p className='stat-subhead-1'>Attendance</p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-vertical">
                        <IonCol>
                                <IonTitle className="title-heading">
                                    Todays Tasks
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-no-margin ion-no-padding">
                        <IonCol className="ion-no-margin ion-no-padding">
                            <IonCard className="ion-no-margin ion-no-padding">
                                <IonCardContent className="ion-no-margin ion-no-padding">
                                            <IonList>
                                            {tasks.map((task, i) => {
                                                return (
                                                    <IonItem detail={false}
                                                        lines={i === (tasks.length-1) ? 'none' : 'inset'}
                                                        button={false}
                                                        key={i}>
                                                            
                                                                    <IonIcon
                                                                    icon={createOutline}
                                                                    color="success"
                                                                    slot="start"
                                                                    />
                                                                
                                                           <IonLabel>{task}</IonLabel>
                                                            <IonButtons slot="end">
                                                                <IonButton> 
                                                                    <IonIcon
                                                                    icon={playCircle}
                                                                    color="secondary"
                                                                    className='tran-icon'
                                                                    />
                                                                </IonButton>
                                                                <IonButton> 
                                                                    <IonIcon
                                                                    icon={closeCircle}
                                                                    color="danger"
                                                                    className='tran-icon'
                                                                    />
                                                                </IonButton>
                                                            </IonButtons>
                                                            
                                                    </IonItem>
                                                )
                                                
                                                })
                                            }
                                            </IonList>
               
                                </IonCardContent>
                            </IonCard>
                         </IonCol>
                    </IonRow>
                    
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default StudentHome;