import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonHeader,
    IonToolbar,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonListHeader,
    IonButtons,
    IonButton,
    IonRow,
    IonCol,
    IonGrid,
    isPlatform,
    IonPopover
} from '@ionic/react';
import {
    informationCircleOutline,
    informationCircleSharp,
    lockClosedOutline,
    lockClosedSharp,
    personOutline,
    personSharp,
    logOutOutline,
    logOutSharp,
    homeSharp,
    homeOutline,
    locateOutline,
    locateSharp,
    navigateOutline,
    navigateSharp,
    schoolOutline,
    schoolSharp,
    manOutline,
    manSharp,
    analyticsOutline,
    analyticsSharp,
    cashOutline,
    cashSharp,
    documentAttachOutline,
    documentAttachSharp,
    notificationsOutline,
    notificationsSharp,
    accessibilityOutline,
    accessibilitySharp,
    businessOutline,
    businessSharp,
    personCircleOutline,
    personCircleSharp,
    calendarOutline,
    calendarSharp,
    ticketOutline,
    ticketSharp,
    receiptOutline,
    receiptSharp,
    barChartOutline,
    barChartSharp,
    closeSharp,
    starOutline,
    starSharp,
    chatbubblesOutline,
    chatbubblesSharp,
    ribbonOutline,
    ribbonSharp,
    bookOutline,
    bookSharp,
    calendarNumberOutline,
    calendarNumberSharp,
    chatboxEllipsesOutline,
    chatboxEllipsesSharp,
    createOutline,
    createSharp,
    calculatorOutline,
    calculatorSharp,
    locationOutline,
    locationSharp,
    documentTextOutline,
    documentTextSharp,
    happyOutline,
    happySharp,
    walletOutline,
    walletSharp,
    documentLockOutline,
    documentLockSharp,
    megaphoneOutline,
    megaphoneSharp,
    pricetagsOutline,
    pricetagsSharp,
    busOutline,
    busSharp,
    womanSharp,
    womanOutline,
    timeOutline,
    timeSharp,
    newspaperOutline,
    newspaperSharp,
    idCardOutline,
    idCardSharp,
    buildOutline,
    buildSharp,
    storefrontOutline,
    storefrontSharp,
    peopleOutline,
    peopleSharp,
    cartOutline,
    cartSharp,
    medicalOutline,
    medicalSharp,
    listOutline,
    listSharp,
    logoWhatsapp,
    closeCircleOutline,
    closeCircleSharp,
    chatbubbleEllipsesOutline,
    chatbubbleEllipsesSharp,
    medalOutline,
    medalSharp,
    contrast,
    easelOutline,
    easelSharp,
    settingsSharp,
    gridOutline,
    gridSharp
} from 'ionicons/icons';
import { useState } from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useAuth} from "./AuthContext";
import '../theme/menu.css';

interface AppPage {
    url: string,
    iosIcon: string,
    mdIcon: string,
    title: string,
    altTitle?: string,
    all?: boolean,
    medical?: boolean,
    transport?: boolean,
    communication?: boolean,
    payment?: boolean,
    payroll?: boolean,
    inventory?: boolean,
    acad?: boolean,
    timetable?: boolean,
    reportcard?: boolean,
    admission?: boolean,
    what?: boolean
}

const Menu: React.FC = () => {
    const {authInfo, logOut} = useAuth()!;
    const location = useLocation();
    const history = useHistory();
    const {t, i18n} = useTranslation();
    const [lang, setLang] = useState < string > (i18n.language);

    let appPages: AppPage[] = [];

    if (authInfo.user.role === "Master") {
        appPages = [
            {
                title: 'Home',
                url: '/app/home',
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true

            },
            {
                title: 'Manage Regions',
                url: `/app/regions`,
                iosIcon: locationOutline,
                mdIcon: locationSharp,
                all: true

            },
            {
                title: 'Manage Institutes',
                url: `/app/institutes`,
                iosIcon: businessOutline,
                mdIcon: businessSharp,
                all: true

            },
            {
                title: 'Manage Super Admins',
                url: `/app/admins/super`,
                iosIcon: personCircleOutline,
                mdIcon: personCircleSharp,
                all: true

            },
            {
                title: 'Manage Institute Admins',
                url: `/app/admins/institute`,
                iosIcon: personCircleOutline,
                mdIcon: personCircleSharp,
                all: true

            },
            {
                title: 'Manage Content Admins',
                url: `/app/admins/content`,
                iosIcon: personCircleOutline,
                mdIcon: personCircleSharp,
                all: true

            },
            {
                title: 'Send Notifications',
                url: `/app/notifications`,
                iosIcon: notificationsOutline,
                mdIcon: notificationsSharp,
                all: true

            }
        ];
    } 

    if (authInfo.user.role === "Content") {
        appPages = [
            {
                title: 'Home',
                url: '/app/home',
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true

            },
            {
                title: 'Courses & Chapters',
                url: `/app/courses`,
                iosIcon: bookOutline,
                mdIcon: bookSharp,
                all: true

            },
            {
                title: 'Manage Topics',
                url: `/app/topics`,
                iosIcon: documentTextOutline,
                mdIcon: documentTextSharp,
                all: true

            },
            {
                title: 'Manage Assessments',
                url: `/app/assessments`,
                iosIcon: createOutline,
                mdIcon: createSharp,
                all: true

            }
            
        ];
    } 

    if (authInfo.user.role === "Student") {
        appPages = [
            {
                title: 'Home',
                url: `/app/home`,
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true

            },
            {
                title: 'My Subjects',
                url: `/app/subjects`,
                iosIcon: bookOutline,
                mdIcon: bookSharp,
                acad: true

            },
            {
                title: 'Class Leaderboard',
                url: `/app/leaderboard`,
                iosIcon: ribbonOutline,
                mdIcon: ribbonSharp,
                acad: true

            },
            {
                title: 'My Attendance',
                url: `/app/attendance`,
                iosIcon: calendarOutline,
                mdIcon: calendarSharp,
                acad: true

            },
            {
                title: "Academic Planner",
                url: `/app/planner`,
                iosIcon: calendarNumberOutline,
                mdIcon: calendarNumberSharp,
                acad: true

            }
        ];
    } 

    if (authInfo.user.role === "Driver") {
        appPages = [
            {
                title: t("home"),
                url: '/app/home',
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true

            },
            {
                title: t("circ_announce"),
                url: `/app/news`,
                iosIcon: newspaperOutline,
                mdIcon: newspaperSharp,
                communication: true

            },
            {
                title: t("completed_trips"),
                url: `/app/trips/completed`,
                iosIcon: navigateOutline,
                mdIcon: navigateSharp,
                transport: true

            }
        ];
    } 

    if (authInfo.user.role === "Teacher") {
        appPages = [
            {
                title: t("home"),
                url: `/app/home`,
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true

            },
            {
                title: t("circ_announce"),
                url: `/app/news`,
                iosIcon: newspaperOutline,
                mdIcon: newspaperSharp,
                communication: true

            },
            {
                title: t("manage_students"),
                url: `/app/students`,
                iosIcon: accessibilityOutline,
                mdIcon: accessibilitySharp,
                all: true

            },
            {
                title: t("student_attendance"),
                url: `/app/attendance`,
                iosIcon: calendarOutline,
                mdIcon: calendarSharp,
                all: true

            },
            {
                title: t("class_comm"),
                altTitle: t("batch_comm"),
                url: `/app/circulars`,
                iosIcon: documentAttachOutline,
                mdIcon: documentAttachSharp,
                communication: true

            },
            {
                title: t("parent_inquiries"),
                url: `/app/inquiries/pending`,
                iosIcon: chatboxEllipsesOutline,
                mdIcon: chatboxEllipsesSharp,
                communication: true

            },
            {
                title: t("my_time_table"),
                url: `/app/planner`,
                iosIcon: calendarNumberOutline,
                mdIcon: calendarNumberSharp,
                acad: true

            },
            {
                title: t("submissions"),
                url: `/app/teacher/submissions/student`,
                iosIcon: documentTextOutline,
                mdIcon: documentTextSharp,
                timetable: false

            },
            {
                title: t("view_subjects"),
                url: `/app/subjects`,
                iosIcon: bookOutline,
                mdIcon: bookSharp,
                timetable: true

            }
        ];
    } 

    if (authInfo.user.role === "Transporter") {
        appPages = [
            {
                title: t("home"),
                url: `/app/home`,
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true

            },
            {
                title: t("circ_announce"),
                url: `/app/news`,
                iosIcon: newspaperOutline,
                mdIcon: newspaperSharp,
                communication: true

            },
            {
                title: t("realtime_tracking"),
                url: `/app/tracking`,
                iosIcon: locateOutline,
                mdIcon: locateSharp,
                transport: true

            },
            {
                title: t("manage_drivers"),
                url: `/app/drivers`,
                iosIcon: manOutline,
                mdIcon: manSharp,
                transport: true

            },
            {
                title: t("manage_routes"),
                url: `/app/routes`,
                iosIcon: analyticsOutline,
                mdIcon: analyticsSharp,
                transport: true

            },
            {
                title: t("manage_vehicles"),
                url: `/app/vehicles`,
                iosIcon: busOutline,
                mdIcon: busSharp,
                transport: true

            },
            {
                title: t("completed_trips"),
                url: `/app/trips/completed`,
                iosIcon: navigateOutline,
                mdIcon: navigateSharp,
                transport: true

            },
            {
                title: t("trip_alerts"),
                url: `/app/trips/alerts`,
                iosIcon: notificationsOutline,
                mdIcon: notificationsSharp,
                transport: true

            }
        ];
    } 

    if (authInfo.user.role === "Accountant") {
        appPages = [
            {
                title: t("home"),
                url: `/app/home`,
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true

            },
            {
                title: t("circ_announce"),
                url: `/app/news`,
                iosIcon: newspaperOutline,
                mdIcon: newspaperSharp,
                communication: true

            },
            {
                title: t("school_ledger"),
                altTitle: t("institute_ledger"),
                url: `/app/transactions`,
                iosIcon: barChartOutline,
                mdIcon: barChartSharp,
                payment: true

            },
            {
                title: t("manage_students"),
                url: `/app/students`,
                iosIcon: accessibilityOutline,
                mdIcon: accessibilitySharp,
                all: true
            },
            {
                title: t("manage_dues"),
                url: `/app/dues`,
                iosIcon: ticketOutline,
                mdIcon: ticketSharp,
                payment: true

            },
            {
                title: t("fee_head_struct"),
                url: `/app/fees`,
                iosIcon: calculatorOutline,
                mdIcon: calculatorSharp,
                payment: true

            },
            {
                title: t("petty_cash"),
                url: `/app/cash`,
                iosIcon: cashOutline,
                mdIcon: cashSharp,
                payment: true

            },
            {
                title: t("payroll"),
                url: `/app/payroll`,
                iosIcon: receiptOutline,
                mdIcon: receiptSharp,
                payroll: true

            }
        ];
    } 

    if (authInfo.user.role === "Academic") {
        appPages = [
            {
                title: t("home"),
                url: '/app/home',
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true
            },
            {
                title: t("circ_announce"),
                url: `/app/news`,
                iosIcon: newspaperOutline,
                mdIcon: newspaperSharp,
                communication: true
            },
            {
                title: t("manage_classes"),
                altTitle: t("manage_batches"),
                url: `/app/classes`,
                iosIcon: schoolOutline,
                mdIcon: schoolSharp,
                all: true
            },
            {
                title: t("manage_students"),
                url: `/app/student`,
                iosIcon: accessibilityOutline,
                mdIcon: accessibilitySharp,
                all: true
            },
            {
                title: t("manage_teachers"),
                url: `/app/teachers`,
                iosIcon: womanOutline,
                mdIcon: womanSharp,
                all: true
            },
            {
                title: t("subjects_time_tables"),
                url: `/app/timetables`,
                iosIcon: bookOutline,
                mdIcon: bookSharp,
                timetable: true
            },
            {
                title: t("manage_schedule"),
                url: `/app/schedule`,
                iosIcon: calendarOutline,
                mdIcon: calendarSharp,
                timetable: false
            },
            {
                title: t("manage_report_cards"),
                url: `/app/cards`,
                iosIcon: idCardOutline,
                mdIcon: idCardSharp,
                reportcard: true
            },
            {
                title: t("manage_certificates"),
                url: `/app/certificates`,
                iosIcon: medalOutline,
                mdIcon: medalSharp,
                reportcard: false
            },
            {
                title: t("class_comm"),
                altTitle: t("batch_comm"),
                url: `/app/circulars`,
                iosIcon: documentAttachOutline,
                mdIcon: documentAttachSharp,
                communication: true

            },
            {
                title: t("parent_inquiries"),
                url: `/app/inquiries/pending`,
                iosIcon: chatboxEllipsesOutline,
                mdIcon: chatboxEllipsesSharp,
                communication: true

            }
        ];
    } 

    if (authInfo.user.role === "AdmissionHead") {
        appPages = [
            {
                title: t("home"),
                url: '/app/home',
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true

            },
            {
                title: t("circ_announce"),
                url: `/app/news`,
                iosIcon: newspaperOutline,
                mdIcon: newspaperSharp,
                communication: true

            },
            {
                title: t("admn_setup"),
                url: '/app/setup',
                iosIcon: buildOutline,
                mdIcon: buildSharp,
                admission: true

            },
            {
                title: t("applications_appointments"),
                url: '/app/applications',
                iosIcon: calendarOutline,
                mdIcon: calendarSharp,
                admission: true

            },
            {
                title: t("admn_list"),
                url: `/app/admission/list`,
                iosIcon: listOutline,
                mdIcon: listSharp,
                admission: true

            },
            {
                title: t("crm"),
                url: `/app/crm`,
                iosIcon: logoWhatsapp,
                mdIcon: logoWhatsapp,
                what: true

            }
        ];
    } 

    if (authInfo.user.role === "Inventory") {
        appPages = [
            {
                title: t("home"),
                url: '/app/home',
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true

            },
            {
                title: t("circ_announce"),
                url: `/app/news`,
                iosIcon: newspaperOutline,
                mdIcon: newspaperSharp,
                communication: true

            },
            {
                title: t("stores_items"),
                url: `/app/stores`,
                iosIcon: storefrontOutline,
                mdIcon: storefrontSharp,
                inventory: true

            },
            {
                title: t("manage_vendors"),
                url: `/app/manage/vendors`,
                iosIcon: peopleOutline,
                mdIcon: peopleSharp,
                inventory: true

            },
            {
                title: t("transact"),
                url: `/app/transact`,
                iosIcon: cartOutline,
                mdIcon: cartSharp,
                inventory: true

            }
        ];
    } 


    if (authInfo.user.role === "Admin") {
        appPages = [
            {
                title: t("home"),
                url: `/app/home`,
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true

            },
            {
                title: t("school_essentials"),
                altTitle: t("institute_essentials"),
                url: `/app/essentials`,
                iosIcon: schoolOutline,
                mdIcon: schoolSharp,
                all: true

            },
            {
                title: t("manage_students"),
                url: `/app/student`,
                iosIcon: accessibilityOutline,
                mdIcon: accessibilitySharp,
                all: true

            },
            {
                title: t("manage_staff"),
                url: `/app/staff`,
                iosIcon: womanOutline,
                mdIcon: womanSharp,
                all: true

            },
            {
                title: t("communications"),
                url: `/app/communication`,
                iosIcon: megaphoneOutline,
                mdIcon: megaphoneSharp,
                communication: true

            },
            {
                title: t("academics"),
                url: `/app/academics`,
                iosIcon: bookOutline,
                mdIcon: bookSharp,
                acad: true

            },
            {
                title: t("transport"),
                url: `/app/transport`,
                iosIcon: busOutline,
                mdIcon: busSharp,
                transport: true

            },
            {
                title: t("accounting"),
                url: `/app/accounting`,
                iosIcon: barChartOutline,
                mdIcon: barChartSharp,
                payment: true

            },
            {
                title: t("inventory"),
                url: `/app/inventory`,
                iosIcon: pricetagsOutline,
                mdIcon: pricetagsSharp,
                inventory: true

            },
            {
                title: t("admissions"),
                url: `/app/admission`,
                iosIcon: calendarNumberOutline,
                mdIcon: calendarNumberSharp,
                admission: true

            },
            {
                title: t("crm"),
                url: `/app/crm`,
                iosIcon: logoWhatsapp,
                mdIcon: logoWhatsapp,
                what: true

            }
        
        ];
    } 

    if (authInfo.user.role === "Super") {
        appPages = [
            {
                title: t("home"),
                url: `/app/home`,
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true

            },
            {
                title: t("view_branches"),
                url: '/app/institutes',
                iosIcon: businessOutline,
                mdIcon: businessSharp,
                all: true

            },
            {
                title:  t("view_admins"),
                url: `/app/admins`,
                iosIcon: personCircleOutline,
                mdIcon: personCircleSharp,
                all: true

            },
            {
                title:  t("view_workshops"),
                url: `/app/workshops`,
                iosIcon: schoolOutline,
                mdIcon: schoolSharp,
                all: true

            },
            {
                title: t("dashboard"),
                url: `/app/home/admin`,
                iosIcon: gridOutline,
                mdIcon: gridSharp,
                all: true

            },
            {
                title: t("school_essentials"),
                altTitle: t("institute_essentials"),
                url: `/app/essentials`,
                iosIcon: schoolOutline,
                mdIcon: schoolSharp,
                all: true

            },
            {
                title: t("manage_students"),
                url: `/app/student`,
                iosIcon: accessibilityOutline,
                mdIcon: accessibilitySharp,
                all: true

            },
            {
                title: t("manage_staff"),
                url: `/app/staff`,
                iosIcon: womanOutline,
                mdIcon: womanSharp,
                all: true

            },
            {
                title: t("communications"),
                url: `/app/communication`,
                iosIcon: megaphoneOutline,
                mdIcon: megaphoneSharp,
                communication: true

            },
            {
                title: t("academics"),
                url: `/app/academics`,
                iosIcon: bookOutline,
                mdIcon: bookSharp,
                acad: true

            },
            {
                title: t("transport"),
                url: `/app/transport`,
                iosIcon: busOutline,
                mdIcon: busSharp,
                transport: true

            },
            {
                title: t("accounting"),
                url: `/app/accounting`,
                iosIcon: barChartOutline,
                mdIcon: barChartSharp,
                payment: true

            },
            {
                title: t("inventory"),
                url: `/app/inventory`,
                iosIcon: pricetagsOutline,
                mdIcon: pricetagsSharp,
                inventory: true

            },
            {
                title: t("admissions"),
                url: `/app/admission`,
                iosIcon: calendarNumberOutline,
                mdIcon: calendarNumberSharp,
                admission: true

            },
            {
                title: t("crm"),
                url: `/app/crm`,
                iosIcon: logoWhatsapp,
                mdIcon: logoWhatsapp,
                what: true

            }
        ];
    } 


    if (authInfo.user.role === "Parent") {
    appPages = [
        {
            title: t("home"),
            url: '/app/home',
            iosIcon: homeOutline,
            mdIcon: homeSharp,
            all: true

        },
        {
            title: t("circ_announce"),
            url: `/app/circulars`,
            iosIcon: documentAttachOutline,
            mdIcon: documentAttachSharp,
            all: true

        },
        {
            title: t("make_inquiry"),
            url: `/app/inquiries`,
            iosIcon: chatboxEllipsesOutline,
            mdIcon: chatboxEllipsesSharp,
            all: true

        },
        {
            title: t("view_attendance"),
            url: `/app/attendance`,
            iosIcon: calendarOutline,
            mdIcon: calendarSharp,
            all: true

        },
        {
            title: t("view_medical_records"),
            url: `/app/medical`,
            iosIcon: medicalOutline,
            mdIcon: medicalSharp,
            medical: true

        },
        {
            title: t("completed_trips"),
            url: `/app/trip/completed`,
            iosIcon: navigateOutline,
            mdIcon: navigateSharp,
            transport: true

        },
        {
            title: t("transaction_history"),
            url: `/app/dues`,
            iosIcon: cashOutline,
            mdIcon: cashSharp,
            payment: true

        },
        {
            title: t("planner"),
            url: `/app/planner`,
            iosIcon: calendarNumberOutline,
            mdIcon: calendarNumberSharp,
            acad: true

        },
        {
            title: t("admn_portal"),
            url: `/app/admission`,
            iosIcon: businessOutline,
            mdIcon: businessSharp,
            all: true

        }
    ];
    } 

    if (authInfo.user.role === "Admission") {
        appPages = [
            {
                title: t("home"),
                url: '/app/home',
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true
            },
            {
                title: t("shortlisted_schools"),
                url: `/app/shortlisted`,
                iosIcon: schoolOutline,
                mdIcon: schoolSharp,
                all: true
            },
            {
                title: t("my_cart"),
                url: '/app/applications/pending',
                iosIcon: cartOutline,
                mdIcon: cartSharp,
                all: true
            },
            {
                title: t("all_appointments"),
                url: '/app/appointments',
                iosIcon: calendarOutline,
                mdIcon: calendarSharp,
                all: true

            },
            {
                title: t("upcoming_workshops"),
                url: '/app/workshops/upcoming',
                iosIcon: calendarNumberOutline,
                mdIcon: calendarNumberSharp,
                all: true

            },
            {
                title: t("attended_workshops"),
                url: '/app/workshops/attended',
                iosIcon: easelOutline,
                mdIcon: easelSharp,
                all: true
            },
            {
                title: t("submitted_apps"),
                url: '/app/applications/submitted',
                iosIcon: documentLockOutline,
                mdIcon: documentLockSharp,
                all: true
            },
            {
                title: t("payments_history"),
                url: `/app/payments`,
                iosIcon: walletOutline,
                mdIcon: walletSharp,
                all: true
            },
            {
                title: t("manage_children"),
                url: '/app/students',
                iosIcon: happyOutline,
                mdIcon: happySharp,
                all: true
            },
            {
                title: t("my_admn_inquiries"),
                url: `/app/admission/inquiries`,
                iosIcon: chatboxEllipsesOutline,
                mdIcon: chatboxEllipsesSharp,
                all: true

            }
        ];
    } 

    if (authInfo.user.role === "Employee") {
        appPages = [
            {
                title: t("home"),
                url: `/app/home`,
                iosIcon: homeOutline,
                mdIcon: homeSharp,
                all: true

            },
            {
                title: t("circ_announce"),
                url: `/app/news`,
                iosIcon: newspaperOutline,
                mdIcon: newspaperSharp,
                communication: true

            }
        
        ];
    
        if (authInfo.user.roles.includes("Academic"))
        {
            appPages.push({
                title: t("manage_classes"),
                altTitle: t("manage_batches"),
                url: `/app/classes`,
                iosIcon: schoolOutline,
                mdIcon: schoolSharp,
                all: true
            });

            appPages.push({
                title: t("manage_students"),
                url: `/app/student`,
                iosIcon: accessibilityOutline,
                mdIcon: accessibilitySharp,
                all: true
            });

            appPages.push({
                title: t("manage_teachers"),
                url: `/app/teachers`,
                iosIcon: womanOutline,
                mdIcon: womanSharp,
                all: true
            });

            appPages.push({
                title: t("academics"),
                url: `/app/academics`,
                iosIcon: bookOutline,
                mdIcon: bookSharp,
                acad: true
            });

            appPages.push({
                title: t("class_comm"),
                altTitle: t("batch_comm"),
                url: `/app/circulars`,
                iosIcon: documentAttachOutline,
                mdIcon: documentAttachSharp,
                communication: true

            });

            appPages.push({
                title: t("parent_inquiries"),
                url: `/app/inquiries/pending`,
                iosIcon: chatboxEllipsesOutline,
                mdIcon: chatboxEllipsesSharp,
                communication: true

            });

        }

        if (!authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher"))
        {
            appPages.push({
                title: t("manage_students"),
                url: `/app/students/teacher/view`,
                iosIcon: accessibilityOutline,
                mdIcon: accessibilitySharp,
                all: true

            });

            appPages.push({
                title: t("student_attendance"),
                url: `/app/attendance`,
                iosIcon: calendarOutline,
                mdIcon: calendarSharp,
                all: true

            });

            appPages.push({
                title: t("class_comm"),
                altTitle: t("batch_comm"),
                url: `/app/circulars`,
                iosIcon: documentAttachOutline,
                mdIcon: documentAttachSharp,
                communication: true

            });

            appPages.push({
                title: t("parent_inquiries"),
                url: `/app/inquiries/pending`,
                iosIcon: chatboxEllipsesOutline,
                mdIcon: chatboxEllipsesSharp,
                communication: true

            });

            appPages.push({
                title: t("my_time_table"),
                url: `/app/planner/teacher`,
                iosIcon: calendarNumberOutline,
                mdIcon: calendarNumberSharp,
                acad: true

            });

            appPages.push({
                title: t("submissions"),
                url: `/app/teacher/submissions/student`,
                iosIcon: documentTextOutline,
                mdIcon: documentTextSharp,
                timetable: false

            });

            appPages.push({
                title: t("view_subjects"),
                url: `/app/subjects/teacher`,
                iosIcon: bookOutline,
                mdIcon: bookSharp,
                timetable: true

            });
            
        }

        if (authInfo.user.roles.includes("Transporter"))
        {
            appPages.push({
                title: t("manage_drivers"),
                url: `/app/drivers`,
                iosIcon: manOutline,
                mdIcon: manSharp,
                transport: true

            });

            appPages.push({
                title: t("transport"),
                url: `/app/transport`,
                iosIcon: busOutline,
                mdIcon: busSharp,
                transport: true

            });
        }

        if (authInfo.user.roles.includes("Accountant"))
        {
            appPages.push({
                title: t("accounting"),
                url: `/app/accounting`,
                iosIcon: barChartOutline,
                mdIcon: barChartSharp,
                payment: true

            });
        }

        if (authInfo.user.roles.includes("Inventory"))
        {
            appPages.push({
                title: t("inventory"),
                url: `/app/inventory`,
                iosIcon: pricetagsOutline,
                mdIcon: pricetagsSharp,
                inventory: true

            });
        }


        if (authInfo.user.roles.includes("AdmissionHead"))
        {
            appPages.push({
                title: t("admissions"),
                url: `/app/admission`,
                iosIcon: calendarNumberOutline,
                mdIcon: calendarNumberSharp,
                admission: true

            });

            appPages.push({
                title: t("crm"),
                url: `/app/crm`,
                iosIcon: logoWhatsapp,
                mdIcon: logoWhatsapp,
                what: true

            });
        }
    }

    const handleThemeChange = () => {
        document.body.classList.toggle('dark');
    };

    const handleLanguageChange = () => {
        if (lang === "en")
        {
            i18n.changeLanguage("hi");
            setLang("hi");
        }
        else
        {
            i18n.changeLanguage("en");
            setLang("en");
        }
    };
    
    return (
    <IonMenu side="start" menuId='auth' contentId="main" type="overlay">

        <IonHeader>
            <IonToolbar color="primary">
                <IonRow>
                    <IonCol size="3" className="ion-text-center">
                            <img src={`assets/images/logo-trans.png`} alt="logo" width="40" className='mt-05'/>
                    </IonCol>
                    <IonCol size="9">
                        <IonNote className='menu-title'> 
                            {(i18n.language === "hi") && (
                                `${process.env.REACT_APP_NAME_HI?.split(" ")[0]}`
                            )}
                            {(i18n.language !== "hi") && (
                                `${process.env.REACT_APP_NAME?.split(" ")[0]}`
                            )}</IonNote>
                    </IonCol>
                </IonRow>
                <IonButtons slot="end">
                   
                    {isPlatform('mobile') && (
                    <>
                        <IonButton id="open-settings"><IonIcon icon={settingsSharp} /></IonButton>
                        <IonPopover trigger="open-settings" dismissOnSelect={true} arrow={false} className='settings-menu' >
                            <IonContent>
                                <IonList>
                                    <IonItem detail={true}
                                        lines="full"
                                        button={true}
                                        onClick={handleThemeChange}
                                        className='ion-no-padding'>
                                            <IonIcon icon={contrast} color="secondary" className='ml-08' />
                                            <IonLabel className='mt-10 ml-16 text-bold' color="secondary">
                                            {t("switch_theme")} 
                                            </IonLabel>
                                    </IonItem>
                                    <IonItem detail={true}
                                        lines="none"
                                        button={true}
                                        onClick={handleLanguageChange}
                                        className='ion-no-padding'>
                                             <img alt="Translate" src="assets/images/translate.png" width="40" height="40" />
                                            <IonLabel color="secondary" className='mt-10 ml-08 text-bold' >
                                            {t("switch_language")} 
                                            </IonLabel>
                                    </IonItem>
                                </IonList>
                                    
                                    
                            </IonContent> 
                        </IonPopover>
                    </>
                    )}
                     <IonMenuToggle>
                        <IonButton><IonIcon icon={closeSharp} className='badge-icon'></IonIcon></IonButton>
                    </IonMenuToggle> 
                </IonButtons>
            </IonToolbar>
        </IonHeader>

        <IonContent>

            {(!(authInfo.user.role === "Master") && !(authInfo.user.role === "Content") && !(authInfo.user.role === "Admission")) && !(authInfo.user.role === "Super") && authInfo.institute && (
                <IonGrid className="ion-no-padding">
                    <IonRow className={isPlatform('ios') ? 'home-row pt-16' : 'home-row'}>
                        <IonCol size="3" className="ion-text-center ion-padding-vertical">
                            <div className="vertical-container">
                                <div className="vertical-center"> 
                                    {authInfo.institute.logo && (authInfo.institute.logo !== "") ?
                                        (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${authInfo.institute.logo}`} alt={t("logo")} width="70" />)
                                        : (<img src={`assets/images/institute.png`} alt={t("logo")} width="70" />)
                                    }
                                </div>
                            </div>
                        </IonCol>
                        <IonCol size="9" className="ion-text-center ion-padding-end">
                            <div className="vertical-container">
                                <div className="vertical-center"> 
                                    <p className="logo-text">{authInfo.institute.name}</p>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            )}

            <IonGrid className="ion-no-padding">
                <IonRow>
                    <IonCol>
                        <IonList id="menu-list">
                            <IonListHeader>{t("dashboard")}</IonListHeader>
                            {
                            appPages.map((appPage, index) => {
                                return (((authInfo.institute && authInfo.institute.transport && appPage.transport) || (authInfo.institute && authInfo.institute.communication && appPage.communication) || (authInfo.institute && authInfo.institute.payment && appPage.payment) 
                                || (authInfo.institute && authInfo.institute.payroll && appPage.payroll) || (authInfo.institute && authInfo.institute.inventory && appPage.inventory) || (authInfo.institute && authInfo.institute.medical && appPage.medical) 
                                || (authInfo.institute && authInfo.institute.academics && appPage.acad) || (authInfo.institute && authInfo.institute.timetable && appPage.timetable) || (authInfo.institute && authInfo.institute.reportcard && appPage.reportcard) 
                                || (authInfo.institute && authInfo.institute.reportcard === false && appPage.reportcard === false) || (authInfo.institute && authInfo.institute.timetable === false && appPage.timetable === false) 
                                || (authInfo.institute && authInfo.institute.admission && appPage.admission) || (authInfo.institute && authInfo.institute.whatsapp && appPage.what) || (appPage.all)) && (
                                    <IonMenuToggle key={index} menu='auth' autoHide={false}>
                                        <IonItem className={
                                                location.pathname === appPage.url ? 'selected' : ''
                                            }
                                            routerLink={
                                                appPage.url
                                            }
                                            routerDirection="forward"
                                            lines="none" 
                                            detail={false}>
                                            <IonIcon slot="start"
                                                ios={
                                                    appPage.iosIcon
                                                }
                                                md={
                                                    appPage.mdIcon
                                                }/>
                                            <IonLabel>{((authInfo.institute && authInfo.institute.type !== "school") && appPage.altTitle) ? appPage.altTitle : appPage.title}</IonLabel>
                                        </IonItem>
                                    </IonMenuToggle>
                                    )
                                );
                            })
                        } 
                        </IonList>
                      
                        <IonList id="account-list">
                            <IonListHeader>{t("account")}</IonListHeader>
                            {((authInfo.user.role === "Driver") || (authInfo.user.role === "Transporter") || (authInfo.user.role === "Teacher") || (authInfo.user.role === "Accountant")
                             || (authInfo.user.role === "Academic") || (authInfo.user.role === "AdmissionHead") || (authInfo.user.role === "Inventory") || (authInfo.user.role === "Employee")) &&
                            (
                            <>
                            {authInfo.institute && authInfo.institute.task && (
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/user/tasks' ? 'selected' : ''
                                    }
                                    routerLink='/app/user/tasks'
                                    routerDirection="forward"
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={createOutline}
                                        md={createSharp}/>
                                    <IonLabel>{t("my_tasks")}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            )}
                             <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/user/chats' ? 'selected' : ''
                                    }
                                    routerLink='/app/user/chats'
                                    routerDirection="forward"
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={chatbubbleEllipsesOutline}
                                        md={chatbubbleEllipsesSharp}/>
                                    <IonLabel>{t("my_chats")}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/user/attendance' ? 'selected' : ''
                                    }
                                    routerLink='/app/user/attendance'
                                    routerDirection="forward"
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={timeOutline}
                                        md={timeSharp}/>
                                    <IonLabel>{t("my_attendance")}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>

                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/user/leaves' ? 'selected' : ''
                                    }
                                    routerLink='/app/user/leaves'
                                    routerDirection="forward"
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={documentTextOutline}
                                        md={documentTextSharp}/>
                                    <IonLabel>{t("my_leaves")}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            </>
                            )}

                            {((authInfo.user.role === "Parent") && (authInfo.institute) && (!authInfo.institute.timetable)) &&
                            (
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/user/submissions' ? 'selected' : ''
                                    }
                                    routerLink='/app/user/submissions'
                                    routerDirection="forward"
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={documentTextOutline}
                                        md={documentTextSharp}/>
                                    <IonLabel>{t("my_submissions")}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            )}

                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/user/profile' ? 'selected' : ''
                                    }
                                    routerLink='/app/user/profile'
                                    routerDirection="forward"
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={personOutline}
                                        md={personSharp}/>
                                    <IonLabel>{t("my_profile")}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/user/notification' ? 'selected' : ''
                                    }
                                    routerLink='/app/user/notification'
                                    routerDirection="forward"
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={notificationsOutline}
                                        md={notificationsSharp}/>
                                    <IonLabel>{t("notifications")}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem button={true} onClick={
                                        async () => {
                                            await logOut();
                                            history.replace('/login');
                                        }
                                    }
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={logOutOutline}
                                        md={logOutSharp}/>
                                    <IonLabel>{t("logout")}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>

                        </IonList>

                        <IonList id="support-list">

                            <IonListHeader>{t("support")}</IonListHeader>

                            {isPlatform('mobile') && (
                    
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem onClick={() => document.location.href = isPlatform('android') ? process.env.REACT_APP_PLAY_STORE! : process.env.REACT_APP_APP_STORE!}
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={starOutline}
                                        md={starSharp}/>
                                    <IonLabel>{t("rate_us")}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>

                            )}
                            
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/support/contact' ? 'selected' : ''
                                    }
                                    routerLink='/app/support/contact'
                                    routerDirection="forward"
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={chatbubblesOutline}
                                        md={chatbubblesSharp}/>
                                    <IonLabel>{t("need_assist")}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/support/delete' ? 'selected' : ''
                                    }
                                    routerLink='/app/support/delete'
                                    routerDirection="forward"
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={closeCircleOutline}
                                        md={closeCircleSharp}/>
                                    <IonLabel>{t("delete_account")}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/policies/terms' ? 'selected' : ''
                                    }
                                    routerLink='/app/policies/terms'
                                    routerDirection="forward"
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={informationCircleOutline}
                                        md={informationCircleSharp}/>
                                    <IonLabel>{t("t_and_c")}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/policies/privacy' ? 'selected' : ''
                                    }
                                    routerLink='/app/policies/privacy'
                                    routerDirection="forward"
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={lockClosedOutline}
                                        md={lockClosedSharp}/>
                                    <IonLabel>{t("privacy_policy")}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        </IonList>
                            
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonMenu>
    );
};

export default Menu;
