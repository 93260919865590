import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Anchorme } from 'react-anchorme';
import {useAuth} from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import { calendar, call, chatbubblesOutline, constructOutline, flameOutline, logoWhatsapp, mailOpen, mailOutline, mailUnread, snowOutline, thermometerOutline, thumbsDown, thumbsUp } from 'ionicons/icons';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

interface Enquiry {
    _id: string,
    instituteInfo: Institute,
    source: string,
    type: string,
    text: string,
    status: string,
    condition?: string,
    userInfo: User,
    updates: Update[],
    createdAt: Date,
    updatedAt: Date
}

interface Institute {
    instituteID: string,
    name: string
}

interface User {
    userID: string,
    name: string,
    phone: string,
    email: string
}

interface Update {
    _id: string,
    timestamp: Date,
    userInfo: UserInfo,
    update: UpdateInfo
}

interface UserInfo {
    userID: string,
    userName: string
}

interface UpdateInfo {
    text: string,
    status: string,
    dated?: Date
}

interface Meeting {
    date: string,
    textColor: string,
    backgroundColor: string
}

interface ParamTypes {
    inquiryID: string
}

const Inquiry: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const {inquiryID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [reply, setReply] = useState < string > ("");
    const [status, setStatus] = useState < string > ("");
    const [enquiry, setEnquiry] = useState < Enquiry > ();
    const [selectedDate, setSelectedDate] = useState < string | string[] | undefined > ();
    const [showUpdate, setShowUpdate] = useState < boolean > (false);
    const [meetingDates, setMeetingDates] = useState < Meeting[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);

    function openURL(value: string) {

        var url = value;
        if (!/^https?:\/\//i.test(value)) {
            url = 'http://' + value;
        }

        window.open(url, '_system');
        return false;
    };

    const formatDated = (value: string | string[] | undefined) => {
        if (typeof value === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB') + " at " + valueDate.toLocaleTimeString('en-US');
        }
        
    };

    const formatDates = (value: Date) => {
        const valueDate = new Date(value);
        return " "+valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' }) + " at " + valueDate.toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" });
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const updateCondition = (condition: string) => {
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upApli = () => {
            return new Promise((resolve, reject) => {
              api.put('/admissionInquiries/'+inquiryID, { condition }).then(res => {
                return resolve(res.data);
              }).catch(err => reject(err));
            });
        }
      
        setShowLoading(true);
        upApli()
          .then((data: any) => {
              setPass(t("admn_inquiry")+t("has_been_updated"));
              setIspass(true);
              setShowLoading(false);
            
          })
          .catch(error => {
              if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
            setIserror(true);
            setShowLoading(false);
        });
        
    }

    const handleSubmit = () => {

        if (!reply || reply === "") {
            setMessage(t("response_mandatory"));
            setIserror(true);
            return;
        }

        if (!status || status === "") {
            setMessage(t("status_mandatory"));
            setIserror(true);
            return;
        }

        if ((status === "followup" || status === "meeting")) {
            if (!selectedDate || selectedDate === "") {
                setMessage(t("select_app_date"));
                setIserror(true);
                return;
            }
        }
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upApli = () => {
            return new Promise((resolve, reject) => {
              api.put('/admissionInquiries/'+inquiryID, { status, reply, 'adminID': authInfo.user._id, 'adminName': authInfo.user.name, selectedDate }).then(res => {
        
                return resolve(res.data);
                
              }).catch(err => reject(err));
    
            });
          }
      
          setShowLoading(true);
        upApli()
          .then(data => {
    
              setPass(t("admn_inquiry")+t("has_been_updated"));
              setIspass(true);
              setShowLoading(false);
            
          })
          .catch(error => {
              if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
            setIserror(true);
            setShowLoading(false);
          });
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/admissionInquiries/' + inquiryID);
                setEnquiry(result.data);

                const meet = await api.get('/admissionInquiries/getMeetings/' + authInfo.user.instituteID);
                setMeetingDates(meet.data);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, inquiryID, refreshed]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/crm"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("admn_inquiry_details")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("admn_inquiry_details")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

            {enquiry && (
                <IonGrid className="ion-no-padding">
                     <IonRow className='ion-margin-top'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-no-margin">
                                                <IonCardContent className="ion-no-padding">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem detail={false}
                                                                lines='none'
                                                                button={false}
                                                                color="secondary">
                                                                    <IonRow>
                                                                        <IonCol size="8">
                                                                            <IonLabel position="stacked" className="input-label">{t("inquiry_status")}</IonLabel>
                                                                            <IonInput readonly={true} className='text-capitalize'>{t(enquiry.status)}</IonInput>
                                                                        </IonCol>
                                                                        <IonCol size="4" >
                                                                            <IonLabel position="stacked" className="input-label">{t("updated_at")}</IonLabel>
                                                                            <IonInput readonly={true} className='text-capitalize'>{formatDate(enquiry.updatedAt)}</IonInput>
                                                            
                                                                        </IonCol>
                                                                    </IonRow>
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                   
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {!showUpdate && (
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12" className='ion-padding-horizontal ion-text-center'>
                            <IonButton onClick={() => setShowUpdate(true)}
                                color="dark"
                                >{t("update_status")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    )}
                    {showUpdate && (<>
                     <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='ion-padding-bottom border-bottom-sec'>
                                {t("update_status")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                   
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-no-margin">
                                                <IonCardContent className="ion-no-margin ion-no-padding">
                                                    <IonRow>
                                                        <IonCol size="5">
                                                            <IonItem detail={false}
                                                                lines='none'
                                                                button={false}
                                                                >
                                                                <IonLabel position="stacked" color="secondary">
                                                                    {t("select_status")}
                                                                </IonLabel>
                                                                    <IonSelect value={status}
                                                                            cancelText={t("cancel")}
                                                                            okText={t("ok")}
                                                                            placeholder={t("select_status")}
                                                                            onIonChange={
                                                                                (e) => setStatus(e.detail.value)
                                                                        }>
                                                                        <IonSelectOption value="new">{t("new")}</IonSelectOption>
                                                                        <IonSelectOption value="unreachable">{t("unreachable")}</IonSelectOption>
                                                                        <IonSelectOption value="shared">{t("shared")}</IonSelectOption>
                                                                        <IonSelectOption value="followup">{t("followup")}</IonSelectOption>
                                                                        <IonSelectOption value="meeting">{t("meeting")}</IonSelectOption>
                                                                        <IonSelectOption value="converted">{t("converted")}</IonSelectOption>
                                                                        <IonSelectOption value="failed">{t("failed")}</IonSelectOption>
                                                                        <IonSelectOption value="disqualified">{t("disqualified")}</IonSelectOption>
                                                                        
                                                                    </IonSelect>
                                                            </IonItem>
                                                        </IonCol>
                                                        <IonCol size="7">
                                                            <IonItem detail={false}
                                                                lines='none'
                                                                button={false}
                                                                >
                                                                <IonLabel position="stacked" color="secondary">{t("remarks")}</IonLabel>
                                                                <IonInput value={reply}
                                                                    placeholder={t("remarks")}
                                                                    onIonInput={
                                                                        (e) => setReply(e.detail.value!)
                                                                }></IonInput>
                                                            </IonItem>
                                                        </IonCol>
                                                        
                                                    </IonRow>
                                                    {(status === "followup" || status === "meeting") && (
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem detail={false}
                                                                lines='full'
                                                                button={false}
                                                                id="open-date-input">
                                                                <IonLabel position="stacked" color="secondary">{status === "followup" ? t("followup") : t("meeting")} - {t("date")}</IonLabel>
                                                                <IonInput value={typeof selectedDate !== 'string' ? t("pick_date") : formatDated(selectedDate)} readonly={true} />
                                                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                                                        initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                                            <IonToolbar>
                                                                                <IonTitle>{status === "followup" ? t("followup") : t("meeting")} - {t("pick_date")}</IonTitle>
                                                                                
                                                                            </IonToolbar>
                                                                            <IonDatetime
                                                                                cancelText={t("cancel")}
                                                                                doneText={t("ok")}
                                                                                value={selectedDate}
                                                                                presentation="date-time"
                                                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                                                showDefaultButtons={true}
                                                                                size="cover"
                                                                                highlightedDates={meetingDates}
                                                                            />
                                                                        </IonContent>
                                                                        
                                                                </IonModal>
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                    )}
                                                    <IonRow className="mt-10">
                                                        <IonCol className="ion-text-center ion-margin-bottom">
                                                            <IonButton onClick={handleSubmit}
                                                                className="first-button"
                                                                fill="clear">{t("update_status")}</IonButton>

                                                        </IonCol>
                                                        
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    </>)}
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol size="6">
                                    <IonTitle className='border-bottom-sec'>
                                        {t("enq_info")}
                                    </IonTitle>
                                </IonCol>
                                <IonCol size="6" className='ion-text-right'>
                                    <IonIcon 
                                        icon={flameOutline}
                                        color="danger"
                                        className='pr-16 table-icon pointer'
                                        onClick={
                                            () => updateCondition('hot')
                                        }
                                    />
                                    <IonIcon 
                                        icon={thermometerOutline}
                                        color="warning"
                                        className='pr-16 table-icon pointer'
                                        onClick={
                                            () => updateCondition('warm')
                                        }
                                    />
                                    <IonIcon 
                                        icon={snowOutline}
                                        color="secondary"
                                        className='pr-16 table-icon pointer'
                                        onClick={
                                            () => updateCondition('cold')
                                        }
                                    />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow className='mt-10'>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className={enquiry.condition ? enquiry.condition : ""}>
                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                        <IonCol>
                                            <IonCard className="ion-no-margin profile-photo-card">
                                                <IonCardContent>
                                                    <IonRow>
                                                        <IonCol size="11">
                                                        <span className="profile-text">{enquiry.userInfo.name}</span>
                                                        </IonCol>
                                                        <IonCol size="1">
                                                            <IonIcon 
                                                                icon={call}
                                                                color="tertiary"
                                                                className='table-icon'
                                                                onClick={
                                                                    () => document.location.href = `tel:${enquiry.userInfo.phone}`
                                                                }
                                                            />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol size="4">
                                                            <p>
                                                                <span className='pt-05 text-capitalize'>{enquiry.userInfo.phone}</span>
                                                                <span className='pt-05'> 
                                                                {t(enquiry.source)}</span>
                                                            </p>

                                                        </IonCol>
                                                        <IonCol size="7">
                                                                <p>
                                                                    <span className="pt-05">{enquiry.type}</span>
                                                                    <span className='pt-05'>{formatDate(enquiry.createdAt)}</span>
                                                            
                                                                </p>
                                                        </IonCol>
                                                        
                                                        <IonCol size="1">
                                                            {enquiry.userInfo.email !== "" && (
                                                            <>
                                                            <IonIcon 
                                                                icon={mailOutline}
                                                                color="secondary"
                                                                className='table-icon mt-10'
                                                                onClick={
                                                                    () => document.location.href = `mailto:${enquiry.userInfo.email}`
                                                                }
                                                            />
                                                            <br/>
                                                            </>
                                                            )}
                                                             
                                                            <IonIcon 
                                                                icon={logoWhatsapp}
                                                                color="success"
                                                                className='table-icon mt-10'
                                                                onClick={
                                                                    () => openURL(`https://wa.me/${enquiry.userInfo.phone}`)
                                                                }
                                                            />
                                                            <br/>
                                                            {(enquiry.userInfo.userID.match(/^[0-9a-fA-F]{24}$/)) && (
                                                            <>
                                                            
                                                                <IonIcon 
                                                                    icon={chatbubblesOutline}
                                                                    color="primary"
                                                                    className='table-icon mt-10'
                                                                    onClick={
                                                                        () => history.push(`/app/admission/inquiry/${enquiry._id}/chat`)
                                                                    }
                                                                />
                                                                <br/>
                                                            </>
                                                            )}
                                                            <IonIcon 
                                                                icon={constructOutline}
                                                                color="dark"
                                                                className='table-icon mt-10'
                                                                onClick={
                                                                    () => history.push(`/app/admission/inquiry/${enquiry._id}/details`)
                                                                }
                                                            />
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className='ion-margin-top'>
                                                        <IonCol className='anchorme-text'>
                                                            <Anchorme>{enquiry.text}</Anchorme>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                   
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                   
                    {(enquiry.updates.length > 0) && (
                    <>
                    <IonRow className="mt-30">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("timeline")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <VerticalTimeline layout='1-column' animate={false} lineColor="#086E7D">
                            
                                        {(enquiry.updates.slice(0).reverse().map((update, i) => {
                                        return (
                                            <VerticalTimelineElement
                                                key={update._id}
                                                className="vertical-timeline-element"
                                                icon={ <IonIcon icon={(update.update.status === 'new') ? mailUnread : (update.update.status === 'meeting' || update.update.status === 'followup') 
                                                    ? calendar : (update.update.status === 'converted') ? thumbsUp : (update.update.status === 'failed' || update.update.status === 'disqualified') 
                                                    ? thumbsDown : mailOpen} className='timeline-icon' />}
                                                iconStyle={{ background: 'rgb(8, 110, 125)', color: '#fff', textAlign: 'center' }}>
                                                <IonText className='timeline-para'>
                                            
                                                    {t(update.update.status)} {update.update.dated && " - "+t("on")+formatDates(update.update.dated)}
                                                    
                                                
                                                </IonText>
                                                <IonText className='timeline-date'>
                                                    <br/>{update.update.text}
                                                    <br/>{t("updated_on")} {formatDate(update.timestamp)}
                                                    <IonText> {t("marked_by")} {t(update.userInfo.userName)}</IonText>
                                                </IonText>
                                            
                                            </VerticalTimelineElement> 
                                        )
                                    }))}
                                
                            </VerticalTimeline>
                        </IonCol>
                    </IonRow>
                    </>)}
                    </IonGrid>
                )}
            </IonContent>
        </IonPage>
    );
};

export default Inquiry;