import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../components/AuthContext';


interface DaySchedule {
    timeSlot: Slot,
    subject: Subject,
    teacher: Teacher
}

interface Slot {
    timeSlotID: string,
    startTime: string,
    endTime: string
}

interface Subject {
    subjectID: string,
    subjectName: string
}

interface Teacher {
    teacherID: string,
    teacherName: string
}

interface ClassInfo {
    classID: string,
    className: string
}

interface TimeTable {
    _id: string,
    name: string,
    classInfo: ClassInfo,
    mon: DaySchedule[],
    tue: DaySchedule[],
    wed: DaySchedule[],
    thu: DaySchedule[],
    fri: DaySchedule[],
    sat: DaySchedule[]
}

interface Segment {
    day: string,
    date: string
}

const Planner: React.FC = () => {
    const {authInfo} = useAuth()!;

    const [timetable, setTimeTable] = useState < TimeTable > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [day, setDay] = useState < string > ("");
    const [segments, setSegments] = useState <Segment[]> ([]);

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const stud = await api.get(`/students/getByAccount/${authInfo.user._id}`);
                
                const result = await api.get(`/timeTables/getCurrentByClass/${stud.data.classID}`);
                if (result.data)
                {
                    setTimeTable(result.data);
                }
                

                const weekday = ["sun","mon","tue","wed","thu","fri","sat"];

                const d = new Date(Date.now());

                if (d.getDay() === 0)
                {
                    d.setDate(d.getDate() + 1);
                }

                setDay(weekday[d.getDay()]);

                let segs: Segment[] = [];

                for (let i=0; i<6; i++)
                {
                    let day = d.getDate();
                    let theday = weekday[d.getDay()];
                    if(theday === 'sun')
                    {
                        d.setDate(d.getDate() + 1);
                        day = d.getDate();
                        theday = weekday[d.getDay()];
                    }
                    segs.push({'day': theday, 'date': day.toString()});
                    d.setDate(d.getDate() + 1);
                }
                setSegments(segs);


            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app" />
                    </IonButtons>
                    <IonTitle color="secondary">Academic Planner</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Academic Planner</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="border-bottom-sec">
                        <IonCol>
                            <IonSegment mode="md" scrollable={true} value={day} onIonChange={e => setDay( e.detail.value!.toString() )} className="planner-segment">

                            {segments && segments.map((seg, i) => (
                                <IonSegmentButton key={i} value={seg.day} className='planner-button'>
                                    <IonLabel>{seg.day}<br/>{seg.date}</IonLabel>
                                </IonSegmentButton>
                            ))}
                               
                
                            </IonSegment>
                        </IonCol>
                    </IonRow>

                  
                    {(day === 'mon') && (timetable && timetable.mon.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.mon.map((entry, i) => (
                                    <IonRow key={i}>
                                        <IonCol size="2" className='planner-timeline'>
                                            <h1>{entry.timeSlot.startTime}</h1>
                                            <h2>{entry.timeSlot.endTime}</h2>
                                        </IonCol>
                                        <IonCol size="10">
                                            <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                                <IonCardContent className="ion-no-padding ion-no-margin">
                                                   
                                                        <IonRow>
                                                            <IonCol className='planner-col ion-margin-horizontal ion-padding-bottom'>
                                                           
                                                                <IonLabel>
                                                                    <h3>Subject</h3>
                                                                    <p>{entry.subject.subjectName}</p>
                                                                </IonLabel>
                                                                {(entry.teacher.teacherName !== "None") && (
                                                                <IonLabel>
                                                                    <h3>Taught By</h3>
                                                                    <p>{entry.teacher.teacherName}</p>
                                                                </IonLabel>
                                                              )}
                                                            </IonCol>
                                                        </IonRow>

                                                    </IonCardContent>
                                                </IonCard>
                                        </IonCol>
                                    </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'tue') && (timetable && timetable.tue.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.tue.map((entry, i) => (
                                <IonRow key={i}>
                                <IonCol size="2" className='planner-timeline'>
                                    <h1>{entry.timeSlot.startTime}</h1>
                                    <h2>{entry.timeSlot.endTime}</h2>
                                </IonCol>
                                <IonCol size="10">
                                    <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                           
                                                <IonRow>
                                                    <IonCol className='planner-col ion-margin-horizontal ion-padding-bottom'>
                                                   
                                                        <IonLabel>
                                                            <h3>Subject</h3>
                                                            <p>{entry.subject.subjectName}</p>
                                                        </IonLabel>
                                                        {(entry.teacher.teacherName !== "None") && (
                                                        <IonLabel>
                                                            <h3>Taught By</h3>
                                                            <p>{entry.teacher.teacherName}</p>
                                                        </IonLabel>
                                                      )}
                                                    </IonCol>
                                                </IonRow>

                                            </IonCardContent>
                                        </IonCard>
                                </IonCol>
                            </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'wed') && (timetable && timetable.wed.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.wed.map((entry, i) => (
                                 <IonRow key={i}>
                                 <IonCol size="2" className='planner-timeline'>
                                     <h1>{entry.timeSlot.startTime}</h1>
                                     <h2>{entry.timeSlot.endTime}</h2>
                                 </IonCol>
                                 <IonCol size="10">
                                     <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                         <IonCardContent className="ion-no-padding ion-no-margin">
                                            
                                                 <IonRow>
                                                     <IonCol className='planner-col ion-margin-horizontal ion-padding-bottom'>
                                                    
                                                         <IonLabel>
                                                             <h3>Subject</h3>
                                                             <p>{entry.subject.subjectName}</p>
                                                         </IonLabel>
                                                         {(entry.teacher.teacherName !== "None") && (
                                                         <IonLabel>
                                                             <h3>Taught By</h3>
                                                             <p>{entry.teacher.teacherName}</p>
                                                         </IonLabel>
                                                       )}
                                                     </IonCol>
                                                 </IonRow>

                                             </IonCardContent>
                                         </IonCard>
                                 </IonCol>
                             </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'thu') && (timetable && timetable.thu.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.thu.map((entry, i) => (
                                <IonRow key={i}>
                                <IonCol size="2" className='planner-timeline'>
                                    <h1>{entry.timeSlot.startTime}</h1>
                                    <h2>{entry.timeSlot.endTime}</h2>
                                </IonCol>
                                <IonCol size="10">
                                    <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                           
                                                <IonRow>
                                                    <IonCol className='planner-col ion-margin-horizontal ion-padding-bottom'>
                                                   
                                                        <IonLabel>
                                                            <h3>Subject</h3>
                                                            <p>{entry.subject.subjectName}</p>
                                                        </IonLabel>
                                                        {(entry.teacher.teacherName !== "None") && (
                                                        <IonLabel>
                                                            <h3>Taught By</h3>
                                                            <p>{entry.teacher.teacherName}</p>
                                                        </IonLabel>
                                                      )}
                                                    </IonCol>
                                                </IonRow>

                                            </IonCardContent>
                                        </IonCard>
                                </IonCol>
                            </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'fri') && (timetable && timetable.fri.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.fri.map((entry, i) => (
                                <IonRow key={i}>
                                <IonCol size="2" className='planner-timeline'>
                                    <h1>{entry.timeSlot.startTime}</h1>
                                    <h2>{entry.timeSlot.endTime}</h2>
                                </IonCol>
                                <IonCol size="10">
                                    <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                           
                                                <IonRow>
                                                    <IonCol className='planner-col ion-margin-horizontal ion-padding-bottom'>
                                                   
                                                        <IonLabel>
                                                            <h3>Subject</h3>
                                                            <p>{entry.subject.subjectName}</p>
                                                        </IonLabel>
                                                        {(entry.teacher.teacherName !== "None") && (
                                                        <IonLabel>
                                                            <h3>Taught By</h3>
                                                            <p>{entry.teacher.teacherName}</p>
                                                        </IonLabel>
                                                      )}
                                                    </IonCol>
                                                </IonRow>

                                            </IonCardContent>
                                        </IonCard>
                                </IonCol>
                            </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}

                    {(day === 'sat') && (timetable && timetable.sat.length > 0) && (
                        <IonRow className="ion-padding">
                            <IonCol>
                                {timetable.sat.map((entry, i) => (
                               <IonRow key={i}>
                               <IonCol size="2" className='planner-timeline'>
                                   <h1>{entry.timeSlot.startTime}</h1>
                                   <h2>{entry.timeSlot.endTime}</h2>
                               </IonCol>
                               <IonCol size="10">
                                   <IonCard className='border-radius-10 ion-margin-bottom planner-card'>
                                       <IonCardContent className="ion-no-padding ion-no-margin">
                                          
                                               <IonRow>
                                                   <IonCol className='planner-col ion-margin-horizontal ion-padding-bottom'>
                                                  
                                                       <IonLabel>
                                                           <h3>Subject</h3>
                                                           <p>{entry.subject.subjectName}</p>
                                                       </IonLabel>
                                                       {(entry.teacher.teacherName !== "None") && (
                                                       <IonLabel>
                                                           <h3>Taught By</h3>
                                                           <p>{entry.teacher.teacherName}</p>
                                                       </IonLabel>
                                                     )}
                                                   </IonCol>
                                               </IonRow>

                                           </IonCardContent>
                                       </IonCard>
                               </IonCol>
                           </IonRow>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}

                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Planner;
