import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRow,
    IonTitle,
    IonToggle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import { informationCircleOutline } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAuth} from '../../../../components/AuthContext';

interface ParamTypes {
    instituteID: string
}

interface AdmnField {
    name: string,
    category: string,
    options?: string[],
    isMandatory: boolean,
    isActive: boolean,
    order: number
}

const FormSetup: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {instituteID} = useParams < ParamTypes > ();
    const [admnFields, setAdmnFields] = useState < AdmnField[] > ([]);
    const [allFields, setAllFields] = useState < AdmnField[] > ([]);
    const [categories, setCategories] = useState < string[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");

    const [refreshed, setRefreshed] = useState < number > (0);


    const handleChange = (value: boolean, fieldName: string) => {

        let admn = [...admnFields];

        if (value === false)
        {
            const indexOfObject = admn.findIndex(obj => obj.name === fieldName);
            if (indexOfObject > -1) {
                admn.splice(indexOfObject, 1); // 2nd parameter means remove one item only
              }
        }
        if (value === true)
        {
            const indexOfObject = admn.findIndex(obj => obj.name === fieldName);
            if (!(indexOfObject > -1)) {
                
                    const newField = allFields.find(obj => obj.name === fieldName);
                    if (newField && newField.options && newField.options.length > 0)
                    {
                        admn.push({name: newField.name, category: newField.category, options: newField.options, isMandatory: newField.isMandatory, isActive: newField.isActive, order: newField.order});
                    }
                    else if (newField)
                    {
                        admn.push({name: newField.name, category: newField.category, isMandatory: newField.isMandatory, isActive: newField.isActive, order: newField.order});
                    }
              }
            
        }
        setAdmnFields(admn);
    }

    const handleSubmit = () => {
    
        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upClass = (admnFields: AdmnField[]) => {
          return new Promise((resolve, reject) => {
            api.post('/institutes/updateFields', { instituteID, admnFields }).then(res => {
      
              return resolve(res.data.institute.name);
              
            }).catch(err => reject(err));
  
          });
        }
    
    
      upClass(admnFields)
        .then(data => {
  
            setPass(data+"'s admission form has been updated.");
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
          setIserror(true);
          setShowLoading(false);
        });
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const insti = await api.get(`/institutes/${instituteID}`);

                if(insti.data.admnFields && insti.data.admnFields.length > 0)
                {
                    setAdmnFields(insti.data.admnFields);
                }

                const result = await api.get(`/masters/getFields`);
                const formFields: AdmnField[] = result.data.admissionFields;
                setAllFields(formFields);

                const unique = [...new Set(formFields.map(item => item.category))];
                setCategories(unique);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchData();

    }, [authInfo, instituteID, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle color="secondary">Form Setup</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Form Setup</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh No!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header="Awesome!"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonCard className='note-card-1'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="1">
                                            <IonIcon icon={informationCircleOutline}
                                                color="secondary" 
                                                className="note-icon" />
                                        </IonCol>
                                        <IonCol size="11" className='note-text pl-08'>
                                            <p>
                                                These fields will be shown to parents when they apply for admission in your school.
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
 
              
                    <IonRow className="ion-margin-bottom">
                        <IonCol offsetMd="4" offsetSm="2" offset="0" sizeMd="4" sizeSm="8" size="12">
                            
                        {categories && categories.length > 0 && categories.map((category, i) => (
                            <IonCard key={i}>
                                <IonCardHeader>
                                    <IonCardSubtitle className='info-subtitle'>{category}'s Fields</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                              
                                    <IonRow>
                                            <IonCol>
                                              
                                                {allFields.length > 1 && allFields.map((field, i) => (
                                                    (field.isActive) && (field.category === category) &&
                                                    (<IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        key={i}
                                                        >
                                                        <IonLabel color="secondary">{field.name}</IonLabel>
                                                        <IonToggle checked={admnFields.some(el => el.name === field.name)} onIonChange={e => handleChange(e.detail.checked, field.name)} />
                                                    </IonItem>)
                                                ))}

                                            </IonCol>
                                        
                                    </IonRow>
                                          
                                </IonCardContent>
                            </IonCard>
                            ))}

                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                expand="block">Save Changes</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default FormSetup;
