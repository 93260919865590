import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { alertCircleOutline } from 'ionicons/icons';
import SwitchBranch from '../../../components/SwitchBranch';

interface DaySchedule {
    timeSlotID: string,
    startTime: string,
    endTime: string,
    classInfoName: string,
    subjectName: string
}

interface TimeTable {
    mon: DaySchedule[],
    tue: DaySchedule[],
    wed: DaySchedule[],
    thu: DaySchedule[],
    fri: DaySchedule[],
    sat: DaySchedule[]
}

interface Teacher {
    _id: string,
    name: string,
    isActive: boolean
}
interface TimeSlot {
    _id: string,
    name: string,
    startTime: string,
    endTime: string,
    isActive: boolean
}

const TeacherTimeTable: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const [slots, setSlots] = useState < TimeSlot[] > ([]);
    const [teachers, setTeachers] = useState < Teacher[] > ([]);
    const [timetable, setTimeTable] = useState < TimeTable > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [day, setDay] = useState < string > ("mon");
    const [teacherID, setTeacherID] = useState < string > ("");

    const handleTeacher = () => {

        if (!teacherID || teacherID === "") {
            setMessage(t("teacher_mandatory"));
            setIserror(true);
            return;
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const tops = await api.get(`/timeTables/getByTeacher/${authInfo.user.instituteID}/${teacherID}`);

                let array = tops.data;

                array.mon.sort(function(a: any, b: any){
                    return parseInt(a.startTime) - parseInt(b.startTime);
                  });

                array.tue.sort(function(a: any, b: any){
                    return parseInt(a.startTime) - parseInt(b.startTime);
                  });

                array.wed.sort(function(a: any, b: any){
                    return parseInt(a.startTime) - parseInt(b.startTime);
                  });

                array.thu.sort(function(a: any, b: any){
                    return parseInt(a.startTime) - parseInt(b.startTime);
                  });

                array.fri.sort(function(a: any, b: any){
                    return parseInt(a.startTime) - parseInt(b.startTime);
                  });

                array.sat.sort(function(a: any, b: any){
                    return parseInt(a.startTime) - parseInt(b.startTime);
                  });



                setTimeTable(array);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/users/${authInfo.user.instituteID}/teachers`);
                setTeachers(result.data);
                setTimeTable(undefined);

                const query = await api.get(`/timeSlots/getByInstitute/${authInfo.user.instituteID}`);

                let array = query.data;

                array.sort(function(a: any, b: any){
                    return parseInt(a.startTime) - parseInt(b.startTime);
                  });

                setSlots(array);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/academics`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("teacher_timetable")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("teacher_timetable")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow>
                        <IonCol sizeXl="3" size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="stacked" color="secondary" className='input-label'>{t("teacher")}</IonLabel>
                                                    <IonSelect value={teacherID}
                                                            className='input-field'
                                                            cancelText={t("cancel")}
                                                            okText={t("ok")}
                                                            placeholder={t("select_teacher")}
                                                            onIonChange={
                                                                (e) => setTeacherID(e.detail.value)
                                                        }>
                                                        { teachers.map((teacher) => 
                                                            (teacher.isActive) && 
                                                            (<IonSelectOption key={teacher._id} value={`${teacher._id}`}>{teacher.name}</IonSelectOption>)
                                                        )}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeXl="3" size="6" className="ion-padding">
                            <IonButton onClick={() => handleTeacher() }
                                color="primary" className='mt-15'>{t("view_time_table")}
                            </IonButton>
                        </IonCol>
                    </IonRow>

                    {(!isPlatform("mobile")) && timetable && (
                    <div className="ion-table mt-30 min-xl">

                        <IonRow className="table-title ion-padding-vertical ion-text-center">

                            <IonCol size="1.5" className='table-heading heading-fixed'>
                            {t("period")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("monday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("tuesday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("wednesday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("thursday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("friday")}
                            </IonCol>
                            <IonCol size="1.75" className='table-heading'>
                            {t("saturday")}
                            </IonCol>
                        </IonRow>
                        {slots.length > 0 && slots.map((slot, i) => (
                        <IonRow key={slot._id} className='row-table'>
                            <IonCol size="1.5" className='table-heading heading-fixed'>     
                                <div className="vertical-container">
                                    <div className="vertical-center">     
                                        {slot.name}<br/>{slot.startTime} - {slot.endTime}
                                    </div>
                                </div>
                            </IonCol>
                            
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">{t("class")}</IonLabel>
                                            <IonInput type="text"
                                                    value={timetable.mon.find((val) => val.timeSlotID === slot._id) !== undefined ? timetable.mon.find((val) => val.timeSlotID === slot._id)!.classInfoName : "None"}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                            <IonInput type="text"
                                                    value={timetable.mon.find((val) => val.timeSlotID === slot._id) !== undefined ? timetable.mon.find((val) => val.timeSlotID === slot._id)!.subjectName : "None"}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>

                                    </IonCardContent>  
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">{t("class")}</IonLabel>
                                            <IonInput type="text"
                                                    value={timetable.tue.find((val) => val.timeSlotID === slot._id) !== undefined ? timetable.tue.find((val) => val.timeSlotID === slot._id)!.classInfoName : "None"}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                            <IonInput type="text"
                                                    value={timetable.tue.find((val) => val.timeSlotID === slot._id) !== undefined ? timetable.tue.find((val) => val.timeSlotID === slot._id)!.subjectName : "None"}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">{t("class")}</IonLabel>
                                            <IonInput type="text"
                                                    value={timetable.wed.find((val) => val.timeSlotID === slot._id) !== undefined ? timetable.wed.find((val) => val.timeSlotID === slot._id)!.classInfoName : "None"}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                            <IonInput type="text"
                                                    value={timetable.wed.find((val) => val.timeSlotID === slot._id) !== undefined ? timetable.wed.find((val) => val.timeSlotID === slot._id)!.subjectName : "None"}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">{t("class")}</IonLabel>
                                            <IonInput type="text"
                                                    value={timetable.thu.find((val) => val.timeSlotID === slot._id) !== undefined ? timetable.thu.find((val) => val.timeSlotID === slot._id)!.classInfoName : "None"}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                            <IonInput type="text"
                                                    value={timetable.thu.find((val) => val.timeSlotID === slot._id) !== undefined ? timetable.thu.find((val) => val.timeSlotID === slot._id)!.subjectName : "None"}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">{t("class")}</IonLabel>
                                            <IonInput type="text"
                                                    value={timetable.fri.find((val) => val.timeSlotID === slot._id) !== undefined ? timetable.fri.find((val) => val.timeSlotID === slot._id)!.classInfoName : "None"}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                            <IonInput type="text"
                                                    value={timetable.fri.find((val) => val.timeSlotID === slot._id) !== undefined ? timetable.fri.find((val) => val.timeSlotID === slot._id)!.subjectName : "None"}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol size="1.75" className='table-field'>
                                <IonCard>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">{t("class")}</IonLabel>
                                            <IonInput type="text"
                                                    value={timetable.sat.find((val) => val.timeSlotID === slot._id) !== undefined ? timetable.sat.find((val) => val.timeSlotID === slot._id)!.classInfoName : "None"}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            >
                                            
                                            <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                            <IonInput type="text"
                                                    value={timetable.sat.find((val) => val.timeSlotID === slot._id) !== undefined ? timetable.sat.find((val) => val.timeSlotID === slot._id)!.subjectName : "None"}
                                                    readonly={true}
                                                    ></IonInput>
                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        ))}
                    </div>
                )}

                {(isPlatform("mobile"))  && timetable && (
                <>
                    <IonRow>
                        <IonCol>
                            <IonSegment scrollable={true} value={day} onIonChange={e => setDay( e.detail.value!.toString() )}  >
                                <IonSegmentButton value="mon">
                                    <IonLabel>{t("mon")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="tue">
                                    <IonLabel>{t("tue")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="wed">
                                    <IonLabel>{t("wed")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="thu">
                                    <IonLabel>{t("thu")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="fri">
                                    <IonLabel>{t("fri")}</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="sat">
                                    <IonLabel>{t("sat")}</IonLabel>
                                </IonSegmentButton>
                               
                            </IonSegment>
                        </IonCol>
                    </IonRow>
                  
                    {(day === 'mon') && (timetable && timetable.mon.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {timetable.mon.map((entry, i) => (
                                <IonCard className='border-radius-10' key={i}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                            <IonRow className="tran-pad title-row">
                                                <IonCol className='ion-text-center'>
                                                   <IonText>{entry.startTime} - {entry.endTime}</IonText>
                                                </IonCol>
                                              
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("class")}</IonLabel>
                                                        <IonInput type="text"
                                                                value={entry.classInfoName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                                        <IonInput type="text"
                                                                value={entry.subjectName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                           
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'mon') && (timetable && timetable.mon.length === 0) && (
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    {(day === 'tue') && (timetable && timetable.tue.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {timetable.tue.map((entry, i) => (
                                <IonCard className='border-radius-10' key={i}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                        <IonRow className="tran-pad title-row">
                                                <IonCol className='ion-text-center'>
                                                   <IonText>{entry.startTime} - {entry.endTime}</IonText>
                                                </IonCol>
                                              
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("class")}</IonLabel>
                                                        <IonInput type="text"
                                                                value={entry.classInfoName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                                        <IonInput type="text"
                                                                value={entry.subjectName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                        
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'tue') && (timetable && timetable.tue.length === 0) && (
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    {(day === 'wed') && (timetable && timetable.wed.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {timetable.wed.map((entry, i) => (
                                <IonCard className='border-radius-10' key={i}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                        <IonRow className="tran-pad title-row">
                                                <IonCol className='ion-text-center'>
                                                   <IonText>{entry.startTime} - {entry.endTime}</IonText>
                                                </IonCol>
                                              
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("class")}</IonLabel>
                                                        <IonInput type="text"
                                                                value={entry.classInfoName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                                        <IonInput type="text"
                                                                value={entry.subjectName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'wed') && (timetable && timetable.wed.length === 0) && (
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    {(day === 'thu') && (timetable && timetable.thu.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {timetable.thu.map((entry, i) => (
                                <IonCard className='border-radius-10' key={i}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                        <IonRow className="tran-pad title-row">
                                                <IonCol className='ion-text-center'>
                                                   <IonText>{entry.startTime} - {entry.endTime}</IonText>
                                                </IonCol>
                                              
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("class")}</IonLabel>
                                                        <IonInput type="text"
                                                                value={entry.classInfoName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                                        <IonInput type="text"
                                                                value={entry.subjectName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'thu') && (timetable && timetable.thu.length === 0) && (
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    {(day === 'fri') && (timetable && timetable.fri.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {timetable.fri.map((entry, i) => (
                                <IonCard className='border-radius-10' key={i}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                        <IonRow className="tran-pad title-row">
                                                <IonCol className='ion-text-center'>
                                                   <IonText>{entry.startTime} - {entry.endTime}</IonText>
                                                </IonCol>
                                              
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("class")}</IonLabel>
                                                        <IonInput type="text"
                                                                value={entry.classInfoName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                                        <IonInput type="text"
                                                                value={entry.subjectName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'fri') && (timetable && timetable.fri.length === 0) && (
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                    {(day === 'sat') && (timetable && timetable.sat.length > 0) && (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                {timetable.sat.map((entry, i) => (
                                <IonCard className='border-radius-10' key={i}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                        <IonGrid className="ion-no-padding ion-no-margin">
                                        <IonRow className="tran-pad title-row">
                                                <IonCol className='ion-text-center'>
                                                   <IonText>{entry.startTime} - {entry.endTime}</IonText>
                                                </IonCol>
                                              
                                            </IonRow>
                                            <IonRow className='border-top'>
                                                <IonCol>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("class")}</IonLabel>
                                                        <IonInput type="text"
                                                                value={entry.classInfoName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        
                                                        <IonLabel position="stacked" color="secondary">{t("subject")}</IonLabel>
                                                        <IonInput type="text"
                                                                value={entry.subjectName}
                                                                readonly={true}
                                                                ></IonInput>
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonCardContent>
                                </IonCard>
                                ))}

                            </IonCol>
                        </IonRow>
                    )}
                    {(day === 'sat') && (timetable && timetable.sat.length === 0) && (
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger" />
                                        <IonLabel className="list-title"><h3>{t("no_entries")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    )}
                </>)}
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default TeacherTimeTable;
