import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

const AddRegion: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage("Name is a mandatory field!");
            setIserror(true);
            return;
        }

        setShowLoading(true);

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const addRegion = ( name: string ) => {
          return new Promise((resolve, reject) => {
            api.post('/regions/add', { 'name': name }).then(res => {
      
              return resolve(res.data.region.name);
              
            }).catch(err => reject(err));
  
          });
        }
  
    
      addRegion(name)
        .then(data => {
          
          setPass(data+' has been added.');
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
  
          setIserror(true);
          setShowLoading(false);
        });
        
      }

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/regions" />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined}  color="secondary">Add Region</IonTitle>
                   {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Add Region</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => history.push(`/app/regions`)
                    }
                    header="Success!"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>


                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Region Details
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonList className="ion-no-padding">

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Name</IonLabel>
                                    <IonInput type="text"
                                        placeholder='Region Name'
                                        value={name}
                                        onIonInput={
                                            (e) => setName(e.detail.value!)
                                    }></IonInput>
                                </IonItem>
                              
                            </IonList>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                expand="block">Add Region</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddRegion;
