import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonPopover,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonTextarea,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { addCircle, arrowUndoCircle, closeCircle } from 'ionicons/icons';

interface ParamTypes {
    chapterID: string,
    topicID: string,
    questionID: string
}

const QuestionDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {chapterID, topicID, questionID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [uploadFile, setUploadFile] = useState < File | null > (null);
    const [solutionFile, setSolutionFile] = useState < File | null > (null);
    const [mode, setMode] = useState < string > ("Question");
    const [oldOptions, setOldOptions] = useState < string[] > ([]);
    const [options, setOptions] = useState < string[] > ([]);
    const [title, setTitle] = useState < string > ("");
    const [statement, setStatement] = useState < string > ("");
    const [solution, setSolution] = useState < string > ("");
    const [statementImage, setStatementImage] = useState < string > ("none");
    const [solutionImage, setSolutionImage] = useState < string > ("none");
    const [answer, setAnswer] = useState < number > (0);

    const handleUndo = () => {
        setOptions(oldOptions);
    }

    const handleOption = (option: string, index: number) => {
        let comps = [...options];
        comps[index] = option;
        setOptions(comps);
    }

    const handleDelete = (index: number) => 
    {
        let comps = [...options];
        if (index > -1) {
            comps.splice(index, 1); // 2nd parameter means remove one item only
          }
        setOptions(comps);
    }

    const handleAdd = () => {
        let comps = [...options];
        comps.push("");
        setOptions(comps);
    }

    function onFileChange(fileChangeEvent : React.ChangeEvent<HTMLInputElement>) {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                setUploadFile(fileChangeEvent.target.files[0]);
            }
            else
            {
                setUploadFile(null);
            }
        }   
      }

      function onSolFileChange(fileChangeEvent : React.ChangeEvent<HTMLInputElement>) {
        if (null !== (fileChangeEvent.target.files))
        {
            if(fileChangeEvent.target.files[0]){
                setSolutionFile(fileChangeEvent.target.files[0]);
            }
            else
            {
                setSolutionFile(null);
            }
        }   
      }


    const handleSubmit = () => {

        if (!title || title === "") {
            setMessage("Title is mandatory");
            setIserror(true);
            return;
        }

        if (!statement || statement === "") {
            setMessage("Question Statement is mandatory");
            setIserror(true);
            return;
        }

        if (!solution || solution === "") {
            setMessage("Solution Statement is mandatory");
            setIserror(true);
            return;
        }

        if(options.length < 2)
        {
            setMessage("Provide atleast 2 options");
            setIserror(true);
            return;
        }

        if (answer >= options.length)
        {
            setMessage("Select an answer from options provided");
            setIserror(true);
            return;
        }

        setShowLoading(true);

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const addQuestion = ( formData: FormData) => {
          return new Promise((resolve, reject) => {
            api.post('/topics/addQuestion', formData).then(res => {
              return resolve(res.data.title);
            }).catch(err => reject(err));
          });
        }

        
        let formData = new FormData();

        if (uploadFile) {
            formData.append('statementFile', uploadFile, uploadFile.name);
        }
        if (solutionFile) {
            formData.append('solutionFile', solutionFile, solutionFile.name);
        }

        formData.append('topicID', topicID);
        formData.append('questionID', questionID);
        formData.append('title', title);
        formData.append('statement', statement);
        formData.append('solution', solution);
        formData.append('statementImage', statementImage);
        formData.append('solutionImage', solutionImage);
        formData.append('options', JSON.stringify(options));
        formData.append('answer', answer.toString());

        addQuestion(formData)
            .then(data => {
                setPass('Question titled - '+data+' - has been updated.');
                setIspass(true);
                setShowLoading(false);
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage('Please try again afer sometime.');
    
                setIserror(true);
                setShowLoading(false);
            });

    }

      useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/topics/getQuestion/${topicID}/${questionID}`);

                setTitle(result.data.title);

                if(result.data.statementImage)
                {
                    setStatementImage(result.data.statementImage);
                }
                if(result.data.statement)
                {
                    setStatement(result.data.statement);
                }
                if(result.data.solutionImage)
                {
                    setSolutionImage(result.data.solutionImage);
                }
                if(result.data.solution)
                {
                    setSolution(result.data.solution);
                }
                if(result.data.options)
                {
                    setOldOptions(result.data.options);
                    setOptions(result.data.options);
                }
                if(result.data.answer)
                {
                    setAnswer(result.data.answer);
                }
                
                
            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo, topicID, questionID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/assessments/${chapterID}/${topicID}`} />
                    </IonButtons>
                    <IonTitle color="secondary">Topic Question</IonTitle>
                    <IonButtons slot="end">
                        <IonMenuButton color="secondary" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Topic Question</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => {
                            setIspass(false); 
                            history.push(`/app/assessments/${chapterID}/${topicID}`);
                        }
                    }
                    header="Success!"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>


                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonList className="ion-no-padding">

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Title</IonLabel>
                                    <IonInput type="text"
                                        placeholder='Question Title'
                                        value={title}
                                        onIonChange={
                                            (e) => setTitle(e.detail.value!)
                                    }></IonInput>
                                </IonItem>
                              
                            </IonList>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding mt-15">
                        <IonCol>
                            <IonSegment value={mode} onIonChange={e => setMode( e.detail.value!.toString() )} mode="ios" className='isactive-segment'>
                                <IonSegmentButton value="Question">
                                    <IonLabel>Question</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="Options">
                                    <IonLabel>Options</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="Solution">
                                    <IonLabel>Solution</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonCol>
                    </IonRow>

                    {(mode === "Question") && (
                    <>
                    <IonRow className='ion-margin-vertical'>
                        <IonCol>
                            <IonList className="ion-no-padding">

                                <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="floating" color="secondary">Problem Statement*</IonLabel>
                                        <IonTextarea rows={16} value={statement} onIonChange={
                                                (e) => setStatement(e.detail.value!)
                                        }></IonTextarea>
                                    
                                    </IonItem>

                                {(statementImage === "none") && (
                                <IonItem detail={false}
                                    lines='full'
                                    button={false}
                                    >
                                    <IonLabel position="stacked" color="secondary">Image (less than 500 KB) - Optional</IonLabel>
                                    <input type="file" accept=".jpg, .jpeg, .png" onChange={ev => onFileChange(ev)} className='ion-padding'></input>
                                </IonItem>
                                )}

                              
                                
                            </IonList>
                        
                        </IonCol>
                    </IonRow>

                             {(statementImage !== "none") && (
                                <>
                                <IonRow>
                                    <IonCol>
                                        <IonCard className="ion-text-center">
                                            <IonCardHeader>
                                                <IonCardSubtitle>Question Image</IonCardSubtitle>
                                            </IonCardHeader>
                                            <IonCardContent>
                                             
                                                        <img
                                                        src={`${process.env.REACT_APP_STATIC_S3}/images/${statementImage}`} 
                                                        alt="Question" 
                                                        width="320" />
                                              
                                            </IonCardContent>        
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-margin-top mb-60">
                                    <IonCol size="6" className='ion-padding-horizontal'>
                                        <IonButton expand="block" color="dark"  id="open-upload-input">Change</IonButton> 

                                    </IonCol>
                                    <IonCol size="6" className='ion-padding-horizontal'>
                                        <IonButton expand="block" color="danger" onClick={() => setStatementImage("none")}>Remove</IonButton> 

                                    </IonCol>
                                </IonRow>
                                <IonPopover trigger="open-upload-input" size='auto'>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol>
                                                <IonCard>
                                                    <IonCardContent className="ion-no-padding">
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        <IonLabel position="stacked" color="secondary">Image (less than 500 KB) - Optional</IonLabel>
                                                        <input type="file" accept=".jpg, .jpeg, .png" onChange={ev => onFileChange(ev)} className='ion-padding'></input>
                                                    </IonItem>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonPopover>
                            </>
                            )}

                    </>
                    )}

                    {(mode === "Options") && (
                    <>
                     <IonRow className='ion-margin-top'>
                        <IonCol>
                            <IonCard className='border-radius-10' color='tertiary'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonGrid>
                                        <IonRow className="mt-05">
                                            <IonCol size="8" className='ion-padding-horizontal'>
                                                Answer Options
                                            </IonCol>
                                            <IonCol size="2" className='ion-text-right'>
                                               
                                               <IonIcon className='tran-icon'
                                                   icon={arrowUndoCircle}
                                                   color='secondary'
                                                   onClick={handleUndo}
                                               />
                                           
                                           
                                            </IonCol>
                                            <IonCol size="2" className='ion-text-center'>
                                               
                                                    <IonIcon className='tran-icon'
                                                        icon={addCircle}
                                                        color='secondary'
                                                        onClick={handleAdd}
                                                    />
                                                
                                                
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    {options && (options.length > 0) && (
                        <IonRow className='ion-margin-vertical'>
                            <IonCol>
                                <IonList className="ion-no-padding">
                                <IonRadioGroup value={answer} onIonChange={
                                            (e) => setAnswer(e.detail.value!)
                                    }>
                                {options.map((option, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={i}>
                                            <IonLabel position="floating" color="secondary">Option #{i+1} Statement</IonLabel>
                                            <IonRadio slot="start" value={i} className="mt-40"></IonRadio>
                                            <IonTextarea rows={3} value={option} onIonChange={
                                                    (e) => handleOption(e.detail.value!, i)
                                            }></IonTextarea>
                                            
                                                <IonButtons slot="end">
                                                    
                                                    <IonButton onClick={() => handleDelete(i)}> 
                                                        <IonIcon
                                                        icon={closeCircle}
                                                        color="danger"
                                                        />
                                                    </IonButton>
                                                    
                                                </IonButtons>
                                        </IonItem>
                                            )
                                        })
                                    }
                                </IonRadioGroup>
                                </IonList>
                                
                        </IonCol>
                    </IonRow>
                    )}

                </>
                )}

                    {(mode === "Solution") && (
                    <>
                        <IonRow className='ion-margin-vertical'>
                            <IonCol>
                            <IonList className="ion-no-padding">

                                <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="floating" color="secondary">Solution Statement*</IonLabel>
                                        <IonTextarea rows={16} value={solution} onIonChange={
                                                (e) => setSolution(e.detail.value!)
                                        }></IonTextarea>
                                    
                                    </IonItem>

                                {(solutionImage === "none") && (
                                <IonItem detail={false}
                                    lines='full'
                                    button={false}
                                    >
                                    <IonLabel position="stacked" color="secondary">Image (less than 500 KB)- Optional</IonLabel>
                                    <input type="file" accept=".jpg, .jpeg, .png" onChange={ev => onSolFileChange(ev)} className='ion-padding'></input>
                                </IonItem>
                                )}

                            </IonList>
                               
                        </IonCol>
                    </IonRow>
                    {(solutionImage !== "none") && (
                                <>
                                <IonRow>
                                    <IonCol>
                                        <IonCard className="ion-text-center">
                                            <IonCardHeader>
                                                <IonCardSubtitle>Solution Image</IonCardSubtitle>
                                            </IonCardHeader>
                                            <IonCardContent>
                                             
                                                        <img
                                                        src={`${process.env.REACT_APP_STATIC_S3}/images/${solutionImage}`} 
                                                        alt="Solution" 
                                                        width="320" />
                                              
                                            </IonCardContent>        
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-margin-top mb-60">
                                    <IonCol size="6" className='ion-padding-horizontal'>
                                        <IonButton expand="block" color="dark" id="open-sol-input">Change</IonButton> 

                                    </IonCol>
                                    <IonCol size="6" className='ion-padding-horizontal'>
                                        <IonButton expand="block" color="danger" onClick={() => setSolutionImage("none")}>Remove</IonButton> 

                                    </IonCol>
                                </IonRow>
                                <IonPopover trigger="open-sol-input" size="auto">
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol>
                                                <IonCard>
                                                    <IonCardContent className="ion-no-padding">
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        >
                                                        <IonLabel position="stacked" color="secondary">Image (less than 500KB) - Optional</IonLabel>
                                                        <input type="file" accept=".jpg, .jpeg, .png" onChange={ev => onSolFileChange(ev)} className='ion-padding'></input>
                                                    </IonItem>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonPopover>
                            </>
                            )}


                    </>
                    )}
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                expand="block">Submit Question</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default QuestionDetail;
