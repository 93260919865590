import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import React, {useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import { addCircle, closeCircle } from 'ionicons/icons';

interface Chapter {
    chapterName: string,
    isActive: boolean,
    order?: number
}

const AddCourse: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [chapters, setChapters] = useState < Chapter[] > ([{chapterName: '', isActive: true}]);

    const handleName = (name: string, index: number) => {

        let comps = [...chapters];
        comps[index].chapterName = name;
        setChapters(comps);
    
    }

    const handleDelete = (index: number) => {
        let comps = [...chapters];
        if (index > -1) {
            comps.splice(index, 1); // 2nd parameter means remove one item only
          }
          setChapters(comps);
    }

    const handleAdd = () => {
        let comps = [...chapters];
        comps.push({chapterName: '', isActive: true});
        setChapters(comps);
    }


    const handleSubmit = () => {

        if (!name || (name === "")) {
            setMessage("Name is a mandatory field!");
            setIserror(true);
            return;
        }

        if (!text || (text === "")) {
            setMessage("Description is a mandatory field!");
            setIserror(true);
            return;
        }

        if (!chapters || (chapters.length < 1)) {
            setMessage("Assign atleast 1 chapter!");
            setIserror(true);
            return;
        }

        let chaps: Chapter[] = [];
        for (let i = 0; i < chapters.length; i++)
        {
            chaps.push({chapterName: chapters[i].chapterName, isActive: chapters[i].isActive, order: i+1})
        }

        setShowLoading(true);

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const addCourse = ( name: string, desc: string, chapters: Chapter[] ) => {
          return new Promise((resolve, reject) => {
            api.post('/courses/add', { name, chapters }).then(res => {
      
              return resolve(res.data.course.name);
              
            }).catch(err => reject(err));
  
          });
        }
  
    
      addCourse(name, text, chaps)
        .then(data => {
          
          setPass(data+' has been added.');
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
  
          setIserror(true);
          setShowLoading(false);
        });
        
      }

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/courses`} />
                    </IonButtons>
                    <IonTitle color="secondary">Add Course</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Add Course</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => history.push(`/app/courses`)
                    }
                    header="Success!"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>


                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Course Details
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonList className="ion-no-padding">

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Name</IonLabel>
                                    <IonInput type="text"
                                        placeholder='Course Name'
                                        value={name}
                                        onIonChange={
                                            (e) => setName(e.detail.value!)
                                    }></IonInput>
                                </IonItem>
                                <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="floating" color="secondary">Short Description</IonLabel>
                                                <IonTextarea rows={2} value={text} onIonChange={
                                                        (e) => setText(e.detail.value!)
                                                }></IonTextarea>
                                            
                                            </IonItem>
                              
                            </IonList>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonCard className='border-radius-10' color='tertiary'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonGrid>
                                        <IonRow className="mt-05">
                                            <IonCol size="11" className='ion-padding-horizontal'>
                                                Course Chapters
                                            </IonCol>
 
                                            <IonCol size="1">
                                               
                                                    <IonIcon className='tran-icon'
                                                        icon={addCircle}
                                                        color='secondary'
                                                        onClick={handleAdd}
                                                    />
                                                
                                                
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                    {(chapters.length > 0) && (chapters.map((chapter, i) => {
                        return (
                        <IonCard className='ion-no-padding ion-no-margin' key={i}>
                            <IonCardContent className="ion-no-padding ion-no-margin">
                                <IonGrid className="ion-no-padding ion-no-margin">
                                    <IonRow>
                                        <IonCol size="11">
                                            <IonList className="ion-no-padding">

                                                <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="fixed" color="secondary">Chapter {i+1}</IonLabel>
                                                    <IonInput type="text"
                                                        placeholder='Chapter Name'
                                                        value={chapter.chapterName}
                                                        onIonChange={
                                                            (e) => handleName(e.detail.value!, i)
                                                    }></IonInput>
                                                </IonItem>

                                            </IonList>
                                        </IonCol>
                                       
                                        <IonCol size="1">
                                            <IonIcon className='tran-icon mt-10'
                                                icon={closeCircle}
                                                color='dark'
                                                onClick={() => handleDelete(i)}
                                            />
                                        </IonCol>
                                    </IonRow>

                                </IonGrid>
                            </IonCardContent>
                        </IonCard>
                    )}))
                    }
                       
                    </IonRow>

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                expand="block">Add Course</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddCourse;
