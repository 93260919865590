import {
    IonBadge, IonCard, IonCardContent, IonCol, IonRow, IonTitle,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';

import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/autoplay";
import '@ionic/react/css/ionic-swiper.css';

interface Circular {
    _id: string,
    title: string,
    text?: string,
    filename?: string,
    createdAt: Date,
    isActive: boolean
}

interface SwiperPage {
    circulars: Circular[]
}

const CircularsSwiper: React.FC<SwiperPage> = (props) => {
    const { t } = useTranslation();

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    return (
    <IonRow className="ion-margin-top">
        <IonCol className='ion-no-padding'>
            <IonRow className="ion-padding-vertical ion-text-center">
                <IonCol>
                    <IonTitle className='list-title'>
                    {t("latest_news")}
                    </IonTitle>   
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Swiper 
                    modules={[Autoplay, Navigation]}
                    slidesPerView={1}
                    spaceBetween={10}
                    initialSlide={0}
                    navigation={true}
                    loop={false}         
                    autoplay={{
                        delay: 3000
                    }}
                    breakpoints={{
                        700: {
                        slidesPerView: 2
                        },
                        900: {
                        slidesPerView: 3
                        },
                        1400: {
                        slidesPerView: 4
                        }
                    }}
                    >

                        {props.circulars.map((circular) => {
                            return (
                            <SwiperSlide key={circular._id}>
                                <IonRow>
                                    <IonCol>
                                        <IonCard className={circular.filename ? 'circular-card full-width' : 'announce-card full-width'}
                                                    routerLink={`/app/news/${circular._id}`} > 
                                            <IonCardContent className='ion-no-padding'>

                                                    <IonRow className='ion-padding-horizontal'>
                                                        <IonCol className='planner-col ion-padding-bottom'>
                                                            <IonBadge className={circular.filename ? 'circular-badge' : 'announce-badge'}>{circular.filename ?  t('circular') : t('announcement')}</IonBadge>
                                                                <p>{formatDate(circular.createdAt)}</p>
                                                                <h3>{t("title")}</h3>
                                                                <p>{circular.title}</p>

                                                        </IonCol>
                                                    
                                                    </IonRow>

                                                </IonCardContent>
                                            </IonCard>
                                    </IonCol>
                                    
                                </IonRow>
                            
                            </SwiperSlide>  
                            )})
                        }
                    </Swiper>
                </IonCol>
            </IonRow>
            
        </IonCol>
    </IonRow>
    );
}

export default CircularsSwiper;
