import {
  IonButtons,
  IonContent,
  IonHeader,
  IonBackButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonGrid,
  IonRow,
  IonCard,
  IonCardContent,
  IonMenuButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonCardHeader,
  IonCardSubtitle,
  IonButton,
  IonLoading,
  IonAlert,
  isPlatform
} from '@ionic/react';
import axios from 'axios';
import { informationCircleOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useAuth } from '../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';

const ContactUs: React.FC = () => {
const {authInfo} = useAuth()!;
const {t} = useTranslation();
const [showLoading, setShowLoading] = useState < boolean > (false);
const [name, setName] = useState < string > (authInfo.user.name);
const [email, setEmail] = useState < string > (authInfo.user.email);
const [phone, setPhone] = useState < string > (authInfo.user.phone);
const [query, setQuery] = useState < string > ("");
const [iserror, setIserror] = useState < boolean > (false);
const [message, setMessage] = useState < string > ("");
const [ispass, setIspass] = useState < boolean > (false);
const [pass, setPass] = useState < string > ("");

const handleSubmit = () => {

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
          }
      });

    const addQuery = ( userID: string, name: string, email: string, phone: string, query: string) => {
        return new Promise((resolve, reject) => {
            api.post('users/addQuery', { userID, name, email, phone, query }).then(res => {
    
                return resolve(true);
            
            }).catch(err => reject(err));

        });
    }

    if (!name || name === "") {
        setMessage(t("name_mandatory"));
        setIserror(true);
        return;
    }

    if (!email || email === "") {
        setMessage(t("invalid_email"));
        setIserror(true);
        return;
    }

    if (!phone || phone === "") {
        setMessage(t("invalid_mobile"));
        setIserror(true);
        return;
    }

    if (!query || query === "") {
        setMessage(t("concern_mandatory"));
        setIserror(true);
        return;
    }


        setShowLoading(true);


        addQuery(authInfo.user._id, name, email, phone, query)
          .then(() => {
            
                setPass(t("query_submitted"));
                setIspass(true);
             
              setShowLoading(false);
          })
          .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
    
            setIserror(true);
            setShowLoading(false);
          });
    

  }

return (
  <IonPage>
     <IonHeader>
        <IonToolbar className="white-shade">
            <IonButtons slot="start">
                <IonMenuButton color="secondary"/>
                <IonBackButton color="secondary" defaultHref="/app"/>
            </IonButtons>
            <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("need_assist")}</IonTitle>
            {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
        </IonToolbar>
    </IonHeader>


      <IonContent fullscreen={true}
          className="grey-shade">

          <IonHeader collapse="condense">
              <IonToolbar>
                  <IonTitle size="large" color="secondary">{t("need_assist")}</IonTitle>
              </IonToolbar>
          </IonHeader>

          <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(true)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

          <IonGrid className="mb-60">
                    <IonRow>
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonRow>
                                <IonCol>
                                    <IonCard className='note-card'>
                                        <IonCardContent className="ion-no-padding ion-no-margin">
                                            <IonRow>
                                                <IonCol size="1">
                                                    <IonIcon icon={informationCircleOutline}
                                                        color="primary" 
                                                        className="note-icon" />
                                                </IonCol>
                                                <IonCol size="11" className='note-text pl-08'>
                                                    <p>
                                                    {t("support_info")}
                                                    </p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className="ion-no-margin">
                                        <IonCardHeader>
                                                <IonCardSubtitle className='info-subtitle'>{t("concern_info")}</IonCardSubtitle>
                                            </IonCardHeader>
                                        <IonCardContent className="ion-no-padding">
                                            <IonRow>
                                                <IonCol>
                                                    <IonList className="ion-no-padding">
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonLabel position="stacked" color="secondary">{t("student_name")}</IonLabel>
                                                            <IonInput type="text"
                                                                value={name}
                                                                placeholder={t("student_name")}
                                                                onIonInput={
                                                                    (e) => setName(e.detail.value!)
                                                            }></IonInput>
                                                        </IonItem>
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonLabel position="stacked" color="secondary">{t("email")}</IonLabel>
                                                            <IonInput type="text"
                                                                value={email}
                                                                placeholder={t("email")}
                                                                onIonInput={
                                                                    (e) => setEmail(e.detail.value!)
                                                            }></IonInput>
                                                        </IonItem>
                                                        <IonItem detail={false}
                                                            lines='inset'
                                                            button={false}
                                                            className='ion-margin-top'
                                                            >
                                                            <IonLabel position="stacked" color="secondary">{t("phone")}</IonLabel>
                                                            <IonInput type="text"
                                                                value={phone}
                                                                placeholder={t("phone")}
                                                                onIonInput={
                                                                    (e) => setPhone(e.detail.value!)
                                                            }></IonInput>
                                                        </IonItem>
                                                        <IonItem detail={false}
                                                                    lines='full'
                                                                    button={false}>
                                                                    <IonLabel position="floating" color="secondary">{t("query_concern")}</IonLabel>
                                                                    <IonTextarea rows={4} value={query} onIonInput={
                                                                            (e) => setQuery(e.detail.value!)
                                                                    }></IonTextarea>
                                                                
                                                                </IonItem>
                                                    </IonList>
                                                </IonCol>
                                            </IonRow>
                                            
                                            <IonRow className='mt-30 mb-30'>
                                                <IonCol className='pl-24 pr-24'>
                                                    <IonButton onClick={handleSubmit}
                                                        className="first-button"
                                                        fill="clear"
                                                        expand='block'
                                                        >{t("submit")}</IonButton>

                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>

            </IonGrid>

        </IonContent>

    </IonPage>
    );
};

export default ContactUs;
