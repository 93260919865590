import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';

interface ParamTypes {
    taskID: string
}

interface Project {
    _id: string,
    name: string,
    status: string
}

const EditTask: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const history = useHistory();
    const {taskID} = useParams < ParamTypes > ();
    const [instituteID, setInstituteID] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [priority, setPriority] = useState < string > ("");
    const [projects, setProjects] = useState < Project[] > ([]);
    const [project, setProject] = useState < string > ("");
    const [startDate, setStartDate] = useState < string | string[] | undefined > ();
    const [endDate, setEndDate] = useState < string | string[] | undefined > ();
    const [showAlert, setShowAlert] = useState < boolean > (false);

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const handleSubmit = () => {

        if (!title || title === "") {
            setMessage(t("task_name_mandatory"));
            setIserror(true);
            return;
        }

        if (!priority || priority === "") {
            setMessage(t("task_priority_mandatory"));
            setIserror(true);
            return;
        }

        if (!text || text === "") {
            setMessage(t("task_info_mandatory"));
            setIserror(true);
            return;
        }

        if (!project || project === "") {
            setMessage(t("task_project_mandatory"));
            setIserror(true);
            return;
        }

        const projectName = projects.find(val => val._id === project)!.name;

        if (typeof startDate !== "string")
        {
            setMessage(t("start_date_mandatory"));
            setIserror(true);
            return;
        }

        if (typeof endDate !== "string")
        {
            setMessage(t("end_date_mandatory"));
            setIserror(true);
            return;
        }

        const fDate = new Date(startDate);
        const tDate = new Date(endDate);

        if (tDate < fDate)
        {
            setMessage(t("dates_invalid"));
            setIserror(true);
            return;
        }     

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
              'Authorization': 'Bearer ' + authInfo.token
              }
        });

        const editTask = (projectID: string, projectName: string) => {
            return new Promise((resolve, reject) => {
                api.put(`/staffChats/${taskID}`, { title, text, priority, startDate, endDate, projectID, projectName }).then(res => {
                    return resolve(res.data.chat.title);
                }).catch(err => reject(err));
            });
        }
         
        setShowLoading(true);
        editTask(project, projectName)
        .then(data => {
        
            setPass(data + t("has_been_updated"));
            setIspass(true);
            setShowLoading(false);
        })
        .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
    
            setIserror(true);
            setShowLoading(false);
        });

    }

    const handleDelete = () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });
    
        const deleteApp = () => {
            return new Promise((resolve, reject) => {
              api.delete(`/staffChats/${taskID}`).then(res => {
                    return resolve(true);
                  }).catch(err => reject(err));
            });
        }
    
        setShowLoading(true);
        deleteApp()
        .then(() => {
    
            setPass(t("task_removed"));
            setIspass(true);
            setShowLoading(false);
        })
        .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
    
            setIserror(true);
            setShowLoading(false);
        });
          
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const res = await api.get('/staffChats/' + taskID);
                setInstituteID(res.data.instituteID);
                const result = await api.get(`/projects/getAllInstitute/${res.data.instituteID}`);
                setProjects(result.data);
                
                setProject(res.data.taskInfo.projectID);
                setTitle(res.data.title);
                setText(res.data.text);
                setPriority(res.data.taskInfo.priority);
                setStartDate(res.data.taskInfo.startDate);
                setEndDate(res.data.taskInfo.endDate);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, taskID]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("edit_task")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("edit_task")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>
                
                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false); 
                            history.goBack();
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={t("confirm")}
                    message={t("confirm_delete_task")}
                    buttons={[
                        {
                        text: t("no"),
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: t("yes"),
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                    />

                <IonGrid className="ion-no-padding">
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("task_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='inset'
                                        button={false}
                                        >
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_proj")}</IonLabel>
                                            <IonSelect value={project}
                                             className="input-field"
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                placeholder={t("select_proj")}
                                                onIonChange={
                                                    (e) => setProject(e.detail.value)
                                            }>
                                                {projects.map((dep) => (
                                                    (<IonSelectOption key={dep._id} value={`${dep._id}`}>{dep.name}</IonSelectOption>)
                                                ))}
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                                
                                    <IonItem detail={false}
                                        lines='inset'
                                        button={false}
                                        >
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_priority")}</IonLabel>
                                            <IonSelect value={priority}
                                            className="input-field"
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                                placeholder={t("select_priority")}
                                                onIonChange={
                                                    (e) => setPriority(e.detail.value)
                                            }>
                                                    
                                                        <IonSelectOption value="high">{t("high")}</IonSelectOption>  
                                                        <IonSelectOption value="medium">{t("medium")}</IonSelectOption>  
                                                        <IonSelectOption value="low">{t("low")}</IonSelectOption>   
                                            </IonSelect>
                                    </IonItem>
                                    </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>           
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("task_name")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("task_name")}
                                                value={title}
                                                onIonInput={
                                                    (e) => setTitle(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                            <IonLabel className="input-label" position="floating" color="secondary">{t("task_text")}</IonLabel>
                                                <IonTextarea rows={4} value={text} onIonInput={
                                                        (e) => setText(e.detail.value!)
                                                }></IonTextarea>
                                            
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-date-input">
                                            <IonLabel position="stacked" color="secondary" className="input-label">
                                                {t("project_start")}
                                            </IonLabel>
                                            <IonInput value={formatDate(startDate)} readonly={true} className="input-field"/>
                                            <IonModal trigger="open-date-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("project_start")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        value={startDate}
                                                        presentation="date"
                                                        cancelText={t("cancel")}
                                                        doneText={t("done")}
                                                        onIonChange={ev => setStartDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>         
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                               

                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}
                                        id="open-dated-input">
                                        <IonLabel position="stacked" color="secondary" className="input-label">
                                        {t("project_end")}
                                        </IonLabel>
                                        <IonInput value={formatDate(endDate)} readonly={true} className="input-field"/>
                                        <IonModal trigger="open-dated-input" showBackdrop={true} 
                                            initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                            <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                <IonToolbar>
                                                    <IonTitle>{t("project_end")}</IonTitle>
                                                    
                                                </IonToolbar>
                                                <IonDatetime
                                                    value={endDate}
                                                    presentation="date"
                                                    cancelText={t("cancel")}
                                                    doneText={t("done")}
                                                    onIonChange={ev => setEndDate(ev.detail.value!)}
                                                    showDefaultButtons={true}
                                                    size="cover"
                                                />
                                            </IonContent>
                                            
                                        </IonModal>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>        
                                               
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_task")}</IonButton>

                            <IonButton onClick={() => setShowAlert(true)}
                                className="fifth-button"
                                fill="clear">{t("delete")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default EditTask;
