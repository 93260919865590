import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, time, trash } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import { useParams } from 'react-router-dom';

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface Student {
    _id: string,
    name: string,
    isActive: boolean
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

interface Attendance {
    _id: string,
    status: string,
    createdAt: Date
}

interface ParamTypes {
    student?: string
}

const StudentLog: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {student} = useParams < ParamTypes > ();
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [attendance, setAttendance] = useState < Attendance[] > ([]);
    const [students, setStudents] = useState < Student[] > ([]);
    const [classStudent, setClassStudent] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [attendanceID, setAttendanceID] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);

    const loadItems = async () => {

        if (classStudent === "")
        {
            setMessage(t("select_student"));
            setIserror(true);
            return;
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip+30;

        try {
            const result = await api.get(`/attendances/getByStudent/${classStudent}?skip=${limit}`);

            if(result.data)
            {
                setAttendance([...attendance, ...result.data]);
            }
                
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
            else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
      };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const handleDelete = () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
      const deleteTrip = () => {
          return new Promise((resolve, reject) => {
  
              api.delete(`/attendances/${attendanceID}`).then(res => {
      
                  return resolve(res.data);
                  
                }).catch(err => reject(err));
  
          });
        }
  
        setShowLoading(true);
        deleteTrip()
        .then(data => {

            let attends = [...attendance];
            let index = attends.findIndex((val) => val._id === attendanceID);
            if (index > -1) {
                attends.splice(index, 1); // 2nd parameter means remove one item only
            }
            setAttendance(attends);

          setPass(t("entry_deleted"));
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage(t("something_went_wrong"));
  
          setIserror(true);
          setShowLoading(false);
        });
        
    }

    const handleSession = (sessionName : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClassi("");
        setClassStudent("");
        setStudents([]);
    }

    const handleClassi = (classID : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/getByClass/${classID}`);
                setStudents(studs.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchUp();
        setClassi(classID);
        setClassStudent("");
        setAttendance([]);
    }

    const handleStudent = (studentID : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const attend = await api.get(`/attendances/getByStudent/${studentID}`);
                setAttendance(attend.data);
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchUp();
        setClassStudent(studentID);
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                {
                    const classy = await api.get(`/classes/getByTeacher/${authInfo.user._id}`);
                    setClasses(classy.data);

                    if (student) {
                        const attend = await api.get(`/attendances/getByStudent/${student}`);
                        setAttendance(attend.data);
                        const stud = await api.get(`/students/${student}`);
                        setClassi(stud.data.classID);
                        const studs = await api.get(`/students/getByClass/${stud.data.classID}`);
                        setStudents(studs.data);
                        setClassStudent(student);
                    }
                }
                if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                {
                    const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                    setSessions(res.data);

                    if (student) {
                        
                        const attend = await api.get(`/attendances/getByStudent/${student}`);
                        setAttendance(attend.data);

                        const stud = await api.get(`/students/${student}`);
                        const classInfo = await api.get('/classes/' + stud.data.classID);
                        setSession(classInfo.data.sessionID);

                        const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${classInfo.data.sessionID}`);
                        setClasses(query.data);
                        setClassi(stud.data.classID);
                        const studs = await api.get(`/students/getByClass/${stud.data.classID}`);
                        setStudents(studs.data);
                        setClassStudent(student);
                    }
                    else
                    {
                        setClasses([]);
                        setClassi("");
                        setStudents([]);
                        setAttendance([]);
                        setClassStudent("");
                    }
                    
                    setSkip(0);

                    
                }
                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }, [authInfo, student]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/student`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("entry_exit_logs")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("entry_exit_logs")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(true)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                    <IonAlert
                        isOpen={showAlert}
                        onDidDismiss={() => setShowAlert(false)}
                        header={t("confirm")}
                        message={t("delete_entry")}
                        buttons={[
                            {
                            text: t("no"),
                            role: 'cancel',
                            cssClass: 'secondary',
                            },
                            {
                            text: t("yes"),
                            handler: () => {
                                handleDelete();
                            }
                            }
                        ]}
                        />
                        
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding max-xl">
                    <IonRow>
                    {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic"))) && (
                       <IonCol sizeLg="4" size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("class_session")}</IonLabel>
                                            <IonSelect value={session}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => handleSession(e.detail.value)
                                                }>

                                                    {sessions.map((session) => 
                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                    )}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    )}
                        <IonCol sizeLg="4" size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {(authInfo.institute.type === "school") ? t("class") : t("batch")}</IonLabel>
                                            <IonSelect value={classi}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                    onIonChange={
                                                        (e) => handleClassi(e.detail.value)
                                                }>
                                                     
                                                    {classes.map((classy) => (
                                                        (classy.isActive) &&
                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name} ({classy.year})</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol sizeLg="4" size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">                   
                                    
                                    <IonItem
                                    detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_student")}</IonLabel>
                                        <IonSelect value={classStudent}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    className="input-field text-capitalize"
                                                    placeholder={t("select_student")}
                                                    onIonChange={
                                                        (e) => e.detail.value && handleStudent(e.detail.value)
                                                }>
                                                {students.map((student) => 
                                                    (student.isActive) && 
                                                    (<IonSelectOption key={student._id} value={`${student._id}`} className="text-capitalize">{student.name}</IonSelectOption>)
                                                )}
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding max-xl">
                    
                    <IonRow className="mt-30">
                        <IonCol>
                            <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                {t("students_log")}
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    {(attendance.length > 0) && (
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                          <IonList className="ion-no-padding">
                             {(attendance.map((attend, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={attend._id}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                           >
                                            <IonIcon slot="start"
                                                icon={time}
                                                color={
                                                    ((attend.status === 'in') || (attend.status === 'entry') || (attend.status === 'reached')) ? 'success' : ((attend.status === 'picked') || (attend.status === 'left')) ? 'warning' : (attend.status === 'custody') ? 'tertiary' : 'danger'
                                                }/>
                                                <IonLabel className="list-label text-capitalize ion-text-wrap">
                                                    <h3>
                                                    {(attend.status === 'out') && (
                                                        <p>{t("exit_at")} {formatDateTime(attend.createdAt)}</p>
                                                    )}
                                                    {(attend.status === 'in') && (
                                                        <p>{t("entry_at")} {formatDateTime(attend.createdAt)}</p>
                                                    )}
                                                     {(attend.status === 'entry') && (
                                                        <p>{(authInfo.institute.type === "school") ? t("entered_school_on") : t("entered_insti_on")} on {formatDate(attend.createdAt)}</p>
                                                    )}
                                                    {(attend.status === 'custody') && (
                                                        <p>{t("custody_with_parent")} on {formatDateTime(attend.createdAt)}</p>
                                                    )}
                                                    {(attend.status === 'picked') && (
                                                        <p>{t("picked_by_transport")} on {formatDateTime(attend.createdAt)}</p>
                                                    )}
                                                    {(attend.status === 'reached') && (
                                                        <p>{t("reached_by_transport")} on {formatDate(attend.createdAt)}</p>
                                                    )}
                                                    {(attend.status === 'left') && (
                                                        <p>{t("left_by_transport")} on {formatDateTime(attend.createdAt)}</p>
                                                    )}
                                                    {(attend.status === 'dropped') && (
                                                        <p>{t("dropped_by_transport")} on {formatDate(attend.createdAt)}</p>
                                                    )}
                                                    </h3>
                                                </IonLabel>
                                                
                                                <IonButton slot="end"
                                                    onClick={() => {
                                                        setAttendanceID(attend._id);
                                                        setShowAlert(true);
                                                    }}
                                                    >
                                                    <IonIcon
                                                     icon={trash}  />
                                                </IonButton>
                                        </IonItem>
                                    )
                                }))
                                } 
                            </IonList>
                        </IonCol>
                    </IonRow>
                    )}
                     {(attendance.length === 0) && ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_records")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )} 

                        {(attendance.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}>
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                        }
                        
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default StudentLog;
