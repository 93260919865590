import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, chatboxEllipses } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../../components/usePageUnloadHandler';

interface Chat {
    _id: string,
    title: string,
    type: string,
    employee?: ChatEmployee,
    employees?: string[],
    messages: Message[],
    updatedAt: Date
}

interface Message {
    timestamp: Date
}

interface ChatEmployee {
    employeeID: string,
    name: string
}

interface Employee {
    _id: string,
    userInfo: UserInfo,
    isActive: boolean
}

interface UserInfo {
    userID: string,
    name: string
}

interface Department {
    _id: string,
    name: string
}
interface ParamTypes {
    employeeID?: string
}

const EmployeeChats: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {employeeID} = useParams < ParamTypes > ();
    const [chats, setChats] = useState < Chat[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [selectedEmployee, setSelectedEmployee] = useState <string> ("");
    const [employees, setEmployees] = useState < Employee[] > ([]);
    const [departments, setDepartments] = useState < Department[] > ([]);
    const [department, setDepartment] = useState < string > ("");
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem) {
            setScrollPosition(elem.scrollTop);
        }
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleDepartment = (departmentID: string) => {

        setDepartment(departmentID);
        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/employees/getByDepartment/${departmentID}`);
                setEmployees(studs.data);
            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/employees/getAllInstitute/${authInfo.user.instituteID}`);
                setEmployees(studs.data);
            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        if (departmentID === 'all')
        {
            fetchAll();
        }
        else
        {
            fetchUp();
        }

        setSelectedEmployee("");
        setChats([]);
        
    }

    const handleEmployee = (employeeID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/staffChats/getByEmployee/${employeeID}`);
                setChats(result.data.chats);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setSelectedEmployee(employeeID);
    }

    const loadItems = async () => {

        if (!selectedEmployee || selectedEmployee === "") {
            setMessage(t("select_employee"));
            setIserror(true);
            return;
        }

        let limit = skip+30;

        try {
            const result = await api.get(`/staffChats/getByEmployee/${selectedEmployee}?skip=${limit}`);
            if(result.data.chats && result.data.chats.length)
            {
                setChats([...chats, ...result.data.chats]);
                setSkip(limit);
            }
           

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { chats, employees, departments, selectedEmployee, department, skip, scrollPosition } = JSON.parse(storedState);
                // Data is present in local storage, use it to initialize state
                setChats(chats);
                setSkip(skip);
                setEmployees(employees);
                setDepartments(departments);
                setSelectedEmployee(selectedEmployee);
                setDepartment(department);
               
                setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
            } 
            else 
            {

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {

                        const res = await api.get(`/departments/getAllInstitute/${authInfo.user.instituteID}`);
                        setDepartments(res.data);
                            
                            if (employeeID)
                            {
                                const stud = await api.get(`/employees/${employeeID}`);  
                                setDepartment(stud.data.departmentInfo.departmentID);
            
                                const studs = await api.get(`/employees/getByDepartment/${stud.data.departmentInfo.departmentID}`);
                                setEmployees(studs.data);
                                setSelectedEmployee(stud.data.userInfo.userID);
            
                                const result = await api.get(`/staffChats/getByEmployee/${stud.data.userInfo.userID}`);
                                setChats(result.data.chats);
                            }
                            else
                            {
                                setDepartment("");
                                setEmployees([]);
                                setSelectedEmployee("");
                                setChats([]);
                            }
                            setSkip(0);

                    } catch (error: any) {
                        setMessage(error.response?.data.message || t("something_went_wrong"));
                        setIserror(true);
                    } finally {
                        setShowLoading(false);
                    }
                    
                };

                fetchUp();
            }
      
        setInitialRender(false);

    }, [authInfo, employeeID, refreshed, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
                chats,
                skip,
                employees,
                selectedEmployee,
                departments,
                department,
                scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        
        }
    }, [chats, skip, employees, selectedEmployee, departments, department, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/communication`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_emp_chats")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_emp_chats")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding max-xl">
                    <IonRow>
      
                        <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("select_department")}</IonLabel>
                                            <IonSelect value={department}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_department")}
                                                    onIonChange={
                                                        (e) => handleDepartment(e.detail.value)
                                                }>
                                                     <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {departments.map((dep) => (
                                                        (<IonSelectOption key={dep._id} value={`${dep._id}`}>{dep.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>

                        <IonCol size="6">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">                   
                                    
                                    <IonItem
                                    detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_emp")}</IonLabel>
                                        <IonSelect value={selectedEmployee}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    className="input-field text-capitalize"
                                                    placeholder={t("select_emp")}
                                                    onIonChange={
                                                        (e) => e.detail.value && handleEmployee(e.detail.value)
                                                }>
                                                    {employees.map((employee) => (
                                                        (employee.isActive) &&
                                                        (<IonSelectOption key={employee._id} value={`${employee.userInfo.userID}`} className='text-capitalize'>{employee.userInfo.name}</IonSelectOption>)
                                                    ))}
                                                 
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                
                <IonGrid className="ion-no-padding mb-60">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">
                            <IonCol sizeLg="4" size="8" className='table-heading'>
                                {t("title")}
                            </IonCol>

                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                                {t("type")}
                            </IonCol>
                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                                {t("employee")}
                            </IonCol>
                            <IonCol sizeLg="2" size="3" className='table-heading'>
                                {t("updated_at")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={chatboxEllipses}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>

                            {(chats.length > 0) ? (chats.map((chat, i) => {
                                return (
                                    <IonRow key={chat._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={
                                        () => history.push(`/app/chat/${chat._id}`)
                                    }>
                                        
                                            <IonCol sizeLg="4" size="8" className='table-field' >
                                                <IonRow>
                                                    <IonCol>
                                                        {chat.title}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field-1 text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel>
                                                        {chat.employee ? chat.employee.name : `${t("employees")}: ${chat.employees!.length}`} &#8226; {chat.type}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>
                                                
                                            </IonCol>

                                            <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                {chat.type}
                                            </IonCol>
                                            <IonCol sizeLg="3" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                {chat.employee ? chat.employee.name : `${chat.employees!.length}`}
                                            </IonCol>
                                            <IonCol sizeLg="2" size="3" className='table-field'>
                                                {formatDate(chat.updatedAt)}
                                            </IonCol>

                                            <IonCol size="1" className='table-field'>
                                                <IonBadge className=''>{chat.messages.length+1}</IonBadge>
                                            </IonCol>
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_chats")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                    </div>

                    {(chats.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                                >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                    </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow className="ion-padding-vertical">
                        <IonCol className=''>
                            <IonButton 
                                onClick={
                                    () => history.push(`/app/chats/new`)
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("make_inquiry")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default EmployeeChats;