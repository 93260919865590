import {
    ActionSheetButton,
    IonActionSheet,
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSkeletonText,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { accessibilityOutline, accessibilitySharp, calendarOutline, checkboxOutline, chevronDownOutline, closeCircle, closeOutline, createOutline, playCircle } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import Chart from "react-apexcharts";

const tasks = ["Maths Test", "Social Science Test", "English Test", "Science Test"];

const options1 = {
    colors : ['#333333', '#DDDDDD'],
    labels: ['Present', 'Absent'],
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
      },
    stroke: {
        show: false
      },
      plotOptions: {
        pie: {
            donut: {
                labels: {
                  show: false
                }},
          expandOnClick: false,
          
        }
      }
};

interface Student {
    _id: string,
    classID: string,
    name: string
}

const ChildStats: React.FC = () => {
    const {authInfo} = useAuth()!;

    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showActionSheet, setShowActionSheet] = useState < boolean > (false);
    const [students, setStudents] = useState < ActionSheetButton[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [student, setStudent] = useState < Student > ();

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const swapStudent = (selected: string) => {

        setShowLoading(true);

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upStudent = (selected: string) => {
            return new Promise((resolve, reject) => {
             
                api.post('/students/setDefault/', { 'parentID': authInfo.user._id, 'studentID': selected }).then(res => {
                    return resolve(res.data.name);
                  }).catch(err => reject(err)); 
            
            });
          }
      
        upStudent(selected)
          .then((data) => {
             
            setShowLoading(false);
            setShowActionSheet(false);
            setRefreshed(Math.random());   
          })
          .catch(error => {
              if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Please try again afer sometime.');
            setIserror(true);
            setShowLoading(false);
          });
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const student = await api.get(`/students/getByParent/${authInfo.user._id}`);
                setStudent(student.data);

                const students = await api.get(`/students/getAllByParent/${authInfo.user._id}`);

                if(students.data.length > 1)
                {
                    let cObjects: ActionSheetButton[] = [];

                    for (let i=0; i < students.data.length; i++)
                    {
                        let cObject: ActionSheetButton;
                        if (students.data[i]._id !== student.data._id)
                        {
                            if (students.data[i].isActive === true)
                            {
                                cObject = {
                                    text: students.data[i].name,
                                    icon: !isPlatform('ios') ? accessibilityOutline : undefined,
                                    handler: () => swapStudent(students.data[i]._id)
                                }
                                cObjects.push(cObject);

                            }
                        }
                    }
                        cObjects.push({
                            text: 'Cancel',
                            icon: !isPlatform('ios') ? closeOutline : undefined,
                            role: 'cancel'
                        });
    
                        setStudents(cObjects);
                }

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app" />
                    </IonButtons>
                    <IonTitle color="secondary">Child Stats</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Child Stats</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>


                <IonActionSheet isOpen={showActionSheet}
                    onDidDismiss={
                        () => setShowActionSheet(false)
                    }
                    cssClass='swap-sheet'
                    subHeader={`Currently Selected - ${student?.name}`}
                    header='Switch Student'
                    buttons={students} 
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-no-padding">
                        <IonCol>
                        <IonToolbar className="pl-16" color="secondary" mode="ios">
                            {students && (students.length > 1) && (
                            <IonIcon slot='start' icon={accessibilitySharp} color="primary" />
                            )}
                            <IonTitle color="secondary" className='parent-home-title'>
                            {
                                student ? (student.name) : 
                                ((<IonSkeletonText animated={true} style={{ 'width': '140px', 'marginLeft': '20px' }}></IonSkeletonText>))
                            }
                            </IonTitle>
                            {students && (students.length > 1) && (
                            <IonButtons slot="end">
                                    <IonButton onClick={() => setShowActionSheet(true)}><IonIcon icon={chevronDownOutline} color="primary" /></IonButton>
                            </IonButtons>
                            )}
                        </IonToolbar> 
                        </IonCol>
                    </IonRow>
                </IonGrid>
                
                <IonGrid className='mb-60'>
                    <IonRow className="ion-padding ion-margin-top">
                        <IonCol size="6">
                            <IonRow>
                                <IonCol>
                                    <IonCard className="stat-card-1 ion-no-margin">
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol size="3">
                                                        <IonIcon 
                                                            icon={checkboxOutline}
                                                            className='stat-icon'
                                                        />
                                                </IonCol>
                                                <IonCol size="9">
                                                        <IonText className='stat-head'>32 Tests</IonText>
                                                        <p className='stat-subhead'>Completed</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard className="stat-card-2 ion-no-margin">
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol size="3">
                                                    <IonIcon 
                                                            icon={createOutline}
                                                            className='stat-icon'
                                                        />
                                                </IonCol>
                                                <IonCol size="9">
                                                        <IonText className='stat-head'>4 Tasks</IonText>
                                                        <p className='stat-subhead'>Pending</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="6" className='mt-05'>
                            <IonCard className="stat-card-3 ion-no-margin">
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>
                                            <Chart options={options1} series={[75,25]} type="donut" width={120} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="3">
                                            <IonIcon 
                                                    icon={calendarOutline}
                                                    className='stat-icon-1'
                                                />
                                        </IonCol>
                                        <IonCol size="9">
                                                
                                            <IonText className='stat-head-1'>75%</IonText>
                                            <p className='stat-subhead-1'>Attendance</p>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-vertical ion-margin-bottom">
                        <IonCol>
                                <IonTitle className="title-heading">
                                    Pending Tasks
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-no-margin ion-no-padding">
                        <IonCol className="ion-no-margin ion-no-padding">
                            <IonCard className="ion-no-margin ion-no-padding">
                                <IonCardContent className="ion-no-margin ion-no-padding">
                                            <IonList>
                                            {tasks.map((task, i) => {
                                                return (
                                                    <IonItem detail={false}
                                                        lines={i === (tasks.length-1) ? 'none' : 'inset'}
                                                        button={false}
                                                        key={i}>
                                                            
                                                                    <IonIcon
                                                                    icon={createOutline}
                                                                    color="success"
                                                                    slot="start"
                                                                    />
                                                                
                                                           <IonLabel>{task}</IonLabel>
                                                            <IonButtons slot="end">
                                                                <IonButton> 
                                                                    <IonIcon
                                                                    icon={playCircle}
                                                                    color="secondary"
                                                                    className='tran-icon'
                                                                    />
                                                                </IonButton>
                                                                <IonButton> 
                                                                    <IonIcon
                                                                    icon={closeCircle}
                                                                    color="danger"
                                                                    className='tran-icon'
                                                                    />
                                                                </IonButton>
                                                            </IonButtons>
                                                            
                                                    </IonItem>
                                                )
                                                
                                                })
                                            }
                                            </IonList>
               
                                </IonCardContent>
                            </IonCard>
                         </IonCol>
                    </IonRow>
                    
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default ChildStats;
