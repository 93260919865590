import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, checkboxOutline, informationCircle, medical } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useAuth} from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface Incident {
    _id: string,
    type: string,
    createdAt: Date
}

interface Student {
    _id: string,
    instituteID: string,
    name: string,
    dob: Date,
    gender?: string,
    bloodType?: string,
    isDisabled?: boolean,
    disabilityInfo?: string,
    medicalInfo?: string,
    isActive: boolean
}

interface ParamTypes {
    studentID: string
}

const Incidents: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {studentID} = useParams < ParamTypes > ();
    const [incidents, setIncidents] = useState < Incident[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [current, setCurrent] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [duration, setDuration] = useState < string > ("three");
    const [selectedStudent, setSelectedStudent] = useState <Student> ();

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const formatDated = (value: Date) => {

            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
     
    };

    
    const handleSubmit = () => {

        if(!selectedStudent)
        {
            setMessage(t("student_mandatory"));
            setIserror(true);
            return;
        }

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        if (toDOB === "" && (!duration || duration === "")) {
            setMessage(t("med_duration_mandatory"));
            setIserror(true);
            return;
        }

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/incidents/getByStudent/${selectedStudent._id}`, {duration, toDOB, fromDOB});
                setIncidents(result.data.incidents);
                setTotal(result.data.total);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
    }

      const loadItems = async () => {

        let limit = skip + 30;

        if(!selectedStudent)
        {
            setMessage(t("student_mandatory"));
            setIserror(true);
            return;
        }
       
        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        if (toDOB === "" && (!duration || duration === "")) {
            setMessage(t("duration_mandatory"));
            setIserror(true);
            return;
        }

        try {
            const result = await api.post(`/incidents/getByStudent/${selectedStudent._id}?skip=${limit}`, {duration, toDOB, fromDOB});

            if(result.data.incidents && result.data.incidents.length)
            {
                setIncidents([...incidents, ...result.data.incidents]);
                setSkip(limit);
            }
                

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const stud = await api.get(`/students/${studentID}`);
                setSelectedStudent(stud.data);
                const result = await api.post(`/incidents/getByStudent/${studentID}`, {duration: "three"});
                setIncidents(result.data.incidents);
                setTotal(result.data.total);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchData();

    }, [authInfo, studentID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_medical_records")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_medical_records")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol sizeLg="6" size="12" className="ion-padding-horizontal">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("medical_details")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            {selectedStudent ? (
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard className='medical-card'>
                                        <IonCardHeader>
                                            <IonCardTitle className='mt-10'>
                                                {t("student_data")}
                                                <IonIcon 
                                                className='ion-float-right'
                                                color="danger"
                                                size="large"
                                                icon={medical}/>
                                            </IonCardTitle>
                                            
                                        </IonCardHeader>
                                        <IonCardContent className="ion-no-padding">
                                            <IonCardSubtitle className='ion-padding-horizontal border-bottom-sec text-bold'>{t("basic_info")}</IonCardSubtitle>
                                            <IonRow className="ion-padding text-dark">
                                                <IonCol size="5" className='text-bold'>
                                                    {t("student_name")}:<br/>
                                                    {t("student_dob")}:<br/>
                                                    {t("student_blood")}:
                                                </IonCol>
                                                <IonCol size="7" className='text-capitalize'>
                                                    {selectedStudent.name}<br/>
                                                    {formatDated(selectedStudent.dob)}<br/>
                                                    {selectedStudent.bloodType}
                                                </IonCol>
                                            </IonRow>
                                            {(selectedStudent.isDisabled) && (
                                            <>
                                                <IonCardSubtitle className='ion-padding-horizontal border-bottom-sec text-bold'>{t("disability_info")}</IonCardSubtitle>
                                                <IonRow className="ion-padding text-dark">
                                                    <IonCol>
                                                        {selectedStudent.disabilityInfo}
                                                    </IonCol>
                                                </IonRow>
                                            </>
                                            )}

                                                <IonCardSubtitle className='ion-padding-horizontal border-bottom-sec text-bold'>{t("medical_condition")}</IonCardSubtitle>
                                                <IonRow className="ion-padding text-dark">
                                                    <IonCol>
                                                        {selectedStudent.medicalInfo && (selectedStudent.medicalInfo !== "") ? selectedStudent.medicalInfo : t("no_medical_info")}
                                                    </IonCol>
                                                </IonRow>

                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            ) : (
                            <IonRow className="ion-margin-top">
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_medical")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}
                        </IonCol>
                        <IonCol sizeLg="6" size="12" className="ion-padding-horizontal">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                        {t("view_medical_records")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                                <IonRow className="ion-margin-top">
                                    <IonCol size="6">
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        id="open-from-input">
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("from_date")}
                                                        </IonLabel>
                                                        <IonInput value={formatDate(fromDate)} className="input-field" readonly={true} />
                                                        <IonModal trigger="open-from-input" showBackdrop={true} 
                                                            initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                            <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                                <IonToolbar>
                                                                    <IonTitle>{t("from_date")}</IonTitle>
                                                                    
                                                                </IonToolbar>
                                                                <IonDatetime
                                                                 cancelText={t("cancel")}
                                                                 doneText={t("done")}
                                                                    value={fromDate}
                                                                    presentation="date"
                                                                    onIonChange={ev => setFromDate(ev.detail.value!)}
                                                                    showDefaultButtons={true}
                                                                    size="cover"
                                                                />
                                                            </IonContent>
                                                            
                                                        </IonModal>
                                                    </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        id="open-to-input">
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("to_date")}
                                                        </IonLabel>
                                                        <IonInput value={formatDate(toDate)} className="input-field" readonly={true} />
                                                        <IonModal trigger="open-to-input" showBackdrop={true} 
                                                            initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                            <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                                <IonToolbar>
                                                                    <IonTitle>{t("to_date")}</IonTitle>
                                                                    
                                                                </IonToolbar>
                                                                <IonDatetime
                                                                 cancelText={t("cancel")}
                                                                 doneText={t("done")}
                                                                    value={toDate}
                                                                    presentation="date"
                                                                    onIonChange={ev => setToDate(ev.detail.value!)}
                                                                    showDefaultButtons={true}
                                                                    size="cover"
                                                                />
                                                            </IonContent>
                                                            
                                                        </IonModal>
                                                    </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="6">
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                                            {t("duration")}
                                                        </IonLabel>
                                                        <IonSelect value={duration}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                            className="input-field"
                                                            placeholder={t("duration")}
                                                            onIonChange={
                                                                (e) => setDuration(e.detail.value)
                                                            }>
                                                            <IonSelectOption value="three">{t("three")}</IonSelectOption>
                                                            <IonSelectOption value="six">{t("six")}</IonSelectOption>
                                                            <IonSelectOption value="twelve">{t("twelve")}</IonSelectOption> 
                                                            <IonSelectOption value="current">{t("current_fy")}</IonSelectOption>
                                                            <IonSelectOption value="last">{t("last_fy")}</IonSelectOption> 
                                                        </IonSelect>
                                                    </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol size="6" className="ion-padding">
                                        <IonButton onClick={() => handleSubmit() }
                                            color="primary"
                                            className='mt-15 ion-text-wrap'>{t("view_medical_records")}
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                <div className="ion-table">

                                <IonRow className="table-title ion-padding ion-margin-top">

                                    <IonCol size="6" className='table-heading'>
                                        <IonIcon 
                                            icon={checkboxOutline}
                                            color="light"
                                            className='table-icon'
                                        />
                                        <IonText className='ion-margin-start'>{t("date")}</IonText>
                                        
                                    </IonCol>

                                    <IonCol size="5" className='table-heading'>
                                        {t("type")}
                                    </IonCol>
                                    <IonCol size="1" className='ion-text-right ion-padding-end'>
                                        <IonIcon 
                                            icon={informationCircle}
                                            color="light"
                                            className='table-icon'
                                            />
                                    </IonCol>
                                </IonRow>
                                <IonRadioGroup value={current} onIonChange={
                                                    (e) => setCurrent(e.detail.value!)
                                            }>

                                {(incidents.length > 0) ? (incidents.map((incident, i) => {
                                    return (
                                        <IonRow key={incident._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                        onClick={
                                            () => setCurrent(incident._id)
                                        }>
                                            
                                                <IonCol size="6" className='table-field' >
                                                    <IonRadio value={incident._id} className='table-icon' color="secondary" />
                                                    <IonText className='ion-margin-start'>{formatDated(incident.createdAt)}</IonText>
                                                    
                                                </IonCol>
                                                <IonCol sizeLg="5" className='table-field'>
                                                    {incident.type}
                                                </IonCol>
                                                <IonCol size="1" className='ion-text-right ion-padding-end'>
                                                    <IonIcon 
                                                        icon={informationCircle}
                                                        color="dark"
                                                        className='table-icon mt-10'
                                                        onClick={
                                                            () => history.push(`/app/incident/${incident._id}`)
                                                        }
                                                    />
                                                    
                                                </IonCol>
                                            
                                        </IonRow>
                                    )
                                })) : ( 
                                <IonRow>
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent>
                                                <IonItem lines="none">
                                                    <IonIcon icon={alertCircleOutline}
                                                        slot="start"
                                                        color="danger" />
                                                    <IonLabel className="list-title"><h3>{t("no_incidents")}</h3></IonLabel>

                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                )
                                } 
                                </IonRadioGroup>
                                </div>

                                {(incidents.length > 0) &&
                                <IonRow>
                                    <IonCol>
                                        <IonInfiniteScroll
                                            onIonInfinite={async (ev) => {
                                                await loadItems();
                                                ev.target.complete();
                                            }}
                                        >
                                            <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                        </IonInfiniteScroll>
                                    </IonCol>
                                </IonRow>
                                }

                            </IonCol>
                        </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                            <IonCol className="ion-padding-start footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${incidents.length} ${t("of")} ${total}`} 
                        </IonCol>
                        </IonRow>
                            <IonRow className="ion-padding-vertical overflow-x">
                                <IonCol className='min-lg'>

                            <IonButton 
                                onClick={
                                    () => {
                                        if (current === "")
                                        {
                                            setMessage(t("select_incident"));
                                            setIserror(true);
                                        }
                                        else
                                        {
                                            history.push(`/app/incident/${current}`)
                                        }

                                    }
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("view_details")}</IonButton>

                        {(authInfo.user.role !== 'Teacher') && (authInfo.user.role !== 'Academic') && (
                            <IonButton 
                                onClick={
                                    () => {
                                        if (!selectedStudent)
                                        {
                                            setMessage(t("select_student"));
                                            setIserror(true);
                                        }
                                        else
                                        {
                                            history.push(`/app/medical/incidents/add/${selectedStudent._id}`)
                                        }

                                    }
                                }
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("record_an_incident")}</IonButton>
                        )}

                           <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("export_excel")}</IonButton>

                        </IonCol>
                    </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Incidents;