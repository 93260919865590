import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, business, construct } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';


interface Institute {
    _id: string,
    name: string,
    isActive: boolean
}

const Institutes: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const [institutes, setInstitutes] = useState < Institute[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
   

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/institutes`);
                setInstitutes(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/home" />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined}  color="secondary">Institutes</IonTitle>
                   {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Institutes</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>
                

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-padding">
                        <IonCol size="8" offset="2">
                            <IonCard className="action-card" routerLink={`/app/institutes/add`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-text-center ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <img alt="Add New Institute" src="assets/images/institute.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="action-title">
                                                Add New Institute
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Institutes
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow className="ion-margin-top">
                        <IonCol>
                          <IonList className='ion-no-padding'>
                             {(institutes.length > 0) ? (institutes.map((institute) => {
                                    return (
                                        <IonItem detail={true}
                                            detailIcon={construct}
                                            lines='full'
                                            button={true}
                                            key={institute._id}
                                            onClick={
                                                () => history.push(`/app/institute/${institute._id}`)
                                            }>
                                            <IonIcon slot="start"
                                                icon={business}
                                                color={
                                                    institute.isActive ? 'success' : 'danger'
                                                }/>
                                            <IonLabel className="list-label">
                                                <h3>{
                                                    institute.name
                                                }</h3>
                                            </IonLabel>
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start"
                                        color="danger" />
                                        <IonLabel className="list-label">
                                                <h3>No institutes</h3></IonLabel>

                                    </IonItem>
                                )
                                } </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Institutes;
