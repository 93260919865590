import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, chatboxEllipses, chatbubbles, documentAttach, informationCircle, medical } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../components/usePageUnloadHandler';

interface Inquiry {
    status: string;
    _id: string,
    title: string,
    type: string,
    student?: Student,
    students?: string[],
    messages: Message[],
    createdAt: Date
}

interface Message {
    timestamp: Date
}

interface Student {
    studentID: string,
    name: string
}

interface Session {
    _id: string,
    name: string,
    isActive: boolean
}

interface ClassInterface {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}
interface ClassStudent {
    _id: string,
    name: string,
    isActive: boolean
}

interface ParamTypes {
    studentID?: string
}

const StudentInquiries: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {studentID} = useParams < ParamTypes > ();
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [skip, setSkip] = useState < number > (0);
    const [selectedStudent, setSelectedStudent] = useState <string> ("");
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [session, setSession] = useState < string > ("");
    const [classes, setClasses] = useState < ClassInterface[] > ([]);
    const [classi, setClassi] = useState < string > ("");
    const [students, setStudents] = useState < ClassStudent[] > ([]);
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem)
        {
            setScrollPosition(elem.scrollTop);
        }
    };

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleSession = (sessionName : string) => {

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${sessionName}`);
                setClasses(result.data);
                setShowLoading(false);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
        setSession(sessionName);
        setClassi("");
        setSelectedStudent("");
        setStudents([]);
        setInquiries([]);
    }

    const handleClassi = (classID : string) => {

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const studs = await api.get(`/students/getByClass/${classID}`);
                setStudents(studs.data);
                setShowLoading(false);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        fetchUp();
        setClassi(classID);
        setSelectedStudent("");
        setInquiries([]);
    }

    const handleStudent = (studentID : string) => {

        if (!classi || classi === "") {
            setMessage(t("select_class"));
            setIserror(true);
            return;
        }

        setSelectedStudent(studentID);

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/inquiries/getByStudent/${studentID}`);
                setInquiries(result.data);

                setShowLoading(false);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        const fetchAll = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/inquiries/getByClass/${classi}`);
                setInquiries(result.data);

                setShowLoading(false);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
        };

        if (studentID === "all")
        {
            fetchAll();
        }
        else
        {
            fetchUp();
        }

    }

    const loadItems = async () => {

        if (!classi || classi === "") {
            setMessage(t("select_class"));
            setIserror(true);
            return;
        }

        if (!selectedStudent || selectedStudent === "") {
            setMessage(t("student_mandatory"));
            setIserror(true);
            return;
        }

        let limit = skip+30;

        try {

            if (selectedStudent === "all")
            {
                const result = await api.get(`/inquiries/getByClass/${classi}?skip=${limit}`);
                if(result.data && result.data.length)
                {
                    setInquiries([...inquiries, ...result.data]);
                    setSkip(limit);
                }
            }
            else
            {
                const result = await api.get(`/inquiries/getByStudent/${selectedStudent}?skip=${limit}`);
                if(result.data && result.data.length)
                {
                    setInquiries([...inquiries, ...result.data]);
                    setSkip(limit);
                }
            }

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        } 
        
    }

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { inquiries, classes, sessions, students, classi, session, skip, selectedStudent, scrollPosition } = JSON.parse(storedState);
                // Data is present in local storage, use it to initialize state
                setInquiries(inquiries);
                setClasses(classes);
                setSessions(sessions);
                setStudents(students);
                setClassi(classi);
                setSession(session);
                setSelectedStudent(selectedStudent);
                setSkip(skip);
            
                setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
            } 
            else 
            {

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {

                        if ((authInfo.user.role === 'Teacher') || (authInfo.user.role === 'Employee' && !authInfo.user.roles.includes("Academic") && authInfo.user.roles.includes("Teacher")))
                        {
                            if (studentID)
                            {
                                setSelectedStudent(studentID);
                                const result = await api.get(`/inquiries/getByStudent/${studentID}`);
                                setInquiries(result.data);
                            }
                        }
                        if ((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic")))
                        {
                            const res = await api.get(`/sessions/getAllInstitute/${authInfo.user.instituteID}`);
                            setSessions(res.data);
                            
                            if (studentID)
                            {
                                const stud = await api.get(`/students/${studentID}`);
                            
                                const classInfo = await api.get('/classes/' + stud.data.classID);
                                setSession(classInfo.data.sessionID);
            
                                const query = await api.get(`/classes/allInstitute/${authInfo.user.instituteID}/${classInfo.data.sessionID}`);
                                setClasses(query.data);
                                setClassi(stud.data.classID);
                                
                                const studs = await api.get(`/students/getByClass/${stud.data.classID}`);
                                setStudents(studs.data);
                                setSelectedStudent(studentID);
            
                                const result = await api.get(`/inquiries/getByStudent/${studentID}`);
                                setInquiries(result.data);
                            }
                            else
                            {
                                setSession("");
                                setClasses([]);
                                setClassi("");
                                setStudents([]);
                                setSelectedStudent("");
                                setInquiries([]);
                            }
                            setSkip(0);
                        }

                    } catch (error: any) {
                        setMessage(error.response?.data.message || t("something_went_wrong"));
                        setIserror(true);
                    } finally {
                        setShowLoading(false);
                    }
                    
                };

                fetchUp();
            }

        setInitialRender(false);

    }, [authInfo, studentID, refreshed, history.action, history.location.pathname]);

     // Save state to local storage whenever it changes
     useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
                inquiries,
                classes,
                sessions,
                students,
                classi,
                session,
                selectedStudent,
                skip,
                scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));

        
        }
    }, [inquiries, classes, sessions, students, skip, selectedStudent, classi, session, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/communication`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("student_inquiries")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("student_inquiries")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
              
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}
                
                {((authInfo.user.role === 'Super') || (authInfo.user.role === 'Admin') || (authInfo.user.role === 'Academic') || (authInfo.user.role === 'Employee' && authInfo.user.roles.includes("Academic"))) && (
                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-md">
      
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("class_session")}</IonLabel>
                                            <IonSelect value={session}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => handleSession(e.detail.value)
                                                }>

                                                    {sessions.map((session) => 
                                                        (<IonSelectOption key={session._id} value={session._id}>{session.name}</IonSelectOption>)
                                                    )}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}</IonLabel>
                                            <IonSelect value={classi}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={(authInfo.institute.type === "school") ? t("select_class") : t("select_batch")}
                                                    onIonChange={
                                                        (e) => handleClassi(e.detail.value)
                                                }>
                                                     
                                                    {classes.map((classy) => (
                                                        (classy.isActive) &&
                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">                   
                                    
                                    <IonItem
                                    detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">{t("select_student")}</IonLabel>
                                        <IonSelect value={selectedStudent}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    className="input-field text-capitalize"
                                                    placeholder={t("select_student")}
                                                    onIonChange={
                                                        (e) => e.detail.value && handleStudent(e.detail.value)
                                                }>
                                                    <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {students.map((student) => (
                                                        (student.isActive) &&
                                                        (<IonSelectOption key={student._id} value={`${student._id}`} className="text-capitalize">{student.name}</IonSelectOption>)
                                                    ))}
                                                 
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                )}
                
                <IonGrid className="ion-no-padding mb-60 ion-margin-top">
                    <div className="ion-table">

                        <IonRow className="table-title ion-padding">
                            <IonCol size="2">
                                <IonIcon 
                                    icon={informationCircle}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                            <IonCol sizeLg="4" size="9" className='table-heading'>
                                {t("title")}
                            </IonCol>

                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                                {t("type")}
                            </IonCol>
                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                                {t("student")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={chatboxEllipses}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>

                        
                            {(inquiries.length > 0) ? (inquiries.map((inquiry, i) => {
                                return (
                                    <IonRow key={inquiry._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={() => history.push(`/app/inquiry/${inquiry._id}`)}>

                                            <IonCol size="2" className='table-field'>
                                                <IonIcon
                                                    icon={((inquiry.type === "classwork") || (inquiry.type ===  "homework")) ? documentAttach : (inquiry.type ===  "leave") ? medical : chatbubbles}
                                                    className='table-icon'
                                                    color={
                                                        (inquiry.status === 'processed') ? 'danger' : 'success'
                                                    }/>
                                            </IonCol>
                                            <IonCol sizeLg="4" size="9" className='table-field' >
                                                <IonRow>
                                                    <IonCol>
                                                        {inquiry.title}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field-1 text-capitalize'>
                                                    <IonCol>
                                                        <IonLabel>
                                                         {inquiry.student ? inquiry.student.name : `${t("students")}: ${inquiry.students!.length}`} &#8226; {inquiry.type}
                                                        </IonLabel>
                                                            
                                                    </IonCol>
                                                </IonRow>
                                                
                                            </IonCol>
                                         
                                            
                                            <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                {inquiry.type}
                                            </IonCol>
                                            <IonCol sizeLg="3" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                {inquiry.student ? inquiry.student.name : inquiry.students!.length}
                                            </IonCol>
                                            <IonCol size="1" className='table-field'>
                                                <IonBadge className=''>{inquiry.messages.length+1}</IonBadge>
                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_chats")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                    </div>

                    {(inquiries.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
             
                        <IonRow className="ion-padding-vertical">
                            <IonCol className=''>
                                <IonButton 
                                    onClick={
                                        () => history.push(`/app/inquiries/new`)
                                    }
                                    className="first-button ion-margin-start"
                                    fill="clear"
                                    >{t("make_inquiry")}
                                </IonButton>

                           
                        </IonCol>
                    </IonRow>
                        
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default StudentInquiries;