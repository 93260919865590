import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface Institute {
    _id: string,
    name: string,
    isActive: boolean
}

const AddInstituteAdmin: React.FC = () => {  
    const {register, authInfo} = useAuth()!;
    const history = useHistory();
    const [institutes, setInstitutes] = useState < Institute[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [institute, setInstitute] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [email, setEmail] = useState < string > ("");
    const [newPass, setNewPass] = useState < string > ("");
    const [confirmPass, setConfirmPass] = useState < string > ("");

    function validateEmail(email : string) { // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    }

    function validatePhone(phone : string) { // eslint-disable-next-line no-control-regex
        const re = /^[0-9]{10}$/;
        return re.test(phone);
    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage("Name is a mandatory field!");
            setIserror(true);
            return;
        }

        if (newPass !== confirmPass)
        {
            setMessage('Passwords do not match.');
            setIserror(true);
            setShowLoading(false);
            return;
        }

        if (!newPass || newPass.length < 8) {
            setMessage("Password must be atleast 8 characters.");
            setIserror(true);
            setShowLoading(false);
            return;
        }

        if (validateEmail(email) === false) {
            setMessage("Thats an invalid email address!");
            setIserror(true);
            return;
        }

        if (validatePhone(phone) === false) {
            setMessage("Enter 10 digits mobile number!");
            setIserror(true);
            return;
        }
    
        setShowLoading(true);

        register(email, newPass, name, phone, 'Admin', institute).then(res => {

            setPass(name+' has been added.');
            setIspass(true);
            setShowLoading(false);

        }).catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Something went wrong...');
            setIserror(true);
            setShowLoading(false);
        });

      }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/institutes`);
                setInstitutes(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref="/app/admins/institute" />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined}  color="secondary">Add Institute Admin</IonTitle>
                   {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Add Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>
                
                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => history.push(`/app/admins/institute`)
                    }
                    header="Success!"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>


                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Admin's Details
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol>
                                    <IonList className="ion-no-padding">

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">Institute</IonLabel>
                                                    <IonSelect value={institute}
                                                            placeholder="Select Institute"
                                                            onIonChange={
                                                                (e) => setInstitute(e.detail.value)
                                                        }>
                                                        { institutes.map((institute) => {
                                                                if (institute.isActive)
                                                                {
                                                                    return (<IonSelectOption key={institute._id} value={`${institute._id}`}>{institute.name}</IonSelectOption>);
                                                                }
                                                                else
                                                                {
                                                                    return null;
                                                                }
                                                            })
                                                        }
                                            </IonSelect>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Name</IonLabel>
                                            <IonInput type="text"
                                                value={name}
                                                placeholder="Full Name"
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Mobile</IonLabel>
                                            <IonInput type="text"
                                                value={phone}
                                                placeholder="10 Digits Mobile No."
                                                onIonInput={
                                                    (e) => setPhone(e.detail.value!)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Email</IonLabel>
                                            <IonInput type="text"
                                                value={email}
                                                placeholder="Email Address"
                                                onIonInput={
                                                    (e) => setEmail(e.detail.value!)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                          lines='full'
                                          button={false}>
                                          <IonLabel position="fixed" color="secondary">Password</IonLabel>
                                          <IonInput type="password"
                                              value={newPass}
                                              placeholder="Set Password"
                                              onIonInput={
                                                  (e) => setNewPass(e.detail.value !)
                                          }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Confirm</IonLabel>
                                            <IonInput type="password"
                                                value={confirmPass}
                                                placeholder="Confirm Password"
                                                onIonInput={
                                                    (e) => setConfirmPass(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                    

                                    </IonList>

                        </IonCol>
                    </IonRow>

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton expand="block" fill="clear"
                            className='first-button' onClick={handleSubmit}>Add Admin</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddInstituteAdmin;
