import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, book, construct } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';

interface Course {
    _id: string,
    name: string,
    isActive: boolean
}

const Courses: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const [courses, setCourses] = useState < Course[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/courses`);
                setCourses(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle color="secondary">Courses</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Courses</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-padding">
                        <IonCol size="8" offset="2">
                            <IonCard className="action-card" routerLink={`/app/courses/add`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-text-center ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <img alt="Add New Course" src="assets/images/subject.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="action-title">
                                                Add New Course
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Manage Courses
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                          <IonList className="ion-no-padding">
                             {(courses.length > 0) ? (courses.map((course, i) => {
                                    return (
                                        <IonItem detail={true}
                                            detailIcon={construct}
                                            lines='full'
                                            button={true}
                                            key={course._id}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                            onClick={
                                                () => history.push(`/app/course/${course._id}`)
                                            }>
                                            <IonIcon slot="start"
                                                icon={book}
                                                color={
                                                    course.isActive ? 'success' : 'danger'
                                                }/>
                                            <IonLabel className='list-label'>
                                                <h3>{course.name}</h3>
                                            </IonLabel>
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start"
                                        color="danger" />
                                        <IonLabel className="list-label"><h3>No Courses</h3></IonLabel>

                                    </IonItem>
                                )
                                } </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Courses;
