import {
    IonAlert,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { useAuth } from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    tranID: string
}

const AppSuccess: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {tranID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [amount, setAmount] = useState < number > (0);
    const today = new Date();

    const date = today.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    const time = today.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {

                const result = await api.get(`/payments/${tranID}`);
                setAmount(result.data.netPayable);
                setShowLoading(false);

            } catch (error: any) {  
                
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage(t("unable_to_connect"));

                setIserror(true);
                setShowLoading(false);
                
            }
            
        };

        fetchData();

    }, [authInfo, tranID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("payment_status")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("payment_status")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonGrid>
                    <IonRow className="ion-padding-top">
                        <IonCol className="ion-text-center" offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <img alt={t("payment_success")} src="assets/images/success.gif" width="160" height="160" />
                        </IonCol>
                    </IonRow>
     
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonList>
                                <IonItem lines="none" color="secondary" className="ion-text-center">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>
                                                {t("payment_success")}
                                                </IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                                <IonItem lines="none" color="light">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonText className='first-meta'>
                                                {t("trans_id")}
                                                </IonText>
                                            </IonCol>
                                            <IonCol className="ion-text-right">
                                                <IonText className='second-meta'> {tranID} </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                                <IonItem lines="none" color="light">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonText className='first-meta'>
                                                {t("trans_amt")}
                                                    </IonText>
                                            </IonCol>
                                            <IonCol className="ion-text-right">
                                                <IonText className='second-meta'> 
                                                &#8377; {amount}
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                                <IonItem lines="none" color="light">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonText className='first-meta'>
                                                {t("trans_date")}
                                                    </IonText>
                                            </IonCol>
                                            <IonCol className="ion-text-right">
                                                <IonText className='second-meta'>  {date} at {time} </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className='ion-text-center'>
                            <IonButton onClick={() => history.push(`/app/home`)}
                                className="first-button"
                                fill="clear"
                                expand='block'
                                >{t("back_to_home")}</IonButton>

                        </IonCol>
                       
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
}

export default AppSuccess;
