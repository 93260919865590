import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, construct, checkboxOutline} from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';

interface Student {
    _id: string,
    name: string,
    classInfo: ClassInfo,
    dob: Date,
    gender?: string,
    category?: string,
    due: number,
    isActive: boolean
}
interface ClassInfo {
    className: string,
    session: string
}
interface Session {
    _id: string,
    name: string,
    isActive: boolean,
    order: number
}
interface Class {
    _id: string,
    name: string,
    year: string,
    isActive: boolean
}

interface ParamTypes {
    instituteID: string
}

const Students: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {instituteID} = useParams < ParamTypes > ();
    const [students, setStudents] = useState < Student[] > ([]);
    const [sessions, setSessions] = useState < Session[] > ([]);
    const [classes, setClasses] = useState < Class[] > ([]);
    const [classi, setClassi] = useState < string > ("all");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [searchText, setSearchText] = useState < string > ("");
    const [year, setYear] = useState < string > ("");
    const [gender, setGender] = useState < string > ("all");
    const [category, setCategory] = useState < string > ("all");
    const [house, setHouse] = useState < string > ("all");
    const [blood, setBlood] = useState < string > ("all");
    const [houses, setHouses] = useState < string[] > ([]);
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [current, setCurrent] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
      };

      const formatDated = (value: Date) => {

            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
     
      };

      const handleSubmit = () => {

        if (!year || year === "") {
            setMessage(t("select_session_first"));
            setIserror(true);
            return;
        }

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/students/listInstitute/${instituteID}`, {year, classi, gender, category, house, blood, toDOB, fromDOB, searchText});
                setStudents(result.data.students);
                setTotal(result.data.total);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }

      const loadItems = async () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        let limit = skip + 20;
       
        if (!year || year === "") {
            setMessage(t("select_session_first"));
            setIserror(true);
            return;
        }

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof toDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
                const tDate = new Date(toDate);
                const fDate = new Date(fromDate);
                if (tDate < fDate)
                {
                    setMessage(t("dates_invalid"));
                    setIserror(true);
                    return;
                }     
                else
                {
                    toDOB = toDate;
                    fromDOB = fromDate;
                }
        }

        try {
            const result = await api.post(`/students/listInstitute/${instituteID}?skip=${limit}`, {year, classi, gender, category, house, blood, toDOB, fromDOB, searchText});

            if(result.data.students && result.data.students.length)
            {
                setStudents([...students, ...result.data.students]);
            }
                
            setSkip(limit);

        } catch (error : any) {
            if (error.response !== undefined) 
                setMessage(error.response.data.message);
                else 
                setMessage(t("something_went_wrong"));
            setIserror(true);
        }
        
    }

    const handleSession = (sessionName : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.get(`/classes/allInstitute/${instituteID}/${sessionName}`);
                setClasses(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();
        setYear(sessionName);
        setClassi("all");
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const res = await api.get(`/masters/getSessions`);
                const formFields: Session[] = res.data.classSessions;
                setSessions(formFields);

                const insti = await api.get(`/institutes/${instituteID}`);
                if(insti.data.houses)
                {
                    setHouses(insti.data.houses);
                }

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo, instituteID, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/${instituteID}/student`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_students")}</IonTitle>
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_students")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="mt-30 min-xxxl">
                        <IonCol size="1">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("class_session")}</IonLabel>
                                            <IonSelect value={year}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("class_session")}
                                                    onIonChange={
                                                        (e) => handleSession(e.detail.value)
                                                }>

                                                    {sessions.map((session) => (
                                                        (session.isActive) && 
                                                        (<IonSelectOption key={session._id} value={session.name}>{session.name}</IonSelectOption>)
                                                    ))}

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("class")}</IonLabel>
                                            <IonSelect value={classi}
                                                    className="input-field"
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_class")}
                                                    onIonChange={
                                                        (e) => setClassi(e.detail.value)
                                                }>
                                                        <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {classes.map((classy) => (
                                                        (classy.isActive) &&
                                                        (<IonSelectOption key={classy._id} value={`${classy._id}`}>{classy.name} ({classy.year})</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("student_gender")}
                                            </IonLabel>
                                            <IonSelect value={gender}
                                                className="input-field"
                                                placeholder={t("student_gender")}
                                                onIonChange={
                                                    (e) => setGender(e.detail.value)
                                                }>
                                                <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                <IonSelectOption value="male">{t("male")}</IonSelectOption>
                                                <IonSelectOption value="female">{t("female")}</IonSelectOption> 
                                                
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("student_category")}
                                        </IonLabel>
                                        <IonSelect value={category}
                                            className="input-field"
                                            placeholder={t("student_category")}
                                            onIonChange={
                                                (e) => setCategory(e.detail.value)
                                            }>
                                            <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                            <IonSelectOption value="general">{t("general")}</IonSelectOption>
                                            <IonSelectOption value="ews">{t("ews")}</IonSelectOption> 
                                            
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("student_house")}
                                        </IonLabel>
                                        <IonSelect value={house}
                                            className="input-field"
                                            placeholder={t("student_house")}
                                            onIonChange={
                                                (e) => setHouse(e.detail.value)
                                            }>
                                                <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                            { houses.map((house, i) => {
                                                    
                                                return (<IonSelectOption key={i} value={`${house}`}>{house}</IonSelectOption>);
                                                    
                                                })
                                            }
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("student_blood")}
                                            </IonLabel>
                                            <IonSelect value={blood}
                                                className="input-field"
                                                placeholder={t("student_blood")}
                                                onIonChange={
                                                    (e) => setBlood(e.detail.value)
                                                }>
                                                <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                <IonSelectOption value="A+">A+</IonSelectOption>
                                                <IonSelectOption value="A-">A-</IonSelectOption> 
                                                <IonSelectOption value="B+">B+</IonSelectOption>
                                                <IonSelectOption value="B-">B-</IonSelectOption>      
                                                <IonSelectOption value="O+">O+</IonSelectOption>
                                                <IonSelectOption value="O-">O-</IonSelectOption>      
                                                <IonSelectOption value="AB+">AB+</IonSelectOption>
                                                <IonSelectOption value="AB-">AB-</IonSelectOption>         
                                                
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                        <IonCol size="1">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("dob_from")}
                                            </IonLabel>
                                            <IonInput value={formatDate(fromDate)} className="input-field" readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7]} handle={false}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("dob_from")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("dob_to")}
                                            </IonLabel>
                                            <IonInput value={formatDate(toDate)} className="input-field" readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7]} handle={false}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("dob_to")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                        
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("search_student")}
                                            </IonLabel>

                                        <IonInput type="text"
                                            className="input-field"
                                            value={searchText}
                                            placeholder={`\uD83D\uDD0D\u00A0\u00A0\u00A0${t("search_student")}`}
                                            onIonChange={
                                                (e) => setSearchText(e.detail.value!)
                                        } />
                                                
                                     
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="1" className="ion-padding ion-text-right">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                className='mt-15 ion-text-wrap'>{t("view_students")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding mt-30 mb-60 overflow-x">
                    <div className="ion-table min-lg">

                        <IonRow className="table-title ion-padding">

                            <IonCol size="3" className='table-heading heading-fixed ion-padding-start'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                />
                                <IonText className='ion-margin-start'>{t("name")}</IonText>
                            </IonCol>
                            <IonCol size="2" className='table-heading'>
                            {t("class")}
                            </IonCol>
                            <IonCol size="2" className='table-heading'>
                            {t("dob")}
                            </IonCol>
                            <IonCol size="2" className='table-heading'>
                            {t("gender")}
                            </IonCol>
                            <IonCol size="2" className='table-heading'>
                            {t("overdue")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={construct}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                            (e) => setCurrent(e.detail.value!)
                                    }>

                        {(students.length > 0) ? (students.map((student, i) => {
                            return (
                                <IonRow key={student._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}>
                                    
                                        <IonCol size="3" className={((i%2) === 0) ? 'table-field field-fixed ion-padding-start' : 'table-field field-fixed-alt ion-padding-start'} >
                                            <IonRadio value={student._id} className='table-icon' color="secondary" />
                                            <IonText className='ion-margin-start'>{student.name}</IonText>
                                            
                                        </IonCol>
                                        <IonCol size="2" className='table-field'>
                                            {student.classInfo.className}
                                        </IonCol>
                                        <IonCol size="2" className='table-field'>
                                            {formatDated(student.dob)}
                                        </IonCol>
                                        <IonCol size="2" className='table-field'>
                                            {student.gender}
                                        </IonCol>
                                        <IonCol size="2" className='table-field'>
                                            {student.due}
                                        </IonCol>
                                        <IonCol size="1">
                                            <IonIcon 
                                                icon={construct}
                                                color="dark"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/student/${student._id}`)
                                                }
                                            />
                                            
                                        </IonCol>
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_students")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 
                    </IonRadioGroup>
                    </div>

                    {(students.length > 0) &&
                    <IonInfiniteScroll
                        onIonInfinite={async (ev) => {
                            await loadItems();
                            ev.target.complete();
                        }}
                    >
                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                    }

                   
                   
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid className="overflow-x">
                    <IonRow className='min-xxxxl'>
                        <IonCol size="1" className="ion-padding footer-stats">
                            {t("showing")} {total === 0 ? t("no_records") : `1 - ${students.length} ${t("of")} ${total}`} 
                        </IonCol>
                        <IonCol size="11" className="ion-padding-horizontal">

                            <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("view_communications")}</IonButton>

                            <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("view_records")}</IonButton>

                            <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("view_cards")}</IonButton>

                            <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("view_ledger")}</IonButton>

                            <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("map_fee_structure")}</IonButton>

                            <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("view_transport")}</IonButton>

                            <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("withdraw_student")}</IonButton>

                           <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("export_excel")}</IonButton>

                          <IonButton 
                                className="first-button ion-margin-start"
                                fill="clear"
                                >{t("export_pdf")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Students;
