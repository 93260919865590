import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonReorder,
    IonReorderGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    ItemReorderEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { addCircle, arrowUndoCircle, construct, toggle } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import { useHistory, useParams } from 'react-router-dom';

interface ParamTypes {
    chapter?: string
}

interface Course {
    _id: string,
    name: string,
    chapters: Chapter[],
    isActive: boolean
}

interface Chapter {
    _id: string,
    chapterName: string,
    isActive: boolean,
    order: number
}

interface Topic {
    _id: string,
    name: string,
    isActive: boolean,
    order?: number
}

const Topics: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {chapter} = useParams < ParamTypes > ();
    const history = useHistory();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");

    const [courses, setCourses] = useState < Course[] > ([]);
    const [courseID, setCourseID] = useState < string > ("");
    const [topics, setTopics] = useState < Topic[] > ([]);
    const [chapterID, setChapterID] = useState < string > ("");
    const [chapterName, setChapterName] = useState < string > ("");
    const [initial, setInitial] = useState < boolean > (true);

    const handleCourse = (course : string) => {
        if(!initial)
        {
            setCourseID(course);
            setChapterID("");
            setTopics([]);
        }
        else
        {
            setInitial(false);
        }
    }

    const handleChapter = (chapter : string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const tops = await api.get(`/topics/getByChapter/${chapter}`);
                setTopics(tops.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        if (chapter !== "")
        {
            setChapterID(chapter);
            setChapterName(courses.find(val => val._id === courseID)!.chapters.find(val => val._id === chapter)!.chapterName);
            fetchUp();
        }
    }


    const handleUndo = () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const tops = await api.get(`/topics/getByChapter/${chapterID}`);
                setTopics(tops.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        if (chapterID !== "")
        {
            fetchUp();
        }
    }

    const handleName = (name: string, index: number) => {

        let comps = [...topics];
        comps[index].name = name;
        setTopics(comps);
    
    }

    const handleDelete = (index: number) => 
    {

        let comps = [...topics];
        comps[index].isActive = !comps[index].isActive;
        setTopics(comps);
    }

    const handleAdd = () => {
        let r = (Math.random() + 1).toString(36).substring(7);
        let comps = [...topics];
        comps.push({_id: r, name: '', isActive: true});
        setTopics(comps);
    }


    const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {

        let from = event.detail.from;
        let to = event.detail.to;
        let toCopy = event.detail.to;

        let chaps = [...topics];

        if (to < from)
        {
            chaps[to] = {...topics[from]};
            to = to + 1;
            while (to <= from)
            {
                chaps[to] = {...topics[to-1]};
                to = to + 1;
            }
        }

        if (toCopy > from)
        {
            chaps[toCopy] = {...topics[from]};
            toCopy = toCopy - 1;
            while (toCopy >= from)
            {
                chaps[toCopy] = {...topics[toCopy+1]};
                toCopy = toCopy - 1;
            }
        }

        setTopics(chaps);
        
        event.detail.complete();
      }

    
      const handleTopics = () => {

        if (!topics || (topics.length < 1)) {
            return;
        }

        let sendTopics: Topic[] = [];
        for (let i = 0; i < topics.length; i++)
        {
            sendTopics.push({
                _id: topics[i]._id,
                name: topics[i].name,
                isActive: topics[i].isActive
            });
        }

        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const chapterInfo = {
            chapterID,
            chapterName
        }
  
        const upTopic = (topics: Topic[]) => {
          return new Promise((resolve, reject) => {
            api.post('/topics/set', { courseID, chapterInfo, topics }).then(res => {
              return resolve(res.data);
            }).catch(err => reject(err));
  
          });
        }

        upTopic(sendTopics)
            .then(data => {
    
                setPass(chapterName+' has been updated.');
                setIspass(true);
                setShowLoading(false);
            
            })
            .catch(error => {
                if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Please try again afer sometime.');
            setIserror(true);
            setShowLoading(false);
            });
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

       

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/courses/');

                setCourses(result.data);

                if(chapter !== undefined)
                {
                    const index = result.data.findIndex((co: { chapters: any[]; }) => co.chapters.find((chap: { _id: string; }) => chap._id === chapter));
                    setCourseID(result.data[index]._id);

                    setChapterID(chapter);
                    setChapterName(result.data.find((val: { _id: string; }) => val._id === result.data[index]._id)!.chapters.find((val: { _id: string; }) => val._id === chapter)!.chapterName);
                    const tops = await api.get(`/topics/getByChapter/${chapter}`);
                    setTopics(tops.data);
                    
                }
                else 
                {
                    setInitial(false);
                }
                

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(error.message);
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, chapter]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle color="secondary">Manage Topics</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Manage Topics</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle className="list-title">
                                    Select Chapter
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">
                                        
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">Course</IonLabel>
                                                    <IonSelect value={courseID}
                                                            placeholder="Select Course"
                                                            onIonChange={
                                                                (e) => handleCourse(e.detail.value)
                                                        }>
                                                        { courses.map((course) => {
                                                                if (course.isActive)
                                                                {
                                                                    return (<IonSelectOption key={course._id} value={`${course._id}`}>{course.name}</IonSelectOption>);
                                                                }
                                                                else
                                                                {
                                                                    return null;
                                                                }
                                                            })
                                                        }
                                            </IonSelect>
                                        </IonItem>
                                        {(courseID !== "") && (
                                            <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">Chapter</IonLabel>
                                                    <IonSelect value={chapterID}
                                                            placeholder="Select Chapter"
                                                            onIonChange={
                                                                (e) => handleChapter(e.detail.value)
                                                        }>
                                                        { courses.find(val => val._id === courseID)!.chapters.map((chapter) => {
                                                                if (chapter.isActive)
                                                                {
                                                                    return (<IonSelectOption key={chapter._id} value={`${chapter._id}`}>{chapter.chapterName}</IonSelectOption>);
                                                                }
                                                                else
                                                                {
                                                                    return null;
                                                                }
                                                            })
                                                        }
                                                </IonSelect>
                                            </IonItem>
                                             )}
                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                   

                {(chapterID !== "") && (
                    <IonRow>
                        <IonCol>
                            <IonCard className='border-radius-10' color='tertiary'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonGrid>
                                        <IonRow className="mt-05">
                                            <IonCol size="8" className='ion-padding-horizontal'>
                                                Chapter Topics
                                            </IonCol>
                                            <IonCol size="2" className='ion-text-right'>
                                               
                                               <IonIcon className='tran-icon'
                                                   icon={arrowUndoCircle}
                                                   color='secondary'
                                                   onClick={handleUndo}
                                               />
                                           
                                           
                                            </IonCol>
                                            <IonCol size="2" className='ion-text-center'>
                                               
                                                    <IonIcon className='tran-icon'
                                                        icon={addCircle}
                                                        color='secondary'
                                                        onClick={handleAdd}
                                                    />
                                                
                                                
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                )}

                {topics && (topics.length > 0) && (
                <>
                 
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                                            {topics.map((topic, i) => {
                                                return (
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        key={topic._id}>
                                                            <IonReorder slot="start"/>
                                                            <IonInput type="text"
                                                                    placeholder='Topic Name'
                                                                    value={topic.name}
                                                                    onIonChange={
                                                                        (e) => handleName(e.detail.value!, i)
                                                                }
                                                                readonly={!topic.isActive}
                                                                ></IonInput>
                                                            <IonButtons slot="end">
                                                                <IonButton onClick={() => handleDelete(i)}> 
                                                                    <IonIcon
                                                                    icon={toggle}
                                                                    color={topic.isActive ? "success" : "danger"}
                                                                    />
                                                                </IonButton>
                                                                <IonButton onClick={() => (topic.isActive && (topic._id.length > 10))  ? history.push(`/app/assessments/${chapterID}/${topic._id}`) : null }> 
                                                                    <IonIcon
                                                                    icon={construct}
                                                                    color="dark"
                                                                    />
                                                                </IonButton>
                                                            </IonButtons>
                                                            
                                                    </IonItem>
                                                )
                                                
                                                })
                                            }

                                    </IonReorderGroup>
                                </IonCardContent>
                            </IonCard>
                         </IonCol>
                    </IonRow>

                </>
                )}


                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleTopics}
                                className="first-button"
                                fill="clear"
                                expand="block">Update Topics</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Topics;
