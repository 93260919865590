import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, informationCircle } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import SwitchBranch from '../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../components/usePageUnloadHandler';

interface Incident {
    _id: string,
    type: string,
    student: Student,
    createdAt: Date
}

interface Student {
    studentID: string,
    name: string
}

const Incidents: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [incidents, setIncidents] = useState < Incident[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [total, setTotal] = useState < number > (0);
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [duration, setDuration] = useState < string > ("three");
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem) setScrollPosition(elem.scrollTop);
    };

    const formatDate = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };


    const formatDated = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const handleSubmit = () => {

        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }
        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        if (toDOB === "" && (!duration || duration === "")) {
            setMessage(t("med_duration_mandatory"));
            setIserror(true);
            return;
        }

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.post(`/incidents/getByInstitute/${authInfo.user.instituteID}`, {duration, toDOB, fromDOB});
                setIncidents(result.data.incidents);
                setTotal(result.data.total);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();

    }

    const loadItems = async () => {
        let limit = skip + 30;
       
        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }

        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        if (toDOB === "" && (!duration || duration === "")) {
            setMessage(t("duration_mandatory"));
            setIserror(true);
            return;
        }

        try {
            const result = await api.post(`/incidents/getByInstitute/${authInfo.user.instituteID}?skip=${limit}`, {duration, toDOB, fromDOB});

            if(result.data.incidents && result.data.incidents.length)
            {
                setIncidents([...incidents, ...result.data.incidents]);
                setSkip(limit);
            }

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
    }

    useEffect(() => {

            const storedState = sessionStorage.getItem(history.location.pathname);
            if (history.action === "POP" && storedState) {
                const { incidents, duration, total, skip, toDate, fromDate, scrollPosition } = JSON.parse(storedState);
                // Data is present in local storage, use it to initialize state
                setIncidents(incidents);
                setDuration(duration);
                setTotal(total);
                setSkip(skip);
                setToDate(toDate);
                setFromDate(fromDate);
               
                setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
            } 
            else
            {
                const fetchData = async () => {
                    setShowLoading(true);
                    try {
    
                        const result = await api.post(`/incidents/getByInstitute/${authInfo.user.instituteID}`, {duration: "three", toDOB: "", fromDOB: ""});
                        setIncidents(result.data.incidents);
                        setTotal(result.data.total);
                        setSkip(0);
    
                    } catch (error: any) {
                        setMessage(error.response?.data.message || t("something_went_wrong"));
                        setIserror(true);
                    } finally {
                        setShowLoading(false);
                    }
                };
    
                fetchData();
            }
           

        setInitialRender(false);

    }, [authInfo.user.instituteID, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
                incidents,
                duration,
                total,
                skip,
                toDate,
                fromDate,
                scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        
        }
    }, [incidents, total, skip, toDate, fromDate, duration, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/essentials`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("view_medical_records")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                   )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("view_medical_records")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />
                    
                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-md">
                        
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("duration")}
                                            </IonLabel>
                                            <IonSelect value={duration}
                                                placeholder={t("duration")}
                                                cancelText={t("cancel")}
                                                okText={t("ok")}
                                                onIonChange={
                                                    (e) => setDuration(e.detail.value)
                                                }>
                                                <IonSelectOption value="three">{t("three")}</IonSelectOption>
                                                <IonSelectOption value="six">{t("six")}</IonSelectOption>
                                                <IonSelectOption value="twelve">{t("twelve")}</IonSelectOption> 
                                                <IonSelectOption value="current">{t("current_fy")}</IonSelectOption>
                                                <IonSelectOption value="last">{t("last_fy")}</IonSelectOption> 
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                      
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("from_date")}
                                            </IonLabel>
                                            <IonInput value={formatDate(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("from_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("done")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("to_date")}
                                            </IonLabel>
                                            <IonInput value={formatDate(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("to_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                    cancelText={t("cancel")}
                                                    doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
   
                        
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_medical_records")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">
                        <IonRow className="table-title ion-padding">

                            <IonCol sizeLg="4" size="5" className='table-heading'>
                                
                               {t("date")}
                            </IonCol>
                            <IonCol sizeLg="4" size="6" className='table-heading'>
                                {t("student")}
                            </IonCol>
                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                                {t("type")}
                            </IonCol>
                            <IonCol size="1" className='ion-text-right ion-padding-end'>
                                <IonIcon 
                                    icon={informationCircle}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                       
                        {(incidents.length > 0) ? (incidents.map((incident, i) => {
                            return (
                                <IonRow key={incident._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                
                                onClick={
                                    () => history.push(`/app/incident/${incident._id}`)
                                }>
                                    
                                        <IonCol sizeLg="4" size="5" className='table-field' >
                                            
                                            <IonRow>
                                                <IonCol>
                                                    
                                                     {formatDated(incident.createdAt)}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='ion-hide-lg-up vid-responsive-field-1 text-capitalize'>
                                                <IonCol>
                                                    <IonLabel>
                                                        {incident.type}
                                                    </IonLabel>
                                                            
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                        <IonCol sizeLg="4" size="6" className='table-field text-capitalize'>
                                            {incident.student.name}
                                        </IonCol>
                                        <IonCol sizeLg="3" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                            {incident.type}
                                        </IonCol>
                                        <IonCol size="1" className='ion-text-right ion-padding-end'>
                                            <IonIcon 
                                                icon={informationCircle}
                                                color="dark"
                                                className='table-icon mt-10'
                                                onClick={
                                                    () => history.push(`/app/incident/${incident._id}`)
                                                }
                                            />
                                            
                                        </IonCol>
                                    
                                </IonRow>
                            )
                        })) : ( 
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"
                                                color="danger" />
                                            <IonLabel className="list-title"><h3>{t("no_incidents")}</h3></IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        )
                        } 
                    </div>

                    {(incidents.length > 0) &&
                    <IonRow>
                        <IonCol>
                            <IonInfiniteScroll
                                onIonInfinite={async (ev) => {
                                    await loadItems();
                                    ev.target.complete();
                                }}
                            >
                                <IonInfiniteScrollContent></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCol>
                   </IonRow>
                    }
                    
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                    {t("showing")} {total === 0 ? t("no_records") : `1 - ${incidents.length} ${t("of")} ${total}`} 
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>
                                  

                                    <IonButton 
                                        onClick={
                                            () => history.push(`/app/medical/incidents/add`) 
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("record_an_incident")}</IonButton>

                                   
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Incidents;