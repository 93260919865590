import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { PhotoViewer } from '@capacitor-community/photoviewer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../components/AuthContext';
import FileOpener from "../../../components/FileOpener";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';

interface ParamTypes {
    circularID: string
}

const allRoles = ["Teacher", "Academic", "Driver", "Transporter", "Accountant", "AdmissionHead", "Inventory"];

const CircularDetailStaff: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {circularID} = useParams < ParamTypes > ();
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");
    const [fileName, setFileName] = useState < string > ("");
    const [fileExt, setFileExt] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > (new Date().toISOString());
    const [text, setText] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");
    const [roles, setRoles] = useState < string[] > ([]);
    const [type, setType] = useState < string > ("");
    const [buttonColor, setButtonColor] = useState < string > ("light");

    const formatDate = (value: string | string[]) => {
        if (typeof value === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        
    };

    const openImage = (url: string, name: string) => {
        PhotoViewer.show({images: [{url, title: name}]});
    };

    const handleChecked = (value: string) => {

        if(roles.includes(value))
        {
         const index = roles.indexOf(value);
             if (index > -1) { // only splice array when item is found
                 roles.splice(index, 1); // 2nd parameter means remove one item only
             }
        }
        else
        {
             roles.push(value);
        }
    }

    const handleAll = () => {

        if (buttonColor === "light")
        {
            setRoles(allRoles);
            setButtonColor("warning");
        }
        else
        {
            setRoles([]);
            setButtonColor("light");
        }
    }

    const handleSection = (section: string) => {
    
        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const toggleCircular = (isActive: string) => {
            return new Promise((resolve, reject) => {

                api.put(`/circulars/${circularID}`, { 'isActive': section }).then(res => {
                    return resolve(res.data.title);  
                }).catch(err => reject(err));

            });
        }
        if ((active !== "") && (section !== active))
        {
            setShowLoading(true);

            toggleCircular(section)
            .then(data => {

                setActive(section);
                setPass(data+t("has_been_updated"));
                setIspass(true);
                setShowLoading(false);

            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));

                setIserror(true);
                setShowLoading(false);
            });
        }
        
    }

    const handleSubmit = () => {

        if (!title || title === "") {
            setMessage(t("title_mandatory"));
            setIserror(true);
            return;
        }

        if (mode === "Announcement")
        {
            if (!text || text === "") {
                setMessage(t("message_mandatory"));
                setIserror(true);
                return;
            }
        }

        if(!roles.length)
        {
            setMessage(t("select_roles"));
            setIserror(true);
            return;
        }

    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upCircular = () => {
          return new Promise((resolve, reject) => {
            api.put('/circulars/'+circularID, { title, type, roles }).then(res => {
                return resolve(res.data.title);
            }).catch(err => reject(err));
          });
        }

        const upAnnouncement = () => {
            return new Promise((resolve, reject) => {
              api.put('/circulars/'+circularID, { title, type, text, roles }).then(res => {
                    return resolve(res.data.title);
              }).catch(err => reject(err));
            });
          }

        if (mode === "Circular")
        {
            setShowLoading(true);
            upCircular()
            .then(data => {
    
                setPass(data+t("has_been_updated"));
                setIspass(true);
                setShowLoading(false);
            
            })
            .catch(error => {
                if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
            setIserror(true);
            setShowLoading(false);
            });
        }

        if (mode === "Announcement")
        {
            setShowLoading(true);
            upAnnouncement()
            .then(data => {
    
                setPass(data+t("has_been_updated"));
                setIspass(true);
                setShowLoading(false);
            
            })
            .catch(error => {
                if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage(t("something_went_wrong"));
            setIserror(true);
            setShowLoading(false);
            });
        }
    }
    
    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        return mime;
    };

    const downloadCircular = async (fileName : string) => {

        const myArray = fileName.split(".");
        const mimeType = getMimeType(myArray[1]);
        const url = process.env.REACT_APP_STATIC_S3 + '/circulars/' + fileName;

        if (isPlatform('capacitor'))
        {
        try {
            Filesystem.checkPermissions().then(async (res) => {
                if (res.publicStorage !== 'granted') {
                    Filesystem.requestPermissions().then(async (ress) => {
                        if (ress.publicStorage === 'denied') {
                            setMessage(t("storage_denied"));
                            setIserror(true);
                        }
                        else {
                            try {
                                const savedFile = await Filesystem.downloadFile({
                                    path: fileName,
                                    url,
                                    directory: Directory.External,
                                });
                                
                                if(savedFile.path)
                                {
                                    await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                                }
                                else
                                {
                                    setMessage(t("download_error"));
                                    setIserror(true);
                                }
                                
                            }
                            catch(error: any) {
                                setMessage(t("download_error"));  
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const savedFile = await Filesystem.downloadFile({
                            path: fileName,
                            url,
                            directory: Directory.External,
                        });
                        
                        if(savedFile.path)
                        {
                            await FileOpener.open({filePath: savedFile.path, contentType: mimeType});
                        }
                        else
                        {
                            setMessage(t("download_error"));
                            setIserror(true);
                        }
                        
                    }
                    catch(error: any) {
                        setMessage(t("download_error"));  
                        setIserror(true);
                    }
                }
            });

            } catch {
                setMessage(t("download_error"));
                setIserror(true);
            }
        }
       
    }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/circulars/' + circularID);

                setTitle(result.data.title);
                setType(result.data.type);

                if(result.data.filename)
                {
                    setFileName(result.data.filename);
                    const myArray = result.data.filename.split(".");
                    setFileExt(myArray[1]);
                    setMode("Circular");
                }
                if(result.data.text)
                {
                    setText(result.data.text);
                    setMode("Announcement");
                }
                
                setSelectedDate(result.data.createdAt);

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                setRoles(result.data.roles);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, circularID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref={`/app`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary"> {mode === "Circular" ? t("circular") : t("announcement")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                    </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary"> {mode === "Circular" ? t("circular") : t("announcement")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => {
                            setIspass(false)
                        }
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonGrid className="ion-no-padding">
                    <IonRow className="ion-padding-vertical max-xl">
                        <IonCol sizeLg="6" size="12">
                           
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("communication_details")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            <IonRow className="mt-10">
                                <IonCol>
                                    <IonCard className='border-radius-10 active-group'>
                                        <IonCardContent>
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        
                                                        {t("current_status")}
                                                        
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                                    {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>{t("active")}</IonBadge>) : 
                                                    ( <IonBadge color="danger" className='ion-padding-horizontal'>{t("inactive")}</IonBadge>)}
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-padding-top ion-padding-horizontal">
                                                    <IonCol>
                                                        <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                            <IonSegmentButton value="enable">
                                                                <IonLabel>{t("make_active")}</IonLabel>
                                                            </IonSegmentButton>
                                                            <IonSegmentButton value="disable">
                                                                <IonLabel>{t("make_inactive")}</IonLabel>
                                                            </IonSegmentButton>
                                                        </IonSegment>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("comm_title")}*
                                                </IonLabel>
                                                    <IonInput type="text"
                                                        className="input-field"
                                                        placeholder={t("comm_title")}
                                                        value={title}
                                                        onIonInput={
                                                            (e) => setTitle(e.detail.value!)
                                                    }></IonInput>
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                    lines='full'
                                                    button={false}>
                                                    <IonLabel position="stacked" className="input-label" color="secondary">
                                                        {t("comm_type")}*
                                                    </IonLabel>
                                                    <IonSelect value={type}
                                                        className="input-field"
                                                        placeholder={t("comm_type")}
                                                        cancelText={t("cancel")}
                                                        okText={t("ok")}
                                                        onIonChange={
                                                            (e) => setType(e.detail.value)
                                                        }>
                                                    
                                                        <IonSelectOption value="general">{t("general_comm")}</IonSelectOption>
                                                        <IonSelectOption value="academic">{t("academic_comm")}</IonSelectOption>
                                                    </IonSelect>
                                                </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            {(mode === "Circular") && (fileName !== "") &&(
                                <>
                                 <IonRow className="ion-margin-top">
                                    <IonCol className="ion-padding-horizontal">
                                        {isPlatform("capacitor") && (
                                            <IonButton expand="block" color="dark" onClick={() => downloadCircular(fileName)}>{t("download_circular")}</IonButton> 
                                        )}
                                        {!isPlatform("capacitor") && (
                                            <a href={`${process.env.REACT_APP_STATIC_S3}/circulars/${fileName}`} target="_blank" rel="noreferrer" download className='font-none'> 
                                                <IonButton expand="block" color="dark">{t("download_circular")}</IonButton> 
                                            </a>
                                        )}
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-margin-top">
                                    <IonCol>
                                        <IonCard className="ion-text-center">
                                            <IonCardHeader>
                                                <IonCardSubtitle>{t("circular_preview")}</IonCardSubtitle>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                {(fileExt) && ((fileExt === 'jpeg') || (fileExt === 'png')) &&
                                                    (
                                                        <img onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/circulars/${fileName}`, title)}
                                                        src={`${process.env.REACT_APP_STATIC_S3}/circulars/${fileName}`} 
                                                        alt={t("circular_preview")}
                                                        width="320" />
                                                )
                                                }
                                                {(fileExt) && (fileExt === 'pdf') &&
                                                    (<div className="pdf-preview">
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <img onClick={() => downloadCircular(fileName)} src="assets/images/pdf-icon.png" alt="PDF" />
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                                
                                            </IonCardContent>        
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                               
                            </>
                            )}
                            {(mode === "Announcement") && (
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}>
                                                <IonLabel position="floating" color="secondary">{t("circular_msg")}</IonLabel>
                                                <IonTextarea rows={20} value={text} onIonInput={
                                                        (e) => setText(e.detail.value!)
                                                }></IonTextarea>
                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )}

                        </IonCol>
                        <IonCol sizeLg="6" size="12">
                            <IonRow className="mt-30">
                                <IonCol>
                                        <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                            {t("comm_roles")}
                                        </IonTitle>
                                </IonCol>
                            </IonRow>
                            
                          
                            <IonRow className="mt-30">
                                <IonCol >
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonList className="ion-no-padding">
                                                <IonItemDivider color="secondary">
                                                   
                                                    <IonButtons slot="start">
                                                        <IonButton onClick={handleAll} color={buttonColor}>{t("select_all")}</IonButton>
                                                    </IonButtons>
                                                </IonItemDivider>
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Teacher" checked={roles.includes("Teacher")} 
                                                            onClick={e => handleChecked("Teacher")}/>
                                                        <IonLabel>{t("teacher")}</IonLabel>
                                                    </IonItem>
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Academic" checked={roles.includes("Academic")} 
                                                            onClick={e => handleChecked("Academic")}/>
                                                        <IonLabel>{t("academic_incharge")}</IonLabel>
                                                    </IonItem>
                                                    {(authInfo.institute.transport) && (<>
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Driver" checked={roles.includes("Driver")} 
                                                            onClick={e => handleChecked("Driver")}/>
                                                        <IonLabel>{t("driver")}</IonLabel>
                                                    </IonItem>
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Transporter" checked={roles.includes("Transporter")} 
                                                            onClick={e => handleChecked("Transporter")}/>
                                                        <IonLabel>{t("transport_incharge")}</IonLabel>
                                                    </IonItem>
                                                    </>)}
                                                    {(authInfo.institute.payment) && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Accountant" checked={roles.includes("Accountant")} 
                                                            onClick={e => handleChecked("Accountant")}/>
                                                        <IonLabel>{t("accountant")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                    {(authInfo.institute.admission) && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="AdmissionHead" checked={roles.includes("AdmissionHead")} 
                                                            onClick={e => handleChecked("AdmissionHead")}/>
                                                        <IonLabel>{t("admission_head")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                    {(authInfo.institute.inventory) && (
                                                    <IonItem 
                                                        detail={false}
                                                        lines='full'
                                                        button={false}>
                                                        <IonCheckbox slot="start" value="Inventory" checked={roles.includes("Inventory")} 
                                                            onClick={e => handleChecked("Inventory")}/>
                                                        <IonLabel>{t("inventory_manager")}</IonLabel>
                                                    </IonItem>
                                                    )}
                                                   
                                            </IonList>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem>
                                                <IonLabel position="stacked" className="input-label" color="secondary">
                                                    {t("issued_on")}
                                                </IonLabel>
                                                <IonInput className="input-field" value={formatDate(selectedDate)} readonly={true} />
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>

                        </IonCol>
                    </IonRow>
                </IonGrid>
                
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_comm")} {mode === "Circular" ? t("circular") : t("announcement")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default CircularDetailStaff;
