import {
    ActionSheetButton,
    IonActionSheet,
    IonAlert,
    IonAvatar,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { accessibilityOutline, alertCircleOutline, calendarOutline, cashOutline, chatbubbles, chatbubblesOutline, chevronForward, closeOutline, create, documentsOutline, idCardOutline, medicalOutline, notifications, qrCodeOutline, sparkles, time } from 'ionicons/icons';
import { useTranslation } from "react-i18next";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { BarcodeScanner, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning';
import { InAppBrowser, InAppBrowserObject, InAppBrowserOptions } from '@ionic-native/in-app-browser';
import { SendIntent } from "send-intent";
import {useAuth} from "../../components/AuthContext";
import ThemeToggle from '../../components/ThemeToggle';
import LanguageToggle from '../../components/LanguageToggle';
import CircularsSwiper from '../../components/ui/CircularsSwiper';

interface Student {
    _id: string,
    name: string,
    photo: string,
    dob: Date
}

interface Event {
    _id: string,
    title: string,
    description: string,
    media?: string,
    calendarDate: Date
}

interface Inquiry {
    _id: string,
    title: string,
    type: string,
    student?: Stud,
    students?: string[],
    createdAt: Date
}

interface Stud {
    studentID: string,
    name: string
}

interface Circular {
    _id: string,
    title: string,
    text?: string,
    filename?: string,
    createdAt: Date,
    isActive: boolean
}

interface Chat {
    _id: string,
    title: string,
    type: string,
    status: string,
    employee?: ChatEmployee,
    employees?: string[],
    taskInfo?: TaskInfo,
    createdAt: Date,
    updatedAt: Date
}

interface ChatEmployee {
    employeeID: string,
    name: string
}

interface TaskInfo {
    startDate: Date,
    endDate: Date,
    priority: string
}

interface Attendance {
    _id: string,
    status: string,
    createdAt: Date
}

interface StudentAttendance {
    _id: string,
    studentID: string,
    studentName: string,
    status: string,
    createdAt: Date
}
interface StaffAttendance {
    _id: string,
    employee: Employee,
    status: string,
    createdAt: Date
}

interface Employee {
    employeeID: string,
    name: string
}

const AcademicHome: React.FC = () => {
    const {authInfo, logOut} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [isexpired, setIsexpired] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [isold, setIsold] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [iserror1, setIserror1] = useState < boolean > (false);
    const [message1, setMessage1] = useState < string > ("");
    const [comm, setComm] = useState < boolean > (false);
    const [acad, setAcad] = useState < boolean > (false);
    const [students, setStudents] = useState < Student[] > ([]);
    const [events, setEvents] = useState < Event[] > ([]);
    const [inquiries, setInquiries] = useState < Inquiry[] > ([]);
    const [chats, setChats] = useState < Chat[] > ([]);
    const [tasks, setTasks] = useState < Chat[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
    const [circulars, setCirculars]= useState < Circular[] > ([]);
    const [nothing, setNothing] = useState < number > (0);
    const [isSuspended, setIsSuspended] = useState < boolean > (false);
    const [type, setType] = useState < string > ("");
    const [attendance, setAttendance]= useState < Attendance > ();
    const [studentAttend, setStudentAttend] = useState < StudentAttendance[] > ([]);
    const [staffAttend, setStaffAttend] = useState < StaffAttendance[] > ([]);
    const [unread, setUnread] = useState < number > (0);
    const [student, setStudent] = useState < Student > ();
    const [actionButtons, setActionButtons] = useState < ActionSheetButton[] > ([]);
    const [showActionSheet, setShowActionSheet] = useState < boolean > (false);
    const [scannerActive, setScannerActive] = useState < boolean > (false);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB');
    };

    const formatDateTime = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' }) + ' at ' + valueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
    };

    const options: InAppBrowserOptions = {
        location: 'no',
        hidden: 'no',
        zoom: 'yes',
        toolbar: 'yes',
        hidenavigationbuttons: 'yes',
        toolbarposition: 'bottom',
        closebuttoncaption: 'Close'
    }

    function openURL(value: string) {
        /*
        var url = value;
        if (!/^https?:\/\//i.test(value)) {
            url = 'http://' + value;
        }

        window.open(url, '_system');
        */

        let browser : InAppBrowserObject;

        // _blank for iphone, _self for others
        if (isPlatform('capacitor') && isPlatform('ios'))
        {
            browser = InAppBrowser.create(String(value), '_blank', options);
        }
        else
        {
            browser = InAppBrowser.create(String(value), '_self', options);
        }
    };

    const openScanner = async () => {
        try {
            const status = await BarcodeScanner.checkPermissions();

            if (status.camera !== 'granted')
            {
                const res = await BarcodeScanner.requestPermissions();
                if (res.camera === 'denied')
                {
                    setMessage(t("camera_denied"));
                    setIserror(true);
                }
                else if (res.camera === 'granted') {

                    document.body.classList.add('scanner-active');
                    document.getElementById("ongoing-trip")?.classList.add('hide');
                    setScannerActive(true);

                    // Add the `barcodeScanned` listener
                    const listener = await BarcodeScanner.addListener(
                        'barcodeScanned',
                        async (result) => {
                            await listener.remove();
                            document.body.classList.remove('scanner-active');
                            document.getElementById("ongoing-trip")?.classList.remove('hide');
                            setScannerActive(false);
                            await BarcodeScanner.stopScan();
                            handleScan(result.barcode.displayValue);
                        },
                    );

                    await BarcodeScanner.startScan({ formats: [BarcodeFormat.QrCode] });
                    
                }
            }
            else
            {
                document.body.classList.add('scanner-active');
                document.getElementById("ongoing-trip")?.classList.add('hide');
                setScannerActive(true);

                // Add the `barcodeScanned` listener
                const listener = await BarcodeScanner.addListener(
                    'barcodeScanned',
                    async (result) => {
                        await listener.remove();
                        document.body.classList.remove('scanner-active');
                        document.getElementById("ongoing-trip")?.classList.remove('hide');
                        setScannerActive(false);
                        await BarcodeScanner.stopScan();
                        handleScan(result.barcode.displayValue);
                    },
                );

                await BarcodeScanner.startScan({ formats: [BarcodeFormat.QrCode] });
            }

        } catch (error: any) {
            setMessage(t("qr_error"));
            setIserror(true);
        }
        
    };

    const stopScan = async () => {
        document.body.classList.remove('scanner-active');
        document.getElementById("ongoing-trip")?.classList.remove('hide');
        setScannerActive(false);
        await BarcodeScanner.removeAllListeners();
        await BarcodeScanner.stopScan();
    };


    const handleScan = async (data : string) => {

        const studentID = data.split("/").pop();

        if (studentID && studentID.match(/^[0-9a-fA-F]{24}$/))
        {
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });

            const result = await api.get('/students/' + studentID);
            setStudent(result.data);

            let cObjects: ActionSheetButton[] = [];

            cObjects.push({
                text: t("view_profile"),
                icon: !isPlatform('ios') ? accessibilityOutline : undefined,
                handler: () => history.push(`/app/student/${studentID}`)
            });

            if (((authInfo.institute._id === "623b78f1bbe7171e30738c7a") || (authInfo.institute._id === "623b7220bbe7171e30738a7c") || (authInfo.institute._id === "623b7808bbe7171e30738c74") || (authInfo.institute._id === "623b784fbbe7171e30738c77") ) && result.data.admnNo && (result.data.admnNo !== ""))
            {
                cObjects.push({
                    text: t("view_official_card"),
                    icon: !isPlatform('ios') ? idCardOutline : undefined,
                    handler: () => openURL(`https://softwareportal.gen.in/teacher/printidcard4app.php?id=${result.data.admnNo}`)
                });
            } 

            cObjects.push({
                text: t("view_ledger"),
                icon: !isPlatform('ios') ? cashOutline : undefined,
                handler: () => history.push(`/app/student/ledger/${studentID}`)
            });

            cObjects.push({
                text: t("view_communications"),
                icon: !isPlatform('ios') ? chatbubblesOutline : undefined,
                handler: () => history.push(`/app/inquiries/student/${studentID}`)
            });

            cObjects.push({
                text: t("view_attendance"),
                icon: !isPlatform('ios') ? calendarOutline : undefined,
                handler: () => history.push(`/app/student/attendance/${studentID}`)
            });

            if (!authInfo.institute.timetable)
            {
                cObjects.push({
                    text: t("view_submissions"),
                    icon: !isPlatform('ios') ? documentsOutline : undefined,
                    handler: () => history.push(`/app/submissions/student/${studentID}`)
                });
            } 

            if (authInfo.institute.medical)
            {
                cObjects.push({
                    text: t("view_records"),
                    icon: !isPlatform('ios') ? medicalOutline : undefined,
                    handler: () => history.push(`/app/student/incidents/${studentID}`)
                });
            }

            cObjects.push({
                text: t('cancel'),
                icon: !isPlatform('ios') ? closeOutline : undefined,
                role: 'cancel'
            });

            setActionButtons(cObjects);

            setShowActionSheet(true);
        }
        else 
        {
            setMessage(t("qr_error"));
            setIserror(true);
        }
        
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const upUser = (push: Token) => {
            return new Promise((resolve, reject) => {
              api.put('/users/'+authInfo.user._id, { 'push': push.value }).then(res => {
        
                return resolve(res.data);
                
              }).catch(err => reject(err));
    
            });
          }

        const register = () => {
            
            // If new device, this will run everytime
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
    
            // On success, we should be able to receive notifications
            PushNotifications.addListener('registration',
                (token: Token) => {
                    if(!authInfo.user.push)
                    {
                        upUser(token);
                    }
                    else if (authInfo.user.push !== token.value)
                    {
                        upUser(token);
                    }
                  
                }
            );
    
            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError',
                (error: any) => {
                    setMessage(t("push_error"));
                    setIserror(true);
                }
            );
    
            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification: PushNotificationSchema) => {

                }
            );
    
            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification: ActionPerformed) => {
                    
                }
            );
        }

        const fetchData = async () => {
            setShowLoading(true);
            try {

                setType(authInfo.institute.type);
                setComm(authInfo.institute.communication);
                setAcad(authInfo.institute.timetable);

                if (authInfo.institute.academics)
                {
                    const birthday = await api.get(`/students/getBirthdaySchool/${authInfo.user.instituteID}`);
                    setStudents(birthday.data);

                    const eve = await api.get(`/feeds/getEventsByInstitute/${authInfo.user.instituteID}`);
                    setEvents(eve.data);

                    const inq = await api.get(`/inquiries/getSomePendingInstitute/${authInfo.user.instituteID}`);
                    setInquiries(inq.data);

                    const circ = await api.get(`/circulars/getSomeByRole/${authInfo.user.instituteID}/${authInfo.user.role}`);
                    setCirculars(circ.data);

                    const chat = await api.get(`/staffChats/getOneByEmployee/${authInfo.user._id}`);
                    setChats(chat.data);

                    const task = await api.get(`/staffChats/getHomeByEmployee/${authInfo.user._id}`);
                    setTasks(task.data);

                    const entry = await api.get(`/staffAttends/getOneByEmployee/${authInfo.user._id}`);
                    setAttendance(entry.data);

                    if (!entry.data && (circ.data.length === 0) && (eve.data.length === 0) && (inq.data.length === 0) && (birthday.data.length === 0) && (chat.data.length === 0) && (task.data.length === 0))
                    {
                        setNothing(1);
                    }

                    const count = await api.get(`/notifications/getCountUser/${authInfo.user._id}`);
                    setUnread(count.data);

                    const attend = await api.get(`/attendances/getByInstitute/${authInfo.user.instituteID}`);
                    setStudentAttend(attend.data.attends);

                    const staff = await api.get(`/staffAttends/getLogAcademic/${authInfo.user.instituteID}`);
                    setStaffAttend(staff.data);

                }
                else
                {
                    setIsSuspended(true);
                }
 
               
                setShowLoading(false);
              
            } catch (error: any) {  
                if (error.response !== undefined) 
                {
                    if (error.response.data.message === 'expired')
                    {
                        setIsexpired(true);
                    }
                    else
                    {
                        setMessage(error.response.data.message);
                        setIserror(true);
                    }
                }   
                else 
                {
                    setMessage(t("unable_to_connect"));
                    setIserror(true);
                }
                setShowLoading(false);
            }
            
        };

        const checkIntent = () => {
           
            SendIntent.checkSendIntentReceived().then((result : any) => {
                if (result) {
                        
                    setMessage1(t("intent_error"));
                    setIserror1(true);
                    
                }
                else
                {
                    fetchData();
                }
            })
            .catch((err: any) => {
                fetchData();
            });

        }

        const fetchVersion = async () => {

            try {
                const result = await api.get(`/version-check`);
                if (parseInt(result.data) > parseInt(process.env.REACT_APP_VERSION!))
                {
                    setIsold(true);
                }
                else
                {
                    if (isPlatform('android') && isPlatform('capacitor'))
                    {
                        checkIntent();
                    }
                    else
                    {
                        fetchData();
                    }
                
                    if (isPlatform('capacitor'))
                    {
                        PushNotifications.checkPermissions().then((res) => {
                            if (res.receive !== 'granted') {
                            PushNotifications.requestPermissions().then((ress) => {
                                if (ress.receive === 'denied') {
                                    setMessage(t("push_denied"));
                                    setIserror(true);
                                }
                                else if (ress.receive === 'granted') {
                                    register();
                                }
                            });
                            }
                            else {
                                register();
                            }
                        });
                    }
                }
  

            } catch (error: any) {  
                if (error.response !== undefined) 
                {
                    if (error.response.data.message === 'expired')
                    {
                        setIsexpired(true);
                    }
                    else
                    {
                        setMessage(error.response.data.message);
                        setIserror(true);
                    }
                }   
                else 
                {
                    setMessage(t("unable_to_connect"));
                    setIserror(true);
                }

            }
            
        };

        fetchVersion();

        return () => {
            if (isPlatform('capacitor'))
            {
                stopScan();
            }
        }
       
    }, [authInfo, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary" className="text-capitalize">{t("hello")}, {authInfo.user.name}!</IonTitle>
                    <IonButtons slot="end">
                        {!isPlatform('mobile') && (
                        <>
                            <ThemeToggle />
                            <LanguageToggle />
                        </>
                        )}
                         <IonButton onClick={() => history.push('/app/user/notification')} fill="clear" className='ion-no-padding badge-button'> 
                            <IonIcon icon={notifications} color="secondary" className="badge-icon" />
                            {(unread !== 0) && (<span className="badge-label">{unread}</span>)}
                        </IonButton>
                        {(isPlatform("capacitor")) && !scannerActive && (
                        <IonButton onClick={openScanner} >
                            <IonIcon 
                                icon={qrCodeOutline}
                                color="warning" />
                        </IonButton>
                        )}
                        {scannerActive && (
                        <IonButton onClick={stopScan} >
                            <IonIcon 
                                icon={closeOutline}
                                color="warning" />
                        </IonButton>
                        )}
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" id="ongoing-trip">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary" className="text-capitalize">
                            {t("hello")}, {authInfo.user.name}!
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isexpired}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/login");
                    }}
                    header={t("session_expired")}
                    message={t("login_again")}
                    buttons={[`${t("logout")}`]}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={iserror1}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => SendIntent.finish()
                    }
                    header={t("error")}
                    message={message1}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isold}
                    backdropDismiss={false}
                    onDidDismiss={
                        () => document.location.href = isPlatform('android') ? process.env.REACT_APP_PLAY_STORE! : process.env.REACT_APP_APP_STORE!
                    }
                    header={t("outdated_version")}
                    message={t("outdated_msg")}
                    buttons={
                        [`${t("update_app")}`]
                    }
                />

                <IonAlert
                    cssClass="first-alert"
                    isOpen={isSuspended}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/home");
                    }}
                    header={t("suspended")}
                    message={t("account_suspended")}
                    buttons={[`${t("logout")}`]}
                />

                <IonActionSheet isOpen={showActionSheet}
                    onDidDismiss={
                        () => setShowActionSheet(false)
                    }
                    cssClass='swap-sheet'
                    subHeader={`${t("currently_selected")} - ${student?.name}`}
                    header={t("manage_student")}
                    buttons={actionButtons} 
                />

                 <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className='mb-60'>

                {(isPlatform("capacitor")) && (
                    <IonRow className='mt-30'>
                        <IonCol size="4">
                            <IonCard className="dash-card-5 ion-no-margin" routerLink={`/app/classes`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={(type === "school") ? t("manage_classes") : t("manage_batches")} src="assets/images/classes.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                            {(type === "school") ? t("manage_classes") : t("manage_batches")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard className="dash-card-4 ion-no-margin" routerLink={`/app/student`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("manage_students")} src="assets/images/children.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("manage_students")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                        <IonCol size="4">
                            <IonCard className="dash-card-2 ion-no-margin" routerLink={`/app/teachers`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("manage_teachers")} src="assets/images/teacher.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("manage_teachers")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        {acad && (
                        <IonCol size="4">
                            <IonCard className="dash-card-3 ion-no-margin" routerLink={`/app/subjects`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={t("manage_subjects")} src="assets/images/subjects.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                            {t("manage_subjects")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                        {acad && (
                        <IonCol size="4">
                            <IonCard className="dash-card-6 ion-no-margin" routerLink={`/app/timetables`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt={t("manage_time_tables")} src="assets/images/events.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                                {t("manage_time_tables")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}
                         
                        {comm && (
                        <IonCol size="4">
                            <IonCard className="dash-card-1 ion-no-margin" routerLink={`/app/circulars`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt={(type === "school") ? t("class_comm") : t("batch_comm")} src="assets/images/circulars.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title-1 ion-no-padding">
                                            {(type === "school") ? t("class_comm") : t("batch_comm")}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        )}


                    </IonRow>
                    
                )}

                {(nothing === 1) && (
                    <IonRow className="ion-margin">
                        <IonCol>
                            <IonCard className='red-card'>
                                <IonCardContent>
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                            slot="start"
                                            color="danger"
                                            size="large" />
                                        <IonLabel className="list-title ion-text-wrap"><h3>{t("no_updates")}</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                )}

                {(attendance) && (
                    <>
                    <IonRow className="ion-padding-vertical">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className="title-heading">
                                    {t("attendance_status")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                    
                            <IonCard className={
                                    (attendance.status === 'in') ? 'success-card' : 'danger-card'
                                }>
                                <IonCardContent className='ion-no-padding ion-padding-vertical'>
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}
                                            
                                            >
                                            <IonIcon slot="start"
                                                icon={time}
                                                color={
                                                    (attendance.status === 'in') ? 'success' : 'danger'
                                                    }
                                                />
                                                <IonLabel className="recent-label ion-text-wrap">
                                                        
                                                        {(attendance.status === 'out') && (
                                                            <>
                                                                <h3>{t("your_out_time")} </h3>
                                                                <p>on {formatDateTime(attendance.createdAt)}</p>
                                                            </>
                                                        )}

                                                        {(attendance.status === 'in') && (
                                                            <>
                                                                <h3>{t("your_in_time")} </h3>
                                                                <p>on {formatDateTime(attendance.createdAt)}</p>
                                                            </>
                                                        )}
                                                        
                                                </IonLabel>
                                        </IonItem>
                                    </IonCardContent>
                            </IonCard>
                        
                        {(attendance.status === 'in') && (
                            <IonButton expand="block" color="dark" className='ion-margin-horizontal' onClick={() => history.push('/app/user/attendance')}>{t("mark_exit")}</IonButton>
                        )}
                        </IonCol>
                    </IonRow>
                </>
                )}

                {circulars && (circulars.length > 0) && 
                (<CircularsSwiper circulars={circulars} />)}

                    <IonRow className="ion-margin-vertical">
                        <IonCol className='ion-no-padding'>
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{768: 1, 992: 2, 1200: 3}}
                            >
                                <Masonry>
                            {(tasks.length > 0) && (

                                    <IonCard className='ion-margin'>
                                        <IonCardHeader>
                                            <IonRow>
                                                <IonCol size="8">
                                                    <IonCardSubtitle className='info-subtitle pt-08'>{t("my_tasks")}</IonCardSubtitle>
                                                </IonCol>
                                                <IonCol size="4" className='ion-text-right'>
                                                    <IonButton color="danger" size="small" className='pr-16' onClick={() => history.push(`/app/user/tasks`)}>{t("view_all")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>

                                                    <IonList className='ion-no-padding'>
                                                        {(tasks.map((chat, i) => {
                                                                return (
                                                                    <IonItem detail={true}
                                                                    detailIcon={chevronForward}
                                                                    lines='full'
                                                                    button={true}
                                                                    key={chat._id}
                                                                    onClick={
                                                                        () => history.push(`/app/user/task/${chat._id}`)
                                                                    }>
                                                                    <IonIcon slot="start"
                                                                        icon={create}
                                                                        color='danger'/>
                                                                    <IonLabel className="list-label text-capitalize ion-text-wrap">
                                                                        <h3>{chat.title}
                                                                        <IonBadge className='ml-05' color={(chat.taskInfo!.priority === "high") ? "danger" : (chat.taskInfo!.priority === "medium") ? "warning" : "medium"}>
                                                                            {t(chat.taskInfo!.priority)}
                                                                        </IonBadge>
                                                                        <br/> 
                                                                        {t(chat.status)} <br/>
                                                                        {formatDate(chat.taskInfo!.startDate)} {t("to")} {formatDate(chat.taskInfo!.endDate)}
                                                                        </h3>
                                                                    </IonLabel>
                                                                </IonItem>
                                                                )
                                                            }))
                                                            } 
                                                    </IonList>
                                                </IonCol>
                                            </IonRow>     
                                        
                                            
                                        </IonCardContent>
                                    </IonCard>

                                )}

                            {(chats.length > 0) && (
                                <IonCard className='ion-margin'>
                                    <IonCardHeader>
                                        <IonRow>
                                            <IonCol size="8">
                                                <IonCardSubtitle className='info-subtitle pt-08'>{t("my_chats")}</IonCardSubtitle>
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right'>
                                                <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/user/chats`)}>{t("view_all")}</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>

                                                <IonList className='ion-no-padding'>
                                                    {(chats.map((chat, i) => {
                                                            return (
                                                                <IonItem detail={true}
                                                                detailIcon={chevronForward}
                                                                lines='full'
                                                                button={true}
                                                                key={chat._id}
                                                                onClick={
                                                                    () => history.push(`/app/user/chat/${chat._id}`)
                                                                }>
                                                                <IonIcon slot="start"
                                                                    icon={chatbubbles}
                                                                    color='secondary'/>
                                                                <IonLabel className="list-label text-capitalize">
                                                                    <h3>{chat.title}<br/> {chat.employee ? chat.employee.name : `${t("employees")}: ${chat.employees!.length}`} ({chat.type})<br/>{formatDate(chat.createdAt)}</h3>
                                                                </IonLabel>
                                                            </IonItem>
                                                            )
                                                        }))
                                                        } 
                                                </IonList>
                                            </IonCol>
                                        </IonRow>     
                                    
                                        
                                    </IonCardContent>
                                </IonCard>
                            )}

                            {(inquiries.length > 0) && (
                                    <IonCard className="ion-margin">
                                        <IonCardHeader>
                                            <IonRow>
                                                <IonCol size="8">
                                                    <IonCardSubtitle className='info-subtitle pt-08'>{t("manage_inquiries")}</IonCardSubtitle>
                                                </IonCol>
                                                <IonCol size="4" className='ion-text-right'>
                                                    <IonButton color="tertiary" size="small" className='pr-16' onClick={() => history.push(`/app/inquiries/pending`)}>{t("view_all")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>

                                                    <IonList className='ion-no-padding'>
                                                        {(inquiries.map((inquiry, i) => {
                                                                return (
                                                                    <IonItem detail={true}
                                                                    detailIcon={chevronForward}
                                                                    lines='full'
                                                                    button={true}
                                                                    key={inquiry._id}
                                                                    onClick={
                                                                        () => history.push(`/app/inquiry/${inquiry._id}`)
                                                                    }>
                                                                    <IonIcon slot="start"
                                                                        icon={chatbubbles}
                                                                        color='tertiary'/>
                                                                    <IonLabel className="list-label text-capitalize">
                                                                        <h3>{inquiry.title}<br/>{inquiry.student ? inquiry.student.name : `${t("students")}: ${inquiry.students!.length}`} ({inquiry.type})<br/>{formatDate(inquiry.createdAt)}</h3>
                                                                    </IonLabel>
                                                                </IonItem>
                                                                )
                                                            }))
                                                            } 
                                                    </IonList>
                                                </IonCol>
                                            </IonRow>     
                                        
                                            
                                        </IonCardContent>
                                    </IonCard>
                            )}

                            {(events.length > 0) && (
                                    <IonCard className='ion-margin'>
                                        <IonCardHeader>
                                            <IonRow>
                                                <IonCol size="8">
                                                    <IonCardSubtitle className='info-subtitle pt-08'>{t("upcoming_events")}</IonCardSubtitle>
                                                </IonCol>
                                                <IonCol size="4" className='ion-text-right'>
                                                    <IonButton color="primary" size="small" className='pr-16' onClick={() => history.push(`/app/planner/view`)}>{t("view_all")}</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardHeader>
                                        <IonCardContent className="ion-no-padding">
                                            <IonRow>
                                                <IonCol>

                                        {events.map((event) => {
                                        return (
                                            <IonCard className="planner-card-2" key={event._id}>
                                                <IonCardContent>
                                                    <IonRow>
                                                        <IonCol>            
                                                            <IonItem lines="none"
                                                                button={false}
                                                                detail={false}
                                                                >
                                                                    <IonIcon icon={sparkles}
                                                                        slot="start"
                                                                        color="primary" />
                                                                    <IonLabel className="action-title-one">
                                                                        <h3>{event.title}</h3>
                                                                        <p>{formatDate(event.calendarDate)}</p>
                                                                        </IonLabel>
                                                                
                                                                </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>            
                                                            <IonText>{event.description}</IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCardContent>
                                            </IonCard>
                                                );
                                            })
                                        }
                                                </IonCol>
                                            </IonRow>          
                                            
                                        </IonCardContent>
                                    </IonCard>
                            )}
                            {(students.length > 0) && (
                                    <IonCard className='ion-margin'>
                                        <IonCardHeader>
                                            <IonRow>
                                                <IonCol>
                                                    <IonCardSubtitle className='info-subtitle pt-08'>{t("birthdays_this_week")}</IonCardSubtitle>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>

                                                    <IonList className='ion-no-padding'>
                                                        {(students.map((student, i) => {
                                                                return (
                                                                    <IonItem detail={false}
                                                                        lines='inset'
                                                                        button={false}
                                                                        key={student._id}
                                                                        className="leaderboard-item"
                                                                        >
                                                                    
                                                                        <IonAvatar className="leaderboard-avatar" onClick={() => history.push(`/app/student/${student._id}`)}>
                                                                        {student.photo && (student.photo !== "") ?
                                                                            (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${student.photo}`} alt={student.name} />)
                                                                            : (<img src={`assets/images/avatar.png`} alt={student.name} />)
                                                                        }
                                                                        </IonAvatar>
                                                                        <IonLabel className='leaderboard-name'>
                                                                            <h3>{student.name}</h3>
                                                                            <p>{formatDate(student.dob)}</p>
                                                                        </IonLabel>
                                                                    
                                                                    </IonItem>
                                                                )
                                                            }))
                                                            } 
                                                    </IonList>
                                                </IonCol>
                                            </IonRow>     
                                        
                                            
                                        </IonCardContent>
                                    </IonCard>
                                    )}
                                    {(studentAttend.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("attend_logs")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/attend/log`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent className='ion-no-padding'>
                                                <IonRow>
                                                    <IonCol className='ion-no-padding'>
                                                        <IonList className='ion-no-padding'>
                                                        {(studentAttend.map((attend, i) => {
                                                        return (
                                                            <IonItem detail={false}
                                                                lines='full'
                                                                button={false}
                                                                key={attend._id}
                                                                className={((i%2) === 0) ? 'alt-item' : ''}
                                                            >
                                                                <IonIcon slot="start"
                                                                    icon={time}
                                                                    color={
                                                                        ((attend.status === 'in') || (attend.status === 'entry') || (attend.status === 'reached')) ? 'success' : ((attend.status === 'picked') || (attend.status === 'left')) ? 'warning' : (attend.status === 'custody') ? 'tertiary' : 'danger'
                                                                    }/>
                                                                    <IonLabel className="list-label ion-text-wrap text-capitalize">
                                                                       <h3>
                                                                    <Link to={`/app/entry/log/${attend.studentID}`}>{attend.studentName}</Link>
                                                                        {(attend.status === 'out') && (
                                                                            <span> {t("exit_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'in') && (
                                                                            <span> {t("entry_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'entry') && (
                                                                            <span> {(authInfo.institute.type === "school") ? t("entered_school_on") : t("entered_insti_on")} on {formatDate(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'custody') && (
                                                                            <span> {t("custody_with_parent")} on {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'picked') && (
                                                                            <span> {t("picked_by_transport")} on {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'reached') && (
                                                                            <span> {t("reached_by_transport")} on {formatDate(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'left') && (
                                                                            <span> {t("left_by_transport")} on {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'dropped') && (
                                                                            <span> {t("dropped_by_transport")} on {formatDate(attend.createdAt)}</span>
                                                                        )}
                                                                        </h3> 
                                                                    </IonLabel>
                                                            </IonItem>
                                                        )
                                                        }))
                                                        } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    )}
                                      {(staffAttend.length > 0) && (
                                        <IonCard className='ion-margin'>
                                            <IonCardHeader>
                                                <IonRow>
                                                    <IonCol size="8">
                                                        <IonCardSubtitle className='info-subtitle pt-08'>{t("staff_attend_logs")}</IonCardSubtitle>
                                                    </IonCol>
                                                    <IonCol size="4" className='ion-text-right'>
                                                        <IonButton color="secondary" size="small" className='pr-16' onClick={() => history.push(`/app/attendance/teacher/log`)}>{t("view_all")}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardHeader>
                                            <IonCardContent className='ion-no-padding'>
                                                <IonRow>
                                                    <IonCol className='ion-no-padding'>
                                                        <IonList className='ion-no-padding'>
                                                        {(staffAttend.map((attend, i) => {
                                                        return (
                                                            <IonItem detail={false}
                                                                lines='full'
                                                                button={false}
                                                                key={attend._id}
                                                                className={((i%2) === 0) ? 'alt-item' : ''}
                                                            >
                                                                <IonIcon slot="start"
                                                                    icon={time}
                                                                    color={
                                                                        (attend.status === 'in') ? 'success' : 'danger'
                                                                    }/>
                                                                    <IonLabel className="list-label ion-text-wrap text-capitalize">
                                                                        <h3>
                                                                        <Link to={`/app/attendance/teacher/log/${attend.employee.employeeID}`}>{attend.employee.name}</Link>
                                                                        {(attend.status === 'out') && (
                                                                            <span> {t("exit_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        {(attend.status === 'in') && (
                                                                            <span> {t("entry_at")} {formatDateTime(attend.createdAt)}</span>
                                                                        )}
                                                                        </h3>
                                                                    </IonLabel>
                                                            </IonItem>
                                                        )
                                                        }))
                                                        } 
                                                        </IonList>
                                                    </IonCol>
                                                </IonRow>     
                                            
                                                
                                            </IonCardContent>
                                        </IonCard>
                                    )}
                                </Masonry>
                            </ResponsiveMasonry>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default AcademicHome;