import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    IonFooter,
    IonToggle,
    IonSelect,
    IonSelectOption,
    isPlatform,
    IonIcon,
    IonPopover,
    IonText
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';
import { useTranslation } from "react-i18next";
import ThemeToggle from '../../../components/ThemeToggle';
import LanguageToggle from '../../../components/LanguageToggle';
import { addCircle, alertCircleOutline, arrowUndoCircle, personRemove, woman } from 'ionicons/icons';

interface ParamTypes {
    subjectID: string
}

interface Course {
    _id: string,
    name: string,
    isActive: boolean
}
interface AllTeacher {
    _id: string,
    name: string,
    isActive: boolean
}
interface Teacher {
    teacherID: string,
    teacherName: string,
}

const SubjectDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {t} = useTranslation();
    const {subjectID} = useParams < ParamTypes > ();
    const [teachers, setTeachers] = useState < Teacher[] > ([]);
    const [allTeachers, setAllTeachers] = useState < AllTeacher[] > ([]);
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [academic, setAcademic] = useState < boolean > (false);
    const [courses, setCourses] = useState < Course[] > ([]);
    const [course, setCourse] = useState < string > ("");
    const [selectedTeacher, setSelectedTeacher] = useState < string > ("");
    const popover = useRef<HTMLIonPopoverElement>(null);
    const [pop, setPop] = useState < boolean > (false);
    const [refreshed, setRefreshed] = useState < number > (0);

    const handleToggle = (syllabus: boolean) => {
        
        if (syllabus)
        {
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });
    
            const fetchData = async () => {
                setShowLoading(true);
                try {
                    const result = await api.get('/courses/');
                    setCourses(result.data);
                    setShowLoading(false);
    
                } catch (error : any) {
                    if (error.response !== undefined) 
                        setMessage(error.response.data.message);
                     else 
                        setMessage(t("something_went_wrong"));
                    
                    setIserror(true);
                    setShowLoading(false);
                }
            };
    
            fetchData();
        }
        else
        {
            setCourses([]);
            setCourse("");
        }
        setAcademic(syllabus);
    }

    const handleSection = (section: string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

    const toggleSubject = (isActive: string) => {
        return new Promise((resolve, reject) => {
            api.put(`/subjects/${subjectID}`, { 'isActive': section }).then(res => {
                return resolve(res.data.name);
              }).catch(err => reject(err));
        });
      }

        if ((active !== "") && (section !== active))
        {
            setShowLoading(true);
            toggleSubject(section)
            .then(data => {
                let msg: string = "";
                if (section === 'enable')
                {
                    setActive("enable");
                    msg = data+t("now_active");
                }
                    
                if (section === 'disable')
                {
                    setActive("disable");
                    msg = data+t("now_inactive");
                }
                    
                setPass(msg);
                setIspass(true);
                setShowLoading(false);
            })
            .catch(error => {
                if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));

                setIserror(true);
                setShowLoading(false);
            });
        }
    }

    const handleAdd = () => {

        if (!selectedTeacher || selectedTeacher === "") {
            setMessage(t("teacher_mandatory"));
            setIserror(true);
            return;
        }

        if (teachers.find(e => e.teacherID === selectedTeacher)) {
            setMessage(t("teacher_added"));
            setIserror(true);
            return;
          }

          
        const result = allTeachers.find(item => item._id === selectedTeacher);

        let teachs = [...teachers];
        teachs.push({'teacherName': result!.name, 'teacherID': selectedTeacher});
        setTeachers(teachs);

        popover.current?.dismiss();
    }

    const handleRemove = (index: number) => {
        let teachs = [...teachers];
        if (index > -1) {
            teachs.splice(index, 1); // 2nd parameter means remove one item only
          }
        setTeachers(teachs);
    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage(t("name_mandatory"));
            setIserror(true);
            return;
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
              }
          });
    
          const upSubject = (name: string ) => {
            return new Promise((resolve, reject) => {
              api.put('/subjects/'+subjectID, { name, teachers, 'subjectType': 'general' }).then(res => {
                return resolve(res.data.name);
              }).catch(err => reject(err));
    
            });
          }

          const upAcadSubject = (name: string, courseInfo: { courseID: string; courseName: string; } ) => {
            return new Promise((resolve, reject) => {
              api.put('/subjects/'+subjectID, { name, teachers, courseInfo }).then(res => {
                return resolve(res.data.name);
              }).catch(err => reject(err));
    
            });
          }

          
        if (academic && course && course !== "")
        {
            const courseInfo = {
                'courseID': course,
                'courseName': courses.find((val) => val._id === course)!.name
            };
            setShowLoading(true);
            upAcadSubject(name, courseInfo)
            .then(data => {
      
                setPass(data+t("has_been_updated"));
                setIspass(true);
                setShowLoading(false);
              
            })
            .catch(error => {
                if (error.response !== undefined)
                  setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            });
        }
        else
        {
            setShowLoading(true);
            upSubject(name)
            .then(data => {
      
                setPass(data+t("has_been_updated"));
                setIspass(true);
                setShowLoading(false);
              
            })
            .catch(error => {
                if (error.response !== undefined)
                  setMessage(error.response.data.message);
                else
                    setMessage(t("something_went_wrong"));
                setIserror(true);
                setShowLoading(false);
            });
        }
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                
                const result = await api.get('/subjects/' + subjectID);

                setName(result.data.name);
                if(result.data.subjectType === 'academic')
                {
                    setAcademic(true);
                    const res = await api.get('/courses/');
                    setCourses(res.data);
                    setCourse(result.data.courseInfo.courseID);
                }

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                setTeachers(result.data.teachers);

                const all = await api.get(`/users/${result.data.instituteID}/teachers`);
                setAllTeachers(all.data);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage(t("something_went_wrong"));
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, subjectID, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary" />
                        <IonBackButton color="secondary" defaultHref="/app" />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_subject")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                        <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_subject")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonAlert isOpen={ispass}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header={t("success")}
                    message={pass}
                    buttons={
                        [`${t("close")}`]
                    }/>

                <IonGrid className="ion-no-padding">
                
                    <IonRow className="mt-30">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                                <IonTitle className='border-bottom-sec ion-padding-bottom'>
                                    {t("subject_details")}
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="mt-10">
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard className='border-radius-10 active-group'>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="8">
                                                
                                                {t("current_status")}
                                               
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                            {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>{t("active")}</IonBadge>) : 
                                            ( <IonBadge color="danger" className='ion-padding-horizontal'>{t("inactive")}</IonBadge>)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-top ion-padding-horizontal">
                                            <IonCol>
                                                <IonSegment value={active} onIonChange={e => handleSection( e.detail.value!.toString() )} mode="ios" className="isactive-segment">
                                                    <IonSegmentButton value="enable">
                                                        <IonLabel>{t("make_active")}</IonLabel>
                                                    </IonSegmentButton>
                                                    <IonSegmentButton value="disable">
                                                        <IonLabel>{t("make_inactive")}</IonLabel>
                                                    </IonSegmentButton>
                                                </IonSegment>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                            {t("subject_name")}
                                        </IonLabel>
                                            <IonInput type="text"
                                                className="input-field"
                                                placeholder={t("subject_name")}
                                                value={name}
                                                onIonInput={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                  

                              {/*
                                <IonItem detail={false}
                                    lines='full'
                                    button={false}
                                    color="tertiary"
                                    >
                                    <IonLabel>Assign Syllabus?</IonLabel>
                                    <IonToggle slot="end" color="secondary" checked={academic} onIonChange={e => handleToggle(e.detail.checked)} />
                                </IonItem>

                                    {academic && (courses.length > 0) && (
                                         <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">Course</IonLabel>
                                                    <IonSelect value={course}
                                                            placeholder="Select Course"
                                                            onIonChange={
                                                                (e) => setCourse(e.detail.value)
                                                        }>

                                                        { courses.map((cou) => {
                                                                if (cou.isActive)
                                                                {
                                                                    return (<IonSelectOption key={cou._id} value={`${cou._id}`}>{cou.name}</IonSelectOption>);
                                                                }
                                                                else
                                                                {
                                                                    return null;
                                                                }
                                                            })
                                                        }
                                            </IonSelect>
                                        </IonItem>
                                        )}

                                */}
                           
                    <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12" className='border-bottom-sec ion-padding-bottom'>
                               
                                <IonRow>
                                    <IonCol size="8" className='mt-05'>
                                        <IonText className='list-title ion-padding-start'>
                                            {t("subject_teachers")}
                                        </IonText>
                                    </IonCol>

                                    <IonCol size="2" className='ion-text-right'>
                                    
                                        <IonIcon size="large"
                                            icon={arrowUndoCircle}
                                            color='secondary'
                                            onClick={() => setRefreshed(Math.random())}
                                        />
                                    
                                    
                                    </IonCol>
                                    <IonCol size="2" className='ion-text-center'>
                                        
                                            <IonIcon size="large"
                                                icon={addCircle}
                                                color='secondary'
                                                onClick={() => setPop(true)}
                                            />
                                        
                                        
                                    </IonCol>
                                </IonRow>
                                
                        </IonCol>
                    </IonRow>
                  
                    <IonPopover ref={popover} isOpen={pop} size='auto' onIonPopoverDidDismiss={() => setPop(false)}>
                        <IonGrid className="ion-no-padding ion-no-margin">
                            <IonRow>
                                <IonCol className='ion-padding'>
                                    <IonCard>
                                        <IonCardContent className="ion-no-padding">
                                            <IonItem detail={false}
                                                lines='full'
                                                button={false}
                                                
                                                >
                                                
                                                <IonLabel position="stacked" color="secondary">{t("teacher")}*</IonLabel>
                                                    <IonSelect value={selectedTeacher}
                                                                    placeholder={t("select_teacher")}
                                                                    onIonChange={
                                                                        (e) => setSelectedTeacher(e.detail.value)
                                                                }>
                                                                { allTeachers.map((teacher) => {
                                                                        
                                                                    return (<IonSelectOption key={teacher._id} value={`${teacher._id}`}>{teacher.name}</IonSelectOption>);
                                                                        
                                                                    })
                                                                }
                                                    </IonSelect>
                                                            
                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-padding-horizontal ion-padding-bottom">
                                    <IonButton onClick={handleAdd}
                                        expand="block"
                                        className="first-button"
                                        fill="clear">{t("add_teacher")}</IonButton>

                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonPopover>

                   <IonRow className='mt-30'>
                        <IonCol offsetXl="4" offsetMd="2" offset="0" sizeXl="4" sizeMd="8" size="12">
                          <IonList className="ion-no-padding">
                             {(teachers.length > 0) ? (teachers.map((teacher, i) => {
                                    return (
                                        <IonItem detail={true}
                                            detailIcon={personRemove}
                                            lines='full'
                                            button={true}
                                            key={teacher.teacherID}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                            onClick={
                                                () => handleRemove(i)
                                            }>
                                            <IonIcon slot="start"
                                                icon={woman}
                                                />
                                            <IonLabel className='list-label'>
                                                <h3>{teacher.teacherName}</h3>
                                            </IonLabel>
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start" 
                                        color="danger" />
                                        <IonLabel className="list-label"><h3>{t("no_teachers")}</h3></IonLabel>

                                    </IonItem>
                                )
                                } </IonList>
                        </IonCol>
                    </IonRow>


                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear">{t("update_subject")}</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default SubjectDetail;
