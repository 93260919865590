import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, chatboxEllipses, checkboxOutline } from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useAuth} from '../../../../components/AuthContext';
import ThemeToggle from '../../../../components/ThemeToggle';
import LanguageToggle from '../../../../components/LanguageToggle';
import SwitchBranch from '../../../../components/SwitchBranch';
import usePageUnloadHandler from '../../../../components/usePageUnloadHandler';

interface Chat {
    _id: string,
    title: string,
    status: string,
    employee: Employee,
    createdAt: Date,
    taskInfo: TaskInfo
}

interface TaskInfo {
    projectID: string,
    startDate: Date,
    endDate: Date,
    priority: string
}

interface Employee {
    employeeID: string,
    name: string
}

interface Project {
    _id: string,
    name: string,
    status: string
}

interface ParamTypes {
    projectID?: string
}

const Tasks: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {t} = useTranslation();
    const {projectID} = useParams < ParamTypes > ();
    const [chats, setChats] = useState < Chat[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [skip, setSkip] = useState < number > (0);
    const [current, setCurrent] = useState < Chat > ();
    const [total, setTotal] = useState < number > (0);
    const [status, setStatus] = useState < string > ("all");
    const [projects, setProjects] = useState < Project[] > ([]);
    const [project, setProject] = useState < string > ("all");
    const [priority, setPriority] = useState < string > ("all");
    const [toDate, setToDate] = useState < string | string[] | undefined > ();
    const [fromDate, setFromDate] = useState < string | string[] | undefined> ();
    const [searchText, setSearchText] = useState < string > ("");
    const [scrollPosition, setScrollPosition] = useState < number > (0);
    const scrollContent = useRef<HTMLIonContentElement | null> (null);
    const [initialRender, setInitialRender] = useState < boolean > (true);

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: parseInt(process.env.REACT_APP_API_TO!),
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
        }
    });
   
    const handleScrollEnd = async () => {
        const elem = await scrollContent.current?.getScrollElement();
        if(elem) {
            setScrollPosition(elem.scrollTop);
        }
    };

    const formatDate = (value: Date) => {
        const valueDate = new Date(value);
        return valueDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' });
    };

    const formatDated = (value: string | string[] | undefined) => {
        if (typeof value === "string") 
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        else
        {
            return t("pick_date");
        }
    };

    const handleSubmit = () => {
        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof fromDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }
        
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        const fetchUp = async () => {
            
            setShowLoading(true);
            try {
                const result = await api.post(`/staffChats/listTaskInstitute/${authInfo.user.instituteID}`, {status, project, priority, toDOB, fromDOB, searchText});
                setChats(result.data.tasks);
                setTotal(result.data.total);

            } catch (error: any) {
                setMessage(error.response?.data.message || t("something_went_wrong"));
                setIserror(true);
            } finally {
                setShowLoading(false);
            }
            
        };

        fetchUp();
    };

    const loadItems = async () => {

        let limit = skip + 30;
        if (((typeof toDate === "string") && (typeof fromDate !== "string")) || ((typeof toDate !== "string") && (typeof toDate === "string")))
        {
            setMessage(t("dates_mandatory"));
            setIserror(true);
            return;
        }
        let toDOB = "";
        let fromDOB = "";

        if ((typeof toDate === "string") && (typeof fromDate === "string"))
        {
            const tDate = new Date(toDate);
            const fDate = new Date(fromDate);
            if (tDate < fDate)
            {
                setMessage(t("dates_invalid"));
                setIserror(true);
                return;
            }     
            else
            {
                toDOB = toDate;
                fromDOB = fromDate;
            }
        }

        try {
            const result = await api.post(`/staffChats/listTaskInstitute/${authInfo.user.instituteID}?skip=${limit}`, {status, project, priority, toDOB, fromDOB, searchText});

            if(result.data.tasks && result.data.tasks.length)
            {
                setChats([...chats, ...result.data.tasks]); 
                setSkip(limit);
            }
                

        } catch (error: any) {
            setMessage(error.response?.data.message || t("something_went_wrong"));
            setIserror(true);
        }
    };

    useEffect(() => {

        const storedState = sessionStorage.getItem(history.location.pathname);
        if (history.action === "POP" && storedState) {
            const { chats, projects, total, skip, status, priority, project, toDate, fromDate, searchText, scrollPosition } = JSON.parse(storedState);
                // Data is present in local storage, use it to initialize state
                setChats(chats);
                setProjects(projects);
                setTotal(total);
                setSkip(skip);
                setStatus(status);
                setPriority(priority);
                setProject(project);
                setToDate(toDate);
                setFromDate(fromDate);
                setSearchText(searchText);
               
                setTimeout(() => scrollContent.current?.scrollToPoint(0, scrollPosition, 500), 500);
            } 
            else 
            {

                const fetchUp = async () => {
                    setShowLoading(true);
                    try {
                    
                        const result = await api.get(`/projects/getAllInstitute/${authInfo.user.instituteID}`);
                        setProjects(result.data);

                        if (projectID){
                            setProject(projectID);
                            const result = await api.post(`/staffChats/listTaskInstitute/${authInfo.user.instituteID}`, {'status': 'all', 'project': projectID, 'priority': 'all', 'toDOB' : '', 'fromDOB': '', 'searchText': ''});
                            setChats(result.data.tasks);
                            setTotal(result.data.total);
                        }
                        else
                        {
                            const result = await api.post(`/staffChats/listTaskInstitute/${authInfo.user.instituteID}`, {'status': 'all', 'project': 'all', 'priority': 'all', 'toDOB' : '', 'fromDOB': '', 'searchText': ''});
                            setChats(result.data.tasks);
                            setTotal(result.data.total);
                            setProject("all");
                        }
                        setSkip(0);

                    } catch (error: any) {
                        setMessage(error.response?.data.message || t("something_went_wrong"));
                        setIserror(true);
                    } finally {
                        setShowLoading(false);
                    }
                };

                fetchUp();
            }
       
        setInitialRender(false);

    }, [authInfo, projectID, history.action, history.location.pathname]);

    // Save state to local storage whenever it changes
    useEffect(() => {
        if (!initialRender) 
        {
            const currentState = {
            chats,
            projects,
            total,
            skip,
            status,
            priority,
            project,
            toDate,
            fromDate,
            searchText,
            scrollPosition
            };
            sessionStorage.setItem(history.location.pathname, JSON.stringify(currentState));
        
        }
    }, [chats, projects, total, skip, status, priority, project, toDate, fromDate, searchText, scrollPosition, history.location.pathname, initialRender]);

    // Effect to handle page reload 
    usePageUnloadHandler();

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                        <IonBackButton color="secondary" defaultHref={`/app/staff`} />
                    </IonButtons>
                    <IonTitle size={isPlatform('mobile') ? "small" : undefined} color="secondary">{t("manage_tasks")}</IonTitle>
                    {!isPlatform('mobile') && (
                    <IonButtons slot="end">
                       <ThemeToggle />
                        <LanguageToggle />
                   </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade" ref={scrollContent} scrollEvents={true} onIonScrollEnd={handleScrollEnd}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">{t("manage_tasks")}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message={t("please_wait")}
                />

                <IonAlert isOpen={iserror}
                    cssClass="first-alert"
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header={t("error")}
                    message={message}
                    buttons={
                        [`${t("close")}`]
                    }
                />

                {(authInfo.user.role === "Super") && (
                    <SwitchBranch />
                )}

                <IonGrid className="ion-no-padding overflow-x">
                    <IonRow className="min-xl">

                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                     <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("current_status")}</IonLabel>
                                            <IonSelect value={status}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("current_status")}
                                                    onIonChange={
                                                        (e) => e.detail.value && setStatus(e.detail.value)
                                                }>
                                                    <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    <IonSelectOption value="upcoming">{t("upcoming")}</IonSelectOption>
                                                    <IonSelectOption value="ongoing">{t("ongoing")}</IonSelectOption>
                                                    <IonSelectOption value="review">{t("review")}</IonSelectOption>
                                                    <IonSelectOption value="completed">{t("completed")}</IonSelectOption>
                                                    <IonSelectOption value="deffered">{t("deffered")}</IonSelectOption>
                                                    <IonSelectOption value="reopened">{t("reopened")}</IonSelectOption>

                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("select_proj")}</IonLabel>
                                            <IonSelect value={project}
                                                    cancelText={t("cancel")}
                                                    okText={t("ok")}
                                                    placeholder={t("select_proj")}
                                                    onIonChange={
                                                        (e) => setProject(e.detail.value)
                                                }>
                                                        <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                                    {projects.map((proj, i) => (
                                                       
                                                        (<IonSelectOption key={i} value={proj._id}>{proj.name}</IonSelectOption>)
                                                    ))}
                                            </IonSelect>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                        lines='full'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("select_priority")}
                                        </IonLabel>
                                        <IonSelect value={priority}
                                            cancelText={t("cancel")}
                                            okText={t("ok")}
                                            placeholder={t("select_priority")}
                                            onIonChange={
                                                (e) => setPriority(e.detail.value)
                                            }>
                                            <IonSelectOption value="all">{t("all")}</IonSelectOption>
                                            <IonSelectOption value="high">{t("high")}</IonSelectOption>  
                                            <IonSelectOption value="medium">{t("medium")}</IonSelectOption>  
                                            <IonSelectOption value="low">{t("low")}</IonSelectOption>   
                                            
                                        </IonSelect>
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                        
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-from-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("from_date")}
                                            </IonLabel>
                                            <IonInput value={formatDated(fromDate)} readonly={true} />
                                            <IonModal trigger="open-from-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("from_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={fromDate}
                                                        presentation="date"
                                                        onIonChange={ev => setFromDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            id="open-to-input">
                                            <IonLabel position="stacked" className="input-label" color="secondary">
                                                {t("to_date")}
                                            </IonLabel>
                                            <IonInput value={formatDated(toDate)} readonly={true} />
                                            <IonModal trigger="open-to-input" showBackdrop={true} 
                                                initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.9]} handle={true}>
                                                <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                                    <IonToolbar>
                                                        <IonTitle>{t("to_date")}</IonTitle>
                                                        
                                                    </IonToolbar>
                                                    <IonDatetime
                                                     cancelText={t("cancel")}
                                                     doneText={t("done")}
                                                        value={toDate}
                                                        presentation="date"
                                                        onIonChange={ev => setToDate(ev.detail.value!)}
                                                        showDefaultButtons={true}
                                                        size="cover"
                                                    />
                                                </IonContent>
                                                
                                            </IonModal>
                                        </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol size="2">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                        
                                    <IonItem detail={false}
                                        lines='none'
                                        button={false}>
                                        <IonLabel position="stacked" className="input-label" color="secondary">
                                        {t("name")}
                                            </IonLabel>

                                        <IonInput type="text"
                                            value={searchText}
                                            placeholder={`\uD83D\uDD0D\u00A0\u00A0\u00A0${t("name")}`}
                                            onIonInput={
                                                (e) => setSearchText(e.detail.value!)
                                        } />
                                                
                                     
                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        
                    </IonRow>
                </IonGrid>

                <IonGrid className="ion-no-padding mt-10 mb-60">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={() => handleSubmit() }
                                color="primary"
                                size="small"
                                className='ion-text-wrap'>{t("view_tasks")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <div className="ion-table mt-10">

                        <IonRow className="table-title ion-padding">
                            <IonCol sizeLg="4" size="8" className='table-heading'>
                                <IonIcon 
                                    icon={checkboxOutline}
                                    color="light"
                                    className='table-icon'
                                    />
                                    <IonText className='ion-margin-start'>{t("title")}</IonText>
                            </IonCol>

                            <IonCol sizeLg="2" size="0" className='table-heading ion-hide-lg-down'>
                            {t("current_status")}
                            </IonCol>
                            <IonCol sizeLg="3" size="0" className='table-heading ion-hide-lg-down'>
                            {t("employee")}
                            </IonCol>
                            <IonCol sizeLg="2" size="3" className='table-heading'>
                            {t("dates")}
                            </IonCol>
                            <IonCol size="1">
                                <IonIcon 
                                    icon={chatboxEllipses}
                                    color="light"
                                    className='table-icon'
                                    />
                            </IonCol>
                        </IonRow>
                        <IonRadioGroup value={current} onIonChange={
                                    (e) => setCurrent(e.detail.value!)
                            }>

                            {(chats.length > 0) ? (chats.map((chat, i) => {
                                return (
                                    <IonRow key={chat._id} className={((i%2) === 0) ? 'row-table ion-padding-horizontal' : 'row-table-alt ion-padding-horizontal'}
                                    onClick={
                                        () => setCurrent(chat)
                                    }
                                    onDoubleClick={
                                        () => history.push(`/app/task/${chat._id}`)
                                    }>
                                        
                                            <IonCol sizeLg="4" size="8" className='table-field' >
                                                <IonRow>
                                                    <IonCol size="2">
                                                        <IonRadio value={chat._id} className='table-icon' color="secondary" />
                                                    </IonCol>
                                                    <IonCol size="10">
                                                        <IonText className='text-capitalize'>{chat.title}</IonText>
                                                        <IonBadge className='ml-05' color={(chat.taskInfo.priority === "high") ? "danger" : (chat.taskInfo.priority === "medium") ? "warning" : "medium"}>
                                                         {t(chat.taskInfo.priority)}
                                                        </IonBadge>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className='ion-hide-lg-up vid-responsive-field-1 text-capitalize'>
                                                    <IonCol offset='2'>
                                                        <IonLabel>
                                                         {chat.employee.name} &#8226; {t(chat.status)}
                                                        </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                                
                                            </IonCol>
                                            
                                            <IonCol sizeLg="2" size="0" className='table-field ion-hide-lg-down'>
                                                {t(chat.status)} 
                                            </IonCol>
                                            <IonCol sizeLg="3" size="0" className='table-field ion-hide-lg-down text-capitalize'>
                                                {chat.employee.name}
                                            </IonCol>
                                            <IonCol sizeLg="2" size="3" className='table-field'>
                                                {formatDate(chat.taskInfo.startDate)} {t("to")} {formatDate(chat.taskInfo.endDate)}
                                            </IonCol>
                                            <IonCol size="1" >
                                                    <IonIcon 
                                                    icon={chatboxEllipses}
                                                    color="dark"
                                                    className='table-icon mt-10'
                                                    onClick={
                                                        () => history.push(`/app/chat/${chat._id}`)
                                                    }
                                                    />

                                            </IonCol>
                                        
                                    </IonRow>
                                )
                            })) : ( 
                            <IonRow>
                                <IonCol>
                                    <IonCard>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>{t("no_tasks")}</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            )
                        } 
                        </IonRadioGroup>
                    </div>

                    {(chats.length > 0) &&
                        <IonRow>
                            <IonCol>
                                <IonInfiniteScroll
                                    onIonInfinite={async (ev) => {
                                        await loadItems();
                                        ev.target.complete();
                                    }}
                                    >
                                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </IonCol>
                        </IonRow>
                    }

                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding-top footer-shade'>
                <IonGrid className="ion-no-padding ion-no-margin">
                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol className="ion-padding-start footer-stats">
                                    {t("showing")} {total === 0 ? t("no_records") : `1 - ${chats.length} ${t("of")} ${total}`} 
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-padding-vertical">
                                <IonCol className=''>

                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (!current)
                                                {
                                                    setMessage(t("select_task"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/task/${current._id}/edit`);
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("edit_task")}
                                    </IonButton>
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (!current)
                                                {
                                                    setMessage(t("select_task"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/task/${current._id}`);
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("view_task")}
                                    </IonButton>
                                    <IonButton 
                                        onClick={
                                            () => {
                                                if (!current)
                                                {
                                                    setMessage(t("select_project"));
                                                    setIserror(true);
                                                }
                                                else
                                                {
                                                    history.push(`/app/tasks/add/${current.taskInfo.projectID}`);
                                                }

                                            }
                                        }
                                        className="first-button ion-margin-start"
                                        fill="clear"
                                        >{t("add_task")}
                                    </IonButton>
                                
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Tasks;